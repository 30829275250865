import {Deserializable} from '../interfaces/deserializable.interface';
import {NotificationGrcData} from "./notification-grc-data.model";

export class NotificationGrc implements Deserializable {

  public nb?: number;
  public data?: NotificationGrcData[];

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }

}
