<table mat-table [dataSource]="dataSource" (matSortChange)="sortData($event)" multiTemplateDataRows matSort
       class="table mat-responsive table-hover table-striped-n3 table-bordered mat-elevation-z8 text-center">

  <ng-container matColumnDef="email">
    <th mat-header-cell *matHeaderCellDef mat-sort-header class="px-10">{{'Email' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="px-10 align-middle">
      {{element?.email}}
    </td>
  </ng-container>
  <ng-container matColumnDef="username">
    <th mat-header-cell *matHeaderCellDef mat-sort-header class="px-10">{{'Identifiant' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="px-10 align-middle">
      {{element?.username}}
    </td>
  </ng-container>
  <ng-container matColumnDef="numero_client">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Numéro client' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="px-10 align-middle">
      {{element?.numero_client}}
    </td>
  </ng-container>
  <ng-container matColumnDef="client">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Client' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="px-10 align-middle">
      {{element?.client}}
    </td>
  </ng-container>
  <ng-container matColumnDef="activer_demande">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Demande GRC' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="px-10 align-middle">
      <div *ngIf="element?.activer_demande == 'O'">
        <i class="fas fa-check text-green"></i>
      </div>
      <div *ngIf="element?.activer_demande == 'N'" >
        <i class="fas fa-times text-danger"></i>
      </div>
    </td>
  </ng-container>
  <ng-container matColumnDef="activer_message">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Réponse GRC' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="align-middle" >
      <div *ngIf="element?.activer_message == 'O'">
        <i class="fas fa-check text-green"></i>
      </div>
      <div *ngIf="element?.activer_message == 'N'" >
        <i class="fas fa-times text-danger"></i>
      </div>
    </td>
  </ng-container>
  <ng-container matColumnDef="options">
    <th mat-header-cell *matHeaderCellDef class="text-center align-middle">
      <button *ngIf="showValidateBtn && notifLength > 0" data-style="zoom-in" tourAnchor="panel.notification_grc.edit_all"
              ngbTooltip="{{'Modifier tous les paramètres' | translate }}"
              placement="left"
              container="body"
              (click)="selectEditAll()"
              class="btn btn-rounded btn-sm btn-agediss ml-1"><i class="fas fa-edit"></i>
      </button>
    </th>
    <td mat-cell *matCellDef="let element; let i = index" class="with-img align-middle text-nowrap">
      <button *ngIf="showValidateBtn" [ladda]="element.pending" data-style="zoom-in" tourAnchor="panel.notification_grc.edit"
              ngbTooltip="{{'Modifier les paramètres' | translate }}"
              placement="left"
              container="body"
              (click)="selectEdit(element.id, element.client)"
              class="btn btn-rounded btn-sm btn-agediss ml-1"><i class="fa fa-pen"></i>
      </button>
      <button *ngIf="showInfosBtn && this.innerWidth < 850"
              ngbTooltip="{{'Informations complémentaires' | translate }}"
              placement="left"
              container="body"
              class="btn btn-sm btn-agediss ml-1"
              (click)="expandedElement = expandedElement === element ? null : element">
        <i class="fas fa-angle-double-down"></i>
      </button>
    </td>
  </ng-container>
  <ng-container matColumnDef="noData">
    <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length" innerHTML="{{'Aucun paramétrage' | translate }}"></td>
  </ng-container>
  <ng-container matColumnDef="loading">
    <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length" innerHTML="{{'Chargement en cours' | translate }} <i class='fas fa-spin fa-circle-notch'></i>"></td>
  </ng-container>

  <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
  <ng-container matColumnDef="expandedDetail">
    <td class="table-td p-0" mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">

      <div class="table-detail"
           *ngIf="this.innerWidth < 768"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description">
          <strong>{{'Email' | translate }} : </strong><span>{{element?.email}}</span>
        </div>
      </div>
      <div class="table-detail"
           *ngIf="this.innerWidth < 850"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description">
          <strong>{{'Identifiant' | translate }} : </strong><span>{{element?.username}}</span>
        </div>
      </div>
    </td>
  </ng-container>

  <tr mat-header-row style="height: 15px;" *matHeaderRowDef="displayedColumns;  sticky: true"></tr>
  <tr mat-row *matRowDef="let element; columns: displayedColumns;" [hidden]="loading"
      class="table-row overflow-scroll"
      [class.expanded-row]="expandedElement === element">
  </tr>
  <tr mat-footer-row *matFooterRowDef="['noData']" [hidden]="dataSource && dataSource.data.length > 0 || loading"></tr>
  <tr mat-footer-row *matFooterRowDef="['loading']" [hidden]="!loading"></tr>
  <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="table-detail-row"></tr>
</table>

<mat-paginator [length]="length"  [pageIndex]="pageIndex" (page)="emit($event)" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
               showFirstLastButtons></mat-paginator>
