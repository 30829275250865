import {Component, Input, OnInit} from '@angular/core';
import {Stats} from '../../../shared/models/api/stats.model';

@Component({
  selector: 'app-nps-stats',
  templateUrl: './nps-stats.component.html',
  styleUrls: ['./nps-stats.component.scss']
})
export class NpsStatsComponent implements OnInit {

  @Input() loadingData: boolean = false;
  @Input() loadingCharts: boolean = false;
  @Input() stats: Stats;
  @Input() multi;

  view: any[];

  colorScheme = {
    domain: ['#ffffff']
  };

  // options for the chart
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = false;
  showGridLines = false;
  showXAxisLabel = true;
  showYAxisLabel = true;
  timeline = true;

  onSelect(event) {
    console.log(event);
  }

  constructor() { }

  ngOnInit() {
  }
}
