import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {map} from "rxjs/operators";
import {environment} from '../../../../environments/environment';
import {TypeDemande} from '../../models/api/type-demande.model';


@Injectable({
  providedIn: 'root'
})
export class TypeDemandeService {


  urlRessource = environment.url_api_espace_client + "/type-demande-grc";

  constructor(private http: HttpClient) {

  }

  getTypeDemandesClient(): Observable<TypeDemande[]> {
    return this.http
      .get<TypeDemande[]>(`${this.urlRessource}/client`)
      .pipe(map((observe) => observe.map(function (observe) {
        return new TypeDemande().deserialize(observe);
      })));
  }

  getTypeDemandesClientToGrc(): Observable<TypeDemande[]> {
    return this.http
      .get<TypeDemande[]>(`${this.urlRessource}/client-to-grc`)
      .pipe(map((observe) => observe.map(function (observe) {
        return new TypeDemande().deserialize(observe);
      })))
  }

}
