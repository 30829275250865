<div class="modal-header">
  <h4 class="modal-title  w-100 text-center">{{'Ajouter une contestation' | translate }}</h4>
</div>
<div class="modal-body">
  <form [formGroup]="contestationForm">
    <div class="row m-0 form-group">
      <div class="col-12">
        <label class="col-12">{{'Objet' | translate }}
          <textarea class="form-control form-control-filter"
                    [ngClass]="{'is-invalid':f.objet.invalid && (f.objet.dirty || formSubmit) }"
                    cols="30" rows="10" formControlName="objet">
          </textarea>
          <div *ngIf="f.objet.invalid && (f.objet.dirty || formSubmit)" class=" text-center invalid-tooltip">
            {{"Veuillez renseigner l'objet de la contestation" | translate }}
          </div>
        </label>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer p-5">
  <button type="submit" class="btn btn-agediss" [ladda]="loadingBtn" data-style="zoom-in"
          (click)="submitFormAddContestation()">{{'Ouvrir' | translate }}
  </button>
  <button type="button" class="btn  bg-black-lighter text-white"
          (click)="cancel()">{{'Fermer' | translate }}</button>
</div>
