<div *ngIf="view.hasDroit('COMMANDE_MENU_LIVRAISON')">
  <div class="row m-0 padding">
    <h1 class="page-header text-center m-auto padding-h1">{{'Mes livraisons' | translate }}</h1>
  </div>

  <div class="row m-0 padding-table">
    <div class="col-12 m-0 alert alert-light fade show shadow">
      <app-filter-livraison (submitFilter)="onSubmit($event)" (onLoad)="onLoadFilter($event)"></app-filter-livraison>
    </div>
  </div>

  <div class="row m-0 padding-table" tourAnchor="tableau.livraisons">
    <div class="col-12 p-0">
      <panel noButton="true" bodyClass="no-padding panel">
        <div class="row" header>
          <div class="col-12"><h5 class="p-t-5 text-agediss">{{'Mes livraisons' | translate }} : <span
            *ngIf="!loadingNbLivraison">{{nbData}}</span> <i *ngIf="loadingNbLivraison"
                                                             class='fas fa-spin fa-circle-notch'></i></h5></div>
        </div>
        <div>
          <app-table-livraison [data]="commandesData" [length]="nbData" [pageSize]="pageSize"
                               (paginatorEvent)="onLoadDataByEvent($event)" [pageSizeOptions]="pageSizeOption"
                               [showInfosBtn]="true"
                               [loading]="loadingTableLivraison"></app-table-livraison>
        </div>
      </panel>
    </div>
  </div>
</div>
