import {Component, Input, OnInit} from '@angular/core';
import {Contact} from '../../../../shared/models/api/contact.model';

@Component({
  selector: 'app-contact-privilegie',
  templateUrl: './contact-privilegie.component.html',
  styleUrls: ['./contact-privilegie.component.scss']
})
export class ContactPrivilegieComponent implements OnInit {

  @Input() loading = false;
  @Input() contacts: Contact[];

  constructor() { }

  ngOnInit() {
  }

}
