<table mat-table [dataSource]="dataSource" (matSortChange)="sortData($event)" multiTemplateDataRows matSort
       matSortActive="id" matSortDirection="desc"
       class="table mat-responsive table-hover table-striped-n3 table-bordered mat-elevation-z8 text-center">

  <ng-container matColumnDef="voir">
    <th mat-header-cell *matHeaderCellDef class="text-center">{{'Voir' | translate }}</th>
    <td class="text-center align-middle" mat-cell *matCellDef="let element">
      <button *ngIf="showViewMessageBtn && element.messages !== null"
              placement="right" container="body" [ngbPopover]="viewConversation" triggers="mouseenter:mouseleave"
              class="btn btn-agediss btn-sm btn-rounded ml-1"
              (click)="onSelectConversation(element)">
        <i class="fa fa-comments"></i>
      </button>
      <div *ngIf="showViewMessageBtn && element.messages === null"
           placement="right" container="body" [ngbPopover]="noMessage" triggers="mouseenter:mouseleave"
           class="btn glow text-white btn-sm btn-rounded ml-1">
        <i class="fas fa-question-circle"></i>
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="id">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      {{'Id' | translate }}
    </th>
    <td mat-cell *matCellDef="let element" class="align-middle">
      {{element?.id}}
    </td>
  </ng-container>

  <ng-container matColumnDef="ord_id">
    <th mat-header-cell *matHeaderCellDef mat-sort-header style="min-width: 50px; max-width: 100px">
      {{'BL' | translate }}
    </th>
    <td mat-cell *matCellDef="let element" class="align-middle">
      <a [routerLink]="['/','livraisons', element.ord_id]" class="btn btn-icon btn-danger">
        {{element?.ord_id}}
      </a>
    </td>
  </ng-container>

  <ng-container matColumnDef="numero_commande">
    <th mat-header-cell *matHeaderCellDef mat-sort-header style="min-width: 50px; max-width: 100px">
      {{'Commande' | translate }}
    </th>
    <td mat-cell *matCellDef="let element" class="align-middle">
      {{element?.numero_commande}}
    </td>
  </ng-container>

  <ng-container matColumnDef="description">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Description' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="align-middle text-truncate" style="max-width: 100px">
      <div>
        <ng-template #description>
          <div class="p-0">
            <span>{{element?.description  | titlecase}}</span>
          </div>
        </ng-template>
        <div class="text-truncate cursor-pointer" placement="top" container="body" [ngbPopover]="description">
          <span>{{element?.description  | titlecase}}</span>
        </div>
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="created_at">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Date' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="align-middle">
      {{element?.created_at}}
    </td>
  </ng-container>

  <ng-container matColumnDef="updated_at">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Dernière maj' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="align-middle">
      {{element?.updated_at }}
    </td>
  </ng-container>

  <ng-container matColumnDef="created_by">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Identifiant' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="align-middle">
      {{element?.created_by | firstLetterOfFirstnamePipe }}
    </td>
  </ng-container>

  <ng-container matColumnDef="statut">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Statut' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="align-middle text-truncate" style="max-width: 180px">
      <div
        *ngIf="element?.libelle_statut === 'ASSIGNED' && (element.assigned_id === this.view.srcClient)">

        <div placement="left" container="body" [ngbPopover]="statutGrcSiegeFr" triggers="mouseenter:mouseleave"
             class="label"
             [ngClass]="{'label-warning':element.libelle_statut === 'ASSIGNED' && (element.assigned_id === this.view.srcClient)}">
          <span>{{'En traitement' | translate }}</span>
        </div>
      </div>

      <div
        *ngIf="element?.libelle_statut === 'ASSIGNED' && element.assigned_id !== this.view.srcClient">

        <div placement="left" container="body" [ngbPopover]="statutGrcDoFr" triggers="mouseenter:mouseleave"
             class="label"
             [ngClass]="{'label-primary':element.libelle_statut === 'ASSIGNED' && element.assigned_id !== this.view.srcClient}">
          <span>{{'A traiter' | translate }}</span>
        </div>
      </div>

      <div *ngIf="element?.libelle_statut === 'RESOLVED'">
        <div placement="left" container="body" [ngbPopover]="statutGrcResolved" triggers="mouseenter:mouseleave"
             class="label {{ element?.libelle_statut | statutGrcPipe}}">
          <span *ngIf="element?.libelle_statut === 'RESOLVED'">{{'Résolue' | translate}}</span>
        </div>
      </div>

      <div *ngIf="element?.libelle_statut === 'CLOSED'">
        <div placement="left" container="body" [ngbPopover]="statutGrcClosed" triggers="mouseenter:mouseleave"
             class="label {{ element?.libelle_statut | statutGrcPipe}}">
          <span *ngIf="element?.libelle_statut === 'CLOSED'">{{'Clôturée' | translate}}</span>
        </div>
      </div>

      <div *ngIf="element?.libelle_statut === 'DELETED'">
        <div placement="left" container="body" [ngbPopover]="statutGrcDeleted" triggers="mouseenter:mouseleave"
             class="label {{ element?.libelle_statut | statutGrcPipe}}">
          <span *ngIf="element?.libelle_statut === 'DELETED'">{{'Supprimée' | translate}}</span>
        </div>
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="options">
    <th mat-header-cell *matHeaderCellDef></th>
    <td class="text-center align-middle" mat-cell *matCellDef="let element; let i = dataIndex" style="min-width: 80px">
      <button *ngIf="showFileBtn && element.doc != null"
              placement="left" container="body" [ngbPopover]="viewDocuments" triggers="mouseenter:mouseleave"
              class="btn btn-agediss btn-sm btn-rounded ml-1 mr-1"
              (click)="selectFile(element)">
        <i class="fa fa-paperclip"></i>
      </button>
      <button
        *ngIf="showDeleteDemandGrcBtn && element.deletable"
        [ladda]="element.pending"
        data-style="zoom-in"
        placement="left" container="body" [ngbPopover]="deleteGrc" triggers="mouseenter:mouseleave"
        class="btn btn-agediss btn-sm btn-rounded mr-1"
        (click)="delete(element.id)">
        <i class="fa fa-trash"></i>
      </button>
      <button *ngIf="showInfosBtn && (this.innerWidth < 1516 && element?.updated_at === null) || (this.innerWidth < 1700 && element?.updated_at !== null)"
              placement="left" container="body" [ngbPopover]="informations" triggers="mouseenter:mouseleave"
              class="button btn btn-rounded-arrow btn-sm btn-agediss"
              (click)="expandedElement = expandedElement === element ? null : element">
        <i class="fas fa-angle-double-down"></i>
      </button>
    </td>
  </ng-container>


  <ng-container matColumnDef="noData">
    <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length"
        innerHTML="{{'Aucune demande GRC' | translate }}"></td>
  </ng-container>
  <ng-container matColumnDef="loading">
    <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length"
        innerHTML="{{'Chargement en cours' | translate }} <i class='fas fa-spin fa-circle-notch'></i>"></td>
  </ng-container>

  <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
  <ng-container matColumnDef="expandedDetail">
    <td class="table-td p-0" mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">

      <div class="table-detail"
           *ngIf="this.innerWidth < 410 && element?.numero_avis !== null"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description">
          <strong>{{'BL' | translate }} : </strong><span>{{element?.numero_avis }}</span>
        </div>
      </div>

      <div class="table-detail"
           *ngIf="this.innerWidth < 540 && element?.numero_commande !== null"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description">
          <strong>{{'Commande' | translate }} : </strong><span>{{element?.numero_commande }}</span>
        </div>
      </div>

      <div class="table-detail"
           *ngIf="this.innerWidth < 970 && element?.description !== null"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description">
          <strong>{{'Description' | translate }} : </strong><span>{{element?.description | titlecase}}</span>
        </div>
      </div>

      <div class="table-detail"
           *ngIf="this.innerWidth < 1516 && element?.created_at !== null"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description">
          <strong>{{'Date' | translate }} : </strong><span>{{element?.created_at}}</span>
        </div>
      </div>

      <div class="table-detail"
           *ngIf="this.innerWidth < 1700 && element?.updated_at !== null"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description">
          <strong>{{'Dernière maj' | translate }} : </strong><span>{{element?.updated_at}}</span>
        </div>
      </div>

      <div class="table-detail"
           *ngIf="this.innerWidth < 1360 && element?.created_by !== null"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description">
          <strong>{{'Identifiant' | translate }}
            : </strong><span>{{element?.created_by | firstLetterOfFirstnamePipe}}</span>
        </div>
      </div>

      <div class="table-detail"
           *ngIf="this.innerWidth < 1095"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description">
          <strong>{{'Statut' | translate }} : </strong>
          <span
            *ngIf="element?.libelle_statut === 'ASSIGNED' && (element.assigned_id === this.view.srcClient)">
              <div placement="top" container="body" [ngbPopover]="statutGrcSiegeFr" triggers="mouseenter:mouseleave"
                   class="label"
                   [ngClass]="{'label-warning':element.libelle_statut === 'ASSIGNED' && (element.assigned_id === this.view.srcClient)}">
                <span>{{'En traitement' | translate }}</span>
              </div>
          </span>

          <span
            *ngIf="element?.libelle_statut === 'ASSIGNED' && element.assigned_id !== this.view.srcClient">
              <div placement="top" container="body" [ngbPopover]="statutGrcDoFr" triggers="mouseenter:mouseleave"
                   class="label"
                   [ngClass]="{'label-primary':element.libelle_statut === 'ASSIGNED' && element.assigned_id !== this.view.srcClient}">
                <span>{{'A traiter' | translate }}</span>
              </div>
            </span>

          <span *ngIf="element?.libelle_statut === 'RESOLVED'">
              <div placement="top" container="body" [ngbPopover]="statutGrcResolved" triggers="mouseenter:mouseleave"
                   class="label {{ element?.libelle_statut | statutGrcPipe}}">
                <span *ngIf="element?.libelle_statut === 'RESOLVED'">{{'Résolue' | translate}}</span>
              </div>
            </span>

          <span *ngIf="element?.libelle_statut === 'CLOSED'">
              <div placement="top" container="body" [ngbPopover]="statutGrcClosed" triggers="mouseenter:mouseleave"
                   class="label {{ element?.libelle_statut | statutGrcPipe}}">
                <span *ngIf="element?.libelle_statut === 'CLOSED'">{{'Clôturée' | translate}}</span>
              </div>
            </span>

          <span *ngIf="element?.libelle_statut === 'DELETED'">
              <div placement="top" container="body" [ngbPopover]="statutGrcDeleted" triggers="mouseenter:mouseleave"
                   class="label {{ element?.libelle_statut | statutGrcPipe}}">
                <span *ngIf="element?.libelle_statut === 'DELETED'">{{'Supprimée' | translate}}</span>
              </div>
            </span>
        </div>
      </div>
    </td>
  </ng-container>

  <tr mat-header-row style="height: 15px;" *matHeaderRowDef="displayedColumns;  sticky: true"></tr>
  <tr mat-row *matRowDef="let element; columns: displayedColumns;" [hidden]="loading"
      class="table-row overflow-scroll"
      [class.expanded-row]="expandedElement === element">
  </tr>
  <tr mat-footer-row *matFooterRowDef="['noData']"
      [hidden]="dataSource && dataSource.data.length > 0 || loading"></tr>
  <tr mat-footer-row *matFooterRowDef="['loading']" [hidden]="!loading"></tr>
  <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="table-detail-row"></tr>
</table>

<mat-paginator [length]="dataSource.data.length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
               showFirstLastButtons></mat-paginator>

<ng-template #statutGrcSiegeFr>
  <div class="p-0">
    <span>{{'Agediss doit vous répondre' | translate }}</span>
  </div>
</ng-template>

<ng-template #statutGrcDoFr>
  <div class="p-0">
    <span>{{'Agediss attend une réponse' | translate }}</span>
  </div>
</ng-template>

<ng-template #statutGrcResolved>
  <div class="p-0">
    <span>{{'La conversation est résolue' | translate }}</span>
  </div>
</ng-template>

<ng-template #statutGrcClosed>
  <div class="p-0">
    <span>{{'Cette conversation est clôturée' | translate }}</span>
  </div>
</ng-template>

<ng-template #statutGrcDeleted>
  <div class="p-0">
    <span>{{'La conversation est archivée' | translate }}</span>
  </div>
</ng-template>

<ng-template #noMessage>
  <div class="p-0">
    <span>{{'La demande GRC ne contient aucun message' | translate }}. </span><span>{{'Veuillez contacter le service client Agediss' | translate }}</span>
  </div>
</ng-template>

<ng-template #viewConversation>
  <div class="p-0">
    <span>{{'Visualisation de la conversation' | translate }}</span>
  </div>
</ng-template>

<ng-template #deleteGrc>
  <div class="p-0">
    <span>{{'Supprimer la demande GRC' | translate }}</span>
  </div>
</ng-template>

<ng-template #viewDocuments>
  <div class="p-0">
    <span>{{'Visualisation des documents' | translate }}</span>
  </div>
</ng-template>

<ng-template #informations>
  <div class="p-0">
    <span>{{'Informations complémentaires' | translate }}</span>
  </div>
</ng-template>
