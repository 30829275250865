import {Deserializable} from '../interfaces/deserializable.interface';
import {GrcData} from "./grc-data.model";

export class Grc implements Deserializable {

  public nb?: number;
  public data?: GrcData[];

  deserialize(input: any): this {
    Object.assign(this, input);
    return this
  }

}
