import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {Subscription} from 'rxjs';
import {Medias} from '../../../../shared/models/api/medias.model';
import {MediasService} from '../../../../shared/services/api/media/medias.service';
import {View} from '../../../../shared/models/view.model';
import {AuthenticationService} from '../../../../shared/services/authentication.service';
import {TranslateService} from '@ngx-translate/core';
import {CommandeService} from "../../../../shared/services/api/commande.service";
import {PieceJointeGed} from "../../../../shared/models/api/ged/piece-jointe-ged.model";
import {CommandeData} from "../../../../shared/models/api/commande-data.model";

@Component({
  selector: 'app-media-content',
  styleUrls: ['./media-content.scss'],
  templateUrl: './media-content.html'
})

export class MediaContent implements OnInit, OnDestroy {

  @Input() commandeId: number;
  @Input() commande: CommandeData;

  public media: Medias;
  public activModal: NgbModalRef;
  public pieceJointe: PieceJointeGed = null;
  public view: View;

  // tableaux
  public subscriptions: Subscription[] = [];
  public medias: Medias[];
  public pieceJointes: PieceJointeGed[];
  public pageSizeOption = [5, 10, 20];

  //number
  public nbData: number = 0;
  public pageSize: number = 10;

  // loading
  public loadingTableGed = false;
  public loadingTableMessage: boolean = false;

  constructor(private translate: TranslateService,
              private modalSvc: NgbModal,
              private authSvc: AuthenticationService,
              private commandeSvc: CommandeService,
              private mediaSvc: MediasService) {
  }

  ngOnInit() {
    this.authSvc.currentView.subscribe(x => this.view = x);
    this.subscriptions.push(this.translate.onLangChange.subscribe(() => {
      this.loadDataMessage();
      this.loadDataDocuments();
    }))
    this.loadDataMessage();
    this.loadDataDocuments();
  }

  loadDataMessage() {
    this.loadingTableMessage = true;
    this.medias = [];
    this.subscriptions.push(this.mediaSvc.getMedias(this.commande.numero_avis).subscribe(medias => {
        this.medias = medias;
        this.loadingTableMessage = false;
      },
      () => {
        this.loadingTableMessage = false;
      }));
  }

  loadDataDocuments() {
    this.loadingTableGed = true;
    this.pieceJointes = [];
    let valeur = this.commande.numero_avis;
    if (this.commande.reference_commande != '') {
      valeur = this.commande.reference_commande;
    }
    this.subscriptions.push(this.commandeSvc.getPieceJointes(valeur).subscribe((pieceJointes) => {
        this.pieceJointes = pieceJointes;
        this.loadingTableGed = false;
      },
      () => {
        this.loadingTableGed = false;
      }));
  }

  openModalVisualisationMessage(modalName: any, media: Medias) {
    this.media = media;
    this.activModal = this.modalSvc.open(modalName, {
      size: 'lg',
    });
  }

  openModalVisualisationFile(modalName: any, pieceJointe: PieceJointeGed) {
    this.pieceJointe = pieceJointe
    this.activModal = this.modalSvc.open(modalName, {
      size: 'lg',
    });
  }

  openModalUpload(modalName: any) {
    this.activModal = this.modalSvc.open(modalName, {
      size: 'xl', keyboard: false
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

}
