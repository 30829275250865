import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {Subscription} from 'rxjs';
import {LitigeService} from '../../../../shared/services/api/litige.service';
import {Conversation} from '../../../../shared/models/api/conversation.model';
import {ToastrService} from 'ngx-toastr';
import {View} from '../../../../shared/models/view.model';
import {AuthenticationService} from '../../../../shared/services/authentication.service';
import {TranslateService} from '@ngx-translate/core';
import {ConversationData} from "../../../../shared/models/api/conversation-data.model";
import {PageEvent} from "@angular/material/paginator";
import {FilterMultiCritereModel} from "../../../../shared/models/filters/filterMultiCritere.model";
import {PaginationService} from "../../../../shared/services/pagination.service";

@Component({
  selector: 'app-litige-conversation-content',
  templateUrl: './litige-conversation-content.html',
  styleUrls: ['./litige-conversation-content.scss']
})
export class LitigeConversationContent implements OnInit {

  @Input() litigeId: number;

  @ViewChild('modal_conversation', {static: true}) modalConversation: ElementRef;

  public filter: FilterMultiCritereModel = new FilterMultiCritereModel();

  public selectConversation: number = null;
  public activModal: NgbModalRef;
  public view: View;

  // loader
  public loadingPage = false;
  public loadingTableConversation: boolean = false;
  public loadingNbConversation = false;
  public loadingAddConversation: boolean = false;

  // tableaux
  public conversations: Conversation[];
  public conversationsData: ConversationData[];
  public pageSizeOption = [5, 10, 20];
  public subscriptions: Subscription[] = [];

  // number
  public pageSize = 5;
  public nbData: number = 0;

  constructor(private translate: TranslateService,
              private modalSvc: NgbModal,
              private litigeSvc: LitigeService,
              private toastr: ToastrService,
              private paginationSvc: PaginationService,
              private authSvc: AuthenticationService) {
    this.paginationSvc.defaultFilterPagination(this.filter, this.pageSize)
  }

  ngOnInit() {
    this.authSvc.currentView.subscribe(x => this.view = x);
    this.subscriptions.push(this.translate.onLangChange.subscribe(() => {
      this.loadDataConversation();
    }))
    this.loadDataConversation();
  }

  /**
   * on recupère toutes les conversations en fonction de l'id du litige
   */
  loadDataConversation() {
    this.loadingTableConversation = true;
    this.loadingNbConversation = true;
    this.subscriptions.push(this.litigeSvc.getConversations(this.filter, this.litigeId).subscribe(conversation => {
      this.conversationsData = conversation.data;
      this.nbData = conversation.nb;
      this.loadingTableConversation = false;
      this.loadingNbConversation = false;
      },
      (error) => {
        this.loadingTableConversation = false;
        this.loadingNbConversation = false;
      }
    ));
  }

  /**
   * On ajoute une conversation au litige
   */
  openModalAddConversation() {
    if (this.litigeId) {
      this.loadingAddConversation = true;
      const sub = this.litigeSvc.postConversation(this.litigeId, <ConversationData>{context: 'DO|LI'}).subscribe((conversation) => {
        this.loadingAddConversation = false;
        this.toastr.success(
          this.translate.instant("Une nouvelle conversation a été créée"),
          this.translate.instant("Informations"),
          {progressBar: true}
        );
        this.openModalConversation(this.modalConversation, {id: conversation.id});
        sub.unsubscribe();
      });
      return;
    }

  }

  /**
   * On affiche les messages de la conversation
   * @param modalName
   * @param value
   */
  openModalConversation(modalName, value: { id: number }) {
    this.selectConversation = value.id;
    this.activModal = this.modalSvc.open(modalName, {
      size: 'xl', keyboard: false
    });
  }

  onLoadDataByEvent(event: PageEvent) {
    this.filter.limit = event.pageSize
    this.filter.offset = event.pageIndex ? event.pageIndex * event.pageSize : 0
    this.loadDataConversation();
  }

  ngOnDestroy(){
    this.subscriptions.forEach(s => s.unsubscribe());
  }


}
