<div class="error">
  <div class="error-code m-b-10">401</div>
  <div class="error-content">
    <div class="error-message">{{'Accès non autorisé' | translate }}</div>
    <div class="error-desc m-b-30">
    </div>
    <div>
      <a [href]="url" class="btn btn-agediss-inverse p-l-20 p-r-20">{{'Retourner à la liste des applications' | translate }}</a>
    </div>

    <br/>

    <div>
      <img src="../../../../assets/img/logo.svg" alt="Logo" class="logo-img">
      <h3 class="text-white">{{'Espace client' | translate }}</h3>
    </div>
  </div>
</div>
