import {Component, Input, OnInit} from '@angular/core';
import {Contestation} from '../../../shared/models/api/contestation.model';
import {ContestationData} from "../../../shared/models/api/contestation-data.model";

@Component({
  selector: 'app-commande-contestation',
  templateUrl: './commande-contestation.component.html',
  styleUrls: ['./commande-contestation.component.scss']
})
export class CommandeContestationComponent implements OnInit {

  @Input() contestationData: ContestationData;
  @Input() loading = false;
  public loadingPage = false;


  constructor() { }

  ngOnInit() {
    if(!this.contestationData) {
      throw new Error('Le composent contestation attend un objet de type contestation.')
    }
  }

}
