<div *ngIf="loadingPage" style="height: 500px" class="modal-body">
  <div class="spinner"></div>
</div>
<div *ngIf="!loadingPage">
  <div>
    <div class="modal-header">
      <h4 class="modal-title w-100 text-center">{{ pieceJointeData.libelle }}</h4>
    </div>
    <div class="modal-body">
      <app-file-preview [pieceJointeData]="pieceJointeData" [piecesJointesGed]="piecesJointesGed" [currentType]="currentType" [showImage]="showImage" [urlPreview]="urlPreview" [loadingPage]="loadingPage"></app-file-preview>
    </div>
  </div>
  <div class="modal-footer p-5">
    <button type="button" class="btn  bg-black-lighter text-white"
            (click)="cancel()">Fermer
    </button>
  </div>
</div>
