<!-- begin #header -->
<div class="header navbar-default" id="header">
  <!-- begin navbar-header -->
  <div class="navbar-header">
    <ng-container>
      <button (click)="mobileSidebarToggle()" class="navbar-toggle pull-left" type="button">
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
      </button>
      <button (click)="mobileRightSidebarToggle()" class="f-s-14 navbar-toggle pull-right" type="button">
        <div *ngIf="loadingNbNotifs" class="spinner-border spinner-border-sm text-white"></div>
        <div *ngIf="!loadingNbNotifs">
          <i class="fa fa-bell text-white"></i>
          <span class="label label-badge bg-primary">{{notifLength}}</span>
        </div>
      </button>
      <a tourAnchor="menu.agediss" class="navbar-brand d-none d-md-block bg-agediss"
         href="{{urlsso}}"><img src="../../../../assets/img/logo.svg" alt="Logo" class="logo-img">
        <span class="pl-5 py-2 size-title-header">{{'Espace client' | translate }}</span>
        <span class="size-title-header text-truncate" *ngIf="view">
        > {{view.libelle}}
      </span>
      </a>
      <a class="navbar-brand d-block d-md-none bg-agediss"
         href="{{urlsso}}"><img src="../../../../assets/img/logo.svg" alt="Logo" class="logo-img">
      </a>
      <ul class="navbar-nav navbar-left pl-1">
        <li class="p-0 d-block d-md-none">
          <a class="p-0 text-truncate color-client"
             href="{{urlsso}}">{{'Espace client' | translate }} > {{view.libelle}}
          </a>
        </li>
      </ul>
    </ng-container>
  </div>
  <!-- end navbar-header -->

  <!-- begin mega-menu -->
  <div *ngIf="this.pageSettings.pageMegaMenu"
       [ngStyle]="{ 'display': (this.pageSettings.pageMegaMenu && this.pageSettings.pageMobileMegaMenuToggled) ? 'block' : '' }"
       class="collapse navbar-collapse pull-left">
    <ul class="nav navbar-nav">
      <li class="dropdown dropdown-lg" ngbDropdown>
        <a class="dropdown-toggle" href="javascript:" ngbDropdownToggle><i class="fa fa-th-large fa-fw"></i> Mega <b
          class="caret"></b></a>
        <div class="dropdown-menu dropdown-menu-lg" ngbDropdownMenu>
          Mega menu content here
        </div>
      </li>
      <li>
        <a href="javascript:">
          <i class="fa fa-gem fa-fw"></i> Client
        </a>
      </li>
      <li class="dropdown" ngbDropdown>
        <a class="dropdown-toggle" href="javascript:" ngbDropdownToggle>
          <i class="fa fa-database fa-fw"></i> New <b class="caret"></b>
        </a>
        <ul class="dropdown-menu" ngbDropdownMenu>
          <li><a href="javascript:">Action</a></li>
          <li><a href="javascript:">Another action</a></li>
          <li><a href="javascript:">Something else here</a></li>
          <li class="divider"></li>
          <li><a href="javascript:">Separated link</a></li>
          <li class="divider"></li>
          <li><a href="javascript:">One more separated link</a></li>
        </ul>
      </li>
    </ul>
  </div>
  <!-- end mega-menu -->

  <!-- begin header-nav -->
  <ul class="navbar-nav navbar-right bg-white">

    <li class="divider d-none d-md-block"></li>
    <li class="d-none d-md-block">
      <a class="font-weight-bold cursor-pointer" (click)="tour()" tourAnchor="start.tour"><i
        class="fa fa-play icon-agediss"></i> {{'Tutoriel' | translate }}</a>
    </li>
    <!-- langue -->
    <li class="divider d-block"></li>
    <li class="dropdown navbar-user" ngbDropdown tourAnchor="menu.langue">
      <a class="text-decoration-none" href="javascript:" ngbDropdownToggle>
        <span class="d-inline font-weight-bold"><img [src]="'../../../../assets/img/flags/'+changeLang()+'.svg'" [alt]="changeLang()" class="logo-img"
                                                            style="width: 45px; border: 0!important;"></span>
      </a>
      <div class="dropdown-menu dropdown-menu-right p-0" ngbDropdownMenu style="margin-top: 20px">
        <div *ngFor="let locale of locales">
          <a *ngIf="locale.code !== changeLang()" class="dropdown-item text-dark font-weight-bold cursor-pointer my-3" (click)="switchLang(locale.code)">
            <img [src]="'../../../../assets/img/flags/'+locale.code+'.svg'" alt="{{locale.code}}" class="logo-img"
                 style="width: 40px; border: 0!important; margin-right: 10px">
            <span>{{ locale.libelle | translate }}</span>
          </a>
        </div>
      </div>
    </li>

    <!-- utilisateur -->
    <li class="divider d-block"></li>
    <li class="dropdown navbar-user" ngbDropdown tourAnchor="menu.parametres">
      <a class="dropdown-toggle" href="javascript:" ngbDropdownToggle>
        <div class="image image-icon bg-black text-grey-darker">
          <i class="fa fa-user"></i>
        </div>
        <span class="d-inline font-weight-bold"> {{currentUser.getFullname()}}</span> <b class="caret"></b>
      </a>
      <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
        <div *ngIf="view.hasDroit('ADMINISTRATION_MENU_GESTION-UTILISATEUR')">
          <a class="dropdown-item font-weight-bold" [routerLink]="['/','administration']"><i
            class="fa fa-users-cog icon-agediss"></i> {{'Gestion des utilisateurs' | translate }}</a>
          <div class="dropdown-divider"></div>
        </div>
        <div *ngIf="view.hasDroit('UTILISATEUR_MENU_PARAMETRE')">
          <a class="dropdown-item font-weight-bold" [routerLink]="['/','paramètres']"><i
            class="fa fa-cog icon-agediss"></i> {{'Mes paramètres' | translate }}</a>
          <div class="dropdown-divider"></div>
        </div>
        <div *ngIf="view.hasDroit('DOCUMENTS_MENU_ILES-ET-ALTITUDES')">
          <button class="btn btn-agediss btn-iles-et-altitudes" [ladda]="loadingIlesEtAltitudesBtn" data-style="zoom-in" (click)="downloadExcelFile()">
            <i class="fa fa-download"></i> Fichier Iles et Altitudes </button>
          <div class="dropdown-divider"></div>
        </div>
        <div>
          <a class="dropdown-item font-weight-bold cursor-pointer" (click)="logout(false)"><i
            class="fa fa-sign-out-alt icon-agediss fa-rotate-180"></i> {{'Déconnexion' | translate }}</a>
        </div>
      </div>
    </li>
    <li class="d-none d-md-block mr-2" tourAnchor="menu.notification" *ngIf="view.context === '3'">

      <!-- notification -->

      <a class="f-s-14" (click)="openModalNotifications(modal_notification)" href="javascript:">
        <div *ngIf="loadingNbNotifs" class="spinner-border spinner-border-sm"></div>
        <div *ngIf="!loadingNbNotifs">
          <i class="fa fa-bell"></i>
          <span *ngIf="notifLength !== null" class="label label-badge bg-primary">{{notifLength}}</span>
          <span *ngIf="notifLength === null" class="label label-badge bg-danger"><i class="fas fa-times text-white"></i></span>
        </div>
      </a>
    </li>
  </ul>
  <!-- end header navigation right -->
</div>
<!-- end #header -->
<tour-step-template>
  <ng-template let-step="step">
    <div class="scrollbar scrollbar-black bordered-black square thin overflow-scroll" style="max-height: 580px">
      <p class="tour-step-content" [innerHTML]="step?.content"></p>
    </div>
    <div class="tour-step-navigation mt-2">
      <button *ngIf="tourService.hasPrev(step)" class="btn btn-radius btn-grey-agediss mx-1"
              (click)="tourService.prev()"><i class="fas fa-chevron-left"></i></button>
      <button *ngIf="tourService.hasNext(step)" class="btn btn-radius btn-grey-agediss mx-1"
              (click)="tourService.next()"><i class="fas fa-chevron-right"></i></button>
      <button class="btn bg-black-lighter btn-radius text-white mx-1"
              (click)="tourService.end()">{{'Fermer' | translate }}</button>
    </div>
  </ng-template>
</tour-step-template>


<!-- modal notification -->
<ng-template #modal_notification let-modal>
  <app-notifications (canceled)="cancel()"></app-notifications>
</ng-template>
<!-- fin de modal notification -->
