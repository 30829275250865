import {Deserializable} from '../interfaces/deserializable.interface';
import {LitigeData} from "./litige-data.model";

export class Litige implements Deserializable {

  public nb?: number;
  public data?: LitigeData[];

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}
