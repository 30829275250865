import {Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {tableAnimation} from '../../../animations';
import {DatatableHelper} from '../../../shared/helpers/datatable.helper';
import {SuiviLivraison} from '../../../shared/models/api/suivi-livraison.model';
import {TranslateService} from '@ngx-translate/core';
import {PageEvent} from "@angular/material/paginator";
import {SuiviLivraisonData} from "../../../shared/models/api/suivi-livraison-data.model";

@Component({
  selector: 'app-table-suivi-de-livraison',
  templateUrl: './table-suivi-de-livraison.component.html',
  styleUrls: ['./table-suivi-de-livraison.component.scss'],
  animations: [tableAnimation],
})
export class TableSuiviDeLivraisonComponent implements OnInit {

  expandedElement: SuiviLivraisonData | null;

  @Input() data: SuiviLivraisonData[];
  @Input() displayedColumns: string[] = [
    'numero_avis',
    'reference_commande',
    'numero_commande',
    'numero_commande_2',
    'destinataire_nom',
    'destinataire_code_postal',
    'destinataire_localite',
    'date',
    'activite',
    'statut',
    'options'
  ];
  @Input() loading = false;
  @Input() hiddenColumns: string[] = [];
  @Input() length: number;
  @Input() pageSize: number = 10;
  @Input() pageIndex: number;
  @Input() pageEvent: PageEvent;
  @Input() pageSizeOptions: number[] = [5, 10, 20];
  @Input() showInfosBtn: boolean = false;

  @Output() paginatorEvent : EventEmitter<PageEvent> = new EventEmitter<PageEvent>()

  public innerWidth: number;
  public dataSource: any;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private translate: TranslateService) {
  }

  ngOnInit() {
    this.onResizeTable();
    this.dataSource = new MatTableDataSource<SuiviLivraison>(this.data);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    if (this.hiddenColumns.length > 0) {
      this.hiddenColumns.forEach((value) => {
        let index = this.displayedColumns.indexOf(value);
        if (index) {
          this.displayedColumns.splice(index, 1)
        }
      })
    }
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  emit($event: PageEvent) {
    return this.paginatorEvent.emit($event);
  }

  /**
   * LifeHook : à chaque changement de value de data on rafraichi les données du tableau
   * @param changes
   */
  ngOnChanges(changes: any) {
    if (changes.data) {
      this.dataSource = new MatTableDataSource(changes.data.currentValue);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
    if (changes.loading) {
      if (changes.data) {
        this.dataSource = new MatTableDataSource(changes.data.currentValue);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
      if (changes.loading) {
        this.loading = changes.loading.currentValue;
      }
    }
  }

  /**
   * Permet d'ordonner les colones.
   */
  sortData(sort: Sort) {
    const data = this.data.slice();
    if (!sort.active || sort.direction === '') {
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      return;
    }
    this.dataSource = new MatTableDataSource(data.sort(
      (a, b) => {
        const isAsc = sort.direction === 'asc';
        if (sort.active in a) {
          return DatatableHelper.compare(a[sort.active], b[sort.active], isAsc);
        }
        return 0;
      }));
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }


  @HostListener('window:resize', ['$event'])
  onResizeTable() {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth < 400) {
      this.displayedColumns = [
        'numero_avis',
        'reference_commande',
        'options'
      ];
    } else if (this.innerWidth > 400 && this.innerWidth <= 500) {
      this.displayedColumns = [
        'numero_avis',
        'reference_commande',
        'numero_commande',
        'options'
      ];
    } else if (this.innerWidth > 500 && this.innerWidth <= 660) {
      this.displayedColumns = [
        'numero_avis',
        'reference_commande',
        'numero_commande',
        'destinataire_nom',
        'options'
      ];
    } else if (this.innerWidth > 660 && this.innerWidth <= 767) {
      this.displayedColumns = [
        'numero_avis',
        'reference_commande',
        'numero_commande',
        'destinataire_nom',
        'destinataire_localite',
        'options'
      ];
    } else if (this.innerWidth > 767 && this.innerWidth <= 877) {
      this.displayedColumns = [
        'numero_avis',
        'reference_commande',
        'numero_commande',
        'destinataire_nom',
        'options'
      ];
    } else if (this.innerWidth > 877 && this.innerWidth <= 1051) {
      this.displayedColumns = [
        'numero_avis',
        'numero_commande',
        'destinataire_nom',
        'destinataire_localite',
        'statut',
        'options'
      ];
    } else if (this.innerWidth > 1051 && this.innerWidth <= 1211) {
      this.displayedColumns = [
        'numero_avis',
        'reference_commande',
        'numero_commande',
        'destinataire_nom',
        'destinataire_localite',
        'options'
      ];
    } else if (this.innerWidth > 1051 && this.innerWidth <= 1240) {
      this.displayedColumns = [
        'numero_avis',
        'reference_commande',
        'numero_commande',
        'destinataire_nom',
        'destinataire_code_postal',
        'destinataire_localite',
        'statut',
        'options'
      ];
    } else if (this.innerWidth > 1240 && this.innerWidth <= 1400) {
      this.displayedColumns = [
        'numero_avis',
        'reference_commande',
        'numero_commande',
        'numero_commande_2',
        'destinataire_nom',
        'destinataire_localite',
        'statut',
        'options'
      ];
    } else if (this.innerWidth > 1400) {
      this.displayedColumns = [
        'numero_avis',
        'reference_commande',
        'numero_commande',
        'numero_commande_2',
        'destinataire_nom',
        'destinataire_localite',
        'date',
        'statut',
      ];
    }
  }


}
