import {Component, OnDestroy} from '@angular/core';
import pageSettings from '../../../config/page-settings';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-page401',
  templateUrl: './page401.html',
  styleUrls: ['./page401.scss']
})
export class Page401Page implements OnDestroy {

  pageSettings = pageSettings;

  public url: string = environment.urlsso;

  constructor() {
    this.pageSettings.pageEmpty = true;
  }

  ngOnDestroy() {
    this.pageSettings.pageEmpty = false;
  }

}
