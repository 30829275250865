import {Deserializable} from '../interfaces/deserializable.interface';

export class AnomalieModel implements Deserializable {

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }

}
