import {Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild} from '@angular/core';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {Historique} from '../../../../shared/models/api/historique.model';
import {DatatableHelper} from '../../../../shared/helpers/datatable.helper';
import {tableAnimation} from '../../../../animations';
import {MatPaginator, PageEvent} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {HistoriqueData} from "../../../../shared/models/api/historique-data.model";
import {LitigeData} from "../../../../shared/models/api/litige-data.model";

@Component({
  selector: 'app-table-historique',
  templateUrl: './table-historique.component.html',
  styleUrls: ['./table-historique.component.scss'],
  animations: [tableAnimation],
})
export class TableHistoriqueComponent implements OnInit {

  expandedElement: HistoriqueData | null;

  @Output() selectShowDocument: EventEmitter<{id: number, index: number}> = new EventEmitter<{id: number, index: number}>();

  @Input() data: HistoriqueData[];
  @Input() displayedColumns: string[] =['libelle', 'created_at', 'emetteur','options'];
  @Input() loading = false;
  @Input() showInfosBtn: boolean = false;
  @Input() length: number;
  @Input() pageSize: number = 10;
  @Input() pageIndex: number;
  @Input() pageEvent: PageEvent;
  @Input() pageSizeOptions: number[] = [5, 10, 20];

  @Output() paginatorEvent : EventEmitter<PageEvent> = new EventEmitter<PageEvent>()

  public innerWidth: number;
  public dataSource: MatTableDataSource<HistoriqueData>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  ngOnInit() {
    this.onResizeTable()
    this.dataSource = new MatTableDataSource<HistoriqueData>(this.data);
  }

  emit($event: PageEvent) {
    return this.paginatorEvent.emit($event);
  }

  /**
   * LifeHook : à chaque changement de value de data on rafraichi les données du tableau
   * @param changes
   */
  ngOnChanges(changes: any) {
    if (changes.data) {
      this.dataSource = new MatTableDataSource(changes.data.currentValue);
      this.dataSource.sort = this.sort;
    }
    if (changes.loading) {
      if (changes.data) {
        this.dataSource = new MatTableDataSource(changes.data.currentValue);
        this.dataSource.sort = this.sort;
      }
      if (changes.loading) {
        this.loading = changes.loading.currentValue;
      }
    }
  }

  /**
   * Permet d'ordonner les colones.
   * @param sort
   */
  sortData(sort: Sort) {
    const data = this.data.slice();
    if (!sort.active || sort.direction === '') {
      this.dataSource = new MatTableDataSource(data);
      return;
    }
    this.dataSource = new MatTableDataSource(data.sort(
      (a, b) => {
        const isAsc = sort.direction === 'asc';
        if (sort.active in a) {
          return DatatableHelper.compare(a[sort.active], b[sort.active], isAsc);
        }
        return 0;
      }));
  }

  @HostListener('window:resize', ['$event'])
  onResizeTable() {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth < 1020) {
      this.displayedColumns = [
        'libelle',
        'options',
      ];
    } else if (this.innerWidth > 1020 && this.innerWidth <= 1100) {
      this.displayedColumns = [
        'libelle',
        'created_at',
        'options',
      ];
    } else if (this.innerWidth > 1100) {
      this.displayedColumns = [
        'libelle',
        'created_at',
        'emetteur'
      ];
    }
  }
}
