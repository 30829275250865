import {Component, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Subscription} from "rxjs";
import {View} from "../../shared/models/view.model";
import {AuthenticationService} from "../../shared/services/authentication.service";
import {ExtractionService} from "../../shared/services/api/extraction.service";
import {FilterMultiCritereModel} from "../../shared/models/filters/filterMultiCritere.model";
import {TranslateService} from "@ngx-translate/core";
import {TableUtil} from "../../shared/utils/table.util";
import {ExtractionData} from "../../shared/models/api/extraction-data.model";
import {PaginationService} from "../../shared/services/pagination.service";
import {PageEvent} from "@angular/material/paginator";
import {Extraction} from "../../shared/models/api/extraction.model";
import {ToastrService} from "ngx-toastr";
import Swal from "sweetalert2";
import {FilterService} from "../../shared/services/filter.service";
import {Router} from "@angular/router";


@Component({
  selector: 'app-extractions',
  templateUrl: './extractions.component.html',
  styleUrls: ['./extractions.component.scss']
})
export class ExtractionsPage implements OnInit, OnDestroy {

  public filter: FilterMultiCritereModel = new FilterMultiCritereModel();

  // tableaux
  public subscriptions: Subscription[] = [];
  public extractionsData: ExtractionData[];
  public extractionsDataExcel: ExtractionData[];
  public extraction: Extraction;
  public pageSizeOption = [5, 10, 20];

  // number
  public pageSize = 10;
  public pageIndex = 0;
  public nbData: number = 0;
  public nbDataMax: number = 2000;
  public daysDifferenceSaisie: number;
  public daysDifferenceRdv: number;
  public daysDifferenceLivraison: number;

  // loading
  public loadingTableExtraction = false;
  public loadingExtractionBtn: boolean = false;
  public loadingNbExtraction = false;
  public showExpand = false;
  public loadingPanelExtraction = false;
  public showTableExtraction = false;

  public view: View;

  constructor(
    private translate: TranslateService,
    private extractionsSvc: ExtractionService,
    private paginationSvc: PaginationService,
    private toastr: ToastrService,
    private filterSvc: FilterService,
    private router: Router,
    private authSvc: AuthenticationService) {
    this.paginationSvc.defaultFilterPagination(this.filter, this.pageSize)
    this.authSvc.currentView.subscribe(x => this.view = x);
  }

  ngOnInit() {
    if (this.view.hasDroit('EXTRACTION_MENU_EXTRACTION')) {
      this.subscriptions.push(this.translate.onLangChange.subscribe(() => {
        this.loadDataExtractions();
      }))
    } else {
      this.router.navigate(['/', 'home']);
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  onSubmit(filter = null) {
    //récupère les infos du filtre
    if (filter) {
      this.filter = filter;
      this.showTableExtraction = !(this.filter.minDateSaisie == null || this.filter.maxDateSaisie == null || this.filter.extraction == null);
    }
    this.paginationSvc.defaultFilterPagination(this.filter, this.pageSize)
    this.loadDataExtractions();
  }

  /**
   * On récupère toutes les commandes liès au compte client
   */
  loadDataExtractions() {
    this.loadingTableExtraction = true;
    this.loadingNbExtraction = true;
    this.extractionsData = [];
    this.nbData = 0;
    this.subscriptions.push(this.extractionsSvc.getDataExtractions(this.filter).subscribe((extraction) => {
      this.extractionsData = extraction.data;
      this.nbData = extraction.nb;
      this.loadingTableExtraction = false;
      this.loadingNbExtraction = false;
      },
      (error) => {
        this.loadingTableExtraction = false;
        this.loadingNbExtraction = false;
      }
    ));
  }

  onLoadDataByEvent(event: PageEvent) {
    this.paginationSvc.onPaginationEvent(this.filter, event)
    this.loadDataExtractions();
  }

  exportArray() {
    this.daysDifferenceSaisie = this.filterSvc.intervalDate(this.filter.minDateSaisie, this.filter.maxDateSaisie)
    this.daysDifferenceRdv = this.filterSvc.intervalDate(this.filter.minDateRdv, this.filter.maxDateRdv)
    this.daysDifferenceLivraison = this.filterSvc.intervalDate(this.filter.minDateLivraison, this.filter.maxDateLivraison)

    if (this.daysDifferenceSaisie >= 31) {
      Swal.fire({
        title: this.translate.instant('Attention !'),
        text: this.translate.instant("L'écart des dates est très important - Le résultat de l'extraction risque d'être long", {value: this.translate.instant('Saisie')}),
        type: 'warning',
        showCancelButton: true,
        showConfirmButton: true,
        cancelButtonText: this.translate.instant('Annuler'),
        confirmButtonText: this.translate.instant('Continuer'),
      }).then((result) => {
        if (result.value) {
          this.loadDataExtractionForExcel()
        }
      })
    } else if (this.daysDifferenceRdv >= 31) {
      Swal.fire({
        title: this.translate.instant('Attention !'),
        text: this.translate.instant("L'écart des dates est très important - Le résultat de l'extraction risque d'être long", {value: this.translate.instant('Saisie')}),
        type: 'warning',
        showCancelButton: true,
        showConfirmButton: true,
        cancelButtonText: this.translate.instant('Annuler'),
        confirmButtonText: this.translate.instant('Continuer'),
      }).then((result) => {
        if (result.value) {
          this.loadDataExtractionForExcel()
        }
      })
    } else if(this.daysDifferenceLivraison >= 31) {
      Swal.fire({
        title: this.translate.instant('Attention !'),
        text: this.translate.instant("L'écart des dates est très important - Le résultat de l'extraction risque d'être long", {value: this.translate.instant('Saisie')}),
        type: 'warning',
        showCancelButton: true,
        showConfirmButton: true,
        cancelButtonText: this.translate.instant('Annuler'),
        confirmButtonText: this.translate.instant('Continuer'),
      }).then((result) => {
        if (result.value) {
          this.loadDataExtractionForExcel()
        }
      })
    } else {
      this.loadDataExtractionForExcel()
    }
    this.paginationSvc.defaultFilterPagination(this.filter, this.pageSize)
  }

   loadDataExtractionForExcel() {
     delete this.filter.limit;
     delete this.filter.offset;
     this.filter.extractExcel = true;
     this.loadingExtractionBtn = true;
     let toastInfos = this.toastr.info(this.translate.instant("Extraction en cours"), this.translate.instant('Mes extractions'), {disableTimeOut: true, positionClass: 'toast-top-right-extraction'});
     this.extractionsSvc.getDataExtractions(this.filter).subscribe((extraction) => {
       TableUtil.exportArrayToExcel(extraction.data, this.view.libelle.replace(/\/|\\|\?|\*|\[|\]/g, ' '));
       this.toastr.clear(toastInfos.toastId);
       this.loadingExtractionBtn = false;
     }, () => {
       this.toastr.clear(toastInfos.toastId);
       this.loadingExtractionBtn = false;
       this.toastr.error(this.translate.instant("Une erreur est survenue"), this.translate.instant('Mes extractions'), {timeOut: 20000, positionClass: 'toast-top-right-extraction'});
     }, () => {
       this.toastr.clear(toastInfos.toastId);
       this.loadingExtractionBtn = false;
       this.toastr.success(this.translate.instant("Extraction effectuée avec succès !"), this.translate.instant('Mes extractions'), {positionClass: 'toast-top-right-extraction'});
     });
   }

}
