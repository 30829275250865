<div *ngIf="loadingPage" class="spinner"></div>
<div *ngIf="!loadingPage">
  <div class="row m-0 pt-4">
    <div class="col-lg-12">
      <p class="text-truncate">
        <strong placement="right"
                container="body"
                ngbTooltip="{{litigeData?.client?.libelle | uppercase}}">{{'Sous-compte' | translate }} :</strong>
        <span> {{litigeData?.client?.libelle | uppercase}}</span>
      </p>
    </div>
  </div>
  <div class="row m-0">
    <div class="col-xl-6 col-lg-12 col-md-6 col-sm-12 px-2">
      <p class="text-truncate">
        <strong placement="right"
                container="body"
                ngbTooltip="{{litigeData?.numero_commande}}">{{'Commande' | translate }} n°1 :</strong>
        <span> {{litigeData?.numero_commande}}</span>
      </p>
    </div>
    <div class="col-xl-6 col-lg-12 col-md-6 col-sm-12 px-2">
      <p class="text-truncate">
        <strong placement="right"
                container="body"
                ngbTooltip="{{litigeData?.commande_data?.numero_commande_2}}">{{'Commande' | translate }} n°2: </strong>
        <span> {{litigeData?.commande_data?.numero_commande_2}}</span>
      </p>
    </div>
  </div>
  <div class="row m-0">
    <div class="col-xl-6 col-lg-12 col-md-6 col-sm-12 px-2">
      <p class="text-truncate">
        <strong placement="right"
                container="body"
                ngbTooltip="{{litigeData?.plateforme?.libelle | uppercase}}">{{'Plateforme' | translate }} :</strong>
        <span> {{litigeData?.plateforme?.libelle | uppercase}}</span>
      </p>
    </div>
    <div class="col-xl-6 col-lg-12 col-md-6 col-sm-12 px-2">
      <p class="text-truncate">
        <strong placement="right"
                container="body"
                ngbTooltip="{{litigeData?.destinataire_nom | uppercase}}">{{'Destinataire' | translate }} :</strong>
        <span> {{litigeData?.destinataire_nom | uppercase}}</span>
      </p>
    </div>
  </div>
  <div class="row m-0">
    <div class="col-lg-12">
      <p class="text-truncate">
        <strong placement="right"
                container="body"
                ngbTooltip="{{litigeData?.destinataire_adresse | uppercase}}
                          {{litigeData?.destinataire_code_postal}}
                          {{litigeData?.destinataire_ville | uppercase}}">{{'Adresse' | translate }} :</strong>
        <span>
           {{litigeData?.destinataire_adresse | uppercase}}
           {{litigeData?.destinataire_code_postal}}
           {{litigeData?.destinataire_ville | uppercase}}
        </span>
      </p>
    </div>
  </div>
  <div class="row m-0 py-3">
    <div class="offset-lg-1 col-lg-5 offset-md-1 col-md-5 offset-sm-1 col-sm-5 offset-2 col-8 p-5">
      <a *ngIf="showViewCommandeBtn" class="btn btn-radius btn-agediss btn-block text-truncate shadow"
         [routerLink]="['/','livraisons',litigeData.numero_avis]">{{'Visualisation de la commande' | translate }}
      </a>
    </div>
    <div class="offset-lg-0 col-lg-5 offset-md-0 col-md-5 offset-sm-0 col-sm-5 offset-2 col-8 p-5">
      <button *ngIf="showViewDocumentBtn" class="btn btn-radius btn-agediss btn-block text-truncate shadow"
              (click)="selectShowDocumentByCommandeId(this.litigeData.id)">{{'Visualisation des documents' | translate }}
      </button>
    </div>
  </div>
</div>
