import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {FilterMultiCritereModel} from '../../shared/models/filters/filterMultiCritere.model';
import {User} from '../../shared/models/user.model';
import {UserService} from '../../shared/services/api/sso/user.service';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {UserData} from "../../shared/models/user-data.model";
import {PaginationService} from "../../shared/services/pagination.service";
import {PageEvent} from "@angular/material/paginator";
import {View} from "../../shared/models/view.model";
import {AuthenticationService} from "../../shared/services/authentication.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-administration',
  templateUrl: './administration.html',
  styleUrls: ['./administration.scss']
})
export class AdministrationPage implements OnInit, OnDestroy {

  public filter: FilterMultiCritereModel = new FilterMultiCritereModel();

  public activModal: NgbModalRef;
  public view: View;

  //tableau
  public pageSizeOption: number[] = [5, 10, 20];
  public users: User[];
  public usersData: UserData[];
  public subscriptions: Subscription[] = [];

  // number
  public pageSize: number = 5;
  public nbUsers: number = 0;

  //loading
  public loadingTableUser: boolean = false;
  public selectUsername: string = null;
  public selectUserId: number;
  public loadingNbUsers: boolean = false;

  constructor(private translate: TranslateService,
              private userSvc: UserService,
              private authSvc: AuthenticationService,
              private paginationSvc: PaginationService,
              private router: Router,
              private modalSvc: NgbModal,) {
    this.paginationSvc.defaultFilterPagination(this.filter, this.pageSize)
    this.authSvc.currentView.subscribe(x => this.view = x);
  }

  ngOnInit() {
    if(this.view.hasDroit('ADMINISTRATION_MENU_GESTION-UTILISATEUR')) {
      this.subscriptions.push(this.translate.onLangChange.subscribe(() => {
        this.loadDataSsoUsers();
      }));
      this.loadDataSsoUsers();
    } else {
      this.router.navigate(['/','home']);
    }
  }

  ngOnDestroy(){
    this.subscriptions.forEach(s => s.unsubscribe());
  }
  /**
   * On récupère tous les litiges liès au compte client
   */
  loadDataSsoUsers() {
    this.loadingTableUser = true;
    this.loadingNbUsers = true;
    this.usersData = [];
    this.nbUsers = 0;
    this.subscriptions.push(this.userSvc.getUsers(this.filter).subscribe((user) => {
      this.usersData = user.data;
      this.nbUsers = user.nb;
      this.loadingTableUser = false;
      this.loadingNbUsers = false;
      },
      () => {
        this.loadingTableUser = false;
        this.loadingNbUsers = false;
      }
    ));
  }

  /**
   * @param event
   */
  onLoadDataByEvent(event: PageEvent) {
    this.filter.limit = event.pageSize
    this.filter.offset = event.pageIndex ? event.pageIndex * event.pageSize : 0
    this.loadDataSsoUsers();
  }

  /**
   * On ajoute un utilisateur
   * @param modalName
   */
  openModalAddUser(modalName: any) {
    this.activModal = this.modalSvc.open(modalName, {
      size: 'xl', keyboard: false
    });
  }

  /**
   * On édit les informations de l'utilisateur
   * @param modalName
   * @param value
   */
  openModalEditDataUser(modalName: any, value: { id: number, username: string }) {
    this.selectUsername = value.username;
    this.selectUserId = value.id;
    this.activModal = this.modalSvc.open(modalName, {
      size: 'xl', keyboard: false
    });
  }

}
