import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {FieldParametersModel} from "../../../shared/models/filters/field-parameters.model";
import {FilterMultiCritereModel} from "../../../shared/models/filters/filterMultiCritere.model";
import {FormControl, FormGroup} from "@angular/forms";
import {Subscription} from "rxjs";
import {TranslateService} from "@ngx-translate/core";
import {FilterService} from "../../../shared/services/filter.service";
import {FormfilterHelper} from "../../../shared/helpers/Formfilter.helper";
import {Extraction} from "../../../shared/models/api/extraction.model";
import {ExtractionService} from "../../../shared/services/api/extraction.service";
import {DatePickerMinDateComponent} from "../../shared/date-picker/date-picker-min-date/date-picker-min-date.component";
import {DatePickerMaxDateComponent} from "../../shared/date-picker/date-picker-max-date/date-picker-max-date.component";
import {DatePickerMinDateExtractionLivraisonComponent} from "../../shared/date-picker/extraction-livraison/date-picker-min-date-extraction-livraison/date-picker-min-date-extraction-livraison.component";
import {DatePickerMaxDateExtractionLivraisonComponent} from "../../shared/date-picker/extraction-livraison/date-picker-max-date-extraction-livraison/date-picker-max-date-extraction-livraison.component";
import {DatePickerMinDateExtractionRdvComponent} from "../../shared/date-picker/extraction-rdv/date-picker-min-date-extraction-rdv/date-picker-min-date-extraction-rdv.component";
import {DatePickerMaxDateExtractionRdvComponent} from "../../shared/date-picker/extraction-rdv/date-picker-max-date-extraction-rdv/date-picker-max-date-extraction-rdv.component";
import {Client} from "../../../shared/models/api/client.model";
import Swal from "sweetalert2";
import {Activite} from "../../../shared/models/api/activite.model";
import {ActiviteService} from "../../../shared/services/api/activite.service";
import {ToastrService} from "ngx-toastr";
import {SousCompteSsoService} from "../../../shared/services/api/sso/sous-compte-sso.service";
import {Pays} from "../../../shared/models/api/pays.model";
import {PaysService} from "../../../shared/services/api/pays.service";

@Component({
  selector: 'app-filter-extraction',
  templateUrl: './filter-extraction.component.html',
  styleUrls: ['./filter-extraction.component.scss']
})
export class FilterExtractionComponent implements OnInit, OnDestroy {

  @ViewChild('inputMinDateSaisie') inputMinDateSaisie: DatePickerMinDateComponent;
  @ViewChild('inputMaxDateSaisie') inputMaxDateSaisie: DatePickerMaxDateComponent;
  @ViewChild('inputMinDateRdv') inputMinDateRdv: DatePickerMinDateExtractionRdvComponent;
  @ViewChild('inputMaxDateRdv') inputMaxDateRdv: DatePickerMaxDateExtractionRdvComponent;
  @ViewChild('inputMinDateLivraison') inputMinDateLivraison: DatePickerMinDateExtractionLivraisonComponent;
  @ViewChild('inputMaxDateLivraison') inputMaxDateLivraison: DatePickerMaxDateExtractionLivraisonComponent;
  @ViewChild('option') ref:ElementRef;


  @Input() displayInput: FieldParametersModel[] = [];

  @Output() submitFilter = new EventEmitter<FilterMultiCritereModel>();
  @Output() onLoad = new EventEmitter<FilterMultiCritereModel>();

  public filter: FilterMultiCritereModel = new FilterMultiCritereModel();
  public filterForm = new FormGroup({
    client: new FormControl(),
    extraction: new FormControl(),
    activites: new FormControl(),
    pays: new FormControl(),
    minDateSaisie: new FormControl(),
    maxDateSaisie: new FormControl(),
    optionDates: new FormControl(),
    minDateRdv: new FormControl(),
    maxDateRdv: new FormControl(),
    minDateLivraison: new FormControl(),
    maxDateLivraison: new FormControl(),
  });

  // Tableaux
  public hiddenInput = [];
  public clients: Client[];
  public extractions: Extraction[];
  public activites: Activite[];
  public pays: Pays[];
  public subscriptions: Subscription[] = [];

  // Loader
  public loadClient: boolean = false;
  public loadActivites: boolean = false;
  public loadPays: boolean = false;
  public loadExtraction: boolean = false;
  public showDateRdv: boolean = false;
  public showDateLivraison: boolean = false;
  public showBtnFilter: boolean = false;
  public showTypeExtraction: boolean = false;
  public selectedOptionCode: string;
  @Input() showTableExtraction: boolean = false;
  public input: boolean = false;
  public minDateSaisie;
  public maxDateSaisie;
  public minDateRdv;
  public maxDateRdv;
  public minDateLivraison;
  public maxDateLivraison;

  public daysDifferenceSaisie: number;
  public daysDifferenceRdv: number;
  public daysDifferenceLivraison: number;

  optionDates = [
    {
      code: 'RDV',
      name: 'Date de Rdv',
    },
    {
      code: 'LIV',
      name: 'Date de livraison'
    },
  ];

  type(item){return item.type}

  constructor(private translate: TranslateService,
              private clientSvc: SousCompteSsoService,
              private extractionSvc: ExtractionService,
              private activiteSvc: ActiviteService,
              private paysSvc: PaysService,
              private toastr: ToastrService,
              private filterSvc: FilterService) {
  }

  ngOnInit() {
    this.subscriptions.push(this.translate.onLangChange.subscribe(() => {
      this.loadClient = true;
      this.loadExtraction = true;
      this.loadActivites = true;
      this.loadPays = true;
      this.onLoadSousComptes();
      this.onLoadExtractionsList();
      this.onLoadActivites();
      this.onLoadPays();
    }))
    this.onInitFilter();
  }

  onInitFilter() {
    this.loadClient = true;
    this.loadExtraction = true;
    this.loadActivites = true;
    this.loadPays = true;
    FormfilterHelper.hydrateForm(this.displayInput, this.filterForm);
    this.hiddenInput = FormfilterHelper.extractHiddenInput(this.displayInput);
    this.onLoadSousComptes();
    this.onLoadExtractionsList();
    this.onLoadActivites();
    this.onLoadPays();
    this.getDateLocalstorage('extractionMinDateSaisieFilter', 'minDateSaisie', this.filterSvc.addDays(new Date(), -31).toString());
    this.getDateLocalstorage('extractionMaxDateSaisieFilter', 'maxDateSaisie', new Date());
    this.getValueLocalstorage();
    this.onLoad.emit(this.filterForm.getRawValue());
  }

  showDateSaisie() {
    if(this.selectedOptionCode != "") {
      if (this.selectedOptionCode == 'RDV') {
        this.showDateRdv = true;
        this.showDateLivraison = false;
      } else if (this.selectedOptionCode == 'LIV') {
        this.showDateLivraison = true;
        this.showDateRdv = false;
      } else {
        this.showDateLivraison = true;
        this.showDateRdv = false;
      }
    } else if (this.selectedOptionCode == null) {
      this.showDateLivraison = false;
      this.showDateRdv = false;
    }
  }

  /**
   * Chargement des sous-comptes
   */
  onLoadSousComptes() {
    this.clients = [];
    this.subscriptions.push(this.clientSvc.getSousComptes().subscribe(value => {
      this.clients = value;
      this.loadClient = false;
      },
      (error) => {
        this.toastr.error(
          this.translate.instant("La liste des sous-comptes n'est pas disponible", {application: this.translate.instant('Mes extractions')}),
          this.translate.instant('Désolé'),
          {
            timeOut: 20000,
            progressBar: true
          }
        );
        this.loadClient = false;
      }
    ));
  }

  /**
   * Chargement des sous-comptes
   */
  onLoadExtractionsList() {
    this.extractions = [];
    this.subscriptions.push(this.extractionSvc.getExtractions().subscribe(value => {
      this.extractions = value;
      this.loadExtraction = false;
      },
      (error) => {
        this.toastr.error(
          this.translate.instant("La liste des type d'extractions n'est pas disponible", { application: this.translate.instant('Mes extractions')}),
          this.translate.instant('Désolé'),
          {
            timeOut: 20000,
            progressBar: true
          }
        );
        this.loadClient = false;
      }
    ));
  }

  /**
   * Chargement des activités
   */
  onLoadActivites() {
    this.activites = [];
    this.subscriptions.push(this.activiteSvc.getActivites(ActiviteService.EXTRACTION).subscribe(value => {
      this.activites = value;
      this.loadActivites = false;
      },
      (error) => {
        this.toastr.error(
          this.translate.instant("La liste des activités n'est pas disponible", {application: this.translate.instant("Mes extractions")}),
          this.translate.instant('Désolé'),
          {
            timeOut: 20000,
            progressBar: true
          }
        );
        this.loadActivites = false;
      }
    ));
  }

  /**
   * Chargement des pays
   */
  onLoadPays() {
    this.pays = [];
    this.subscriptions.push(this.paysSvc.getPays().subscribe(
      value => {
        this.pays = value;
        this.loadPays = false;
      },
      (error) => {
        this.toastr.error(
          this.translate.instant("La liste des pays n'est pas disponible", {application: this.translate.instant("Mes extractions")}),
          this.translate.instant('Désolé'),
          {
            timeOut: 20000,
            progressBar: true
          }
        );
        this.loadPays = false;
      }
    ));
  }

  /**
   * On récupère les valeurs du localstorage
   */
  getValueLocalstorage() {
    this.filterSvc.convertDateLocalstorageForUrl(this.filterForm, 'minDateSaisie', 'extractionMinDateSaisieFilter');
    this.filterSvc.convertDateLocalstorageForUrl(this.filterForm, 'maxDateSaisie', 'extractionMaxDateSaisieFilter');
    this.ShowDateLocalstorageInput();
  }

  /**
   *  on affiche la date enregistrée dans le localstorage, dans l'input
   */
  ShowDateLocalstorageInput() {
    if (localStorage.getItem('extractionMinDateSaisieFilter') != null && localStorage.getItem('extractionMaxDateSaisieFilter') == null) {
      if (!this.minDateSaisie && !this.maxDateSaisie) {
        this.minDateSaisie = this.filterSvc.convertDateLocalstorageForShowInput(localStorage.getItem('extractionMinDateSaisieFilter'));
        this.maxDateSaisie = null;
      }
    } else if (localStorage.getItem('extractionMinDateSaisieFilter') != null && localStorage.getItem('extractionMaxDateSaisieFilter') != null) {
      if (!this.minDateSaisie && !this.maxDateSaisie) {
        this.minDateSaisie = this.filterSvc.convertDateLocalstorageForShowInput(localStorage.getItem('extractionMinDateSaisieFilter'));
        this.maxDateSaisie = this.filterSvc.convertDateLocalstorageForShowInput(localStorage.getItem('extractionMaxDateSaisieFilter'));
      }
    }
  }

  onSubmit() {
    //récupère les infos du filtre
    if(this.filterForm.get('extraction').value == null) {
      Swal.fire({
        title: this.translate.instant('Désolé'),
        text: this.translate.instant('Vous devez sélectionner un type d\'extraction'),
        type: 'error',
        showCancelButton: true,
        showConfirmButton: false,
        cancelButtonText: this.translate.instant('Retour'),
      })
      return;
    }
    this.filterSvc.ckeckInputIsNull(this.filterForm, this.inputMinDateSaisie, 'minDateSaisie')
    this.filterSvc.ckeckInputIsNull(this.filterForm, this.inputMaxDateSaisie, 'maxDateSaisie')
    if(this.filterForm.get('optionDates').value == 'RDV' && this.showDateRdv == true) {
        this.filterSvc.ckeckInputIsNull(this.filterForm, this.inputMinDateRdv, 'minDateRdv')
        this.filterSvc.ckeckInputIsNull(this.filterForm, this.inputMaxDateRdv, 'maxDateRdv')
        this.filterForm.get('minDateLivraison').reset();
        this.filterForm.get('maxDateLivraison').reset();
    } else if (this.filterForm.get('optionDates').value == 'LIV' && this.showDateLivraison == true) {
        this.filterSvc.ckeckInputIsNull(this.filterForm, this.inputMinDateLivraison, 'minDateLivraison')
        this.filterSvc.ckeckInputIsNull(this.filterForm, this.inputMaxDateLivraison, 'maxDateLivraison')
        this.filterForm.get('minDateRdv').reset();
        this.filterForm.get('maxDateRdv').reset();
    } else {
      this.filterForm.get('minDateLivraison').reset();
      this.filterForm.get('maxDateLivraison').reset();
      this.filterForm.get('minDateRdv').reset();
      this.filterForm.get('maxDateRdv').reset();
    }

    this.daysDifferenceSaisie = this.filterSvc.intervalDate(this.filterForm.get('minDateSaisie').value, this.filterForm.get('maxDateSaisie').value)
    this.daysDifferenceRdv = this.filterSvc.intervalDate(this.filterForm.get('minDateRdv').value, this.filterForm.get('maxDateRdv').value)
    this.daysDifferenceLivraison = this.filterSvc.intervalDate(this.filterForm.get('minDateLivraison').value, this.filterForm.get('maxDateLivraison').value)

    if(this.filterForm.get('minDateSaisie').value == null || this.filterForm.get('maxDateSaisie').value == null) {
      Swal.fire({
        title: this.translate.instant('Désolé'),
        text: this.translate.instant('Vous devez obligatoirement sélectionner des dates de saisie !'),
        type: 'error',
        showCancelButton: true,
        showConfirmButton: false,
        cancelButtonText: this.translate.instant('Retour'),
      });
      return;
    }
    if (this.daysDifferenceSaisie >= 40) {
      Swal.fire({
        title: this.translate.instant('Attention !'),
        text: this.translate.instant("L'écart des dates est très important - Le résultat de l'extraction risque d'être long",{value: this.translate.instant('Saisie')}),
        type: 'warning',
        showCancelButton: true,
        cancelButtonText: this.translate.instant('Annuler'),
        confirmButtonText: this.translate.instant('Continuer'),
      }).then((result) => {
        if (result.value) {
          this.submitFilter.emit(this.filterForm.getRawValue());
        }
      })
    } else if (this.daysDifferenceRdv >= 40) {
      Swal.fire({
        title: this.translate.instant('Attention !'),
        text: this.translate.instant("L'écart des dates est très important - Le résultat de l'extraction risque d'être long", {value: this.translate.instant('Rdv')}),
        type: 'warning',
        showCancelButton: true,
        cancelButtonText: this.translate.instant('Annuler'),
        confirmButtonText: this.translate.instant('Continuer'),
      }).then((result) => {
        if (result.value) {
          this.submitFilter.emit(this.filterForm.getRawValue());
        }
      })
    } else if (this.daysDifferenceLivraison >= 40) {
      Swal.fire({
        title: this.translate.instant('Attention !'),
        text: this.translate.instant("L'écart des dates est très important - Le résultat de l'extraction risque d'être long", {value: this.translate.instant('Livraison')}),
        type: 'warning',
        showCancelButton: true,
        cancelButtonText: this.translate.instant('Annuler'),
        confirmButtonText: this.translate.instant('Continuer'),
      }).then((result) => {
        if (result.value) {
          this.submitFilter.emit(this.filterForm.getRawValue());
        }
      })
    } else  {
      this.submitFilter.emit(this.filterForm.getRawValue());
    }
  }

  /**
   *  On efface tous les champs du filter
   */
  reset(): void {
    this.filterForm.reset();
    this.getValueLocalstorage()
    this.inputMinDateRdv.reset();
    this.inputMaxDateRdv.reset();
    this.inputMinDateLivraison.reset();
    this.inputMaxDateLivraison.reset();
    this.showDateRdv = false;
    this.showDateLivraison = false;
    this.showTypeExtraction = false;
    this.showTableExtraction = false;
    this.showBtnFilter = false;
  }

  getDateLocalstorage(nameDateFilter, date, newDate) {
    if (this.filterForm.get(date).value !== null) {
      localStorage.setItem(nameDateFilter, this.filterForm.get(date).value);
    } else {
      localStorage.setItem(nameDateFilter, newDate);
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  get f() {
    return this.filterForm.controls;
  }
}
