<!-- begin #sidebar -->
<div [ngClass]="{ 'sidebar-transparent': this.pageSidebarTransparent}" class="sidebar" id="sidebar">
  <!-- begin sidebar scrollbar -->
  <div #sidebarScrollbar (scroll)="onScroll($event)" *ngIf="desktopMode" class="overflow-scroll height-full">
    <ng-container *ngTemplateOutlet="sidebarNav"></ng-container>
  </div>
  <div (scroll)="onScroll($event)" *ngIf="mobileMode" class="overflow-scroll height-full">
    <ng-container *ngTemplateOutlet="sidebarNav"></ng-container>
  </div>
  <!-- end sidebar scrollbar -->
  <ng-template #sidebarNav>
    <!-- begin sidebar user -->
    <ul class="nav">
      <li class="nav-profile">
        <a (click)="toggleNavProfile()" href="javascript:">
          <div class="cover with-shadow"></div>
          <div class="image image-icon bg-black text-grey-darker">
            <i class="fa fa-user"></i>
          </div>
          <div class="info">
            {{currentUser.getFullname()}}
            <small>{{currentUser.employment}}</small>
          </div>
        </a>
      </li>
    </ul>
    <!-- end sidebar user -->

    <!-- render menu item -->
    <ng-template #sidebarMenu let-menu="menu">
      <div class="text-truncate"
           placement="right"
           container="body"
           ngbPopover="{{ menu.title | translate }}" triggers="mouseenter:mouseleave">
        <span *ngIf="menu.badge" class="badge pull-right">{{ menu.badge }}</span>
        <b *ngIf="menu.caret" class="caret"></b>
        <div *ngIf="menu.img" class="icon-img"><img src="{{ menu.img }}"/></div>
        <i *ngIf="menu.icon" class="{{ menu.icon }}"></i>
        <span *ngIf="menu.title">
          {{ menu.title | translate }}
          <span *ngIf="menu.label" class="label label-theme m-l-5">{{ menu.label }}</span>
        </span>
      </div>
    </ng-template>

    <!-- render submenu item -->
    <ng-template #sidebarSubMenu let-menu="menu">
      <div class="text-truncate"
           placement="right"
           container="body"
           ngbPopover="{{ menu.title | translate }}" triggers="mouseenter:mouseleave">
        <b *ngIf="menu.caret" class="caret pull-right cursor-pointer"></b>
        {{ menu.title | translate}}
        <i *ngIf="menu.highlight" class="fa fa-paper-plane text-theme m-l-5"></i>
      </div>
    </ng-template>

    <!-- begin sidebar nav -->
    <ul class="nav pt-1" tourAnchor="menu.secondaire">
      <!-- sidebar menu secondaire-->
      <ng-container *ngFor="let menu of pageMenuSecondaire" >
        <li *ngIf="!menu.restrictRole || (menu.restrictRole && view.hasDroit(menu.restrictRole))"
            #rla1="routerLinkActive" [ngClass]="{ 'expand': (menu.state == 'expand') }"
            [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active">
          <a (click)="(menu.submenu) ? expandCollapseSubmenu(menu, pageMenuSecondaire, rla1) : ''" *ngIf="!menu.url">
            <ng-container *ngTemplateOutlet="sidebarMenu; context: {menu: menu}"></ng-container>
          </a>
          <a (click)="expandCollapseSubmenu(menu, pageMenuSecondaire, rla1 )" *ngIf="menu.url" [routerLink]="menu.url">
            <ng-container *ngTemplateOutlet="sidebarMenu; context: {menu: menu}"></ng-container>
          </a>

          <!-- sidebar submenu lvl-1 -->
          <div *ngIf="menu.submenu"
               [@expandCollapse]="(menu.state) ? menu.state : ((rla1.isActive && menu.state) ? 'active' : 'collapse')">
            <ul [ngStyle]="{'margin-top.px': (this.pageSettings.pageSidebarMinified) ? - (scrollTop) : 0 }"
                class="sub-menu">
              <ng-container *ngFor="let sub1 of menu.submenu">
                <li *ngIf="!sub1.restrictRole || (sub1.restrictRole && view.hasDroit(sub1.restrictRole))"
                    #rla2="routerLinkActive" [ngClass]="{ 'expand': (sub1.state == 'expand') }"
                    [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active">
                  <a (click)="(sub1.submenu) ? expandCollapseSubmenu(sub1, menu, rla2) : ''" *ngIf="!sub1.url">
                    <ng-container *ngTemplateOutlet="sidebarSubMenu; context: {menu: sub1}"></ng-container>
                  </a>
                  <a href="" *ngIf="sub1.url" [routerLink]="sub1.url">
                    <ng-container *ngTemplateOutlet="sidebarSubMenu; context: {menu: sub1}"></ng-container>
                  </a>

                  <!-- sidebar submenu lvl-2 -->
                  <div *ngIf="sub1.submenu"
                       [@expandCollapse]="(sub1.state) ? sub1.state : ((rla2.isActive && !sub1.state) ? 'active' : 'collapse')">
                    <ul class="sub-menu">
                      <ng-container *ngFor="let sub2 of sub1.submenu">
                        <li *ngIf="!sub2.restrictRole || (sub2.restrictRole && view.hasDroit(sub2.restrictRole))"
                            #rla3="routerLinkActive" [ngClass]="{ 'expand': (sub2.state == 'expand') }"
                            [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active">
                          <a (click)="(sub2.submenu) ? expandCollapseSubmenu(sub2, sub1.submenu, rla3) : ''"
                             *ngIf="!sub2.url">
                            <ng-container *ngTemplateOutlet="sidebarSubMenu; context: {menu: sub2}"></ng-container>
                          </a>
                          <a (click)="(sub2.submenu) ? expandCollapseSubmenu(sub2, sub1.submenu, rla3) : ''"
                             *ngIf="sub2.url" href="" [routerLink]="sub2.url">
                            <ng-container *ngTemplateOutlet="sidebarSubMenu; context: {menu: sub2}"></ng-container>
                          </a>

                          <!-- sidebar submenu lvl-3 -->
                          <div *ngIf="sub2.submenu"
                               [@expandCollapse]="(sub2.state) ? sub2.state : ((rla3.isActive && !sub2.state) ? 'active' : 'collapse')">
                            <ul class="sub-menu">
                              <ng-container *ngFor="let sub3 of sub2.submenu">
                                <li *ngIf="(sub3.restrictRole && view.hasDroit(sub3.restrictRole))"
                                    #rla4="routerLinkActive" [ngClass]="{ 'expand': (sub3.state == 'expand') }"
                                    [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active">
                                  <a (click)="(sub3.submenu) ? expandCollapseSubmenu(sub3, sub2.submenu, rla4) : ''"
                                     *ngIf="!sub3.url">
                                    <ng-container
                                      *ngTemplateOutlet="sidebarSubMenu; context: {menu: sub3}"></ng-container>
                                  </a>
                                  <a *ngIf="sub3.url" href="" [routerLink]="sub3.url">
                                    <ng-container
                                      *ngTemplateOutlet="sidebarSubMenu; context: {menu: sub3}"></ng-container>
                                  </a>
                                </li>
                              </ng-container>
                            </ul>
                          </div>
                        </li>
                      </ng-container>
                    </ul>
                  </div>
                </li>
              </ng-container>
            </ul>
          </div>
        </li>
      </ng-container>

      <hr/>
    </ul>
    <ul class="nav pt-1" tourAnchor="menu.principal">
      <li class="nav-header">Navigation</li>

      <!-- sidebar menu principal-->
      <ng-container *ngFor="let menu of pageMenuPrincipal">
        <li *ngIf="!menu.restrictRole || (menu.restrictRole && view.hasDroit(menu.restrictRole))"
            #rla1="routerLinkActive" [ngClass]="{ 'expand': (menu.state == 'expand') }"
            [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active">
          <a (click)="(menu.submenu) ? expandCollapseSubmenu(menu, pageMenuPrincipal, rla1) : ''" *ngIf="!menu.url">
            <ng-container *ngTemplateOutlet="sidebarMenu; context: {menu: menu}"></ng-container>
          </a>
          <a (click)="expandCollapseSubmenu(menu, pageMenuPrincipal, rla1 )" *ngIf="menu.url" [routerLink]="menu.url">
            <ng-container *ngTemplateOutlet="sidebarMenu; context: {menu: menu}"></ng-container>
          </a>

          <!-- sidebar submenu principal -->
          <div *ngIf="menu.submenu"
               [@expandCollapse]="(menu.state) ? menu.state : ((rla1.isActive && menu.state) ? 'active' : 'collapse')">
            <ul [ngStyle]="{'margin-top.px': (this.pageSettings.pageSidebarMinified) ? - (scrollTop) : 0 }"
                class="sub-menu">
              <ng-container *ngFor="let sub1 of menu.submenu">
                <li *ngIf="!sub1.restrictRole || (sub1.restrictRole && view.hasDroit(sub1.restrictRole))"
                    #rla2="routerLinkActive" [ngClass]="{ 'expand': (sub1.state == 'expand') }"
                    [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active">
                  <a (click)="(sub1.submenu) ? expandCollapseSubmenu(sub1, menu, rla2) : ''" *ngIf="!sub1.url">
                    <ng-container *ngTemplateOutlet="sidebarSubMenu; context: {menu: sub1}"></ng-container>
                  </a>
                  <a href="" *ngIf="sub1.url" [routerLink]="sub1.url">
                    <ng-container *ngTemplateOutlet="sidebarSubMenu; context: {menu: sub1}"></ng-container>
                  </a>

                  <!-- sidebar submenu lvl-2 -->
                  <div *ngIf="sub1.submenu"
                       [@expandCollapse]="(sub1.state) ? sub1.state : ((rla2.isActive && !sub1.state) ? 'active' : 'collapse')">
                    <ul class="sub-menu">
                      <ng-container *ngFor="let sub2 of sub1.submenu">
                        <li *ngIf="!sub2.restrictRole || (sub2.restrictRole && view.hasDroit(sub2.restrictRole))"
                            #rla3="routerLinkActive" [ngClass]="{ 'expand': (sub2.state == 'expand') }"
                            [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active">
                          <a (click)="(sub2.submenu) ? expandCollapseSubmenu(sub2, sub1.submenu, rla3) : ''"
                             *ngIf="!sub2.url">
                            <ng-container *ngTemplateOutlet="sidebarSubMenu; context: {menu: sub2}"></ng-container>
                          </a>
                          <a (click)="(sub2.submenu) ? expandCollapseSubmenu(sub2, sub1.submenu, rla3) : ''"
                             *ngIf="sub2.url" href="" [routerLink]="sub2.url">
                            <ng-container *ngTemplateOutlet="sidebarSubMenu; context: {menu: sub2}"></ng-container>
                          </a>

                          <!-- sidebar submenu lvl-3 -->
                          <div *ngIf="sub2.submenu"
                               [@expandCollapse]="(sub2.state) ? sub2.state : ((rla3.isActive && !sub2.state) ? 'active' : 'collapse')">
                            <ul class="sub-menu">
                              <ng-container *ngFor="let sub3 of sub2.submenu">
                                <li *ngIf="(sub3.restrictRole && view.hasDroit(sub3.restrictRole))"
                                    #rla4="routerLinkActive" [ngClass]="{ 'expand': (sub3.state == 'expand') }"
                                    [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active">
                                  <a (click)="(sub3.submenu) ? expandCollapseSubmenu(sub3, sub2.submenu, rla4) : ''"
                                     *ngIf="!sub3.url">
                                    <ng-container
                                      *ngTemplateOutlet="sidebarSubMenu; context: {menu: sub3}"></ng-container>
                                  </a>
                                  <a *ngIf="sub3.url" href="" [routerLink]="sub3.url">
                                    <ng-container
                                      *ngTemplateOutlet="sidebarSubMenu; context: {menu: sub3}"></ng-container>
                                  </a>
                                </li>
                              </ng-container>
                            </ul>
                          </div>
                        </li>
                      </ng-container>
                    </ul>
                  </div>
                </li>
              </ng-container>
            </ul>
          </div>
        </li>
      </ng-container>

      <!-- begin sidebar minify button -->
      <li><a (click)="toggleMinified()" class="d-none d-md-block sidebar-minify-btn" href="javascript:"><i
        class="fa fa-angle-double-left"></i></a></li>
      <!-- end sidebar minify button -->
    </ul>
    <!-- end sidebar nav -->
  </ng-template>
</div>
<div class="sidebar-bg"></div>
<!-- end #sidebar -->

