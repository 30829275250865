import {Deserializable} from '../interfaces/deserializable.interface';
import {Conversation} from './conversation.model';


export class MessageConversation implements Deserializable {
  public id: number;
  public conversation: Conversation;
  public expediteur: string;
  public username: string;
  public corps: string;
  public created_at?: Date;
  public updated_at?: Date;
  public pending: boolean;
  public dirty: boolean;


  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}
