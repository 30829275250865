import {Component, Input, OnInit} from '@angular/core';
import {Grc} from '../../../shared/models/api/grc.model';
import {GrcData} from "../../../shared/models/api/grc-data.model";

@Component({
  selector: 'app-infos-commande',
  templateUrl: './infos-commande.component.html',
  styleUrls: ['./infos-commande.component.scss']
})
export class InfosCommandeComponent implements OnInit {

  @Input() grc: GrcData;
  @Input() loading = false;
  @Input() showViewCommandeBtn: boolean = false;

  constructor() { }

  ngOnInit() {
  }
}
