import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Grc} from '../../models/api/grc.model';
import {HttpUtil} from '../../utils/http.util';
import {PieceJointe} from '../../models/api/piece-jointe.model';
import {ConversationGrc} from '../../models/api/conversation-grc.model';
import {GrcData} from "../../models/api/grc-data.model";
import {NotificationGrc} from "../../models/api/notification-grc.model";
import {NotificationGrcData} from "../../models/api/notification-grc-data.model";
import {User} from "../../models/user.model";


@Injectable({
  providedIn: 'root'
})
export class GrcService {

  urlRessource = environment.url_api_espace_client + '/demandes-grc';
  urlRessourceNotification = environment.url_api_espace_client + '/notification-mail-grc';

  constructor(private http: HttpClient) {
  }

  getDemandesGrcByNumeroAvis(filter: any = null, numeroAvis: string): Observable<Grc> {
    return this.http
      .get<Grc>(`${this.urlRessource}/${numeroAvis}/demandes-grc`,{params: HttpUtil.convertObjectToHttpParams(filter)})
      .pipe(map((grc) => {
        return new Grc().deserialize(grc);
      }));
  }

  getGrcById(id: number, numeroAvis: string): Observable<GrcData> {
    return this.http
      .get<GrcData>(`${this.urlRessource}/${id}-${numeroAvis}`)
      .pipe(map((grc) => {
        return new GrcData().deserialize(grc);
      }));
  }

  getDemandesGrc(filter: any = null): Observable<Grc> {
    return this.http
      .get<Grc>(`${this.urlRessource}`, {params: HttpUtil.convertObjectToHttpParams(filter)})
      .pipe(map((grc) => {
        return new Grc().deserialize(grc);
      }));
  }

  addDemande(numeroAvis: string, grc: Grc): Observable<Grc> {
    return this.http.post<{ message: string, next: boolean }>(`${this.urlRessource}/${numeroAvis}/nouvelle-demande`, grc)
      .pipe(map((response) => {
        return new Grc().deserialize(response);
      }));
  }

  reponseDemandeGrc(numeroAvis: string , id: number, conversationGrc: ConversationGrc): Observable<any> {
    return this.http.post<{ message: string, next: boolean }>(`${this.urlRessource}/${numeroAvis}/reponse-demande-grc/${id}`, conversationGrc)
      .pipe(map((response) => {
        return new ConversationGrc().deserialize(response);
      }));
  }

  archiveMessage(numeroAvis: string, grc: GrcData): Observable<GrcData> {
    return this.http.post<GrcData>(`${this.urlRessource}/${numeroAvis}/messages/archive`, grc)
      .pipe(map((response) => {
        return new GrcData().deserialize(response);
      }));
  }


  delete(id: number) {
    return this.http.delete<Grc>(`${this.urlRessource}/${id}/delete`)
      .pipe(map((grc) => {
        return new Grc().deserialize(grc);
      }));
  }

  /**
   * @param id
   */
  getPieceJointes(id: number): Observable<PieceJointe> {
    return this.http
      .get<PieceJointe[]>(`${this.urlRessource}/${id}/piece-jointes`)
      .pipe(map((pieceJointes) => {
        return new PieceJointe().deserialize(pieceJointes);
      }));
  }

  getNotificationGrc(filter: any = null): Observable<NotificationGrc> {
    return this.http
      .get<NotificationGrc>(`${this.urlRessourceNotification}/parametres`, {params: HttpUtil.convertObjectToHttpParams(filter)})
      .pipe(map((notification) => {
        return new NotificationGrc().deserialize(notification);
      }));
  }

  /**
   * @param id
   */
  getNotificationGrcById(id: number): Observable<NotificationGrcData> {
    return this.http
      .get<NotificationGrcData>(`${this.urlRessourceNotification}/parametres/${id}`)
      .pipe(map((notification) => {
        return new NotificationGrcData().deserialize(notification);
      }));
  }

  /**
   *
   * @param id
   * @param notification
   */
  updateNotificationGrcById(id: number, notification: NotificationGrcData): Observable<any> {
    return this.http.put<{ message: string, next: boolean }>(`${this.urlRessourceNotification}/parametres/${id}/update`, notification)
      .pipe(map((response) => {
        return new User().deserialize(response);
      }));
  }

  /**
   *
   * @param username
   * @param notification
   */
  updateNotificationGrcByUsername(username: string, notification: NotificationGrcData, ): Observable<any> {
    return this.http.put<{ message: string, next: boolean }>(`${this.urlRessourceNotification}/parametres/${username}/update-all`, notification)
      .pipe(map((response) => {
        return new User().deserialize(response);
      }));
  }

  /**
   *
   * @param username
   * @param notification
   */
  updateEmailNotificationGrcByUsername(username: string, notification: NotificationGrcData, ): Observable<any> {
    return this.http.put<{ message: string, next: boolean }>(`${this.urlRessourceNotification}/parametres/${username}/update-mail`, notification)
      .pipe(map((response) => {
        return new User().deserialize(response);
      }));
  }

  /**
   *
   * @param username
   * @param notification
   */
  postNotificationGrcByUsername(username: string, notification: NotificationGrcData, ): Observable<any> {
    return this.http.post<{ message: string, next: boolean }>(`${this.urlRessourceNotification}/parametres/${username}/add`, notification)
      .pipe(map((response) => {
        return new User().deserialize(response);
      }));
  }

}
