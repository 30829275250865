<table mat-table [dataSource]="dataSource" (matSortChange)="sortData($event)" multiTemplateDataRows matSort
       matSortActive="created_at" matSortDirection="desc"
       class="table mat-responsive table-hover table-striped-n3 table-bordered mat-elevation-z8 text-center">

  <ng-container matColumnDef="format">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Format' | translate }}</th>
    <td *matCellDef="let element" [ngbTooltip]="element.format" class="with-img text-center align-middle"
        container="body"
        mat-cell
        placement="top">
      <img *ngIf="element.format === 'pdf'" class="img-rounded height-30" src="../../../../../assets/img/icon-pdf.png"
           alt="">
      <img *ngIf="element.format === 'image'" class="img-rounded height-30"
           src="../../../../../assets/img/icon-jpeg.png" alt="">
      <img *ngIf="element.format !== 'image' && element.format !== 'pdf'" class="img-rounded height-30"
           src="../../../../../assets/img/icon-jpeg.png" alt="">
    </td>
  </ng-container>
  <ng-container matColumnDef="libelle">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Nom' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="align-middle">
      {{element.libelle | titlecase}} </td>
  </ng-container>
  <ng-container matColumnDef="created_at">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Date' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="align-middle">
      {{element?.created_at | date : 'dd/MM/yyyy' }} </td>
  </ng-container>
  <ng-container matColumnDef="type_piece_jointe">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Type' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="align-middle">
      {{element?.type_piece_jointe?.libelle | titlecase }} </td>
  </ng-container>
  <ng-container matColumnDef="options">
    <th mat-header-cell *matHeaderCellDef class="text-center align-middle">
      <button *ngIf="showAddFileBtn"
              placement="top"
              container="body"
              ngbTooltip="Ajouter un document"
              class="btn btn-agediss btn-sm btn-rounded"
              (click)="onAdd.emit()">
        <i class="fa fa-plus"></i>
      </button>
    </th>
    <td mat-cell *matCellDef="let element, let i = index" class="with-img align-middle text-nowrap p-0">
      <button *ngIf="showDownloadFileBtn && element.downloadable && this.innerWidth > 430"
              ngbTooltip="{{'Télécharger le document' | translate }}"
              placement="top"
              container="body"
              class="btn btn-agediss btn-sm btn-rounded ml-1"
              (click)="download(element.id)">
        <i class="fa fa-download"></i>
      </button>
      <button *ngIf="showViewFileBtn && this.innerWidth > 430"
              ngbTooltip="{{'Visualisation des documents' | translate }}"
              placement="top"
              container="body"
              class="btn btn-agediss btn-sm btn-rounded ml-1"
              (click)="selectFile(element,i)"><i class="fa fa-eye"></i>
      </button>
      <button *ngIf="showDeleteFileBtn && element.deletable && this.innerWidth > 430" [ladda]="element.pending"
              data-style="zoom-in"
              ngbTooltip="{{'Supprimer le document' | translate }}"
              placement="top"
              container="body"
              class="btn btn-agediss btn-sm btn-rounded ml-1 mr-1"
              (click)="delete(element.id)">
        <i class="fa fa-trash"></i></button>
      <button *ngIf="showInfosBtn && (this.innerWidth < 701 || (this.innerWidth > 1200 && this.innerWidth < 1300))"
              ngbTooltip="{{'Informations complémentaires' | translate }}"
              placement="left"
              container="body"
              class="btn btn-agediss btn-sm btn-rounded"
              (click)="expandedElement = expandedElement === element ? null : element">
        <i class="fas fa-angle-double-down"></i>
      </button>
    </td>
  </ng-container>

  <ng-container matColumnDef="noData">
    <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length" innerHTML="{{'Aucun document' | translate }}"></td>
  </ng-container>
  <ng-container matColumnDef="loading">
    <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length" innerHTML="{{'Chargement en cours' | translate }} <i class='fas fa-spin fa-circle-notch'></i>"></td>
  </ng-container>

  <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
  <ng-container matColumnDef="expandedDetail">
    <td class="table-td p-0" mat-cell *matCellDef="let element; let i = index" [attr.colspan]="displayedColumns.length">

      <div class="table-detail"
           *ngIf="this.innerWidth < 430"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-title">

        </div>
        <div class="table-description">
          <strong>{{'Options' | translate }} : </strong>
          <span>
         <button *ngIf="element.downloadable"
                 ngbTooltip="{{'Télécharger le document' | translate }}"
                 placement="top"
                 container="body"
                 class="btn btn-sm btn-agediss"
                 (click)="download(element.id)">
          <i class="fa fa-download"></i>
         </button>
          <button *ngIf="element.watchable"
                  ngbTooltip="{{'Visualisation des documents' | translate }}"
                  placement="top"
                  container="body"
                  class="btn btn-sm btn-agediss ml-1"
                  (click)="selectFile(element, i)"><i class="fa fa-eye"></i>
          </button>
          <button *ngIf="element.deletable" [ladda]="element.pending" data-style="zoom-in"
                  ngbTooltip="{{'Supprimer le document' | translate }}"
                  placement="top"
                  container="body"
                  class="btn btn-rounded btn-sm btn-agediss ml-1" (click)="delete(element.id)">
            <i class="fa fa-trash"></i>
          </button>
         </span>
        </div>
      </div>
      <div class="table-detail"
           *ngIf="this.innerWidth < 600"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description">
          <strong>{{'Date' | translate }} : </strong><span>{{element?.created_at | date : 'dd/MM/yyy' }}</span>
        </div>
      </div>
      <div class="table-detail"
           *ngIf="this.innerWidth < 1300"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description">
          <strong>{{'Type' | translate }} : </strong><span>{{element?.type_piece_jointe?.libelle | titlecase}}</span>
        </div>
      </div>
    </td>
  </ng-container>

  <tr mat-header-row style="height: 15px;" *matHeaderRowDef="displayedColumns;  sticky: true"></tr>
  <tr mat-row *matRowDef="let element; columns: displayedColumns;" [hidden]="loading"
      class="table-row overflow-scroll"
      [class.expanded-row]="expandedElement === element">
  </tr>
  <tr mat-footer-row *matFooterRowDef="['noData']" [hidden]="dataSource && dataSource.data.length > 0 || loading"></tr>
  <tr mat-footer-row *matFooterRowDef="['loading']" [hidden]="!loading"></tr>
  <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="table-detail-row"></tr>
</table>



