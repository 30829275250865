import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Subscription} from "rxjs";

import Swal from "sweetalert2";
import {TranslateService} from "@ngx-translate/core";
import {ToastrService} from "ngx-toastr";
import {User} from "../../../../shared/models/user.model";
import { NotificationGrcData } from 'src/app/shared/models/api/notification-grc-data.model';
import {NotificationGrc} from "../../../../shared/models/api/notification-grc.model";
import {GrcService} from "../../../../shared/services/api/grc.service";
import {AuthenticationService} from "../../../../shared/services/authentication.service";
import {FilterMultiCritereModel} from "../../../../shared/models/filters/filterMultiCritere.model";


@Component({
  selector: 'app-edit-all-notification-grc',
  templateUrl: './edit-all-notification-grc.component.html',
  styleUrls: ['./edit-all-notification-grc.component.scss']
})
export class EditAllNotificationGrcComponent implements OnInit {

  @Output() canceled: EventEmitter<void> = new EventEmitter();
  @Output() submited: EventEmitter<User> = new EventEmitter();
  @Input() username: string;
  @Input() email: string;

  public notificationGrcData: NotificationGrcData;
  public notificationGrc: NotificationGrc;
  public subscriptions: Subscription[] = [];

  // loading
  public loading: boolean = false;
  public loadingBtn: boolean = false;

  //form
  public editAllNotificationForm: FormGroup;
  public formSubmit = false;
  public isChecked = false;

  constructor(private grcSvc: GrcService,
              private translate: TranslateService,
              private authSvc: AuthenticationService,
              private toastr: ToastrService,) {
  }

  ngOnInit() {
    this.loading = true;
    this.initForm()
  }

  initForm() {
    this.editAllNotificationForm = new FormGroup({
      username: new FormControl(this.username, Validators.required),
      email: new FormControl(this.email, [Validators.required, Validators.email]),
      activerMessage: new FormControl('', Validators.required),
      activerDemande: new FormControl('', Validators.required),
    });
    this.loading = false;
  }

  public statuts = [
    {id: 1, code: "O", libelle: "Notification activée"},
    {id: 2, code: "N", libelle: "Notification desactivée"},
  ]

  updateFormParamNotification() {
    this.formSubmit = true;
    if (this.editAllNotificationForm.invalid) {
      return;
    }
    this.loadingBtn = true;
    const sub = this.grcSvc.updateNotificationGrcByUsername(this.username, this.editAllNotificationForm.value).subscribe(
      (notification) => {
        sub.unsubscribe();
        this.loadingBtn = false;
        this.formSubmit = false;
        this.toastr.success(
          this.translate.instant("Les paramètres des notifications GRC ont été enregistrés"),
          this.translate.instant("Notifications GRC"),
          {progressBar: true}
        );
        this.submited.emit(notification);
      },
      () => {
        this.loadingBtn = false;
        this.formSubmit = false;
        Swal.fire({
          title: this.translate.instant('Désolé'),
          text: this.translate.instant('Une erreur est survenue'),
          type: 'error',
          showCancelButton: false,
        })
      }
    );
  }

  cancel() {
    this.canceled.emit();
  }

  get f() {
    return this.editAllNotificationForm.controls;
  }

}
