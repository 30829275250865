import {Component, OnDestroy, OnInit} from '@angular/core';
import {FilterMultiCritereModel} from '../../shared/models/filters/filterMultiCritere.model';
import {Subscription} from 'rxjs';
import {PageEvent} from '@angular/material/paginator';
import {Grc} from '../../shared/models/api/grc.model';
import {AuthenticationService} from '../../shared/services/authentication.service';
import {View} from '../../shared/models/view.model';
import {GrcService} from '../../shared/services/api/grc.service';
import {StatutGrc} from '../../shared/models/api/statut-grc.model';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from "@ngx-translate/core";
import {PaginationService} from "../../shared/services/pagination.service";
import {GrcData} from "../../shared/models/api/grc-data.model";
import {WexlogGrcData} from "../../shared/models/api/wexlog/wexlog-grc-data.model";
import {GrcWexlogService} from "../../shared/services/api/wexlog/grc-wexlog.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-gestion-relation-clients',
  templateUrl: './gestion-relation-clients.html',
  styleUrls: ['./gestion-relation-clients.scss']
})
export class GestionRelationClientsPage implements OnInit, OnDestroy {

  private filter: FilterMultiCritereModel = new FilterMultiCritereModel();

  public grc: Grc;
  public grcWexlog: WexlogGrcData;
  public pageEvent: PageEvent;
  public view: View;
  public activModal: NgbModalRef;

  // tableaux
  public grcData: GrcData[];
  public grcDataWexlog: WexlogGrcData[];
  public statuts: StatutGrc[];
  public subscriptions: Subscription[] = [];
  public pageSizeOption = [5, 10, 20];

  // number
  public pageSize: number = 10;
  public nbData: number = 0;

  // loading
  public loadingTableGrc: boolean = false;
  public loadingTableGrcWexlog: boolean = false;
  public loadingNbGrc: boolean = false;
  public loading: boolean = false;

  constructor(private translate: TranslateService,
              private grcSvc: GrcService,
              private grcWexlogSvc: GrcWexlogService,
              private modalSvc: NgbModal,
              private paginationSvc: PaginationService,
              private router: Router,
              private authSvc: AuthenticationService) {
    this.authSvc.currentView.subscribe(x => this.view = x);
    if (this.view.origine == 'tradiss') {
      this.paginationSvc.defaultFilterPagination(this.filter, this.pageSize)
    }
  }

  ngOnInit() {
    if (this.view.hasDroit('GRC_MENU_GESTION-RELATION-CLIENTS')) {
      this.subscriptions.push(this.translate.onLangChange.subscribe(() => {
        if (this.view.origine == 'tradiss') {
          this.loadDataGrc();
        }
        if (this.view.origine == 'wexlog') {
          this.loadDataGrcWexlog();
        }
      }))
    } else {
      this.router.navigate(['/', 'home']);
    }
  }

  onSubmit(filter = null) {
    //récupère les infos du filtre
    if (filter) {
      this.filter = filter;
    }
    if (this.view.origine == 'tradiss') {
      this.paginationSvc.defaultFilterPagination(this.filter, this.pageSize)
      this.loadDataGrc();
    }
    if (this.view.origine == 'wexlog') {
      this.loadDataGrcWexlog();
    }
  }

  onLoad(filter = null) {
    //récupère les infos du filtre
    if (filter) {
      this.filter = {...filter, ...this.filter};
    }
    if (this.view.origine == 'tradiss') {
      this.loadDataGrc();
    }
    if (this.view.origine == 'wexlog') {
      this.loadDataGrcWexlog();
    }
  }

  /**
   * On récupère toutes les Demandes Grc liès au compte client
   */
  loadDataGrc() {
    this.loadingTableGrc = true;
    this.loadingNbGrc = true;
    this.grcData = [];
    this.nbData = 0;
    this.subscriptions.push(this.grcSvc.getDemandesGrc(this.filter).subscribe(grc => {
      this.grcData = grc.data;
      this.nbData = grc.nb;
      this.loadingTableGrc = false;
      this.loadingNbGrc = false;
      },
      error => {
        this.loadingTableGrc = false;
        this.loadingNbGrc = false;
      }));
  }

  /**
   * On récupère toutes les Demandes Grc Wexlog liès au compte client
   */
  loadDataGrcWexlog() {
    this.loadingTableGrcWexlog = true;
    this.grcDataWexlog = [];
    this.subscriptions.push(this.grcWexlogSvc.getDemandesGrcWexlog(this.filter).subscribe(grc => {
      this.grcDataWexlog = grc;
      this.grcDataWexlog.forEach((value, index) => {
        if (value.libelle_statut === 'ASSIGNED' && value.creator_id !== this.view.srcClient) {
          if (value.messages.length === 1) {
            this.grcDataWexlog[index]['deletable'] = true
          }
        }
      });
      this.loadingTableGrcWexlog = false;
      },
      error => {
        console.log(error);
        this.grcDataWexlog = [];
        this.loadingTableGrcWexlog = false;
      }));
  }

  /**
   * On affiche la conversion Grc sélectionnée
   * @param modalName
   * @param grcWexlog
   */
  openModalVisualisationMessages(modalName, grcWexlog: WexlogGrcData) {
    this.grcWexlog = grcWexlog;
    this.activModal = this.modalSvc.open(modalName, {
      size: 'xl', keyboard: false, backdrop: 'static'
    });
    this.activModal.result.then(() => {
      this.loadDataGrcWexlog()
    })
  }

  onLoadDataGrcByEvent(event: PageEvent) {
    this.paginationSvc.onPaginationEvent(this.filter, event)
    this.loadDataGrc();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

}
