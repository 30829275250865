<div *ngIf="view.hasDroit('COMMANDE_MENU_ANOMALIES')">
  <div class="row m-0 padding">
    <h1 class="page-header text-center m-auto padding-h1">{{'Mes anomalies' | translate }}</h1>
  </div>

  <div class="row m-0 padding-table">
    <div class="col-12 m-0 alert alert-light fade show shadow">
      <app-filter-anomalies (submitFilter)="onSubmitFilter($event)" (load)="onLoadFilter($event)"></app-filter-anomalies>
    </div>
  </div>

  <div class="row m-0 padding-table" tourAnchor="tableau.anomalies">
    <div class="col-12 p-0">
      <panel noButton="true" bodyClass="no-padding panel">
        <div class="row" header>
          <div class="col-12">
            <h5 class="p-t-5 text-agediss">{{anomaliesTitle}} :
              <span *ngIf="!loadingAnomalies">{{anomalies.length}}</span>
              <i *ngIf="loadingAnomalies" class='fas fa-spin fa-circle-notch'></i>
              <button class="btn btn-agediss shadow export"
                      (click)="export()">Exporter</button>
            </h5>
          </div>
        </div>
        <div>
          <app-table-anomalies [data]="anomalies" [loading]="loadingAnomalies"></app-table-anomalies>
        </div>
      </panel>
    </div>
  </div>
</div>
