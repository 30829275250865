import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {HttpUtil} from '../../utils/http.util';
import {AnomalieModel} from '../../models/api/anomalie.model';

@Injectable({
  providedIn: 'root'
})
export class AnomaliesService {

  urlRessource = environment.url_api_espace_client + '/anomalies';

  constructor(private http: HttpClient) {
  }

  getAnomalies(filter: any = null): Observable<AnomalieModel[]> {
    return this.http
      .get<AnomalieModel[]>(`${this.urlRessource}`, {params: HttpUtil.convertObjectToHttpParams(filter)})
      .pipe(map(anomalies => anomalies.map(data => new AnomalieModel().deserialize(data)
      )));
  }

}
