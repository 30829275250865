const pageMenuSecondaire = [
  {
    icon: 'fa fa-address-card',
    title: 'Mon contact privilégié',
    url: '/contact',
    restrictRole: 'UTILISATEUR_MENU_CONTACT-PRIVILEGIE'
  },
];

export default pageMenuSecondaire;
