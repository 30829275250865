import {Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {DatatableHelper} from '../../../../shared/helpers/datatable.helper';
import {Grc} from '../../../../shared/models/api/grc.model';
import {tableAnimation} from '../../../../animations';
import Swal from 'sweetalert2';
import {GrcService} from '../../../../shared/services/api/grc.service';
import {ToastrService} from 'ngx-toastr';
import {Subscription} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {AuthenticationService} from "../../../../shared/services/authentication.service";
import {PageEvent} from "@angular/material/paginator";
import {View} from '../../../../shared/models/view.model';
import {GrcData} from "../../../../shared/models/api/grc-data.model";

@Component({
  selector: 'app-table-grc-by-commande',
  templateUrl: './table-grc-by-commande.component.html',
  styleUrls: ['./table-grc-by-commande.component.scss'],
  animations: [tableAnimation],
})
export class TableGrcByCommandeComponent implements OnInit {

  expandedElement: GrcData | null;

  @Output() onSelectFile: EventEmitter<GrcData> = new EventEmitter<GrcData>();
  @Output() onSorted: EventEmitter<Sort> = new EventEmitter();
  @Output() selectShowMessage: EventEmitter<{ id: number }> = new EventEmitter<{ id: number }>();
  @Output() selectShowPieceJointe: EventEmitter<{ id: number }> = new EventEmitter<{ id: number }>();
  @Output() selectConversation: EventEmitter<GrcData> = new EventEmitter<GrcData>();
  @Output() deletedGrc: EventEmitter<number> = new EventEmitter<number>();

  @Input() displayedColumns: string[] = [
    'voir',
    'login',
    'objet',
    'commentaire',
    'created_at',
    'updated_at',
    'statut',
    'options'
  ];
  @Input() data: GrcData[];
  @Input() grc: Grc;
  @Input() loading = false;
  @Input() hiddenColumns: string[] = [];
  @Input() showViewMessageBtn: boolean = false;
  @Input() showFileBtn: boolean = false;
  @Input() showDeleteDemandGrcBtn: boolean = false;
  @Input() showInfosBtn: boolean = false;
  @Input() length: number;
  @Input() pageSize: number = 10;
  @Input() pageIndex: number;
  @Input() pageEvent: PageEvent;
  @Input() pageSizeOptions: number[] = [5, 10, 20];

  @Output() paginatorEvent : EventEmitter<PageEvent> = new EventEmitter<PageEvent>()

  public innerWidth: number;
  public dataSource: any;
  public sub: Subscription;
  public view: View;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private translate: TranslateService,
    private grcSvc: GrcService,
    private authSvc: AuthenticationService,
    private toastr: ToastrService) {
    this.authSvc.currentView.subscribe(x => this.view = x);
  }

  ngOnInit() {
    this.onResizeTable();
    this.dataSource = new MatTableDataSource<GrcData>(this.data);
    if (this.hiddenColumns.length > 0) {
      this.hiddenColumns.forEach((value) => {
        let index = this.displayedColumns.indexOf(value);
        if (index) {
          this.displayedColumns.splice(index, 1);
        }
      });
    }
  }

  emit($event: PageEvent) {
    return this.paginatorEvent.emit($event);
  }

  /**
   * LifeHook : à chaque changement de value de data on rafraichi les données du tableau
   * @param changes
   */
  ngOnChanges(changes: any) {
    if (changes.data) {
      this.dataSource = new MatTableDataSource(changes.data.currentValue);
      this.dataSource.sort = this.sort;
    }
    if (changes.loading) {
      if (changes.data) {
        this.dataSource = new MatTableDataSource(changes.data.currentValue);
        this.dataSource.sort = this.sort;
      }
      if (changes.loading) {
        this.loading = changes.loading.currentValue;
      }
    }
  }

  selectFile(grc: GrcData) {
    this.onSelectFile.emit(grc);
  };

  /**
   * Permet d'ordonner les colones.
   */
  sortData(sort: Sort) {
    const data = this.data.slice();
    if (!sort.active || sort.direction === '') {
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.sort = this.sort;
      return;
    }
    this.dataSource = new MatTableDataSource(data.sort(
      (a, b) => {
        const isAsc = sort.direction === 'asc';
        if (sort.active in a) {
          return DatatableHelper.compare(a[sort.active], b[sort.active], isAsc);
        }
        return 0;
      }));
    this.dataSource.sort = this.sort;
  }

  delete(grcId: number) {
    let grc = this.data.find(x => {
      return x.id == grcId;
    });
    let index = this.data.indexOf(grc);

    Swal.fire({
      title: this.translate.instant('Attention !'),
      text: this.translate.instant("Êtes-vous sûr de vouloir supprimer le message {{ value }} ?", { value: this.dataSource.data[index].id}),
      type: 'warning',
      showCancelButton: true,
      cancelButtonText: this.translate.instant('NON'),
      confirmButtonText: this.translate.instant('OUI'),

    }).then((result) => {
      if (result.value) {
        //affiche le chargement
        this.dataSource.data[index].pending = true;

        //service pour supprimer le fichier
        this.sub = this.grcSvc.delete(grcId).subscribe(
          (grc) => {
            this.grc = grc;
            this.toastr.info(
              this.translate.instant("La demande GRC a été supprimée"),
            );
            this.dataSource.data[index].pending = false;
            this.dataSource.data.splice(index, 1);
            this.dataSource = new MatTableDataSource(this.dataSource.data);
            this.deletedGrc.emit(1);
          },
          () => {
            this.dataSource.data[index].pending = false;
            this.toastr.error(
              this.translate.instant("La demande GRC n'a pas pu être supprimée"),
            );
          },
        );
      }

    });
  }

  onSelectConversation(grc: GrcData) {
    this.selectConversation.emit(grc);
  };

  selectShowFile(id: number) {
    this.selectShowPieceJointe.emit({id});
  };

  @HostListener('window:resize', ['$event'])
  onResizeTable() {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth < 465) {
      this.displayedColumns = [
        'voir',
        'login',
        'options'
      ];
    } else if (this.innerWidth > 465 && this.innerWidth <= 860) {
      this.displayedColumns = [
        'voir',
        'login',
        'statut',
        'options'
      ];
    } else if (this.innerWidth > 860 && this.innerWidth <= 930) {
      this.displayedColumns = [
        'voir',
        'login',
        'objet',
        'statut',
        'options'
      ];
    } else if (this.innerWidth > 930 && this.innerWidth <= 1090) {
      this.displayedColumns = [
        'voir',
        'login',
        'objet',
        'commentaire',
        'statut',
        'options'
      ];
    } else if (this.innerWidth > 1090 && this.innerWidth <= 1200) {
      this.displayedColumns = [
        'voir',
        'login',
        'objet',
        'commentaire',
        'created_at',
        'statut',
        'options'
      ];
    } else if (this.innerWidth > 1200) {
      this.displayedColumns = [
        'voir',
        'login',
        'objet',
        'commentaire',
        'created_at',
        'updated_at',
        'statut',
        'options'
      ];
    }
  }
}
