import {Deserializable} from './interfaces/deserializable.interface';
import {Application} from './api/application.model';
import {CompteClient} from './api/compte-client.model';
import {Role} from './api/role.model';
import {Organisation} from "./api/organisation.model";

export class UserData implements Deserializable {
  public id: number;
  public username: string;
  public roles: string[];
  public email: string;
  public tel: string;
  public application: Application;
  public compte_client: CompteClient;
  public organisation: Organisation;
  public role: Role;
  public fonction: string;
  public password: string;
  public firstname: string;
  public lastname: string;
  public employment?: string;
  public fullname?: string;
  public pending?: boolean;

  public getFullname(reverse = false) {
    if(!this.firstname && !this.lastname && this.fullname){
      return this.fullname;
    }
    if (reverse) {
      return `${this.firstname} ${this.lastname}`;
    }
    return `${this.lastname} ${this.firstname}`;
  }

  deserialize(input: any): this {
    Object.assign(this, input);
    this.application = (input.application) ? new Application().deserialize(input.application) : null;
    this.compte_client = (input.compte_client) ? new CompteClient().deserialize(input.compte_client) : null;
    this.role = (input.role) ? new Role().deserialize(input.role) : null;
    return this;
  }

}
