import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FilterMultiCritereModel} from "../../../shared/models/filters/filterMultiCritere.model";
import {FormControl, FormGroup} from "@angular/forms";
import {FilterService} from "../../../shared/services/filter.service";
import {FormfilterHelper} from "../../../shared/helpers/Formfilter.helper";
import {FieldParametersModel} from "../../../shared/models/filters/field-parameters.model";

@Component({
  selector: 'app-filter-video',
  templateUrl: './filter-video.component.html',
  styleUrls: ['./filter-video.component.scss']
})
export class FilterVideoComponent implements OnInit {

  @Output() submitFilter: EventEmitter<FilterMultiCritereModel> = new EventEmitter<FilterMultiCritereModel>();
  @Output() loadFilter: EventEmitter<FilterMultiCritereModel>  = new EventEmitter<FilterMultiCritereModel>();

  @Input() displayInput: FieldParametersModel[] = [];

  public filterForm = new FormGroup({
    search: new FormControl()
  });

  // Tableaux
  public hiddenInput = [];

  constructor(private filterSvc: FilterService) {
  }

  ngOnInit(): void {
    this.onInitFilter();
  }

  onInitFilter() {
    FormfilterHelper.hydrateForm(this.displayInput, this.filterForm);
    this.hiddenInput = FormfilterHelper.extractHiddenInput(this.displayInput);
    this.getValueLocalstorage();
    this.loadFilter.emit(this.filterForm.getRawValue());
  }

  getValueLocalstorage() {
    this.filterForm.get('search').setValue(localStorage.getItem('videoSearchFilter'));
  }

  onSubmit() {
    // récupère les infos du filtre
    this.filterSvc.onSaveInLocalstorage(this.filterForm, 'videoSearchFilter', 'search');

    if(this.filterForm.get('search').value !== null && this.filterForm.get('search').value === '') {
      this.filterForm.get('search').setValue(null)
      localStorage.removeItem('videoSearchFilter');
    }

    this.submitFilter.emit(this.filterForm.getRawValue());
  }

  reset(): void {
    this.filterForm.reset();
  }
}
