import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {HttpClient, HttpParams} from "@angular/common/http";
import {map} from "rxjs/operators";
import {environment} from '../../../../../environments/environment';
import {WexlogTypeTextMessageGrc} from "../../../models/api/wexlog/wexlog-type-text-message-grc.model";
import {HttpUtil} from "../../../utils/http.util";


@Injectable({
  providedIn: 'root'
})
export class  TypeTextMessageGrcWexlogService {


  urlRessource = environment.url_api_espace_client + "/wexlog-type-text-message-grc";

  constructor(private http: HttpClient) {

  }

  getTypeTextMessagesGrcClient(typeMessageId: string, typeTextMessageId = null): Observable<WexlogTypeTextMessageGrc[]> {

    let params = new HttpParams();

    if (typeTextMessageId !== null) {
      params = HttpUtil.convertObjectToHttpParams({typeTextMessageId: typeTextMessageId})
    }

    return this.http
      .get<WexlogTypeTextMessageGrc[]>(`${this.urlRessource}/${typeMessageId}/type-text-message`,{params: params})
      .pipe(map((observe) => observe.map(function (observe) {
        return new WexlogTypeTextMessageGrc().deserialize(observe);
      })))
  }

}
