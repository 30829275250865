import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'statutTimeline'
})
export class StatutTimelinePipe implements PipeTransform {

  transform(value: string, ...args: any[]): any {
    switch(value) {
      case null:
        return 'no-started';
      case'ATTENTE':
      case 'AT':
        return 'in-progress';
      case 'OK':
        return 'completed';
      case 'NK':
        return 'error';
      default:
        return 'no-started';
    }
  }
}
