import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {CommandeService} from '../../shared/services/api/commande.service';
import {FilterMultiCritereModel} from '../../shared/models/filters/filterMultiCritere.model';
import {PageEvent} from '@angular/material/paginator';
import {PaginationService} from '../../shared/services/pagination.service';
import {SuiviLivraisonData} from '../../shared/models/api/suivi-livraison-data.model';
import {View} from '../../shared/models/view.model';
import {AuthenticationService} from '../../shared/services/authentication.service';
import {StatService} from '../../shared/services/api/stat.service';
import {Stats} from '../../shared/models/api/stats.model';
import {AnomaliesService} from '../../shared/services/api/anomalies.service';
import {Router} from "@angular/router";

@Component({
  selector: 'tableau-de-bord',
  styleUrls: ['./tableau-de-bord.scss'],
  templateUrl: './tableau-de-bord.html'
})

export class TableauDeBord implements OnInit, OnDestroy {

  private filterSuiviLivraison: FilterMultiCritereModel = new FilterMultiCritereModel();
  private filterStats: FilterMultiCritereModel = new FilterMultiCritereModel();
  public suiviLivraison: SuiviLivraisonData;
  public pageEvent: PageEvent;
  public statsNps: Stats;
  public statsLivraison: Stats;
  public statsGrc: Stats;
  public anomaliesCount: number = null;
  public subscription: Subscription;

  // tableau
  public suiviLivraisonData: SuiviLivraisonData[];
  public subscriptions: Subscription[] = [];
  public pageSizeOption = [5, 10, 20];

  // number
  public refresh: number;
  public pageSize = 5;
  public nbData = 0;

  public view: View;

  public multiNps = [];
  public multiLivraison = [];
  public multiGrc = [];
  public single = [];

  // loading
  public loadingNbCommandes: boolean;
  public loadingTableCommande = false;
  public loadingChartsNps = false;
  public loadingChartsLivraison = false;
  public loadingChartsGrc = false;
  public loadingChartsLivraisonByStatut = false;
  public loadingDataNps = false;
  public loadingDataLivraison = false;
  public loadingDataGrcByDate = false;
  public loadingDataGrcByStatut = false;
  public showChartsLivraisonByStatut = false;

  constructor(private translate: TranslateService,
              private authSvc: AuthenticationService,
              private statSvc: StatService,
              private paginationSvc: PaginationService,
              private commandeSvc: CommandeService,
              private router: Router,
              private anomaliesSvc: AnomaliesService) {
      this.authSvc.currentView.subscribe(x => this.view = x);
  }

  ngOnInit() {
    if (this.view.hasDroit('COMMANDE_MENU_TABLEAU-DE-BORD')) {
      this.refresh = setInterval(() => {
        this.loadDataCommandesToday();
      }, 300000);
      this.subscriptions.push(this.translate.onLangChange.subscribe(() => {
        this.loadDataCommandesToday();
      }));
    } else {
      this.router.navigate(['/', 'home']);
    }
  }

  onSubmit(filter = null) {
    // récupère les infos du filtre
    if (filter) {
      this.filterStats = filter;
    }
    this.loadAnomalies();
    this.loadStatsNps();
    this.loadDataNps();
    this.loadStatsLivraison();
    this.loadDataLivraisons();
    this.loadStatsGrcByStatut();
    this.loadDataGrc();
    this.loadDataLivraisonsByStatut();
  }

  onLoadFilter(filter = null) {
    // récupère les infos du filtre
    if (filter) {
      // this.filter = {...filter, ...this.filter};
      this.filterStats = {...filter, ...this.filterStats};
    }
    this.loadAnomalies();
    this.loadStatsNps();
    this.loadDataNps();
    this.loadStatsLivraison();
    this.loadDataLivraisons();
    this.loadStatsGrcByStatut();
    this.loadDataGrc();
    this.loadDataLivraisonsByStatut();
  }

  onSubmitSuiviLivraison(filter = null) {
    // récupère les infos du filtre
    if (filter) {
      this.filterSuiviLivraison = filter;
    }
    this.loadDataCommandesToday();
  }

  onLoadFilterSuiviLivraison(filter = null) {
    // récupère les infos du filtre
    if (filter) {
      // this.filter = {...filter, ...this.filter};
      this.filterSuiviLivraison = {...filter, ...this.filterSuiviLivraison};
    }
    this.loadDataCommandesToday();
  }

  loadAnomalies() {
    this.subscriptions.push(this.anomaliesSvc.getAnomalies({}).subscribe((ano) => {
      this.anomaliesCount = ano.length;
    }));
  }

  /**
   * Stats
   * On récupère le nombre de demande Grc par semaine
   */
  loadStatsNps() {
    this.loadingDataNps = true;
    this.subscriptions.push(this.statSvc.getNpsStats(this.filterStats).subscribe((stats) => {
        this.statsNps = stats;
        this.loadingDataNps = false;
      },
      (error) => {
        this.loadingDataNps = false;
      }
    ));
  }

  /**
   * Stats
   * On récupère le nombre de livraison par semaine
   */
  loadStatsLivraison() {
    this.loadingDataLivraison = true;
    this.subscriptions.push(this.statSvc.getLivraisonsStats(this.filterStats).subscribe((stats) => {
        this.statsLivraison = stats;
        this.loadingDataLivraison = false;
      },
      (error) => {
        this.loadingDataLivraison = false;
      }
    ));
  }

  /**
   * Stats
   * On récupère le nombre de demande Grc par semaine
   */
  loadStatsGrcByStatut() {
    this.loadingDataGrcByStatut = true;
    this.subscriptions.push(this.statSvc.getDemandesGrcStatsByStatut(this.filterStats).subscribe((stats) => {
        this.statsGrc = stats;
        this.loadingDataGrcByStatut = false;
      },
      (error) => {
        this.loadingDataGrcByStatut = false;
      }
    ));
  }

  /**
   * Stats
   * On récupère le NPS par semaine
   */
  loadDataNps() {
    this.loadingChartsNps = true;
    this.multiNps = [];
    this.subscriptions.push(this.statSvc.getChartsNps(this.filterStats).subscribe((data) => {
        this.multiNps.push({name: this.translate.instant('Score NPS'), series: data});
        this.multiNps = this.multiNps.slice();
        this.loadingChartsNps = false;
      },
      (error) => {
        this.loadingChartsNps = false;
      }
    ));
  }

  /**
   * Stats
   * On récupère le nombre de livraisons par semaine
   */
  loadDataLivraisons() {
    this.loadingChartsLivraison = true;
    this.multiLivraison = [];
    this.subscriptions.push(this.statSvc.getChartsLivraisons(this.filterStats).subscribe((data) => {
        this.multiLivraison.push({name: this.translate.instant('Nombre de livraisons'), series: data});
        this.multiLivraison = this.multiLivraison.slice();
        this.loadingChartsLivraison = false;
      },
      (error) => {
        this.loadingChartsLivraison = false;
      }
    ));
  }

  /**
   * Stats
   * On récupère le nombre de demandes Grc par semaine
   */
  loadDataGrc() {
    this.loadingChartsGrc = true;
    this.multiGrc = [];
    this.subscriptions.push(this.statSvc.getChartsDemandesGrc(this.filterStats).subscribe((data) => {
        this.multiGrc.push({name: this.translate.instant('Nombre de demandes GRC'), series: data});
        this.multiGrc = this.multiGrc.slice();
        this.loadingChartsGrc = false;
      },
      (error) => {
        this.loadingChartsGrc = false;
      }
    ));
  }

  /**
   * Stats
   * On récupère le nombre de commandes par statut non livrées
   */
  loadDataLivraisonsByStatut() {
    this.loadingChartsLivraisonByStatut = true;
    this.subscriptions.push(this.statSvc.getChartsCommandesByStatut(this.filterStats).subscribe((data) => {
        this.single = [];
        if (data === 'Aucune donnée n\'est disponible') {
          this.showChartsLivraisonByStatut = false
          this.loadingChartsLivraisonByStatut = false;
        } else {
          for (let i = 0; i < data.length; i++) {
            this.showChartsLivraisonByStatut = true;
            this.single.push(data[i]);
            this.single = this.single.slice();
            this.loadingChartsLivraisonByStatut = false;
          }
        }
      },
      (error) => {
        this.showChartsLivraisonByStatut = false
        this.loadingChartsLivraisonByStatut = false;
      }
    ));
  }

  /**
   * On récupère toutes les commandes liès au compte client
   */
  loadDataCommandesToday() {
    this.loadingTableCommande = true;
    this.suiviLivraisonData = [];
    this.subscriptions.push(this.commandeSvc.getLivraisonsTodayDelivery(this.filterSuiviLivraison).subscribe((commandes) => {
        this.suiviLivraisonData = commandes;
        this.suiviLivraisonData.forEach((value, index) => {
          let progessStatut = this.statut(value);
          this.suiviLivraisonData[index]['progressBar'] = progessStatut['progressBar'];
          this.suiviLivraisonData[index]['color'] = progessStatut['color'];
          this.suiviLivraisonData[index]['statut'] = progessStatut['statut'];
          this.suiviLivraisonData[index]['date'] = progessStatut['date'];
        });
        this.loadingTableCommande = false;
      },
      error => {
        this.loadingTableCommande = false;
      }));
  }

  statut(value) {
    let tab = [];
    if ((value.activite_nom !== 'RE') && (!value.depart_plateforme_ok || value.depart_plateforme_ok === 'AT') && !value.livraison_ok) {
      tab['progressBar'] = 10 + '%';
      tab['color'] = 'bg-secondary';
      tab['statut'] = this.translate.instant('En attente de livraison - Agence régionale');
      tab['date'] = '';
    } else if ((value.activite_nom !== 'RE') && (value.depart_plateforme_ok === 'NK')) {
      tab['progressBar'] = 60 + '%';
      tab['color'] = 'bg-danger';
      tab['statut'] = this.translate.instant('Anomalie lors du chargement - Agence régionale');
      tab['date'] = value.chargement_date_evenement;
    } else if ((value.activite_nom !== 'RE') && (value.depart_plateforme_ok === 'OK') && (!value.livraison_ok)) {
      tab['progressBar'] = 60 + '%';
      tab['color'] = 'bg-info';
      tab['statut'] = this.translate.instant('Livraison en cours');
      tab['date'] = value.chargement_date_evenement;
    } else if ((value.activite_nom !== 'RE') && (value.livraison_ok === 'NK')) {
      tab['progressBar'] = 100 + '%';
      tab['color'] = 'bg-danger';
      tab['statut'] = this.translate.instant('Anomalie lors de la livraison');
      tab['date'] = value.livraison_date_evenement;
    } else if ((value.activite_nom !== 'RE') && (value.depart_plateforme_ok === 'OK' || value.depart_plateforme_ok === 'AT') && value.livraison_ok === 'AT') {
      tab['progressBar'] = 100 + '%';
      tab['color'] = 'bg-warning';
      tab['statut'] = this.translate.instant('Avis de passage');
      tab['date'] = value.livraison_date_evenement;
    } else if ((value.activite_nom !== 'RE') && (value.livraison_ok === 'OK')) {
      tab['progressBar'] = 100 + '%';
      tab['color'] = 'bg-green';
      tab['statut'] = this.translate.instant('Livré');
      tab['date'] = value.livraison_date_evenement;
    } else if ((value.activite_nom == 'RE') && (!value.livraison_ok || value.livraison_ok === 'AT')) {
      tab['color'] = 'bg-secondary';
      tab['statut'] = this.translate.instant('En attente de reprise');
      tab['date'] = value.livraison_date_evenement;
    } else if ((value.activite_nom === 'RE') && (value.livraison_ok === 'OK')) {
      tab['color'] = 'bg-green';
      tab['statut'] = this.translate.instant('Reprise effectuée');
      tab['date'] = value.livraison_date_evenement;
    } else if ((value.activite_nom === 'RE') && (value.livraison_ok === 'NK')) {
      tab['color'] = 'bg-danger';
      tab['statut'] = this.translate.instant('Anomalie lors de la reprise');
      tab['date'] = value.livraison_date_evenement;
    }
    return tab;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

}
