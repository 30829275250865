import {Deserializable} from "../interfaces/deserializable.interface";

export class TypePieceJointe implements Deserializable {

  public id?: number;
  public created_at?: Date;
  public updated_at?: Date;
  public code?: string;
  public libelle?: string;
  public is_justificatif?: boolean;

  deserialize(input: any): this {

    Object.assign(this, input);
    return this;
  }

}
