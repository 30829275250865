import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import Swal from 'sweetalert2';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {CommandeService} from "../../../shared/services/api/commande.service";
import {TourService} from "ngx-tour-ngx-bootstrap";
import {CommandeData} from "../../../shared/models/api/commande-data.model";

@Component({
  selector: 'app-retour',
  templateUrl: './retour.html',
  styleUrls: ['./retour.scss']
})
export class RetourPage implements OnInit, OnDestroy {

  public retour: string = 'retour';
  public loadingPage = false;
  public subscriptions: Subscription[] = [];
  public commandeData: CommandeData;

  constructor(private translate: TranslateService,
              private commandeSvc: CommandeService,
              private router: Router,
              public tourService: TourService,
              private routeActive: ActivatedRoute) {
  }

  ngOnInit() {
    this.subscriptions.push(this.routeActive.params.subscribe((params) => {
      this.subscriptions.push(this.translate.onLangChange.subscribe(() => {
        this.loadDataLivraison(params)
      }))
      this.loadDataLivraison(params)
    }));
  }

  loadDataLivraison(params) {
    this.loadingPage = true;
    this.subscriptions.push(this.commandeSvc.getLivraison(params.id).subscribe(
      (commande) => {
        this.commandeData = commande;
        if(params.id !== this.commandeData.numero_avis) {
          this.router.navigate(['/', 'retours', this.commandeData.numero_avis]);
        }
        this.loadingPage = false;
      },
      () => {
        Swal.fire({
          title: this.translate.instant('Désolé'),
          text: this.translate.instant("La commande demandée n'existe pas !"),
          type: 'error',
        })
          .then(
            () => {
              this.router.navigate(['/retours']);
            }
          ).catch(() => {
          Swal.fire({
            title: this.translate.instant('Désolé'),
            text: this.translate.instant('Une erreur est survenue'),
            type: 'error',
          });
        });
      }));
  }

  ngOnDestroy(){
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
