import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {Subscription} from 'rxjs';
import {Evenement} from '../../../../shared/models/api/evenement.model';
import {TranslateService} from "@ngx-translate/core";
import {CommandeService} from "../../../../shared/services/api/commande.service";
import {View} from "../../../../shared/models/view.model";
import {AuthenticationService} from "../../../../shared/services/authentication.service";
import {CommandeData} from "../../../../shared/models/api/commande-data.model";

@Component({
  selector: 'app-evenement-content',
  styleUrls: ['./evenement-content.scss'],
  templateUrl: './evenement-content.html'
})

export class EvenementContent implements OnInit, OnDestroy {

  @Input() commandeNumeroAvis: number;
  @Input() commande: CommandeData;
  public activModal: NgbModalRef;

  // loader
  public loadingPage = false;
  public loadingTableEvenement = false;


  // tableaux
  public evenements: Evenement[];
  public evenement: Evenement;
  public subscriptions: Subscription[] = [];

  public view: View;

  constructor(private translate: TranslateService,
              private authSvc: AuthenticationService,
              private modalSvc: NgbModal,
              private commandeSvc: CommandeService) {
  }

  ngOnInit() {
    this.authSvc.currentView.subscribe(x => this.view = x);
    this.subscriptions.push(this.translate.onLangChange.subscribe(() => {
      this.loadDataEvenements();
    }));
    this.loadDataEvenements();
  }

  loadDataEvenements() {
    this.loadingTableEvenement = true;
    this.evenements = [];
    const avis = !!this.commandeNumeroAvis ? `${this.commandeNumeroAvis}` : this.commande.numero_avis;
    this.subscriptions.push(this.commandeSvc.getEvenements(avis).subscribe(evenements => {
          this.evenements = evenements;
          this.loadingTableEvenement = false;
        },
        (error) => {
          this.loadingTableEvenement = false;
        }
      )
    );
  }

  openModalVisualisationFile(modalName, evenement: Evenement) {
    this.evenement = evenement
    this.activModal = this.modalSvc.open(modalName, {
      size: 'lg',
    });
  }

  openModalGeolocalisation(modalName, evenement: Evenement) {
    this.evenement = evenement
    this.activModal = this.modalSvc.open(modalName, {
      size: 'lg',
    });
  }

  openModalEvenementColis(modalName, evenement: Evenement) {
    this.evenement = evenement
    this.activModal = this.modalSvc.open(modalName, {
      size: 'xl',
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
