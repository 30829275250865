import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {NgbDateParserFormatter, NgbInputDatepicker} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-date-picker-min-date-extraction-chargement',
  templateUrl: './date-picker-min-date-extraction-chargement.component.html',
  styleUrls: ['./date-picker-min-date-extraction-chargement.component.scss']
})
export class DatePickerMinDateExtractionChargementComponent implements OnInit {

  @Input() filterForm: FormGroup;
  @Input() date: any;
  @Input() filterName: string;
  @Input() formControlname: string;

  @ViewChild('datepicker') private _input: NgbInputDatepicker;

  public innerWidth: number;

  constructor(public _parserFormatter: NgbDateParserFormatter) {
  }

  ngOnInit() {
    this.innerWidth = window.innerWidth;
    if (!(this.filterForm instanceof FormGroup)) {
      throw new Error('Vous devez passer un formGroup en parametre du composant RangeDatePickerComponent');
    }
  }

  onDateSelection(date) {
    if (!this.date) {
      this.date = date;
    }
    if (this.date && this.filterForm.controls['minDateChargement']) {
      this.filterForm.controls['minDateChargement'].setValue(new Date(Date.UTC(this.date.year, this.date.month - 1, this.date.day)));
    }
  }

  openDatePicker() {
    this.reset();
    localStorage.removeItem(this.filterName);
    this._input.toggle();
  }

  reset() {
    this.date = null;
  }

}
