<div *ngIf="loadingPage" class="spinner"></div>
<div *ngIf="!loadingPage">
  <div class="row m-0 padding-tab">
    <div class="col-md-12 col-lg-6 pt-4 m-0">
      <panel variants="danger" title="{{'Commande' | translate}}" noButton="true" bodyClass="no-padding panel" class="text-center">
        <div class="scrollbar scrollbar-black bordered-black square thin overflow-scroll text-left panel-commande-litige">
          <app-litige-livraison-component [litigeData]="litigeData"
                                         [showViewCommandeBtn]="view.hasDroit('COMMANDE_SHOW')"
                                         [showViewDocumentBtn]="view.hasDroit('LITIGE_PIECE-JOINTE_SHOW')"
                                         (selectShowDocument)="openModalShowDocuments(modal_documents_litige, $event )">
          </app-litige-livraison-component>
        </div>
      </panel>
    </div>
    <div class="col-md-12 col-lg-6 pt-4 m-0">
        <panel variants="danger" title="{{'Articles' | translate}}" noButton="true" bodyClass="no-padding panel"
               class="text-center">
          <div class="scrollbar scrollbar-black bordered-black square thin overflow-scroll text-left panel-article-litige">
            <app-table-article [data]="articles" [loading]="loadingTableArticle" [showViewBtnArticle]="false"
                               [showViewBtn]="view.hasDroit('LITIGE_ARTICLE_SHOW')"
                               [showInfosBtn]="true"
                               (selectShowArticle)="openModalShowArticle(modal_visualisation_articles, $event)">
            </app-table-article>
          </div>
        </panel>
    </div>
  </div>
  <div class="row m-0 padding-tab">
    <div class="col-md-12 col-lg-12 col-xl-6 py-4 m-0">
      <panel variants="danger" title="{{'Litige' | translate}}" noButton="true" bodyClass="no-padding panel"
             class="text-center">
        <div class="scrollbar scrollbar-black bordered-black square thin overflow-scroll text-left panel-infos-litige">
          <app-litige-component [litigeData]="litigeData"></app-litige-component>
        </div>
      </panel>
    </div>
    <div class="col-md-12 col-lg-12 col-xl-6 py-4 m-0">
      <panel variants="danger" title="{{'Instructions complémentaires' | translate}}" noButton="true" bodyClass="no-padding panel"
             class="text-center">
        <div class="scrollbar scrollbar-black bordered-black square thin overflow-scroll text-left panel-instruction-litige">
          <app-informations-complementaires [litigeData]="litigeData"></app-informations-complementaires>
        </div>
      </panel>
    </div>
  </div>
</div>

<!-- modal documents Litige -->
<ng-template #modal_documents_litige let-modal>
    <app-modal-documents [litigeId]="litigeData.id" (canceled)="modal.close($event)"></app-modal-documents>
</ng-template>
<!-- fin de modal documents Litige -->

<!--modal Visualistation Article-->
<ng-template #modal_visualisation_articles let-modal>
  <app-visualisation-article [articleId]="selectArticle" (canceled)="modal.close($event)"></app-visualisation-article>
</ng-template>
<!-- fin de modal Visualistation Article-->

