import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Subscription} from 'rxjs';
import {ToastrService} from 'ngx-toastr';
import {Medias} from '../../../../shared/models/api/medias.model';
import {MediasService} from '../../../../shared/services/api/media/medias.service';


@Component({
  selector: 'app-visualisation-message',
  templateUrl: './visualisation-message.component.html',
  styleUrls: ['./visualisation-message.component.scss']
})
export class VisualisationMessageComponent implements OnInit {

  @Input() public media: Medias;

  @Output() canceled: EventEmitter<void> = new EventEmitter();

  public loading = false;
  public subscriptions: Subscription[] = [];

  constructor(private mediasSvc: MediasService, private toastr: ToastrService)  {

  }

  ngOnInit() {
  }

  cancel() {
    this.canceled.emit();
  }

}
