import {Deserializable} from '../../interfaces/deserializable.interface';
import {PieceJointeGed} from "./piece-jointe-ged.model";
import {ImageGed} from "./image-ged.model";

export class SignatureLivraison implements Deserializable {

  public numero_avis: number;
  public numero_client: number;
  public creneau_debut?: Date | string;
  public creneau_fin?: Date | string;
  public latitude: string;
  public longitude: string;
  public numero_tournee?: string;
  public date ?: string | Date;
  public bl_emarge?: ImageGed[];
  public signature?: PieceJointeGed[];

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }

}
