
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  urlsso: 'sso.agediss.com',
  url_api_espace_client: 'api-espace-client.agediss.com',
  url_api_scansrv: 'api-ged.agediss.com',
  api_token_agediss: 'a4fe7285932a7cf45f6a77917fc8e4469baa81d0',
  application: 'ESPACE_CLIENT',
  api_key_google_map: 'AIzaSyB6-wL8kt_AKORcCRJTpy0UpQOWcqIvG_A',
  domain : '.agediss.com',
  sentry_dsn: 'https://7f2c8b360c824cab8ab8b45a67804628@sentry.agediss.com/6',
  sentry_env: 'prod',
  size_max_file: 18000000, // bytes
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
