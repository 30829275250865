<div *ngIf="view.hasDroit('LITIGE_MENU_FACTURE')">
  <div class="row m-0 padding">
    <h1 class="page-header m-auto text-center padding-h1">{{'Mes factures' | translate }}</h1>
  </div>

<div class="row m-0 padding-table">
  <div class="col-12 m-0 alert alert-light fade show shadow">
    <app-filter-facture (submitFilter)="onSubmit($event)" (onLoad)="onLoadFilter($event)"></app-filter-facture>
  </div>
</div>

<div class="row m-0 padding-table" tourAnchor="tableau.factures.traitement">
  <div class="col-12 p-0">
    <panel noButton="true" bodyClass="no-padding panel">
      <div class="row" header>
        <div class="col-12"><h5
          class="p-t-5 text-truncate text-agediss">{{'Mes factures' | translate }} : <span
          *ngIf="!loadingNbData">{{nbData}}</span> <i *ngIf="loadingNbData" class='fas fa-spin fa-circle-notch'></i></h5>
        </div>
      </div>
      <div>
        <app-table-facture-a-traitees [data]="factureData"
                                      [loading]="loadingTableFacture"
                                      [length]="nbData"
                                      [pageSize]="pageSize"
                                      (paginatorEvent)="onLoadDataByEvent($event)"
                                      [pageSizeOptions]="pageSizeOption"
                                      [showViewBtn]="view.hasDroit('LITIGE_FACTURE-TRAITEE_SHOW')"
                                      [showInfosBtn]="true"
                                      (selectFacture)="openModalShowFacture(modal_visualisation_file_and_litige, $event)">
        </app-table-facture-a-traitees>
      </div>
    </panel>
  </div>
</div>

<div class="row m-0 padding-table" tourAnchor="tableau.factures.attente">
  <div class="col-12 p-0">
    <panel noButton="true" bodyClass="no-padding panel">
      <div class="row" header>
        <div class="col-12">
          <h5 class="p-t-5 text-truncate text-agediss">{{'Mes factures en attente' | translate }} :
            <span *ngIf="!loadingNbPieceJointeData">{{nbPieceJointes}}</span> <i *ngIf="loadingNbPieceJointeData" class='fas fa-spin fa-circle-notch'></i>
          </h5>
        </div>
      </div>
      <div>
        <app-table-facture-attente [data]="pieceJointesData"
                                   [length]="nbPieceJointes"
                                   [pageSize]="pageSize"
                                   (paginatorEvent)="onLoadDataByEvent($event)"
                                   [pageSizeOptions]="pageSizeOption"
                                   [showViewBtn]="view.hasDroit('LITIGE_FACTURE-ATTENTE_SHOW')"
                                   [showDeleteBtn]="view.hasDroit('LITIGE_FACTURE-ATTENTE_DELETE')"
                                   [showInfosBtn]="true"
                                   [loading]="loadingTableFactureAttente"
                                   (onSelectFile)="openModalVisualisationFile(modal_visualisation,$event)">
        </app-table-facture-attente>
      </div>
    </panel>
  </div>
</div>

<ng-template #modal_visualisation_file_and_litige let-modal>
  <app-visualisation-facture [pieceJointeData]="pieceJointeData"
                             [piecesJointesGed]="piecesJointesGed"
                             [currentType]="currentType"
                             [showImage]="showImage"
                             [urlPreview]="urlPreview"
                             [loadingPage]="loadingFile"
                             [factureId]="selectFacture"
                             (canceled)="modal.close($event)">
  </app-visualisation-facture>
</ng-template>

<ng-template #modal_visualisation let-modal>
  <app-file-preview-modal [pieceJointeData]="pieceJointeData"
                          [piecesJointesGed]="piecesJointesGed"
                          [currentType]="currentType"
                          [showImage]="showImage"
                          [urlPreview]="urlPreview"
                          [loadingPage]="loadingFile"
                          (canceled)="modal.close($event)">
  </app-file-preview-modal>
</ng-template>
</div>
