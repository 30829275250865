<div class="row m-0">
  <div class="col-12 px-2 py-2">
    <div class="col-12 p-0">
      <label class="d-block d-sm-none">{{'Rechercher' | translate }}</label>
    </div>
    <div class="input-group">
      <div class="input-group-prepend ">
          <span class="input-group-text input-group-border-right d-none d-sm-flex align-items-center">
            {{'Rechercher' | translate }}
          </span>
      </div>
      <input matInput (keyup)="applyFilter($event.target.value)" class="pl-2 form-control form-control-filter"
             placeholder="{{'Rechercher' | translate }}">
    </div>
  </div>
</div>

<table mat-table [dataSource]="dataSource" (matSortChange)="sortData($event)" multiTemplateDataRows matSort
       class="table mat-responsive table-hover table-striped table-bordered mat-elevation-z8 text-center">

  <!-- Checkbox Column -->
  <ng-container matColumnDef="select">
    <th mat-header-cell class="text-center col-size-date" *matHeaderCellDef> {{'Date' | translate }}</th>
    <td mat-cell class="text-center align-middle text-nowrap" *matCellDef="let element">
      <div [style.color]="element.viewed ? '#6c757d' : '#343a40'">
        <div><span>{{element.created_at | date : 'dd-MM-yyyy'}}</span><span class="d-none d-md-inline"> - {{element.created_at | date : 'H:mm'}}</span></div>
        <div class="d-block d-md-none">{{element.created_at | date : 'H:mm'}}</div>
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="content">
    <th mat-header-cell class="text-center" *matHeaderCellDef> {{'Notification' | translate }}</th>
    <td mat-cell *matCellDef="let element, let i = dataIndex" class="text-center" [ngClass]="{'check-notif': element.viewed }"
        placement="top"
        container="body"
        ngbTooltip="{{'BL' | translate }} : {{element.numero_avis}}">
      <div *ngIf="lang == 'fr' || lang == undefined">
        <span *ngIf="!element.adresse_url" [style.color]="element.viewed ? '#6c757d' : '#343a40'">{{element.libelles.fr}}</span>
        <a class="text-decoration-none cursor-pointer" *ngIf="element.adresse_url" (click)="goTo(element.adresse_url, element.id,i)"
           [style.color]="element.viewed ? '#6c757d' : '#343a40'">{{element.libelles.fr}}</a>
      </div>
      <div *ngIf="lang == 'en'">
        <span *ngIf="!element.adresse_url" [style.color]="element.viewed ? '#6c757d' : '#343a40'">{{element.libelles.en}}</span>
        <a class="text-decoration-none" *ngIf="element.adresse_url" (click)="goTo(element.adresse_url, element.id,i)"
           [style.color]="element.viewed ? '#6c757d' : '#343a40'">{{element.libelles.en}}</a>
      </div>
      <div *ngIf="lang == 'nl'">
        <span *ngIf="!element.adresse_url" [style.color]="element.viewed ? '#6c757d' : '#343a40'">{{element.libelles.nl}}</span>
        <a class="text-decoration-none" *ngIf="element.adresse_url" (click)="goTo(element.adresse_url, element.id,i)"
           [style.color]="element.viewed ? '#6c757d' : '#343a40'">{{element.libelles.nl}}</a>
      </div>
      <div *ngIf="lang == 'es'">
        <div *ngIf="element.libelles.es !== null">
          <span *ngIf="!element.adresse_url" [style.color]="element.viewed ? '#6c757d' : '#343a40'">{{element.libelles.es}}</span>
          <a class="text-decoration-none" *ngIf="element.adresse_url" (click)="goTo(element.adresse_url, element.id,i)"
             [style.color]="element.viewed ? '#6c757d' : '#343a40'">{{element.libelles.es}}</a>
        </div>
        <div *ngIf="element.libelles.es == null">
          <span *ngIf="!element.adresse_url" [style.color]="element.viewed ? '#6c757d' : '#343a40'">{{element.libelles.en}}</span>
          <a class="text-decoration-none" *ngIf="element.adresse_url" (click)="goTo(element.adresse_url, element.id,i)"
             [style.color]="element.viewed ? '#6c757d' : '#343a40'">{{element.libelles.en}}</a>
        </div>
      </div>
      <div *ngIf="lang == 'de'">
        <div *ngIf="element.libelles.de !== null">
          <span *ngIf="!element.adresse_url" [style.color]="element.viewed ? '#6c757d' : '#343a40'">{{element.libelles.de}}</span>
          <a class="text-decoration-none" *ngIf="element.adresse_url" (click)="goTo(element.adresse_url, element.id,i)"
             [style.color]="element.viewed ? '#6c757d' : '#343a40'">{{element.libelles.de}}</a>
        </div>
        <div *ngIf="element.libelles.de == null">
          <span *ngIf="!element.adresse_url" [style.color]="element.viewed ? '#6c757d' : '#343a40'">{{element.libelles.en}}</span>
          <a class="text-decoration-none" *ngIf="element.adresse_url" (click)="goTo(element.adresse_url, element.id,i)"
             [style.color]="element.viewed ? '#6c757d' : '#343a40'">{{element.libelles.en}}</a>
        </div>
      </div>
    </td>
  </ng-container>
  <ng-container matColumnDef="created_by">
    <th mat-header-cell class="text-center" *matHeaderCellDef style="width: 80px;"> {{'Créé par' | translate }}</th>
    <td mat-cell *matCellDef="let element, let i = dataIndex" class="text-center align-middle" [ngClass]="{'check-notif': element.viewed }">
      <div class="label" [style.background]="element.viewed ? '#fa908e' : '#dc3545'"
           *ngIf="element.emetteur_context == 1">
        {{element.emetteur_libelle}}
      </div>
      <div class="label" [style.background]="element.viewed ? '#75c376' : '#28a745'"
           *ngIf="element.emetteur_context == 3">
        par le {{element.emetteur_libelle}}
      </div>
      <div class="label" [style.background]="element.viewed ? '#F9C375' : '#ffc107'"
           *ngIf="element.emetteur_context == 4">
        {{element.emetteur_libelle}}
      </div>
      <div class="label" [style.background]="element.viewed ? '#59a9ff' : '#007bff'"
           *ngIf="element.emetteur_context == 2">
        par la {{element.emetteur_libelle}}
      </div>
    </td>
  </ng-container>
  <ng-container matColumnDef="options">
    <th class="text-center align-middle" mat-header-cell *matHeaderCellDef="let element" style="width: 100px;">
      <div *ngIf="!showBtn && dataSource.data.length > 0">
        <button class="btn btn-rounded btn-sm bg-transparent text-grey mr-1"
                (click)="onCheckAllNotif(element)"><i class="fa fa-check-double"></i></button>
      </div>
      <div *ngIf="showBtn && dataSource.data.length > 0">
        <button class="btn btn-rounded btn-sm btn-green text-white mr-1"
                (click)="onCheckAllNotif(element)"><i class="fa fa-check-double"></i></button>
        <button [ladda]="loadingBtn" data-style="zoom-in" class="btn btn-rounded btn-sm btn-agediss mr-1"
                (click)="onDeleteAllNotif(element)"><i
          class="fa fa-trash"></i></button>
      </div>
    </th>
    <td class="text-center align-middle cursor-pointer" mat-cell *matCellDef="let element; let i = dataIndex">
      <button class="btn btn-rounded btn-sm mr-1"
              [style.background]="element.viewed ? 'green' : 'transparent'"
              [style.color]="element.viewed ? 'white' : 'grey'"
              (click)="selectCheckNotifId(element.id,i)"><i class="fa fa-check"></i></button>
      <button *ngIf="element.viewed" [ladda]="element.pending" data-style="zoom-in"
              ngbTooltip="{{'Supprimer la notification' | translate }}"
              placement="top"
              container="body"
              class="btn btn-rounded btn-sm btn-agediss mr-1" (click)="removeNotif(element.id,i)"><i
        class="fa fa-trash"></i></button>
    </td>
  </ng-container>


  <ng-container matColumnDef="noData">
    <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length"
        innerHTML="{{'Aucune notification' | translate }}"></td>
  </ng-container>
  <ng-container matColumnDef="loading">
    <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length"
        innerHTML="{{'Chargement en cours' | translate }} <i class='fas fa-spin fa-circle-notch'></i>"></td>
  </ng-container>

  <tr mat-header-row style="height: 15px;" *matHeaderRowDef="displayedColumns;  sticky: false"></tr>
  <tr mat-row *matRowDef="let element; columns: displayedColumns;" [hidden]="loading"
      class="table-row overflow-scroll">
  </tr>
  <tr mat-footer-row *matFooterRowDef="['noData']" [hidden]="dataSource && dataSource.data.length > 0 || loading"></tr>
  <tr mat-footer-row *matFooterRowDef="['loading']" [hidden]="!loading"></tr>
</table>

<mat-paginator [length]="dataSource.data.length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
               showFirstLastButtons></mat-paginator>
