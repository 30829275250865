<table mat-table [dataSource]="dataSource" (matSortChange)="sortData($event)" multiTemplateDataRows matSort
       matSortActive="created_at" matSortDirection="desc"
       class="table mat-responsive table-hover table-striped-n3 table-bordered mat-elevation-z8 text-center">

  <ng-container matColumnDef="id">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Id' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="align-middle">
      {{element?.id}}
    </td>
  </ng-container>
  <ng-container matColumnDef="contexte">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Contexte' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="align-middle">
      {{element.contexte | contextePipe}}
    </td>
  </ng-container>
  <ng-container matColumnDef="created_at">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Créée le' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="align-middle">
      {{element.created_at |  date:'dd/MM/yyyy'}}
    </td>
  </ng-container>
  <ng-container matColumnDef="updated_at">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Modifiée le' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="align-middle">
      {{element.updated_at |  date:'dd/MM/yyyy'}}
    </td>
  </ng-container>
  <ng-container matColumnDef="message">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Message' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="align-middle">
      {{element.messages?.length}}
    </td>
  </ng-container>
  <ng-container matColumnDef="action">
    <th mat-header-cell *matHeaderCellDef class="text-center align-middle">
      <button *ngIf="showAddConversationBtn"
              placement="top"
              container="body"
              [ladda]="loadingAddConversationBtn"
              data-style="zoom-in"
              ngbTooltip="{{'Nouvelle conversation' | translate }}"
              class="btn btn-agediss btn-sm btn-rounded"
              (click)="selectAddConversation()">
        <i class="fa fa-plus"></i></button>
    </th>
    <td mat-cell *matCellDef="let element; let i = index">
      <button *ngIf="showCommentsBtn && !element.contestation"
              placement="top"
              container="body"
              ngbTooltip="{{'Visualisation de la conversation' | translate }}"
              class="btn btn-agediss btn-sm btn-rounded ml-1"
        (click)="selectOneConversation(element.id, i)">
        <i class="fa fa-comments"></i>
      </button>
      <button *ngIf="showInfosBtn && this.innerWidth < 720"
              ngbTooltip="{{'Informations complémentaires' | translate }}"
              placement="left"
              container="body"
              class="btn btn-agediss btn-sm btn-rounded ml-1 mr-1"
              (click)="expandedElement = expandedElement === element ? null : element">
        <i class="fas fa-angle-double-down"></i>
      </button>
    </td>
  </ng-container>

  <ng-container matColumnDef="noData">
    <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length" innerHTML="{{'Aucune conversation' | translate }}"></td>
  </ng-container>
  <ng-container matColumnDef="loading">
    <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length" innerHTML="{{'Chargement en cours' | translate }} <i class='fas fa-spin fa-circle-notch'></i>"></td>
  </ng-container>

  <ng-container matColumnDef="expandedDetail">
    <td class="table-td p-0" mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">

      <div class="table-detail"
           *ngIf="this.innerWidth <= 400"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description">
          <strong>{{'Modifiée le' | translate }} : </strong><span>{{element.updated_at |  date:'dd/MM/yyyy'}}</span>
        </div>
      </div>
      <div class="table-detail"
           *ngIf="this.innerWidth <= 720"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description">
          <strong>{{'Message' | translate }} : </strong><span>{{element.messages?.length | titlecase}}</span>
        </div>
      </div>
    </td>
  </ng-container>

  <tr mat-header-row style="height: 15px;" *matHeaderRowDef="displayedColumns;  sticky: true"></tr>
  <tr mat-row *matRowDef="let element; columns: displayedColumns;" [hidden]="loading"
      class="table-row overflow-scroll"
      [class.expanded-row]="expandedElement === element">
  </tr>
  <tr mat-footer-row *matFooterRowDef="['noData']" [hidden]="dataSource && dataSource.data.length > 0 || loading"></tr>
  <tr mat-footer-row *matFooterRowDef="['loading']" [hidden]="!loading"></tr>
  <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="table-detail-row"></tr>
</table>

<mat-paginator [length]="length"  [pageIndex]="pageIndex" (page)="emit($event)" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
               showFirstLastButtons></mat-paginator>


