import {Component, Input, OnInit} from '@angular/core';
import {Commande} from "../../../shared/models/api/commande.model";
import {CommandeData} from "../../../shared/models/api/commande-data.model";

@Component({
  selector: 'app-plateforme',
  templateUrl: './plateforme.component.html',
  styleUrls: ['./plateforme.component.scss']
})
export class PlateformeComponent implements OnInit {

  @Input() commande: CommandeData;
  @Input() loading = false;
  public loadingPage = false;

  constructor() {
  }

  ngOnInit() {
    if (!this.commande) {
      throw new Error('Le composent plateforme attend un objet de type commande.');
    }
  }

}
