import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {HttpUtil} from '../../utils/http.util';
import {PieceJointe} from '../../models/api/piece-jointe.model';
import {TypePieceJointe} from '../../models/api/type-piece-jointe.model';
import {PieceJointeData} from "../../models/api/piece-jointe-data.model";
import {PieceJointeGed} from "../../models/api/ged/piece-jointe-ged.model";
import {ImageGed} from "../../models/api/ged/image-ged.model";

@Injectable({
  providedIn: 'root'
})
export class PieceJointeService {

  static CONTEXTES = ['litige', 'commande'];
  static CONTEXTE_LITIGE = 'litige';
  static CONTEXTE_GRC = 'grc';
  static CONTEXTE_COMMANDE = 'commande';

  urlRessourcePieceJointe = environment.url_api_espace_client + '/piece-jointes';
  urlRessourceTypePieceJointe = environment.url_api_espace_client + '/type-piece-jointes';

  constructor(private http: HttpClient) {
  }

  getTypePieceJointe(isJustif = null, isComptable = null): Observable<TypePieceJointe[]> {
    let params = new HttpParams();

    if (isJustif !== null) {
      params = HttpUtil.convertObjectToHttpParams({isJustif: isJustif})
    }
    if (isComptable !== null) {
      params = HttpUtil.convertObjectToHttpParams({isComptable: isComptable})
    }
    return this.http
      .get<TypePieceJointe[]>(`${this.urlRessourceTypePieceJointe}`, {params: params})
      .pipe(map((typePieceJointe) => typePieceJointe.map((typePieceJointe) => {
        return new TypePieceJointe().deserialize(typePieceJointe);
      })));
  }


  /**
   * @param fileId
   */
  downloadFile(fileId): Observable<{ data: Blob, filename: string }> {
    return this.http
      .get(this.urlRessourcePieceJointe + '/' + fileId + '/download',
        {
          responseType: 'blob',
          observe: 'response',
        }
      ).pipe(map(res => {
        if (!res.headers.get('content-disposition')) {
          throw "L'api ne renvoi pas le header 'content-disposition'";
        }
        return {
          filename: HttpUtil.extractFilenameInContentDisposition(res.headers.get('content-disposition')),
          data: res.body,
        };
      }))
  }

  /**
   * Cette methode retourne l'url blob du fichier
   * @param pieceJointeId
   * @return string
   */
  getFilePreview(pieceJointeId: number): Observable<Blob> {
    return this.http
      .get(`${this.urlRessourcePieceJointe}/${pieceJointeId}/visualisation`, {responseType: "blob"})
      .pipe(
        map(response => {
            return response;
          }
        ));
  }

  getFilePreviewGed(pieceJointeId: number): Observable<PieceJointeGed[]> {
    return this.http
        .get<PieceJointeGed[]>(`${this.urlRessourcePieceJointe}/${pieceJointeId}/visualisation-ged`)
        .pipe(map((pieceJointes) => pieceJointes.map((pieceJointe) => {
          return new PieceJointeGed().deserialize(pieceJointe);
        })));
  }

  delete(pieceJointeId: number): Observable<PieceJointe> {
    return this.http.delete<PieceJointe>(`${this.urlRessourcePieceJointe}/${pieceJointeId}/delete`).pipe(map((piecejointe) => {
      return new PieceJointe().deserialize(piecejointe);
    }));
  }
}
