import {Deserializable} from '../interfaces/deserializable.interface';
import {Article} from './article.model';
import {TypePieceJointe} from './type-piece-jointe.model';
import {Client} from './client.model';
import {Litige} from './litige.model';
import {Prestation} from './prestation.model';
import {Statut} from './statut.model';
import {User} from '../user.model';
import {Evenement} from './evenement.model';
import {Timeline} from './timeline.model';
import {SignatureLivraison} from './ged/signature-livraison.model';
import {Grc} from './grc.model';
import {LitigeData} from "./litige-data.model";

export class CommandeData implements Deserializable {

  public id?: number;
  public litige_data?: LitigeData;
  public grc?: Grc;
  public article?: Article;
  public prestation: Prestation;
  public timeline: Timeline;
  public type_piece_jointe?: TypePieceJointe;
  public client?: Client;
  public evenement?: Evenement;
  public code_societe?: string;
  public activite_nom?: string;
  public activite_libelle?: string;
  public activite_detail_libelle?: string;
  public statut?: Statut;
  public signatureLivraison?: SignatureLivraison;
  public numero_avis?: string;
  public numero_client?: string;
  public numero_commande?: string;
  public numero_commande_2?: string;
  public reference_commande?: string;
  public numero_tournee?: string;
  public destinataire_nom?: string;
  public destinataire_adresse?: string;
  public destinataire_code_postal?: string;
  public destinataire_ville?: string;
  public destinataire_dept?: string;
  public destinataire_pays?: string;
  public destinataire_tel_fixe?: number;
  public destinataire_tel_portable?: number;
  public destinataire_email?: string;
  public plateforme_transit_nom?: string;
  public plateforme_transit_code?: string;
  public code_plateforme?: string;
  public plateforme_nom?: string;
  public plateforme_adresse1?: string;
  public plateforme_adresse2?: string;
  public plateforme_code_postal?: number;
  public plateforme_ville?: string;
  public plateforme_tel_fixe?: number;
  public date_reception_hub?: Date;
  public date_depart_hub?: Date;
  public date_reception_plateforme?: Date;
  public date_depart_plateforme?: Date;
  public date_livraison?: string | Date;
  public date_rdv?: string | Date;
  public reception_hub_ok?: string;
  public depart_hub_ok?: string;
  public reception_plateforme_ok?: string;
  public depart_plateforme_ok?: string;
  public livraison_ok?: string;
  public solde?: string;
  public err?: string;
  public livraison_instruction_1?: string;
  public livraison_instruction_2?: string;
  public livraison_instruction_3?: string;
  public type_habitat?: string;
  public interphone?: string;
  public code_interphone?: string | number;
  public numero_etage?: string;
  public ascenseur?: string;
  public instruction_complementaire?: string;
  public volume_total?: number;
  public poids_total?: number;
  public nb_colis?: number;
  public cr_ttc?: number;
  public port_du_ttc?: number;
  public montant_percu?: number;
  public date_commande: Date;
  public created_at: string | Date;
  public traite_par?: User;
  public reception_statut?: string;
  public reception_evenement?: string;
  public reception_date_evenement: Date | string;
  public chargement_statut?: string;
  public chargment_evenement?: string;
  public chargement_date_evenement?: Date | string;
  public livraison_statut?: string;
  public livraison_evenement?: string;
  public livraison_date_evenement?: Date | string;
  public pending: boolean;

  deserialize(input: any): this {
    Object.assign(this, input);
    this.client = (input.client) ? new Client().deserialize(input.client) : null;
    this.article = (input.article) ? new Article().deserialize(input.article) : null;
    this.prestation = (input.prestation) ? new Prestation().deserialize(input.prestation) : null;
    this.statut = (input.statut) ? new Statut().deserialize(input.statut) : null;

    return this
  }

}
