<div *ngIf="loadingPage" class="spinner"></div>
<div *ngIf="!loadingPage">
  <div class="row m-0 padding-tab">
    <div class="col-sm-12 py-4 m-0">
      <panel variants="danger" noButton="true" bodyClass="no-padding panel">
        <div class="row" header>
          <div class="col-12"><h5 class="p-t-5 text-agediss">{{'Litige' | translate}} : <span
            *ngIf="!loadingNbLitiges">{{nbData}}</span> <i *ngIf="loadingNbLitiges"
                                                           class='fas fa-spin fa-circle-notch'></i></h5></div>
        </div>
        <div>
          <app-table-litige [data]="litigesData" [length]="nbData" [pageSize]="pageSize"
                            (paginatorEvent)="onLoadDataGrcByEvent($event)" [pageSizeOptions]="pageSizeOption"
                            [showViewBtn]="view.hasDroit('LITIGE_SHOW')" [showInfosBtn]="true"
                            [loading]="loadingTableLitige"></app-table-litige>
        </div>
      </panel>
    </div>
  </div>
</div>
