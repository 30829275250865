import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {NgbCalendar, NgbDate, NgbDateParserFormatter, NgbDateStruct, NgbInputDatepicker} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-date-picker-max-date-extraction-chargement',
  templateUrl: './date-picker-max-date-extraction-chargement.component.html',
  styleUrls: ['./date-picker-max-date-extraction-chargement.component.scss']
})
export class DatePickerMaxDateExtractionChargementComponent implements OnInit {

  @Input() filterForm: FormGroup;
  @Input() name: string;
  @Input() filterName: string;
  @Input() formControlname: string;
  @Input() date: any;

  @ViewChild('datepicker') private _input: NgbInputDatepicker;

  public innerWidth: number;
  public hoveredDate: NgbDateStruct;
  public model: any;
  public now: any;

  constructor(private calendar: NgbCalendar, public _parserFormatter: NgbDateParserFormatter) {
  }

  ngOnInit() {
    this.innerWidth = window.innerWidth;
    if (!(this.filterForm instanceof FormGroup)) {
      throw new Error('Vous devez passer un formGroup en parametre du composant RangeDatePickerComponent');
    }
  }

  onDateSelection(date) {
    if (!this.date) {
      this.date = date;
    }
    if (this.date && this.filterForm.controls['maxDateChargement']) {
      this.filterForm.controls['maxDateChargement'].setValue(new Date(Date.UTC(this.date.year, this.date.month - 1, this.date.day)));
    }
  }


  openDatePicker() {
    this.reset();
    localStorage.removeItem(this.filterName);
    this._input.toggle();
  }

  reset() {
    this.date = null;
  }

  isHovered(date: NgbDate) {
    return this.date && !this.date && this.hoveredDate && date.after(this.date) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return date.after(this.date) && date.before(this.date);
  }

  isRange(date: NgbDate) {
    return date.equals(this.date) || date.equals(this.date) || this.isInside(date) || this.isHovered(date);
  }
  validateInput(currentValue: NgbDate, input: string): NgbDate {
    const parsed = this._parserFormatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }


}
