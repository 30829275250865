<div class="row m-0">
  <div class="col-md-12 col-lg-12 col-xl-4">
    <div class="row m-0 py-2">
      <div class="col-12 p-0 shadow">
        <div class="info-box bg-light-blue hover-expand-effect">
          <div class="icon">
            <i class="fas fa-smile"></i>
          </div>
          <div class="content">
            <h1 class="size-h1">{{'Mon score NPS en' | translate }}
              <span *ngIf="!loadingData" >{{ stats?.currentYear}}</span>
              <i *ngIf="loadingData" class='fas fa-spin fa-circle-notch'></i>
            </h1>
            <h2>
              <span *ngIf="!loadingData" >{{ stats?.npsCurrentYear}}</span>
              <i *ngIf="loadingData" class='fas fa-spin fa-circle-notch'></i>
            </h2>
          </div>
        </div>
      </div>
    </div>
    <div class="row m-0 py-2">
      <div class="col-12 p-0 shadow">
        <div class="card card-2">
          <div class="body bg-light-blue">
            <ul class="dashboard-stat-list m-0">
              <li class="size-text">{{'CETTE SEMAINE' | translate }}
                <h5 class="pull-right text-white">
                  <span *ngIf="!loadingData" >{{ stats?.npsCurrentWeek}}</span> <i *ngIf="loadingData" class='fas fa-spin fa-circle-notch'></i>
                </h5>
              </li>
              <li class="size-text">{{'LES 30 DERNIERS JOURS' | translate }}
                <h5 class="pull-right text-white">
                  <span *ngIf="!loadingData" >{{ stats?.npsLastThirtyDays}}</span> <i *ngIf="loadingData" class='fas fa-spin fa-circle-notch'></i>
                </h5>
              </li>
              <li class="size-text">{{'LES 3 DERNIERS MOIS' | translate }}
                <h5 class="pull-right text-white">
                  <span *ngIf="!loadingData" >{{ stats?.npsLastThreeMonth}}</span> <i *ngIf="loadingData" class='fas fa-spin fa-circle-notch'></i>
                </h5>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-12 col-lg-12 col-xl-8">
    <div class="row m-0 py-2">
      <div class="col-12 p-0 shadow">
        <div class="card card-1">
          <div class="body bg-light-blue text-center">
            <div *ngIf="loadingCharts" class="spinner-border text-light m-spinner"></div>
            <div *ngIf="!loadingCharts" class="charts">
              <h1>{{'Les 12 dernières semaines' | translate }}</h1>
              <ngx-charts-line-chart *ngIf="multi[0].series != 'Aucune donnée n\'est disponible'"
                                     [view]="view"
                                     [scheme]="colorScheme"
                                     [results]="multi"
                                     [gradient]="gradient"
                                     [animations]="true"
                                     [xAxis]="showXAxis"
                                     [yAxis]="showYAxis"
                                     [legend]="showLegend"
                                     [showGridLines]="showGridLines"
                                     [showXAxisLabel]="showXAxisLabel"
                                     [showYAxisLabel]="showYAxisLabel"
                                     [xAxisLabel]="'Semaines' | translate"
                                     [yAxisLabel]="'Score NPS' | translate"
                                     (select)="onSelect($event)">
              </ngx-charts-line-chart>
              <div class="charts" *ngIf="multi[0].series == 'Aucune donnée n\'est disponible'">
                <h5 class="text-white py-5 mt-4">{{'Aucune donnée disponible' | translate }}</h5>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
