import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Stats} from '../../models/api/stats.model';
import {HttpUtil} from "../../utils/http.util";

@Injectable({
  providedIn: 'root'
})
export class StatService {

  urlRessource = environment.url_api_espace_client + '/stats';

  constructor(
    private http: HttpClient) {
  }

  getLivraisonsStats(filter: any = null): Observable<Stats> {
    return this.http
      .get<Stats>(`${this.urlRessource}/commandes`,{params: HttpUtil.convertObjectToHttpParams(filter)})
  }

  getNpsStats(filter: any = null): Observable<Stats> {
    return this.http
      .get<Stats>(`${this.urlRessource}/nps`, {params: HttpUtil.convertObjectToHttpParams(filter)})
  }

  getChartsLivraisons(filter: any = null): Observable<any> {
    return this.http
      .get<any>(`${this.urlRessource}/commandes-charts`, {params: HttpUtil.convertObjectToHttpParams(filter)});
  }

  getChartsCommandesByStatut(filter: any = null): Observable<any> {
    return this.http
      .get<any>(`${this.urlRessource}/commandes-charts-by-statut`, {params: HttpUtil.convertObjectToHttpParams(filter)});
  }

  getChartsNps(filter: any = null): Observable<any> {
    return this.http
      .get<any>(`${this.urlRessource}/nps-charts`, {params: HttpUtil.convertObjectToHttpParams(filter)});
  }

  getDemandesGrcStatsByStatut(filter: any = null): Observable<Stats> {
    return this.http
      .get<Stats>( `${this.urlRessource}/mes-demandes-grc-by-statut`, {params: HttpUtil.convertObjectToHttpParams(filter)})
  }

  getDemandesGrcStatsByDate(filter: any = null): Observable<Stats> {
    return this.http
      .get<Stats>( `${this.urlRessource}/mes-demandes-grc-by-date`, {params: HttpUtil.convertObjectToHttpParams(filter)})
  }

  getChartsDemandesGrc(filter: any = null): Observable<any> {
    return this.http
      .get<any>(`${this.urlRessource}/demandes-grc-charts`, {params: HttpUtil.convertObjectToHttpParams(filter)});
  }
}
