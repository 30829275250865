import {Component, Input, OnInit} from '@angular/core';
import {Litige} from '../../../shared/models/api/litige.model';
import {LitigeData} from "../../../shared/models/api/litige-data.model";

@Component({
  selector: 'app-sidebar-litige',
  templateUrl: './sidebar-litige.component.html',
  styleUrls: ['./sidebar-litige.component.scss']
})
export class SidebarLitigeComponent implements OnInit {

  @Input() litigeData: LitigeData;
  @Input() loadingPage = false;
  public innerWidth: number = window.innerWidth;

  constructor() { }

  ngOnInit() {
  }

}
