import {Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {FilterMultiCritereModel} from '../../../shared/models/filters/filterMultiCritere.model';
import {Subscription} from 'rxjs';
import {FormControl, FormGroup} from '@angular/forms';
import {Client} from '../../../shared/models/api/client.model';
import {Statut} from '../../../shared/models/api/statut.model';
import {TranslateService} from '@ngx-translate/core';
import {SousCompteSsoService} from '../../../shared/services/api/sso/sous-compte-sso.service';
import {Activite} from '../../../shared/models/api/activite.model';
import {ActiviteService} from '../../../shared/services/api/activite.service';
import {DatePickerMinDateComponent} from '../../shared/date-picker/date-picker-min-date/date-picker-min-date.component';
import {NgbDate} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-filter-anomalies',
  templateUrl: './filter-anomalies.component.html',
  styleUrls: ['./filter-anomalies.component.scss']
})
export class FilterAnomaliesComponent implements OnInit, OnDestroy {

  @ViewChild('inputMinDate') inputMinDate: DatePickerMinDateComponent;

  @Output() submitFilter = new EventEmitter<FilterMultiCritereModel>();
  @Output() load = new EventEmitter<FilterMultiCritereModel>();

  public filter: FilterMultiCritereModel = new FilterMultiCritereModel();

  public filterForm = new FormGroup({
    clients: new FormControl(),
    statuts: new FormControl(),
    activites: new FormControl(),
    minDate: new FormControl()
  });

  public minDate;
  public minDateSelectable: NgbDate = null;
  public clients: Client[];
  public statuts: Statut[];
  public activites: Activite[];
  public subscriptions: Subscription[] = [];
  public dataSource: any;

  public loadingClient = false;
  public loadingStatuts = false;
  public loadingActivites = false;

  constructor(private translate: TranslateService,
              private clientSvc: SousCompteSsoService,
              private activiteSvc: ActiviteService)
  {
    const d = new Date();
    this.minDateSelectable = new NgbDate(d.getFullYear() - 1, d.getMonth() + 1, 1);
  }

  ngOnInit() {
    this.subscriptions.push(this.translate.onLangChange.subscribe(() => {
      this.loadingClient = this.loadingStatuts = this.loadingActivites = true;
      this.loadSousComptes();
      this.loadStatuts();
      this.loadActivites();
    }));
    this.onInitFilter();
  }

  onInitFilter() {
    this.loadingClient = this.loadingStatuts = this.loadingActivites = true;
    this.loadSousComptes();
    this.loadStatuts();
    this.loadActivites();
    this.load.emit(this.filterForm.getRawValue());
  }

  loadActivites() {
    this.subscriptions.push(this.activiteSvc.getActivites(ActiviteService.LIVRAISON).subscribe(value => {
        this.activites = value;
        this.loadingActivites = false;
      }
    ));
  }

  loadSousComptes() {
    this.subscriptions.push(this.clientSvc.getSousComptes().subscribe(value => {
      this.clients = value;
      this.loadingClient = false;
    }));
  }

  loadStatuts() {
    const statuts = [];
    const libelles = ['Réception HUB', 'Réception plateforme', 'Livraison', 'Annulation', 'Adresse/Tel', 'Consommateur absent'];
    for (let i = 1; i < 7; ++i) {
      this.subscriptions.push(this.translate.get(`${libelles[i - 1]}`).subscribe((res: string) => {
        const stat = new Statut();
        stat.code = '' + i;
        stat.libelle = res;
        statuts.push(stat);
        if (statuts.length === libelles.length) {
          this.statuts = statuts;
          this.loadingStatuts = false;
        }
      }));
    }
  }

  onSubmit() {
    this.submitFilter.emit(this.filterForm.getRawValue());
  }

  reset(): void {
    this.filterForm.reset();
    this.inputMinDate.reset();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
