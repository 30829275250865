import {Component, Input, OnInit} from '@angular/core';
import {Contestation} from '../../../shared/models/api/contestation.model';
import {View} from '../../../shared/models/view.model';
import {AuthenticationService} from '../../../shared/services/authentication.service';
import {ContestationData} from "../../../shared/models/api/contestation-data.model";

@Component({
  selector: 'app-statut-contestation',
  templateUrl: './statut-contestation.component.html',
  styleUrls: ['./statut-contestation.component.scss']
})
export class StatutContestationComponent implements OnInit {


  @Input() contestationData: ContestationData;
  @Input() loadingPage = false;
  @Input() showViewLitigeBtn = false;

  public view: View;

  constructor(private authSvc: AuthenticationService) { }

  ngOnInit() {
    if(!this.contestationData) {
      throw new Error('Le composent contestation attend un objet de type contestation.')
    }
    this.authSvc.currentView.subscribe(x => this.view = x);
  }

}
