import {Component, Input, OnInit} from '@angular/core';
import {Stats} from '../../../shared/models/api/stats.model';
import {Subscription} from "rxjs";

@Component({
  selector: 'app-commande-pie-charts',
  templateUrl: './commande-pie-charts.component.html',
  styleUrls: ['./commande-pie-charts.component.scss']
})
export class CommandePieChartsComponent implements OnInit {

  @Input() single = [];
  @Input() stats: Stats;
  @Input() loadingCharts: boolean = false;
  @Input() showCharts: boolean = false;

  public innerWidth: number = window.innerWidth;

  view: any[]

  // options
  gradient: boolean = false;
  showLegend: boolean = true;
  showLabels: boolean = false;
  isDoughnut: boolean = false;
  legendPosition = 'right';
  legendTitle = 'Légende';

  colorScheme = {
    domain: ['#fff9c4', '#fff59d', '#ffb74d', '#f3e5f5', '#e1bee7', '#ce93d8', '#90caf9', '#c5e1a5']
  };

  constructor() {
  }

  ngOnInit() {
    this.responsiveSizeMobile();
  }


  responsiveSizeMobile() {
    if (this.innerWidth >= 300 && this.innerWidth < 400) {
      this.view = [300, 300];
    } else if (this.innerWidth >= 400 && this.innerWidth < 576) {
      this.view = [380, 380]
    } else if (this.innerWidth >= 576 && this.innerWidth < 992) {
      this.view = [400, 350];
    } else if (this.innerWidth >= 922 && this.innerWidth < 1300) {
      this.view = [500, 400];
    } else if (this.innerWidth >= 1300) {
      this.view = [1000, 400];
    }
  }

}
