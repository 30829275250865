import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Subscription} from "rxjs";
import {FormfilterHelper} from "../../../shared/helpers/Formfilter.helper";
import {FilterMultiCritereModel} from "../../../shared/models/filters/filterMultiCritere.model";
import {FormControl, FormGroup} from "@angular/forms";
import {TranslateService} from "@ngx-translate/core";
import {FieldParametersModel} from "../../../shared/models/filters/field-parameters.model";
import {FilterService} from "../../../shared/services/filter.service";
import {PaysService} from "../../../shared/services/api/pays.service";
import {Pays} from "../../../shared/models/api/pays.model";
import Swal from "sweetalert2";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-filter-tableau-de-bord',
  templateUrl: './filter-tableau-de-bord.component.html',
  styleUrls: ['./filter-tableau-de-bord.component.scss']
})
export class FilterTableauDeBordComponent implements OnInit, OnDestroy {

  @Input() displayInput: FieldParametersModel[] = [];

  @Output() onLoad = new EventEmitter<FilterMultiCritereModel>();
  @Output() submitFilter = new EventEmitter<FilterMultiCritereModel>();

  public filterForm = new FormGroup({
    pays: new FormControl(),
  });

  // Tableaux
  public hiddenInput = [];
  public pays: Pays[];
  public subscriptions: Subscription[] = [];

  // Loader
  public loadPays: boolean = false;

  constructor(private translate: TranslateService,
              private filterSvc: FilterService,
              private toastr: ToastrService,
              private paysSvc: PaysService,) { }

  ngOnInit() {
    this.subscriptions.push(this.translate.onLangChange.subscribe(() => {
      this.loadPays = true;
      this.onLoadPays();
    }))
    this.onInitFilter();
  }


  onInitFilter() {
    this.loadPays = true;
    FormfilterHelper.hydrateForm(this.displayInput, this.filterForm);
    this.hiddenInput = FormfilterHelper.extractHiddenInput(this.displayInput);
    this.onLoadPays();
    this.onLoad.emit(this.filterForm.getRawValue());
  }

  /**
   * Chargement des pays
   */
  onLoadPays() {
    this.subscriptions.push(this.paysSvc.getPays().subscribe(
      value => {
        this.pays = value;
        this.loadPays = false;
      },
      (error) => {
        this.toastr.error(
          this.translate.instant("La liste des pays n'est pas disponible"),
          this.translate.instant('Désolé'),
          {
            timeOut: 20000,
            progressBar: true
          }
        );
        this.loadPays = false;
      }
    ));
  }

  /**
   * On récupère les valeurs du localstorage
   */
  getValueLocalstorage() {

    this.filterSvc.onCheckValueLocalStorage(this.filterForm,'tableauDeBordPaysFilter', 'pays');
  }

  onSubmit() {
    //récupère les infos du filtre
    this.filterSvc.onSaveInLocalstorage(this.filterForm,'tableauDeBordPaysFilter', 'pays');
    this.submitFilter.emit(this.filterForm.getRawValue());
  }

  // On efface tous les champs du filter
  reset(): void {
    this.filterForm.reset();
  }

  ngOnDestroy(){
    this.subscriptions.forEach(s => s.unsubscribe());
  }

}
