import {Deserializable} from '../../interfaces/deserializable.interface';

export class ImageGed implements Deserializable {

  public id?: number;
  public nom?: string;
  public extension?: string;
  public path?: string;
  public nom_racine?: string;
  public archive?: string;
  public type_image?: string;
  public commentaire?: string;
  public file_content?: string;

  deserialize(input: any): this {
    return Object.assign(this, input);
  }

}
