import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Subscription} from "rxjs";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {WexlogGrcData} from "../../../../shared/models/api/wexlog/wexlog-grc-data.model";
import {UserData} from "../../../../shared/models/user-data.model";
import {View} from "../../../../shared/models/view.model";
import Swal from "sweetalert2";
import {AuthenticationService} from "../../../../shared/services/authentication.service";
import {TranslateService} from "@ngx-translate/core";
import {GrcWexlogService} from "../../../../shared/services/api/wexlog/grc-wexlog.service";
import {ToastrService} from "ngx-toastr";
import {TypeTextMessageGrcWexlogService} from "../../../../shared/services/api/wexlog/type-text-message-grc-wexlog.service";
import {TypeMessageGrcWexlogService} from "../../../../shared/services/api/wexlog/type-message-grc-wexlog.service";
import {WexlogTypeTextMessageGrc} from "../../../../shared/models/api/wexlog/wexlog-type-text-message-grc.model";
import {WexlogTypeMessageGrc} from "../../../../shared/models/api/wexlog/wexlog-type-message-grc.model";

@Component({
  selector: 'app-nouvelle-demande-grc-wexlog',
  templateUrl: './nouvelle-demande-grc-wexlog.component.html',
  styleUrls: ['./nouvelle-demande-grc-wexlog.component.scss']
})

export class NouvelleDemandeGrcWexlogComponent implements OnInit {

  @Output() canceled: EventEmitter<void> = new EventEmitter();
  @Output() submitted: EventEmitter<WexlogGrcData> = new EventEmitter();

  @Input() wexlogGrcData: WexlogGrcData;
  @Input() referenceCommande: string;
  @Input() numeroClient: string;
  @Input() origine: string;

  //boolean
  public loadingSubmitBtn: boolean = false;
  public loading: boolean = false;
  public formSubmit: boolean = false;
  public disabledButton: boolean = false;
  public loadingTypeMessage: Boolean = false;
  public loadingTypeTextMessage: Boolean = false;
  public showMessage: boolean = false;
  public isReadOnly: Boolean = true;

  //tableau
  public subscriptions: Subscription[] = [];
  public typeMessages: WexlogTypeMessageGrc[];
  public typeTextMessages: WexlogTypeTextMessageGrc[];

  //Form
  public demandeGrcWexlogForm: FormGroup;

  //other
  public currentUser: UserData;
  public view: View;

  //string
  public placeholder: string = this.translate.instant("Sélectionnez d'abord un type de message");

  //number
  public maxNumberOfCharactersMessage = 500;
  public numberOfCharactersMessage = 500;

  public typeMessage = 'QUESTION';

  constructor(private typeTextMessageGrcWexlogSvc: TypeTextMessageGrcWexlogService,
              private typeMessageGrcWexlogSvc: TypeMessageGrcWexlogService,
              private authSvc: AuthenticationService,
              private translate: TranslateService,
              private grcWexlogSvc: GrcWexlogService,
              private toastr: ToastrService) {
  }

  ngOnInit() {
    this.authSvc.currentUser.subscribe(x => this.currentUser = x);
    this.authSvc.currentView.subscribe(x => this.view = x);
    this.iniForm();
  }

  iniForm() {
    this.onloadTypeTextMessages(this.typeMessage);
    this.demandeGrcWexlogForm = new FormGroup({
      username: new FormControl(this.currentUser.getFullname()),
      client: new FormControl(this.numeroClient),
      origine: new FormControl(this.origine),
      description: new FormControl('', Validators.required),
      typeMessage: new FormControl(this.typeMessage, Validators.required),
      typeTextMessage: new FormControl(null, Validators.required),
      message: new FormControl('', Validators.required),
    });
  }

  onloadTypeTextMessages(typeMessageId) {
    this.loadingTypeTextMessage = true;
    this.subscriptions.push(this.typeTextMessageGrcWexlogSvc.getTypeTextMessagesGrcClient(typeMessageId).subscribe(value => {
        this.typeTextMessages = value;
        this.placeholder = this.translate.instant('Choisir');
        this.loadingTypeTextMessage = false;
      },
      (error) => {
        this.toastr.error(
          this.translate.instant("La liste des types de texte n'est pas disponible", { application: this.translate.instant('GRC')}),
          this.translate.instant('Désolé'),
          {
            timeOut: 20000,
            progressBar: true
          }
        );
        this.loadingTypeTextMessage = false;
      }
    ));
  }

  onChangeTextMessage(event: WexlogTypeTextMessageGrc) {
    if (this.demandeGrcWexlogForm.get('typeTextMessage').value) {
      this.isReadOnly = false
      this.demandeGrcWexlogForm.get('message').setValue(event.commentaire);
      this.demandeGrcWexlogForm.get('description').setValue(event.objet);
      this.numberOfCharactersMessage = this.maxNumberOfCharactersMessage - event.commentaire.length
    } else {
      this.isReadOnly = true
      this.demandeGrcWexlogForm.get('message').setValue(null);
      this.demandeGrcWexlogForm.get('description').setValue(null);
      this.numberOfCharactersMessage = this.maxNumberOfCharactersMessage;
    }
  }

  /**
   * On active ou désactive l'input sélectionné en paramètre
   * @param input
   * @param boolean
   */
  disableEnableInput(input: string, boolean: boolean) {
    let formControl = this.demandeGrcWexlogForm.get(input);
    if (boolean) {
      formControl.reset({value: formControl.value, disabled: false});
    } else {
      formControl.reset({value: null, disabled: true});
    }
  }

  onSubmit() {
    this.formSubmit = true;
    if (this.demandeGrcWexlogForm.invalid) {
      return;
    }
    this.loadingSubmitBtn = true;

    const sub = this.grcWexlogSvc.addDemandeGrc(this.referenceCommande,this.demandeGrcWexlogForm.value).subscribe(
      grc => {
        this.submitted.emit(grc);
        this.demandeGrcWexlogForm.reset({value: null});
        this.loadingSubmitBtn = false;
        this.formSubmit = false;
        sub.unsubscribe();
      },
      error => {
        this.loadingSubmitBtn = false;
        this.formSubmit = false;
        Swal.fire({
            title: this.translate.instant('Désolé'),
            text: this.translate.instant('Une erreur est survenue'),
            showCancelButton: false
          }
        ).then(() => {
          sub.unsubscribe();
          this.cancel();
        });
      }
    );
  }

  onKeyUpInfos(maxNumberOfCharacters, event: any): void {
    this.numberOfCharactersMessage = (maxNumberOfCharacters - event.target.value.length);
    if (this.numberOfCharactersMessage < 0) {
      event.target.value = event.target.value.slice(0, maxNumberOfCharacters);
      this.numberOfCharactersMessage = 0;
    }
  }

  cancel() {
    this.canceled.emit();
  }
}
