<div *ngIf="loadingPage" class="spinner"></div>
<div *ngIf="!loadingPage">
  <div class="row m-0 padding-tab">
    <div class="col-sm-12 py-4 m-0">
      <panel variants="danger" title="{{'Historique' | translate}}" noButton="true" bodyClass="no-padding panel">
        <div>
          <app-table-evenement [data]="evenements" [pageSize]="10" [pageSizeOptions]="[5, 10, 20]"
                               [loading]="loadingTableEvenement"
                               [showViewBtn]="true"
                               [showFileBtn]="view.hasDroit('COMMANDE_PIECE-JOINTE_SHOW')"
                               [showInfosBtn]="true"
                               [showGeolocalisationBtn]="true"
                               (onSelectFile)="openModalVisualisationFile(modal_visualisation_file, $event)"
                               (onGeolocalisation)="openModalGeolocalisation(modal_geolocalisation, $event)"
                               (onSelectEvenementColis)="openModalEvenementColis(modal_evenement_colis, $event)"></app-table-evenement>
        </div>
      </panel>
    </div>
  </div>
</div>

<ng-template #modal_visualisation_file let-modal>
  <app-file-preview-ged  [piecesJointesGed]="evenement.doc" [doc]="true" (canceled)="modal.close($event)"></app-file-preview-ged>
</ng-template>

<ng-template #modal_evenement_colis let-modal>
  <app-evenement-colis  [evenement]="evenement" (canceled)="modal.close($event)"></app-evenement-colis>
</ng-template>

<ng-template #modal_geolocalisation let-modal>
  <app-geolocalisation  [data]="evenement"
                        [libelle]="evenement.libelle"
                        [latitude]="evenement.latitude"
                        [longitude]="evenement.longitude"
                        (canceled)="modal.close($event)"></app-geolocalisation>
</ng-template>
