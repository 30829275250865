<table mat-table [dataSource]="dataSource" (matSortChange)="sortData($event)" multiTemplateDataRows matSort
       matSortActive="created_at" matSortDirection="desc"
       class="table mat-responsive table-hover table-striped-n3 table-bordered mat-elevation-z8 text-center">

  <ng-container matColumnDef="voir">
    <th mat-header-cell *matHeaderCellDef class="text-center">{{'Voir' | translate }}</th>
    <td class="text-center align-middle" mat-cell *matCellDef="let element">
      <button *ngIf="showViewMessageBtn"
              ngbTooltip="{{'Visualisation de la conversation' | translate }}"
              placement="right"
              container="body"
              class="btn btn-agediss btn-sm btn-rounded ml-1"
              (click)="onSelectConversation(element)">
        <i class="fa fa-comments"></i>
      </button>
    </td>
  </ng-container>
  <ng-container matColumnDef="created_at">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      {{'Date de création' | translate }}
    </th>
    <td mat-cell *matCellDef="let element" class="align-middle">
      {{element?.created_at }}
    </td>
  </ng-container>
  <ng-container matColumnDef="login">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Identifiant' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="align-middle">
      {{element?.login | titlecase }}
    </td>
  </ng-container>
  <ng-container matColumnDef="statut">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Statut' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="align-middle">
      <ng-template #statutGrc>
        <div class="p-0">
          <span
            *ngIf="element?.libelle_statut === 'En Traitement'">{{'Agediss doit vous répondre' | translate }}</span>
          <span *ngIf="element?.libelle_statut === 'Clos'">{{'Vous pouvez archiver cette conversation' | translate }}</span>
          <span *ngIf="element?.libelle_statut === 'ClOS'">{{'Vous pouvez archiver cette conversation' | translate }}</span>
          <span *ngIf="element?.libelle_statut === 'Info'">{{'Agediss attend une réponse ou Agediss doit vous répondre' | translate }}</span>
          <span *ngIf="element?.libelle_statut === 'Archivé'">{{'La conversation est archivée' | translate }}</span>
          <span *ngIf="element?.libelle_statut === 'A traiter'">{{'Agediss attend une réponse' | translate }}</span>
        </div>
      </ng-template>
      <div placement="left"
           container="body"
           [ngbPopover]="statutGrc" triggers="mouseenter:mouseleave"
           class="label {{ element?.libelle_statut | statutGrcPipe}}">
        {{element?.libelle_statut  | titlecase }}
      </div>
    </td>
  </ng-container>
  <ng-container matColumnDef="objet">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Objet' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="align-middle">
      {{element?.objet}}
    </td>
  </ng-container>
  <ng-container matColumnDef="commentaire">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Commentaire' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="align-middle text-truncate" style="max-width: 200px">
      {{element?.commentaire}}
    </td>
  </ng-container>
  <ng-container matColumnDef="updated_at">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Date de réponse' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="align-middle">
      {{element?.updated_at }}
    </td>
  </ng-container>
  <ng-container matColumnDef="options">
    <th mat-header-cell *matHeaderCellDef></th>
    <td class="text-center align-middle" mat-cell *matCellDef="let element; let i = dataIndex">
      <button *ngIf="showFileBtn && element.doc != null"
              ngbTooltip="{{'Visualisation des documents' | translate }}"
              placement="left"
              container="body"
              class="btn btn-agediss btn-sm btn-rounded ml-1 mr-1"
              (click)="selectFile(element)">
        <i class="fa fa-paperclip"></i>
      </button>
      <button *ngIf="showDeleteDemandGrcBtn && element.code_statut === 'ATTENTE_GRC' &&  element.code_maj === 'OK'"
              [ladda]="element.pending"
              data-style="zoom-in"
              ngbTooltip="{{'Supprimer la demande GRC' | translate }}"
              placement="left"
              container="body"
              class="btn btn-agediss btn-sm btn-rounded mr-1"
              (click)="delete(element.id)">
        <i class="fa fa-trash"></i></button>
      <button *ngIf="showInfosBtn && this.innerWidth < 1200"
              ngbTooltip="{{'Informations complémentaires' | translate }}"
              placement="left"
              container="body"
              class="button btn btn-rounded-arrow btn-sm btn-agediss"
              (click)="expandedElement = expandedElement === element ? null : element">
        <i class="fas fa-angle-double-down"></i>
      </button>
    </td>
  </ng-container>


  <ng-container matColumnDef="noData">
    <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length"
        innerHTML="{{'Aucune demande GRC' | translate }}"></td>
  </ng-container>
  <ng-container matColumnDef="loading">
    <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length"
        innerHTML="{{'Chargement en cours' | translate }} <i class='fas fa-spin fa-circle-notch'></i>"></td>
  </ng-container>

  <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
  <ng-container matColumnDef="expandedDetail">
    <td class="table-td p-0" mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">

      <div class="table-detail"
           *ngIf="this.innerWidth < 860"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description">
          <strong>{{'Objet' | translate }} : </strong><span>{{element?.objet }}</span>
        </div>
      </div>
      <div class="table-detail"
           *ngIf="this.innerWidth < 930"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description">
          <strong>{{'Commentaire' | translate }} : </strong><span>{{element?.commentaire }}</span>
        </div>
      </div>
      <div class="table-detail"
           *ngIf="this.innerWidth < 1090"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description">
          <strong>{{'Date de création' | translate }} : </strong><span>{{element?.created_at }}</span>
        </div>
      </div>
      <div class="table-detail"
           *ngIf="this.innerWidth < 1200"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description">
          <strong>{{'Date de réponse' | translate }} : </strong><span>{{element?.updated_at }}</span>
        </div>
      </div>
      <div class="table-detail"
           *ngIf="this.innerWidth < 465"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description">
          <strong>{{'Statut' | translate }} : </strong>
          <span>
            <ng-template #statutGrc>
              <div class="p-0">
                <span
                  *ngIf="element?.libelle_statut === 'En Traitement'">{{'Agediss doit vous répondre' | translate }}</span>
                <span *ngIf="element?.libelle_statut === 'Clos'">{{'Vous pouvez archiver cette conversation' | translate }}</span>
                <span *ngIf="element?.libelle_statut === 'ClOS'">{{'Vous pouvez archiver cette conversation' | translate }}</span>
                <span *ngIf="element?.libelle_statut === 'Info'">{{'Agediss attend une réponse ou Agediss doit vous répondre' | translate }}</span>
                <span *ngIf="element?.libelle_statut === 'Archivé'">{{'La conversation est archivée' | translate }}</span>
                <span *ngIf="element?.libelle_statut === 'A traiter'">{{'Agediss attend une réponse' | translate }}</span>
              </div>
            </ng-template>
            <div placement="top-left"
                 container="body"
                 [ngbPopover]="statutGrc" triggers="mouseenter:mouseleave"
                 class="label {{ element?.libelle_statut | statutGrcPipe}}">
              {{element?.libelle_statut  | titlecase }}
            </div>
          </span>
        </div>
      </div>
      <div class="table-detail"
           *ngIf="this.innerWidth < 1300 && element.code_statut === 'ATTENTE_GRC' &&  element.code_maj === 'OK'"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description">
          <strong>{{'Documents' | translate }} : </strong>
          <span>
            <button *ngIf="showFileBtn"
                    ngbTooltip="{{'Visualisation des documents' | translate }}"
                    placement="top"
                    container="body"
                    class="btn btn-agediss btn-sm btn-rounded ml-1">
            <i class="fa fa-file"></i>
          </button>
          </span>
        </div>
      </div>
    </td>
  </ng-container>

  <tr mat-header-row style="height: 15px;" *matHeaderRowDef="displayedColumns;  sticky: true"></tr>
  <tr mat-row *matRowDef="let element; columns: displayedColumns;" [hidden]="loading"
      class="table-row overflow-scroll"
      [class.expanded-row]="expandedElement === element">
  </tr>
  <tr mat-footer-row *matFooterRowDef="['noData']"
      [hidden]="dataSource && dataSource.data.length > 0 || loading"></tr>
  <tr mat-footer-row *matFooterRowDef="['loading']" [hidden]="!loading"></tr>
  <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="table-detail-row"></tr>
</table>

<mat-paginator [length]="length"  [pageIndex]="pageIndex" (page)="emit($event)" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
               showFirstLastButtons></mat-paginator>

