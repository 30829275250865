<mat-tab-group class="tab-litige tab-global" #parent disableRipple>
  <div *ngFor="let tab of tabs">
    <mat-tab *ngIf="view.hasDroit(tab.droit)">
      <ng-template mat-tab-label>
        <div class="tab"
             placement="top"
             container="body"
             [popoverTitle]="title"
             [ngbPopover]="content" triggers="click:mouseleave">
          <span class="tab-icon-style"><i class="fas fa-cubes"></i></span>
          <span class="font-size-title px-2">{{ tab.libelle }}</span>
        </div>
      </ng-template>
      <div class="content-color" *ngIf="tab.code === 'CONTESTATION'">
        <app-contestation-content [contestationData]="contestationData"></app-contestation-content>
      </div>
      <div class="content-color" *ngIf="tab.code === 'CONVERSATION'">
        <app-contestation-conversation-content  [conversationId]="contestationData.conversation.id"></app-contestation-conversation-content>
      </div>
    </mat-tab>

    <ng-template #title>
      <div class="scrollbar scrollbar-black bordered-black square thin overflow-scroll" style="max-height: 580px">
        <span class="popover-title" [innerHTML]="tab.libelle"></span>
      </div>
    </ng-template>
    <ng-template #content>
      <div class="scrollbar scrollbar-black bordered-black square thin overflow-scroll" style="max-height: 580px">
        <p class="popover-content" [innerHTML]="tab.content"></p>
      </div>
    </ng-template>

  </div>
</mat-tab-group>
