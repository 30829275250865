import {Component, OnDestroy, OnInit} from '@angular/core';
import {FilterMultiCritereModel} from '../../shared/models/filters/filterMultiCritere.model';
import {Subscription} from 'rxjs';
import {Contestation} from '../../shared/models/api/contestation.model';
import {ContestationService} from "../../shared/services/api/contestation.service";
import {TranslateService} from '@ngx-translate/core';
import {PaginationService} from "../../shared/services/pagination.service";
import {PageEvent} from "@angular/material/paginator";
import {ContestationData} from "../../shared/models/api/contestation-data.model";
import {AuthenticationService} from "../../shared/services/authentication.service";
import {View} from "../../shared/models/view.model";
import {Router} from "@angular/router";

@Component({
  selector: 'app-contestations',
  templateUrl: './contestations.html',
  styleUrls: ['./contestations.scss']
})
export class ContestationsPage implements OnInit, OnDestroy {

  public filter: FilterMultiCritereModel = new FilterMultiCritereModel();
  public view: View;

  //Tableau
  public pageSizeOption = [5, 10, 20];
  public contestations: Contestation[];
  public contestationData: ContestationData[];
  public subscriptions: Subscription[] = [];

  // number
  public pageSize = 10;
  public nbData: number = 0;

  //loading
  public loadingTableContestations = false;
  public loadingNbContestations = false;

  constructor(private translate: TranslateService,
              private paginationSvc: PaginationService,
              private authSvc: AuthenticationService,
              private router: Router,
              private contestationSvc: ContestationService) {
    this.paginationSvc.defaultFilterPagination(this.filter, this.pageSize)
    this.authSvc.currentView.subscribe(x => this.view = x);
  }

  ngOnInit() {
    if (this.view.hasDroit('LITIGE_MENU_CONTESTATION')) {
      this.subscriptions.push(this.translate.onLangChange.subscribe(() => {
        this.loadDataContestations()
      }))
    } else {
      this.router.navigate(['/', 'home']);
    }
  }

  onSubmit(filter = null) {
    //récupère les infos du filtre
    if (filter) {
      this.filter = filter;
    }
    this.paginationSvc.defaultFilterPagination(this.filter, this.pageSize)
    this.loadDataContestations();
  }

  onLoadFilter(filter = null) {
    //récupère les infos du filtre
    if (filter) {
      this.filter = {...filter, ...this.filter};
    }
    this.loadDataContestations();
  }

  ngOnDestroy(){
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  /**
   * On récupère toutes les contestations liès au compte client
   */
  loadDataContestations() {
    this.loadingTableContestations = true;
    this.loadingNbContestations = true;
    this.contestationData = []
    this.nbData = 0;
    this.subscriptions.push(this.contestationSvc.getContestations(this.filter).subscribe((contestation) => {
      this.contestationData = contestation.data;
      this.nbData = contestation.nb;
      this.loadingTableContestations = false;
      this.loadingNbContestations = false;
      },
      (error) => {
        this.loadingTableContestations = false;
        this.loadingNbContestations = false;
      }
    ));
  }

  onLoadDataByEvent(event: PageEvent) {
    this.filter.limit = event.pageSize
    this.filter.offset = event.pageIndex ? event.pageIndex * event.pageSize : 0
    this.loadDataContestations();
  }

}
