<div *ngIf="loadingPage" class="spinner"></div>
<div *ngIf="!loadingPage">
  <div class="row m-0 padding-tab">
    <div class="col-md-12 col-lg-6 pt-4 m-0">
      <panel variants="danger" title="{{'Commande' | translate }}" noButton="true" bodyClass="no-padding panel" class="text-center">
        <div class="infos scrollbar scrollbar-black bordered-black square thin overflow-scroll text-left"
             style="min-height: 70px;">
          <app-commande-contestation [contestationData]="contestationData"></app-commande-contestation>
        </div>
      </panel>
    </div>
    <div class="col-md-12 col-lg-6 pt-4 m-0">
      <panel variants="danger" title="{{'Statut' | translate }}" noButton="true" bodyClass="no-padding panel" class="text-center">
        <div class="scrollbar scrollbar-black bordered-black square thin overflow-scroll text-left p-0"
             style="min-height: 70px;">
          <app-statut-contestation [contestationData]="contestationData" [showViewLitigeBtn]="view.hasDroit('LITIGE_SHOW')"></app-statut-contestation>
        </div>
      </panel>
    </div>
  </div>
  <div class="row m-0 padding-tab">
    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-6 py-4 m-0">
      <panel variants="danger" title="{{'Réclamation' | translate }}" noButton="true" bodyClass="no-padding panel" class="text-center">
          <app-reclamation-contestation [contestationData]="contestationData"></app-reclamation-contestation>
      </panel>
    </div>
    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-6 py-4 m-0">
      <panel variants="danger" title="{{'Documents' | translate }}" noButton="true" bodyClass="no-padding panel"
             class="text-center">
        <div class="row-panel-2 panel-file scrollbar scrollbar-black bordered-black square thin overflow-scroll text-left p-0">
          <app-table-file [showInfosBtn]="true"
                          [showAddFileBtn]="view.hasDroit('LITIGE_PIECE-JOINTE_CREATE')"
                          [showDeleteFileBtn]="view.hasDroit('LITIGE_PIECE-JOINTE_DELETE')"
                          [showDownloadFileBtn]="view.hasDroit('LITIGE_PIECE-JOINTE_DOWNLOAD')"
                          [showViewFileBtn]="view.hasDroit('LITIGE_PIECE-JOINTE_SHOW')"
                          [data]="piecesJointes"
                          [loading]="loadingTableFile" (onAdd)="openModalUpload(modal_file_uploader)"
                          (onSelectFile)="openModalVisualisationFile(modal_visualisation,$event)"></app-table-file>
        </div>
      </panel>
    </div>
  </div>
</div>


<ng-template #modal_file_uploader let-modal>
    <app-file-uploader [showHeader]="true" [idContestation]="contestationData.id" (canceled)="activModal.close()"
                       (uploaded)="onUploaded()"></app-file-uploader>
</ng-template>

<ng-template #modal_visualisation let-modal>
  <app-file-preview-modal [pieceJointeData]="pieceJointe" [piecesJointesGed]="piecesJointesGed" [currentType]="currentType" [showImage]="showImage" [urlPreview]="urlPreview" [loadingPage]="loadingFile" (canceled)="modal.close($event)"></app-file-preview-modal>
</ng-template>
