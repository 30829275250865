import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthenticationService} from "../../../shared/services/authentication.service";
import {PaginationService} from "../../../shared/services/pagination.service";
import {NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {FilterMultiCritereModel} from "../../../shared/models/filters/filterMultiCritere.model";
import {View} from "../../../shared/models/view.model";
import {Subscription} from "rxjs";
import {GrcWexlogService} from "../../../shared/services/api/wexlog/grc-wexlog.service";
import Swal from "sweetalert2";
import {WexlogGrcData} from "../../../shared/models/api/wexlog/wexlog-grc-data.model";

@Component({
  selector: 'app-gestion-relation-client-wexlog',
  templateUrl: './gestion-relation-client-wexlog.html',
  styleUrls: ['./gestion-relation-client-wexlog.scss']
})
export class GestionRelationClientWexlogPage implements OnInit, OnDestroy {

  private filter: FilterMultiCritereModel = new FilterMultiCritereModel();

  public grcWexlog: WexlogGrcData;
  public activModal: NgbModalRef;
  public view: View;

  //number
  public idDoc: number;
  public openId: number = null;
  public pageSize = 5;

  // tableaux
  public grcDataWexlog: WexlogGrcData[];
  public subscriptions: Subscription[] = [];
  public pageSizeOption = [5, 10, 20, 50];

  // loader
  public loadingPage = false;
  public loading: boolean = false;
  public loadingTableGrcWewlog: boolean = false;
  public loadingInfosGrc: boolean = false;

  @ViewChild('modal_visualisation_messages_wexlog', {static: false}) modal_visualisation_messages: ElementRef;

  constructor(private translate: TranslateService,
              private grcWexlogSvc: GrcWexlogService,
              private router: Router,
              private routeActive: ActivatedRoute,
              private authSvc: AuthenticationService,
              private paginationSvc: PaginationService,
              private modalSvc: NgbModal) {
  }

  ngOnInit() {
    this.authSvc.currentView.subscribe(x => this.view = x);
    // récupération de l'id de la commande
    this.subscriptions.push(this.routeActive.params.subscribe((params) => {
      this.subscriptions.push(this.translate.onLangChange.subscribe(() => {
        this.loadInfosGrc(params.id);
      }))
      this.loadInfosGrc(params.id);
    }));
  }

  /**
   * On récupère les demandes Grc wexlog en fonction du numero Avis
   */
  loadDataGrcsWexlog(value) {
    this.loadingTableGrcWewlog = true;
    this.grcDataWexlog = [];
    this.subscriptions.push(this.grcWexlogSvc.getDemandesGrcByNumeroAvis(this.filter, value).subscribe(
      (grc) => {
        this.grcDataWexlog = grc;
        this.grcDataWexlog.forEach((value, index) => {
          if (value.libelle_statut === 'ASSIGNED' && value.creator_id !== this.view.srcClient) {
            if (value.messages.length === 1) {
              this.grcDataWexlog[index]['deletable'] = true
            }
          }
        });
        this.loadingTableGrcWewlog = false;
      },
      () => {
        this.loadingTableGrcWewlog = false;
      }));
  }

  /**
   * On affiche les informations de la commande liés aux demandes Grc
   * @param id
   */
  loadInfosGrc(id) {
    this.loadingPage = true;
    this.subscriptions.push(this.grcWexlogSvc.getGrcById(id).subscribe(
      (grc) => {
        this.grcWexlog = grc;
        this.loadDataGrcsWexlog(this.grcWexlog.ord_id)
        if (this.grcWexlog.messages !== null) {
          this.openModalvisualisationMessages(this.modal_visualisation_messages, this.grcWexlog);
        } else {
          Swal.fire({
            title: this.translate.instant('Désolé'),
            text: this.translate.instant('La demande GRC ne contient aucun message') +'. ' + this.translate.instant('Veuillez contacter le service client Agediss'),
            showCancelButton: false,
            type: 'error',
          }).then(
            () => {
              this.router.navigate(['/gestion-relation-clients']);
            }
          );
        }
        this.loadingPage = false;
      },
      () => {
        Swal
          .fire({
            titleText: this.translate.instant("La demande GRC n'existe pas")
          })
          .then(
            () => {
              this.router.navigate(['/gestion-relation-clients']);
            }
          ).catch(() => {
          Swal.fire({
            title: this.translate.instant('Désolé'),
            text: this.translate.instant('Une erreur est survenue'),
            showCancelButton: false,
            type: 'error',
          });
        });
      }));
  }

  /**
   * On affiche la conversion Grc sélectionnée
   * @param modalName
   * @param grcWexlog
   */
  openModalvisualisationMessages(modalName, grcWexlog: WexlogGrcData) {
    this.grcWexlog = grcWexlog;
    this.activModal = this.modalSvc.open(modalName, {
      size: 'xl', keyboard: false, backdrop: 'static'
    });
    this.activModal.result.then(() => {
      this.loadDataGrcsWexlog(this.grcWexlog.ord_id)
    })
  }

  openModalVisualisationFile(modalName, grcWexlog: WexlogGrcData) {
    this.grcWexlog = grcWexlog
    this.activModal = this.modalSvc.open(modalName, {
      size: 'lg', keyboard: false
    });
  }

  /**
   * On crée une nouvlle demande grc
   * @param modalName
   */
  openModalNouvelleDemande(modalName) {

    this.activModal = this.modalSvc.open(modalName, {
      size: 'xl', keyboard: false, backdrop: 'static'
    });
    this.activModal.result.then(() => {
      this.loadDataGrcsWexlog(this.grcWexlog.ord_id);
    });
  }

  ngOnDestroy() {
    this.activModal.dismiss();
    this.subscriptions.forEach(s => s.unsubscribe());
  }

}
