import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import Swal from 'sweetalert2';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {CommandeService} from "../../../shared/services/api/commande.service";
import {TourService} from "ngx-tour-ngx-bootstrap";
import {CommandeData} from "../../../shared/models/api/commande-data.model";

@Component({
  selector: 'app-livraison-page',
  styleUrls: ['./livraison.scss'],
  templateUrl: './livraison.html'
})

export class LivraisonPage implements OnInit, OnDestroy {

  static readonly TABTYPE = {
    COMMAND: 0,
    HISTORY: 1,
    MEDIA: 2,
    GRC: 3,
    LITIGE: 4
  } as const;

  public loadingPage = false;
  public subscriptions: Subscription[] = [];
  public commandeData: CommandeData;
  public livraison = 'livraison';

  @Input() numeroAvis: number = null;
  @Input() startTabIndex: number = LivraisonPage.TABTYPE.COMMAND;

  constructor(private translate: TranslateService,
              private commandeSvc: CommandeService,
              public tourService: TourService,
              private router: Router,
              private routeActive: ActivatedRoute) {
  }

  ngOnInit() {
    if (!!this.numeroAvis) {
      const params = {id: this.numeroAvis};
      this.subscriptions.push(this.translate.onLangChange.subscribe(() => {
        this.loadDataLivraison(params);
      }));
      this.loadDataLivraison(params);
    }
    else {
      this.subscriptions.push(this.routeActive.params.subscribe((params) => {
        this.subscriptions.push(this.translate.onLangChange.subscribe(() => {
          this.loadDataLivraison(params);
        }));
        this.loadDataLivraison(params);
      }));
    }
  }

  loadDataLivraison(params) {
    this.loadingPage = true;
    this.subscriptions.push(this.commandeSvc.getLivraison(params.id).subscribe(
      (commande) => {
        this.commandeData = commande;
        if(params.id !== this.commandeData.numero_avis) {
          this.router.navigate(['/', 'livraisons', this.commandeData.numero_avis]);
        }
        this.loadingPage = false;
      },
      () => {
        Swal
          .fire({
            title: this.translate.instant('Désolé'),
            text: this.translate.instant("La commande demandée n'existe pas !"),
            type: 'error',
          })
          .then(
            () => {
              this.router.navigate(['/livraisons']);
            }
          ).catch(() => {
          Swal.fire({
            title: this.translate.instant('Désolé'),
            text: this.translate.instant('Une erreur est survenue'),
            showCancelButton: false,
            type: 'error',
          });
        });
      }));
  }

  ngOnDestroy(){
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
