import {Deserializable} from '../interfaces/deserializable.interface';
import {Commande} from "./commande.model";
import {PieceJointeGed} from "./ged/piece-jointe-ged.model";
import {EvenementColis} from "./evenement-colis.model";


export class Evenement implements Deserializable {

  public id?: number;
  public commande: Commande;
  public contexte?: string;
  public code?: string;
  public libelle?: string;
  public date_creation?: Date | string;
  public heure_saisie?: Date | string;
  public date_evenements?: Date | string;
  public heure_evenement?: Date | string;
  public creneau_debut?: Date | string;
  public creneau_fin?: Date | string;
  public date_proposition_1?: Date| string;
  public date_proposition_2?: Date| string;
  public commentaire?: string;
  public cree_par?: string;
  public lastname?: string;
  public firstname?: string;
  public fullname?: string;
  public doc?: PieceJointeGed[];
  public colis?: EvenementColis[];
  public created_at?: Date | string;
  public updated_at?: Date | string;
  public latitude: string;
  public longitude: string;
  public pending?: boolean;

  constructor() {
    this.pending = false;
  }

  public getFullname(reverse = false) {
    if(!this.firstname && !this.lastname && this.fullname){
      return this.fullname;
    }
    if (reverse) {
      return `${this.firstname} ${this.lastname}`;
    }
    return `${this.lastname} ${this.firstname}`;
  }

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }


}
