<div class="row m-0 text-white info">
  <div class="size-block">
    <div class="info-div text-center element color-element-1">
      <div class="title text-truncate"><span class="font-weight-bold">{{'Commande' | translate }} n° 1</span></div>
      <div class="contenu text-truncate"><span>{{commandeData?.numero_commande}}</span></div>
    </div>
  </div>
  <div class="size-block">
    <div class="info-div text-center element color-element-2">
      <div class="title"><span class="font-weight-bold">{{'Commande bis' | translate }}</span></div>
      <div class="contenu text-truncate"><span>{{commandeData?.numero_commande_2}}</span></div>
    </div>
  </div>
  <div class="size-block">
    <div class="info-div text-center element color-element-2">
      <div class="title"><span class="font-weight-bold">{{'Référence' | translate }}</span></div>
      <div class="contenu text-truncate"><span>{{commandeData?.reference_commande}}</span></div>
    </div>
  </div>
  <div class="size-block">
    <div class="info-div text-center element color-element-3">
      <div class="title"><span class="font-weight-bold">{{'Destinataire' | translate }}</span></div>
      <div class="contenu text-truncate"><span>{{commandeData?.destinataire_nom | uppercase}}</span></div>
    </div>
  </div>
  <div class="size-block">
    <div class="info-div text-center element color-element-4">
      <div class="title">
        <span *ngIf="!commandeData.date_livraison && !commandeData.date_rdv"  class="font-weight-bold">{{'Date de rdv' | translate }}</span>
        <span *ngIf="!commandeData.date_livraison && !!commandeData.date_rdv"  class="font-weight-bold">Date de RDV</span>
        <span *ngIf="!!commandeData.date_livraison"  class="font-weight-bold">{{'Date de livraison' | translate }}</span>
      </div>
      <div class="contenu text-truncate">
        <span *ngIf="!commandeData.date_livraison && !commandeData.date_rdv">{{'Non planifié' | translate }}</span>
        <span *ngIf="!commandeData.date_livraison && !!commandeData.date_rdv">{{commandeData?.date_rdv}}</span>
        <span *ngIf="!!commandeData.date_livraison">
          {{commandeData?.date_livraison.replace(' 00:00:00', '')}}
        </span>
      </div>
    </div>
  </div>
  <div class="size-block">
    <div class="info-div text-center element color-element-5">
      <div class="title">
        <span class="font-weight-bold">{{'Activité' | translate }}</span>
      </div>
      <div class="contenu text-truncate">
        <span placement="top"
              container="body"
              ngbTooltip="{{commandeData?.activite_detail_libelle}}">
          {{commandeData?.activite_libelle}}
        </span>
      </div>
    </div>
  </div>
</div>


