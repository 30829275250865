import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {AuthGuard} from './shared/guards/auth.guard';
import {TableauDeBord} from './pages/tableau-de-bord/tableau-de-bord';
import {LitigesPage} from './pages/litiges/litiges';
import {LitigePage} from './pages/litiges/show/litige';
import {ContestationsPage} from './pages/contestations/contestations';
import {ContestationPage} from './pages/contestations/show/contestation';
import {MesFacturesPage} from './pages/factures/mes-factures/mes-factures';
import {NouvelleFacturePage} from './pages/factures/nouvelle-facture/nouvelle-facture';
import {SuiviDeLivraisonPage} from './pages/suivi-de-livraison/suivi-de-livraison.component';
import {GestionRelationClientPage} from './pages/gestion-relation-clients/show/gestion-relation-client';
import {GestionRelationClientsPage} from './pages/gestion-relation-clients/gestion-relation-clients';
import {LivraisonsPage} from './pages/livraisons/livraisons';
import {LivraisonPage} from './pages/livraisons/show/livraison';
import {RetoursPage} from './pages/retours/retours';
import {RetourPage} from './pages/retours/show/retour';
import {ParametresPage} from './pages/parametres/parametres';
import {ContactPage} from './pages/contact/contact';
import {AdministrationPage} from './pages/administration/administration';
import {ExtractionsPage} from './pages/extractions/extractions.component';
import {Page404Page} from './pages/http/page404/page404';
import {Page401Page} from './pages/http/page401/page401';
import {Page503Page} from './pages/http/page503/page503';
import {Page500Page} from './pages/http/page500/page500';
import {HomePage} from './pages/home/home';
import {
    GestionRelationClientWexlogPage
} from './pages/gestion-relation-clients/show-wexlog/gestion-relation-client-wexlog';
import {AnomaliesPageComponent} from './pages/anomalies/anomalies';
import {VideosPage} from "./pages/videos/videos";
import {RapportsKpi} from "./pages/rapports-kpi/rapports-kpi";
import {translate} from "@angular/localize/src/utils";


const routes: Routes = [
    {
        path: '', canActivate: [AuthGuard], data: {title: 'Agediss > Espace client > Accueil'}, children:
            [
                {path: '', redirectTo: 'home', pathMatch: 'full'},
                {path: 'home', component: HomePage},
            ],
    },
    {
        path: 'tableau-de-bord',
        canActivate: [AuthGuard],
        data: {title: 'Agediss > Espace client > Tableau de bord'},
        children:
            [
                {path: '', component: TableauDeBord},
            ]
    },
    {
        path: 'anomalies', canActivate: [AuthGuard], data: {title: 'Agediss > Espace client > Anomalies'}, children:
            [
                {path: '', component: AnomaliesPageComponent},
            ]
    },
    {
        path: 'livraisons',
        canActivate: [AuthGuard],
        data: {title: 'Agediss > Espace client > Mes livraisons'},
        children:
            [
                {path: '', component: LivraisonsPage},
                {path: ':id', component: LivraisonPage},
            ]
    },
    {
        path: 'retours', canActivate: [AuthGuard], data: {title: 'Agediss > Espace client > Mes retours'}, children:
            [
                {path: '', component: RetoursPage},
                {path: ':id', component: RetourPage},
            ]
    },
    {
        path: 'litiges', canActivate: [AuthGuard], data: {title: 'Agediss > Espace client > Mes litiges'}, children:
            [
                {path: '', component: LitigesPage},
                {path: ':id', component: LitigePage},
            ]
    },
    {
        path: 'contestations',
        canActivate: [AuthGuard],
        data: {title: 'Agediss > Espace client > Mes contestations'},
        children:
            [
                {path: '', component: ContestationsPage},
                {path: ':id', component: ContestationPage},
            ]
    },
    {
        path: 'mes-factures',
        canActivate: [AuthGuard],
        data: {title: 'Agediss > Espace client > Mes factures'},
        children:
            [
                {path: '', component: MesFacturesPage},
            ]
    },
    {
        path: 'nouvelle-facture',
        canActivate: [AuthGuard],
        data: {title: 'Agediss > Espace client > Nouvelle facture'},
        children:
            [
                {path: '', component: NouvelleFacturePage},
            ]
    },
    {
        path: 'suivi-de-livraison',
        canActivate: [AuthGuard],
        data: {title: 'Agediss > Espace client > Suivi de livraison'},
        children:
            [
                {path: '', component: SuiviDeLivraisonPage},
            ]
    },
    {
        path: 'gestion-relation-clients',
        canActivate: [AuthGuard],
        data: {title: 'Agediss > Espace client > Gestion relation clients'},
        children:
            [
                {path: '', component: GestionRelationClientsPage, data: {title: 'Mes demandes GRC'}},
                {path: ':id', component: GestionRelationClientPage, data: {title: 'historique des demandes'}},
                {
                    path: 'wexlog/:id',
                    component: GestionRelationClientWexlogPage,
                    data: {title: 'historique des demandes'}
                },
            ]
    },
    {
        path: 'administration',
        canActivate: [AuthGuard],
        data: {title: 'Agediss > Espace client > Mon administration'},
        children:
            [
                {path: '', component: AdministrationPage, data: {title: 'Mon administration'}},
            ]
    },
    {
        path: 'paramètres',
        canActivate: [AuthGuard],
        data: {title: 'Agediss > Espace client > Mes paramètres'},
        children:
            [
                {path: '', component: ParametresPage, data: {title: 'Mes paramètres'}},
            ]
    },
    {
        path: 'contact',
        canActivate: [AuthGuard],
        data: {title: 'Agediss > Espace client > Nous contacter'}, children:
            [
                {path: '', component: ContactPage, data: {title: 'Nous contacter'}},
            ]
    },
    {
        path: 'extractions',
        canActivate: [AuthGuard],
        data: {title: 'Agediss > Espace client > Extractions'}, children:
            [
                {path: '', component: ExtractionsPage, data: {title: 'Extractions'}},
            ]
    },
    {
        path: 'videos',
        canActivate: [AuthGuard],
        data: {title: 'Agediss > Espace client > Mes Vidéos'},
        children:
            [
                {path: '', component: VideosPage, data: {title: 'Mes vidéos'}},
            ]
    },
    {
        path: 'kpi',
        canActivate: [AuthGuard],
        data: {title: 'Agediss > Espace client > Mes KPIs'},
        children:
            [
                 {path: '', component: RapportsKpi, data: {title: 'Mes KPIs'}},
            ]
    },
    {
        path: 'not-found', component: Page404Page
    },
    {
        path: 'not-authorize', component: Page401Page
    },
    {
        path: 'maintenance', component: Page503Page
    },
    {
        path: 'error', component: Page500Page
    },
    {
        path: '**', redirectTo: '/home', data: {title: 'Agediss > Espace client'},
    }];

@NgModule({
    imports: [RouterModule.forRoot(routes, {relativeLinkResolution: 'legacy'})],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
