import {Component, Input, OnInit} from '@angular/core';
import {FactureService} from '../../../../shared/services/api/facture.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {PieceJointeData} from '../../../../shared/models/api/piece-jointe-data.model';
import {FactureData} from '../../../../shared/models/api/facture-data.model';
import {FilterMultiCritereModel} from '../../../../shared/models/filters/filterMultiCritere.model';
import {PieceJointeGed} from '../../../../shared/models/api/ged/piece-jointe-ged.model';
import {PieceJointeService} from '../../../../shared/services/api/piece-jointe.service';
import {MatTableDataSource} from '@angular/material/table';
import {NavigationEnd, Router} from '@angular/router';

@Component({
  selector: 'app-visualisation-facture',
  templateUrl: './visualisation-facture.component.html',
  styleUrls: ['./visualisation-facture.component.scss']
})
export class VisualisationFactureComponent implements OnInit {

  public filter: FilterMultiCritereModel = new FilterMultiCritereModel();

  @Input() factureId: number;

  public pieceJointeData: PieceJointeData;
  public piecesJointesGed: PieceJointeGed[];
  public urlPreview: string | void;
  public showImage = false;
  public currentType: number =  null;

  public facture: FactureData;
  public assoSource: any;
  public assoColumns = ['numero_client', 'montant', 'statut'];

  public availableFormat = [
    'image/png',
    'image/jpeg',
    'application/pdf'
  ];

  // loading
  public loading = true;
  public loadingPieceJointe = true;

  constructor(private fileSvc: PieceJointeService,
              private factureSvc: FactureService,
              private router: Router,
              private modalService: NgbModal)
  {
  }

  ngOnInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.modalService.dismissAll();
      }
    });
    this.loadFacture();
  }

  loadFacture() {
    const subscription = this.factureSvc.getFacture(this.factureId).subscribe(facture => {
        this.facture = facture;
        this.assoSource = new MatTableDataSource(
          this.facture.type_facture.code === 'FACTURE' ? this.facture.avoirs_asso : this.facture.factures_asso
        );
        this.loading = false;
        this.loadPieceJointe();
        subscription.unsubscribe();
      },
      () => {
        this.loading = false;
      }
    );
  }

  loadPieceJointe() {
    this.pieceJointeData = this.facture.pieceJointe;
    if (this.facture.pieceJointe.path.startsWith('../var/')) {
      const subscription = this.fileSvc.getFilePreview(this.facture.pieceJointe.id).subscribe(
        (urlPreview) => {
          this.currentType =  this.availableFormat.indexOf(urlPreview.type);
          if (this.availableFormat.indexOf(urlPreview.type) >= 0) {
            this.showImage = true;
          }
          this.urlPreview = window.URL.createObjectURL(urlPreview);
          this.loadingPieceJointe = false;
          subscription.unsubscribe();
        },
        () => {
          this.pieceJointeError(subscription);
        }
      );
    } else {
      const subscription = this.fileSvc.getFilePreviewGed(this.facture.pieceJointe.id).subscribe(
        (piecesJointesGed) => {
          this.piecesJointesGed = piecesJointesGed;
          this.loadingPieceJointe = false;
          subscription.unsubscribe();
        },
        () => {
          this.pieceJointeError(subscription);
        }
      );
    }
  }

  pieceJointeError(subscription) {
    this.loadingPieceJointe = false;
    subscription.unsubscribe();
  }
}
