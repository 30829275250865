import {Deserializable} from '../interfaces/deserializable.interface';

export class NotificationGrcData implements Deserializable {

  public id?: number;
  public email?: string;
  public username?: string;
  public numero_client?: string;
  public client?: string;
  public groupe_client?: string;
  public activer_message?: string;
  public activer_demande?: string;
  public pending: boolean;

  constructor(){
    this.pending = false;
  }

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }

}
