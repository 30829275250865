import {AfterViewInit, Component, ElementRef, HostBinding, Input, OnInit, Renderer2, ViewChild} from '@angular/core';
import {Litige} from '../../../shared/models/api/litige.model';
import {Commande} from "../../../shared/models/api/commande.model";
import {TourService} from "ngx-tour-ngx-bootstrap";
import {TranslateService} from "@ngx-translate/core";
import {CommandeData} from "../../../shared/models/api/commande-data.model";
import {View} from "../../../shared/models/view.model";
import {AuthenticationService} from "../../../shared/services/authentication.service";
import {DomSanitizer, SafeStyle} from "@angular/platform-browser";


@Component({
  selector: 'app-tab-livraison',
  templateUrl: './tab-livraison.component.html',
  styleUrls: ['./tab-livraison.component.scss'],
})
export class TabLivraisonComponent implements OnInit, AfterViewInit  {

  @Input() commandeData: CommandeData;
  @Input() litige: Litige;
  @Input() litiges: Litige[];
  @Input() startTabIndex = 0;
  @Input() retour: string;
  @Input() livraison: string;

  public loading: boolean = false;
  public widthTab: number;
  public minWidth: string;
  public view: View;
  public tabs = [];
  public tabsCommande = [];

  @ViewChild('parent') someInput: ElementRef;

  constructor(public translate: TranslateService,
              private renderer: Renderer2,
              private authSvc: AuthenticationService) {
    this.authSvc.currentView.subscribe(x => this.view = x);
  }

  ngOnInit() {
    if (!this.commandeData) {
      throw new Error('Le composent tab-commande attend un objet de type commande.');
    }

    this.tabs = [
      {
        code: 'COMMANDE',
        libelle: this.translate.instant('Commande'),
        livraisonTitle: 'livraison',
        livraisonContent: this.translate.instant('Informations générales liées à votre demande de livraison ou de la reprise'),
        retourTitle: 'retour',
        retourContent: this.translate.instant('Informations générales liées à votre demande de retour'),
        droit: 'COMMANDE_MENU_LIVRAISON',
      },
      {
        code: 'HISTORIQUE',
        libelle: this.translate.instant('Historique'),
        livraisonTitle: 'livraison',
        livraisonContent: this.translate.instant('Détail des événements liés aux étapes de la livraison ou de la reprise'),
        retourTitle: 'retour',
        retourContent: this.translate.instant('Détail des événements liés aux étapes du retour'),
        droit: 'COMMANDE_MENU_LIVRAISON',
      },
      {
        code: 'MEDIA',
        libelle: this.translate.instant('Média'),
        livraisonTitle: 'livraison',
        livraisonContent: this.translate.instant('Détail des communications faites par Agediss à destination de votre consommateur'),
        retourTitle: 'retour',
        retourContent: this.translate.instant('Détail des événements liés aux étapes du retour'),
        droit: 'COMMANDE_MENU_LIVRAISON',
      },
      {
        code: 'GRC',
        libelle: this.translate.instant('GRC'),
        livraisonTitle: 'livraison',
        livraisonContent: this.translate.instant('Historique des conversations avec votre conseiller Agediss'),
        retourTitle: 'retour',
        retourContent: this.translate.instant('Historique des conversations avec votre conseiller Agediss'),
        droit: 'GRC_MENU_GESTION-RELATION-CLIENTS',
      },
      {
        code: 'LITIGES',
        libelle: this.translate.instant('Litiges'),
        livraisonTitle: 'livraison',
        livraisonContent: this.translate.instant('Visualisation du dossier litige associé à la demande de livraison ou de la reprise'),
        retourTitle: 'retour',
        retourContent: this.translate.instant('Visualisation du dossier litige associé à la demande de retour'),
        droit: 'LITIGE_MENU_LITIGE',
      },
    ];
    this.tabs.forEach((val) => {
      if(this.view.hasDroit(val.droit)) {
        this.tabsCommande.push(val)
      }
    })
    this.widthTab = 100 / this.tabsCommande.length;
    this.minWidth = this.widthTab + '%';
  }

  ngAfterViewInit() {
    let parent = this.someInput['_elementRef'].nativeElement
    for(let i = 1; i <= this.tabsCommande.length; i++ ) {
      let children = parent['children'][0].querySelector(".mat-tab-label:nth-child(0n+" + i + ")");
      this.renderer.setStyle(children, 'min-width', this.minWidth);
    }
  }
}
