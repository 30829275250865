import {Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild} from '@angular/core';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {DatatableHelper} from '../../../../shared/helpers/datatable.helper';
import {tableAnimation} from '../../../../animations';
import {FactureLitige} from '../../../../shared/models/api/facture-litige.models';

@Component({
  selector: 'app-table-litige-facture',
  templateUrl: './table-litige-facture.component.html',
  styleUrls: ['./table-litige-facture.component.scss'],
  animations: [tableAnimation],
})
export class TableLitigeFactureComponent implements OnInit {

  @Output() selectShowDocument: EventEmitter<{id: number, index: number}> = new EventEmitter<{id: number, index: number}>();
  @Output() selectShowLitige: EventEmitter<{id: number, index: number}> = new EventEmitter<{id: number, index: number}>();

  @Input() data: FactureLitige[];
  @Input() displayedColumns: string[] = ['id', 'numero_avis', 'numero_commande', 'statut_litige', 'date_litige',
    'montant', 'montant_valide', 'montantAvoir', 'montantAvoirAttendu', 'statut'];
  @Input() loading = false;
  @Input() length: number;

  public innerWidth: number;
  public dataSource: any;

  ngOnInit() {
    this.dataSource = new MatTableDataSource<FactureLitige>(this.data);
    this.onResizeTable();
  }

  selectLitigeId(id: number, index: number) {
    this.selectShowLitige.emit({id, index});
  }

  ngOnChanges(changes: any) {
    if (changes.data) {
      this.dataSource = new MatTableDataSource(changes.data.currentValue);
    }
    if (changes.loading) {
      if (changes.data) {
        this.dataSource = new MatTableDataSource(changes.data.currentValue);
      }
      if (changes.loading) {
        this.loading = changes.loading.currentValue;
      }
    }
  }

  @HostListener('window:resize', ['$event'])
  onResizeTable() {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth < 400) {
      this.displayedColumns = [
        'numero_avis',
        'numero_commande',
        'montant'
      ];
    }
    else if (this.innerWidth < 800) {
      this.displayedColumns = [
        'numero_commande',
        'date_litige', 'montant', 'montant_valide',
        'statut'
      ];
    }
    else if (this.innerWidth < 1200) {
      this.displayedColumns = [
        'numero_avis', 'numero_commande',
        'montant', 'montant_valide',
        'montantAvoirAttendu', 'statut'
      ];
    }
    else {
      this.displayedColumns = [
        'id', 'numero_avis', 'numero_commande',
        'statut_litige', 'date_litige', 'montant', 'montant_valide',
        'montantAvoir', 'montantAvoirAttendu', 'statut'
      ];
    }
  }

}
