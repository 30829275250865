import {Deserializable} from '../interfaces/deserializable.interface';
import {ExtractionData} from "./extraction-data.model";

export class Extraction implements Deserializable {

  public nb?: number;
  public data?: ExtractionData[];

  deserialize(input: any): this {
    Object.assign(this, input);
    return this
  }

}
