import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges} from '@angular/core';
import {Evenement} from "../../../../shared/models/api/evenement.model";
import {NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {View} from "../../../../shared/models/view.model";
import {AuthenticationService} from "../../../../shared/services/authentication.service";
import {EvenementColis} from "../../../../shared/models/api/evenement-colis.model";

@Component({
  selector: 'app-evenement-colis',
  templateUrl: './evenement-colis.component.html',
  styleUrls: ['./evenement-colis.component.scss']
})
export class EvenementColisComponent implements OnInit {

  @Input() evenement: Evenement = null;
  @Input() evenementColis: EvenementColis = null;
  @Input() eventId: number;

  @Output() canceled: EventEmitter<void> = new EventEmitter();

  public activModal: NgbModalRef;
  public loading: boolean = false;
  public view: View;

  constructor(private modalSvc: NgbModal,
              private authSvc: AuthenticationService) { }

  ngOnInit() {
    this.authSvc.currentView.subscribe(x => this.view = x);
  }

  openModalVisualisationFile(modalName, evenementColis: EvenementColis) {
    this.evenementColis = evenementColis
    this.activModal = this.modalSvc.open(modalName, {
      size: 'lg',
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.evenement) {
      this.evenement = changes.evenement.currentValue;
    }
  }

  cancel() {
    this.canceled.emit();
  }


}
