import {Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, ViewChild} from '@angular/core';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {tableAnimation} from '../../../../animations';
import {Evenement} from '../../../../shared/models/api/evenement.model';
import {DatatableHelper} from '../../../../shared/helpers/datatable.helper';
import {MatSort} from '@angular/material/sort';
import {MatPaginator, PageEvent} from '@angular/material/paginator';


@Component({
  selector: 'app-table-evenement',
  templateUrl: './table-evenement.component.html',
  styleUrls: ['./table-evenement.component.scss'],
  animations: [tableAnimation],
})
export class TableEvenementComponent implements OnInit, OnChanges {

  expandedElement: Evenement | null;

  @Output() onSelectFile: EventEmitter<Evenement> = new EventEmitter<Evenement>();
  @Output() onGeolocalisation: EventEmitter<Evenement> = new EventEmitter<Evenement>();
  @Output() onSelectEvenementColis: EventEmitter<Evenement> = new EventEmitter<Evenement>();


  @Input() loading = false;
  @Input() showViewBtn = false;
  @Input() showFileBtn = false;
  @Input() showGeolocalisationBtn = false;
  @Input() showInfosBtn = false;
  @Input() data: Evenement[];
  @Input() length: number;
  @Input() pageSize = 10;
  @Input() total: number;
  @Input() pageIndex: number;
  @Input() pageEvent: PageEvent;
  @Input() pageSizeOptions: number[] = [5, 10, 20];
  @Input() displayedColumns: string[] = [
    'id',
    'contexte',
    'code',
    'libelle',
    'date_creation',
    'commentaire',
    'date_evenements',
    'creneaux_debut',
    'creneaux_fin',
    'date_proposition_1',
    'date_proposition_2',
    'options',
  ];

  public innerWidth: number;
  public dataSource: any;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  ngOnInit() {
    this.dataSource = new MatTableDataSource(this.data);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.onResizeTable();
  }

  ngOnChanges(changes: any) {
    if (changes.data) {
      this.dataSource = new MatTableDataSource(changes.data.currentValue);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
    if (changes.loading) {
      if (changes.data) {
        this.dataSource = new MatTableDataSource(changes.data.currentValue);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
      if (changes.loading) {
        this.loading = changes.loading.currentValue;
      }
    }
  }

  selectFile(evenement: Evenement) {
    this.onSelectFile.emit(evenement);
  };

  geolocalisation(evenement: Evenement) {
    this.onGeolocalisation.emit(evenement);
  };

  selectEvenementColis(evenement: Evenement) {
    this.onSelectEvenementColis.emit(evenement);
  };

  /**
   * Permet d'ordonner les colones.
   */
  sortData(sort: Sort) {
    const data = this.data.slice();
    if (!sort.active || sort.direction === '') {
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      return;
    }
    if (sort.active == "date_evenements" || sort.active == "date_creation") {
      let datasourceSort = this.dataSource.data.sort((a, b) => {
        let test = a[sort.active];
        let test2 = b[sort.active];
        let oui = test.replace(/[#-]/g, "/");
        let oui2 = test2.replace(/[#-]/g, "/");
        let nvValeur = oui.split(" ");
        let nvValeur2 = oui2.split(" ");
        let nvValeur01 = nvValeur[0].split("/");
        let nvValeur02 = nvValeur2[0].split("/");
        let nvTab = nvValeur01[1] + "/" + nvValeur01[0] + "/" + nvValeur01[2] + " " + nvValeur[1];
        let nvTab2 = nvValeur02[1] + "/" + nvValeur02[0] + "/" + nvValeur02[2] + " " + nvValeur2[1];
        if (sort.direction == "desc") {
          if (new Date(nvTab) < new Date(nvTab2)) {
            return 1;
          } else {
            return -1;
          }
        } else if (sort.direction == "asc") {
          if (new Date(nvTab) > new Date(nvTab2)) {
            return 1;
          } else {
            return -1;
          }
        }

      });
      this.dataSource = new MatTableDataSource(datasourceSort);
    }

    this.dataSource.paginator = this.paginator;

  }


  @HostListener('window:resize', ['$event'])
  onResizeTable() {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth < 400) {
      this.displayedColumns = [
        'libelle',
        'options',
      ];
    } else if (this.innerWidth > 400 && this.innerWidth <= 600) {
      this.displayedColumns = [
        'libelle',
        'date_creation',
        'options',
      ];
    } else if (this.innerWidth > 600 && this.innerWidth <= 900) {
      this.displayedColumns = [
        'libelle',
        'date_creation',
        'date_evenements',
        'options',
      ];
    } else if (this.innerWidth > 900 && this.innerWidth <= 1000) {
      this.displayedColumns = [
        'contexte',
        'libelle',
        'date_creation',
        'date_evenements',
        'options',
      ];
    } else if (this.innerWidth > 1000) {
      this.displayedColumns = [
        'contexte',
        'libelle',
        'date_creation',
        'date_evenements',
        'commentaire',
        'options',
      ];
    }
  }

}
