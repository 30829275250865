import {Component, OnInit} from '@angular/core';
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {AuthenticationService} from '../../../shared/services/authentication.service';
import {View} from '../../../shared/models/view.model';
import {Router} from "@angular/router";


@Component({
  selector: 'app-nouvelle-facture',
  templateUrl: './nouvelle-facture.html',
  styleUrls: ['./nouvelle-facture.scss']
})
export class NouvelleFacturePage implements OnInit {

  public view: View;
  public activModal: NgbModalRef;

  constructor(private authSvc: AuthenticationService,
              private router: Router) {
  }

  ngOnInit() {
    this.authSvc.currentView.subscribe(x => this.view = x);
    if (!this.view.hasDroit('LITIGE_MENU_NOUVELLE-FACTURE')) {
      this.router.navigate(['/', 'home']);
    }
  }

  onUploaded() {
    this.activModal.close();
  }

}
