import { Injectable } from '@angular/core';
import {environment} from '../../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {StatutGrc} from '../../../models/api/statut-grc.model';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class StatutWexlogService {

  urlRessource = environment.url_api_espace_client + '/wexlog-statuts';

  constructor(private http: HttpClient) {}

  /**
   * retourne les statuts de la grc
   */
  getStatutsGrc(): Observable<StatutGrc[]> {

    return this.http
      .get<StatutGrc[]>(`${this.urlRessource}/grc-statuts`)
      .pipe(map((observe) => observe.map(function (observe) {
        return new StatutGrc().deserialize(observe);
      })))
  }
}
