<table mat-table [dataSource]="dataSource" (matSortChange)="sortData($event)" multiTemplateDataRows matSort
       matSortActive="date" matSortDirection="desc"
       class="table mat-responsive table-hover table-striped-n3 table-bordered mat-elevation-z8 text-center">

  <ng-container matColumnDef="id">
    <th mat-header-cell *matHeaderCellDef  mat-sort-header class="px-10">{{'N° facture' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="px-10 align-middle">
        {{element?.numero_client}}
    </td>
  </ng-container>
  <ng-container matColumnDef="numero_interne">
    <th class="align-middle" mat-header-cell *matHeaderCellDef  mat-sort-header>
      {{'Numéro interne' | translate }}
    </th>
    <td mat-cell *matCellDef="let element" class="align-middle">
      {{element?.numero_interne}}
    </td>
  </ng-container>
  <ng-container matColumnDef="client">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'DO' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="align-middle">
      {{element?.piece_jointe?.compte_client?.libelle}}
    </td>
  </ng-container>
  <ng-container matColumnDef="type_facture">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Type' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="align-middle">
      {{(element?.type_facture?.code == 'FACTURE' ? 'Facture' : 'Avoir') | translate}}
    </td>
  </ng-container>
  <ng-container matColumnDef="date">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Date' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="text-nowrap align-middle">
      {{element?.date | date :'dd-MM-yyyy'}}
    </td>
  </ng-container>
  <ng-container matColumnDef="montant">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Montant total affecté' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="align-middle">
      {{element?.montant | currency: 'EUR':'€'}}
    </td>
  </ng-container>
  <ng-container matColumnDef="statut">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Statut' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="align-middle text-white">
      <div class="label"
           [ngClass]="{
              'label-pink' : !element.statut,
              'label-danger' : !!element.statut && element.statut_bap === 'CONTESTE',
              'label-dark' : !!element.statut && element.statut_bap === 'PRESCRIT',
              'label-lime' : !!element.statut && element.statut_bap === 'BAP' && !element.date_bap,
              'label-primary' : !!element.statut && element.statut_bap === 'BAP' && !!element.date_bap
           }">
        <span *ngIf="!element.statut">{{"En attente d'affectation" | translate }}</span>
        <span *ngIf="!!element.statut && element.statut_bap === 'PRESCRIT'">{{"Prescrit" | translate }}</span>
        <span *ngIf="!!element.statut && element.statut_bap === 'CONTESTE'">{{"En attente d'avoir" | translate }}</span>
        <span *ngIf="!!element.statut && element.statut_bap === 'BAP' && !element.date_bap">{{"Bon à payer" | translate }}</span>
        <span *ngIf="!!element.statut && element.statut_bap === 'BAP' && !!element.date_bap">{{"Transmis pour paiement" | translate }}</span>
      </div>
    </td>
  </ng-container>
  <ng-container matColumnDef="options">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element; let i = index" class="with-img align-middle text-nowrap">
      <button *ngIf="showViewBtn"
              ngbTooltip="{{'Visualisation de la facture' | translate }}"
              placement="top"
              container="body"
              class="button btn btn-rounded-arrow btn-sm btn-agediss mr-1"
              (click)="onSelectFacture(element)">
        <i class="fa fa-eye"></i>
      </button>
      <button *ngIf="showInfosBtn && this.innerWidth < 1200"
              ngbTooltip="{{'Informations complémentaires' | translate }}"
              placement="left"
              container="body"
              class="button btn btn-rounded-arrow btn-sm btn-agediss mr-1"
              (click)="expandedElement = expandedElement === element ? null : element">
        <i class="fas fa-angle-double-down"></i>
      </button>
    </td>
  </ng-container>

  <ng-container matColumnDef="noData">
    <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length" innerHTML="{{'Aucune facture' | translate }}"></td>
  </ng-container>
  <ng-container matColumnDef="loading">
    <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length" innerHTML="{{'Chargement en cours' | translate }} <i class='fas fa-spin fa-circle-notch'></i>"></td>
  </ng-container>

  <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
  <ng-container matColumnDef="expandedDetail">
    <td class="table-td p-0" mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">

      <div class="table-detail"
           *ngIf="this.innerWidth < 390"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description">
          <strong>{{'Statut' | translate }} : </strong>
          <span>
            <div placement="left"
                 container="body"
                 class="label {{ (element?.statut?.libelle ? element?.statut?.libelle : 'En attente d\'affectation') | statutLitigePipe: element?.statut.code }}">
              {{(element?.statut?.libelle ? element?.statut?.libelle : 'En attente d\'affectation') | titlecase}}
            </div>
          </span>
        </div>
      </div>
      <div class="table-detail"
           *ngIf="this.innerWidth < 482"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description">
          <strong>{{'Date' | translate }} : </strong><span>{{element?.date | date :'dd-MM-yyyy'}}</span>
        </div>
      </div>
      <div class="table-detail"
           *ngIf="this.innerWidth < 768"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description">
          <strong>{{'Montant total affecté' | translate }} : </strong><span>{{element?.montant | currency: 'EUR':'€'}}</span>
        </div>
      </div>
      <div class="table-detail"
           *ngIf="this.innerWidth < 1100"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description">
          <strong>{{'Type' | translate }} : </strong><span>{{element?.type_facture?.libelle}}</span>
        </div>
      </div>
    </td>
  </ng-container>

  <tr mat-header-row style="height: 15px;" *matHeaderRowDef="displayedColumns;  sticky: true"></tr>
  <tr mat-row *matRowDef="let element; columns: displayedColumns;" [hidden]="loading"
      class="table-row overflow-scroll"
      [class.expanded-row]="expandedElement === element">
  </tr>
  <tr mat-footer-row *matFooterRowDef="['noData']" [hidden]="dataSource && dataSource.data.length > 0 || loading"></tr>
  <tr mat-footer-row *matFooterRowDef="['loading']" [hidden]="!loading"></tr>
  <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="table-detail-row"></tr>
</table>

<mat-paginator [length]="length"  [pageIndex]="pageIndex" (page)="emit($event)" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
               showFirstLastButtons></mat-paginator>
