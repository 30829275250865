import {HttpParams} from '@angular/common/http';


export class HttpUtil {


  public static convertObjectToHttpParams(object) {
    if(!object ){
      return null
    }
    let target: HttpParams = new HttpParams();
    Object.keys(object).forEach((key: string) => {
      const value: string | number | boolean | Date = object[key];

      if (value instanceof Date) {
        target = target.append(key, value.toISOString());
      }

      if ((typeof value !== 'undefined') && (value !== null) && !(value instanceof Date)) {
        target = target.append(key, value.toString());
      }
    });
    return target;
  }

  public static extractFilenameInContentDisposition(contentDisposition: string): string {
    return contentDisposition
      .split(';')[1]
      .split('filename')[1]
      .split('=')[1]
      .replace(/"/g,"")
      .trim();
  }
}
