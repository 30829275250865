import { Injectable } from '@angular/core';
import Swal from "sweetalert2";

@Injectable({
  providedIn: 'root'
})
export class FileService {

  constructor() { }

  static downloadDataToFile(data: {data: any, filename: string}) {
    let url = window.URL.createObjectURL(data.data);
    let a = document.createElement('a');
    document.body.appendChild(a);
    a.setAttribute('style', 'display: none');
    a.href = url;
    a.download = data.filename;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove(); // remove the element
  }

  static handleDownloadError(filename: string = null) {
    Swal.fire('Désolé', `Impossible de télécharger le fichier ${filename} ` , 'warning')
  }
}
