import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Timeline} from '../../../../shared/models/api/timeline.model';
import {Commande} from "../../../../shared/models/api/commande.model";
import {CommandeData} from "../../../../shared/models/api/commande-data.model";
import {View} from "../../../../shared/models/view.model";
import {AuthenticationService} from "../../../../shared/services/authentication.service";

@Component({
  selector: 'app-timeline-livraison',
  templateUrl: './timeline-livraison.component.html',
  styleUrls: ['./timeline-livraison.component.scss']
})
export class TimelineLivraisonComponent implements OnInit {

  @Input() data: Timeline[];
  @Input() commande: CommandeData;
  @Input() loading: boolean = false;

  @Input() showSignatureLivraisonBtn: boolean = false;

  @Output() onSelectSignatureLivraison: EventEmitter<{id: string}> = new EventEmitter<{id: string}>();
  @Output() onSelectInfosTimeline: EventEmitter<Timeline> = new EventEmitter<Timeline>();

  public view: View;

  constructor(private authSvc: AuthenticationService) {
    this.authSvc.currentView.subscribe(x => this.view = x);
  }

  ngOnInit() {
  }

  selectSignatureLivraison(id: string) {
    this.onSelectSignatureLivraison.emit({id});
  };

  selectInfosTimeline(timeline: Timeline) {
    this.onSelectInfosTimeline.emit(timeline);
  };

}
