import {Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {DatatableHelper} from '../../../../../shared/helpers/datatable.helper';
import {User} from '../../../../../shared/models/user.model';
import {tableAnimation} from '../../../../../animations';
import Swal from "sweetalert2";
import {UserService} from '../../../../../shared/services/api/sso/user.service';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {PageEvent} from "@angular/material/paginator";
import {UserData} from "../../../../../shared/models/user-data.model";

export function TRANSLATE(str: string) {
  return str;
}

@Component({
  selector: 'app-table-users',
  templateUrl: './table-users.component.html',
  styleUrls: ['./table-users.component.scss'],
  animations: [tableAnimation],
})
export class TableUsersComponent implements OnInit {

  expandedElement: UserData | null;

  @Output() onAddUser: EventEmitter<void> = new EventEmitter<void>();
  @Output() onEditUser: EventEmitter<UserData> = new EventEmitter<UserData>();

  @Input() data: UserData[];
  @Input() displayedColumns: string[] = [
    'username',
    'firstname',
    'lastname',
    'email',
    'phone',
    'fonction',
    'options'
  ];
  @Input() loading = false;
  @Input() hiddenColumns: string[] = [];
  @Input() length: number;
  @Input() pageSize: number;
  @Input() pageIndex: number;
  @Input() pageEvent: PageEvent;
  @Input() pageSizeOptions: number[];
  @Input() showInfosBtn: boolean = false;
  @Input() showEditBtn: boolean = false;
  @Input() showDeleteBtn: boolean = false;
  @Input() showAddButton: boolean = false;

  @Output() onSorted: EventEmitter<Sort> = new EventEmitter();
  @Output() paginatorEvent : EventEmitter<PageEvent> = new EventEmitter<PageEvent>()

  public innerWidth: number;
  private subscription: Subscription;
  public dataSource: any;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private translate: TranslateService,
              private userSvc: UserService,
              private toastr: ToastrService) {

  }

  ngOnInit() {
    this.onResizeTable();
    this.dataSource = new MatTableDataSource<UserData>(this.data);
    this.dataSource.sort = this.sort;
    if (this.hiddenColumns.length > 0) {
      this.hiddenColumns.forEach((value) => {
        let index = this.displayedColumns.indexOf(value);
        if (index) {
          this.displayedColumns.splice(index, 1)
        }
      })
    }
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  emit($event: PageEvent) {
    return this.paginatorEvent.emit($event);
  }


  selectAddUser() {
    this.onAddUser.emit()
  }

  selectEditUser(user: UserData) {
    this.onEditUser.emit(user)
  }

  /**
   * LifeHook : à chaque changement de value de data on rafraichi les données du tableau
   * @param changes
   */
  ngOnChanges(changes: any) {
    if (changes.data) {
      this.dataSource = new MatTableDataSource(changes.data.currentValue);
      this.dataSource.sort = this.sort;
    }
    if (changes.loading) {
      if (changes.data) {
        this.dataSource = new MatTableDataSource(changes.data.currentValue);
        this.dataSource.sort = this.sort;
      }
      if (changes.loading) {
        this.loading = changes.loading.currentValue;
      }
    }

  }

  /**
   * Permet d'ordonner les colones.
   */
  sortData(sort: Sort) {
    const data = this.data.slice();
    if (!sort.active || sort.direction === '') {
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.sort = this.sort;
      return;
    }
    this.dataSource = new MatTableDataSource(data.sort(
      (a, b) => {
        const isAsc = sort.direction === 'asc';
        if (sort.active in a) {
          return DatatableHelper.compare(a[sort.active], b[sort.active], isAsc);
        }
        return 0;
      }));
    this.dataSource.sort = this.sort;
  }

  delete(userId, index) {

    Swal.fire({
      title: this.translate.instant('Attention !'),
      text: 'Etes-vous sur de vouloir supprimer l\'utilisateur ' + this.dataSource.data[index].username,
      type: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Non',
      confirmButtonText: 'Oui !'

    }).then((result) => {
      if (result.value) {
        //affiche le chargement
        this.dataSource.data[index].pending = true;

          //service pour supprimer le fichier
          this.subscription = this.userSvc.delete(userId).subscribe(
            () => {
              this.toastr.info(
                this.translate.instant("Le compte de l'utilisateur {{ value }} a été supprimé", { value: this.dataSource.data[index].username} ),
              );
              this.dataSource.data[index].pending = false;
              this.dataSource.data.splice(index, 1);
              this.dataSource = new MatTableDataSource(this.dataSource.data);
            },
            () => {
              this.dataSource.data[index].pending = false;
              this.toastr.error(
                this.translate.instant("Le compte de l'utilisateur {{ value }} n'a pas pu être supprimé", { value: this.dataSource.data[index].username}),
              );
            },
          );
        }

      });
  }

  @HostListener('window:resize', ['$event'])
  onResizeTable() {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth < 550) {
      this.displayedColumns = [
        'username',
        'options'
      ];
    } else if (this.innerWidth > 550 && this.innerWidth <= 700) {
      this.displayedColumns = [
        'username',
        'firstname',
        'options'
      ];
    } else if (this.innerWidth > 700 && this.innerWidth <= 767) {
      this.displayedColumns = [
        'username',
        'firstname',
        'lastname',
        'options'
      ];
    }  else if (this.innerWidth > 767 && this.innerWidth <= 920) {
      this.displayedColumns = [
        'username',
        'firstname',
        'options'
      ];
    } else if (this.innerWidth > 920 && this.innerWidth <= 1180) {
      this.displayedColumns = [
        'username',
        'firstname',
        'lastname',
        'options'
      ];
    }else if (this.innerWidth > 1180 && this.innerWidth <= 1330) {
      this.displayedColumns = [
        'username',
        'firstname',
        'lastname',
        'email',
        'options'
      ];
    } else if (this.innerWidth > 1330 && this.innerWidth <= 1600) {
      this.displayedColumns = [
        'username',
        'firstname',
        'lastname',
        'email',
        'phone',
        'options'
      ];
    } else if (this.innerWidth > 1600) {
      this.displayedColumns = [
        'username',
        'firstname',
        'lastname',
        'email',
        'phone',
        'fonction',
        'options'
      ];
    }
  }

}
