<div *ngIf="loading" style="height: 500px" class="row col-12">
  <span class="spinner"></span>
</div>
<div *ngIf="!loading">
  <div class="modal-header bg-modal">
    <h4 class="modal-title  w-100 text-center">{{"Visualisation de l'article" | translate }} n°
      {{article.id}}
    </h4>
  </div>
  <div class="modal-body bg-modal">
    <div class="row m-0 form-group">
      <div class="offset-0 col-12 ">
        <label class="col-lg-12 col-md-12 col-sm-12">{{'Référence' | translate }}
          <div class="input-group">
            <input id="reference" type="text" class="form-control form-control-filter" value="{{ article.reference }}" autocomplete="off">
          </div>
        </label>
      </div>
    </div>
    <div class="row m-0 form-group">
      <div class="offset-0 col-12">
        <label class="col-lg-12 col-md-12 col-sm-12">{{'Libellé' | translate }}
          <div class="input-group">
            <input id="libelle" type="text" class="form-control form-control-filter" value="{{ article.libelle }}" autocomplete="off">
          </div>
        </label>
      </div>
    </div>
    <div class="row m-0 form-group">
      <div class="offset-0 col-12">
        <label class="col-lg-12 col-md-12 col-sm-12">{{'Volume' | translate }}
          <div class="input-group">
            <input id="volume" type="text" class="form-control form-control-filter" value="{{ article.volume }}" autocomplete="off">
            <span class="input-group-addon bg-grey-transparent-5">m³</span>
          </div>
        </label>
      </div>
    </div>
    <div class="row m-0 form-group">
      <div class="offset-0 col-12">
        <label class="col-lg-12 col-md-12 col-sm-12">{{'Poids' | translate }}
          <div class="input-group">
            <input id="poids" type="text" class="form-control form-control-filter" value="{{ article.poids }}" autocomplete="off">
            <span class="input-group-addon bg-grey-transparent-5">kg</span>
          </div>
        </label>
      </div>
    </div>
  </div>
  <div class="row m-0 py-4 px-2">
    <div class="col-lg-12 col-md-12">
      <panel noButton="true" bodyClass="no-padding" class="panel">
        <div class="row" header>
          <div class="col-xl-6 col-12 title-table"><h4 class="pt-2 text-agediss m-0">Liste
            des colis</h4></div>
          <div class="col-xl-6 col-12">
            <div class="input-group">
              <div class="input-group-prepend"><span class="input-group-text input-group-border-right">Nombre de colis</span>
              </div>
              <input class="form-control form-control-filter" readonly value="{{article.nb_colis}}">
            </div>
          </div>
        </div>
        <div style="max-height: 181.8px" class="scrollbar scrollbar-black bordered-black square thin overflow-scroll">
          <app-table-colis [displayedColumns]="displayedColumns" [loading]="loadingTableColis"
                           [data]="article.colis"></app-table-colis>
        </div>
      </panel>
    </div>
  </div>
  <div class="modal-footer p-5">
    <button type="button" class="btn  bg-black-lighter text-white" (click)="cancel()">{{'Fermer' | translate }}</button>
  </div>
</div>

