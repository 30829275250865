import {Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, ViewChild} from '@angular/core';
import {MatSort, Sort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {DatatableHelper} from '../../../../shared/helpers/datatable.helper';
import {tableAnimation} from '../../../../animations';
import {Litige} from '../../../../shared/models/api/litige.model';
import {Conversation} from '../../../../shared/models/api/conversation.model';
import Swal from 'sweetalert2';
import {Subscription} from 'rxjs';
import {PieceJointeService} from '../../../../shared/services/api/piece-jointe.service';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {PieceJointeData} from "../../../../shared/models/api/piece-jointe-data.model";
import {MatPaginator, PageEvent} from "@angular/material/paginator";

@Component({
  selector: 'app-table-facture-attente',
  templateUrl: './table-facture-attente.component.html',
  styleUrls: ['./table-facture-attente.component.scss'],
  animations: [tableAnimation],
})
export class TableFactureAttenteComponent implements OnInit, OnChanges {

  expandedElement: Litige | null;

  @Input() data: PieceJointeData[];
  @Input() displayedColumns: string[] = [
    'id',
    'filename',
    'type_piece_jointe',
    'created_at',
    'options'
  ];

  @Input() length: number;
  @Input() pageSize: number = 10;
  @Input() pageIndex: number;
  @Input() pageEvent: PageEvent;
  @Input() pageSizeOptions: number[] = [5, 10, 20];
  @Input() loading = false;
  @Input() hiddenColumns: string[] = [];
  @Input() showViewBtn: boolean = false;
  @Input() showInfosBtn: boolean = false;
  @Input() showDeleteBtn: boolean = false;
  @Input() showTitle: boolean = false;
  @Input() showAddFileBtn: boolean = false;

  @Output() Affecter: EventEmitter<any> = new EventEmitter();
  @Output() onAdd: EventEmitter<Conversation> = new EventEmitter();
  @Output() paginatorEvent : EventEmitter<PageEvent> = new EventEmitter<PageEvent>()
  @Output() onSelectFile: EventEmitter<{ id: number, index: number, filename: string, path:string }> = new EventEmitter<{ id: number, index: number, filename: string, path:string}>();


  public dataSource: MatTableDataSource<PieceJointeData>;
  public innerWidth: number;
  public filesSub: Subscription;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private translate: TranslateService,
              private pieceJointeService: PieceJointeService,
              private toastr: ToastrService) {
  }


  ngOnInit() {
    this.onResizeTable();
    this.dataSource = new MatTableDataSource(this.data);
    if (this.hiddenColumns.length > 0) {
      this.hiddenColumns.forEach((value) => {
        let index = this.displayedColumns.indexOf(value);
        if (index) {
          this.displayedColumns.splice(index, 1);
        }
      });
    }
  }

  emit($event: PageEvent) {
    return this.paginatorEvent.emit($event);
  }


  /**
   * LifeHook : à chaque changement de value de data on rafraichi les données du tableau
   * @param changes
   */
  ngOnChanges(changes: any) {
    if (changes.data) {
      this.dataSource = new MatTableDataSource(changes.data.currentValue);
    }
    if (changes.loading) {
      if (changes.data) {
        this.dataSource = new MatTableDataSource(changes.data.currentValue);
      }
      if (changes.loading) {
        this.loading = changes.loading.currentValue;
      }
    }

  }

  /**
   * Permet d'ordonner les colones.
   * @param sort
   */
  sortData(sort: Sort) {
    const data = this.data.slice();
    if (!sort.active || sort.direction === '') {
      this.dataSource = new MatTableDataSource(data);
      return;
    }
    this.dataSource = new MatTableDataSource(data.sort(
      (a, b) => {
        const isAsc = sort.direction === 'asc';
        if (sort.active in a) {
          return DatatableHelper.compare(a[sort.active], b[sort.active], isAsc);
        }
        return 0;
      }));
  }

  selectFile(file: PieceJointeData, index: number) {
    return this.onSelectFile.emit({
      id: file.id,
      index: index,
      path: file.path,
      filename: file.libelle
    });
  }


  delete(pieceJointeId: number) {

    let pieceJointe = this.data.find(x => {
      return x.id == pieceJointeId;
    });
    let index = this.data.indexOf(pieceJointe);


    Swal.fire({
      title: this.translate.instant('Attention !'),
      text: this.translate.instant("Êtes-vous sûr de vouloir supprimer le fichier {{ value }} ?", { value: this.dataSource.data[index].libelle}),
      type: 'warning',
      showCancelButton: true,
      cancelButtonText: this.translate.instant('NON'),
      confirmButtonText: this.translate.instant('OUI'),

    }).then((result) => {
      if (result.value) {
        //affiche le chargement
        this.dataSource.data[index].pending = true;

        //service pour supprimer le fichier
        this.filesSub = this.pieceJointeService.delete(pieceJointeId).subscribe(
          () => {
            this.toastr.info(
              this.translate.instant("Le fichier a été supprimé"),
            );
            this.dataSource.data[index].pending = false;
            this.dataSource.data.splice(index, 1);
            this.dataSource = new MatTableDataSource(this.dataSource.data);
          },
          () => {
            this.dataSource.data[index].pending = false;
            this.toastr.error(
              this.translate.instant("Le fichier n'a pas pu être supprimé"),
            );
          },
        );
      }

    });
  }

  @HostListener('window:resize', ['$event'])
  onResizeTable() {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth < 460) {
      this.displayedColumns = [
        'filename',
        'options'
      ];
    } else if (this.innerWidth > 460 && this.innerWidth < 550) {
      this.displayedColumns = [
        'filename',
        'created_at',
        'options'
      ]
    } else if (this.innerWidth > 550 && this.innerWidth < 900) {
      this.displayedColumns = [
        'id',
        'filename',
        'created_at',
        'options'
      ]
    } else if (this.innerWidth > 900) {
      this.displayedColumns = [
        'id',
        'filename',
        'created_at',
        'type_piece_jointe',
        'options'
      ];
    }
  }
}
