import {Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output} from '@angular/core';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {DatatableHelper} from '../../../../shared/helpers/datatable.helper';
import {FileService} from '../../../../shared/services/file.service';
import {PieceJointeService} from '../../../../shared/services/api/piece-jointe.service';
import {PieceJointe} from '../../../../shared/models/api/piece-jointe.model';
import {tableAnimation} from '../../../../animations';
import {Conversation} from '../../../../shared/models/api/conversation.model';
import Swal from "sweetalert2";
import {ToastrService} from 'ngx-toastr';
import {Subscription} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {PieceJointeData} from "../../../../shared/models/api/piece-jointe-data.model";

@Component({
  selector: 'app-table-file',
  templateUrl: './table-file.component.html',
  styleUrls: ['./table-file.component.scss'],
  animations: [tableAnimation],
})
export class TableFileComponent implements OnInit, OnChanges {

  expandedElement: PieceJointeData | null;

  @Input() data: PieceJointeData[];
  @Input() loading = false;
  @Input() showAddFileBtn: boolean = false;
  @Input() showDeleteFileBtn: boolean = false;
  @Input() showViewFileBtn: boolean = false;
  @Input() showDownloadFileBtn: boolean = false;
  @Input() showInfosBtn: boolean = false;
  @Input() showTitle: boolean = false;
  @Input() hiddenColumns: string[] = [];
  @Input() displayedColumns: string[] = [
    'format',
    'libelle',
    'created_at',
    'type_piece_jointe',
    'options',
  ];

  @Output() onAdd: EventEmitter<Conversation> = new EventEmitter();
  @Output() onSelectFile: EventEmitter<{ id: number, index: number, path: string, filename: string }> = new EventEmitter<{ id: number, index: number, path: string, filename: string }>();

  public innerWidth: number;
  public dataSource: MatTableDataSource<PieceJointeData>;
  public filesSub: Subscription;


  constructor(private translate: TranslateService,
              private pieceJointeService: PieceJointeService,
              private toastr: ToastrService) {
  }

  ngOnInit() {
    this.dataSource = new MatTableDataSource(this.data);
    this.onResizeTable()
  }

  /**
   * LifeHook : à chaque changement de value de data on rafraichi les données du tableau
   * @param changes
   */
  ngOnChanges(changes: any) {
    if (changes.data) {
      this.dataSource = new MatTableDataSource(changes.data.currentValue);
    }
    if (changes.loading) {
      if (changes.data) {
        this.dataSource = new MatTableDataSource(changes.data.currentValue);
      }
      if (changes.loading) {
        this.loading = changes.loading.currentValue;
      }
    }

  }


  /**
   * Permet d'ordonner les colones.
   */
  sortData(sort: Sort) {
    const data = this.data.slice();
    if (!sort.active || sort.direction === '') {
      this.dataSource = new MatTableDataSource(data);
      return;
    }
    this.dataSource = new MatTableDataSource(data.sort(
      (a, b) => {
        const isAsc = sort.direction === 'asc';
        if (sort.active in a) {
          return DatatableHelper.compare(a[sort.active], b[sort.active], isAsc);
        }
        return 0;
      }));
  }

  download(fileId) {
    this.pieceJointeService.downloadFile(fileId).subscribe(res => {
        FileService.downloadDataToFile(res);
      },
      FileService.handleDownloadError
    );
  }

  selectFile(file: PieceJointeData, index: number) {
    return this.onSelectFile.emit({
      id: file.id,
      index: index,
      path: file.path,
      filename: file.libelle
    });
  }

  delete(pieceJointeId: number) {
    let pieceJointe = this.data.find(x => {
      return x.id == pieceJointeId
    });
    let index = this.data.indexOf(pieceJointe);

    Swal.fire({
      title: this.translate.instant('Attention !'),
      text: this.translate.instant('Êtes-vous sûr de vouloir supprimer le fichier {{ value }} ?', { value: this.dataSource.data[index].libelle}),
      type: 'warning',
      showCancelButton: true,
      cancelButtonText: this.translate.instant('Annuler'),
      confirmButtonText: this.translate.instant('OUI'),
    }).then((result) => {
      if (result.value) {
        //affiche le chargement
        this.dataSource.data[index].pending = true;

        //service pour supprimer le fichier
        this.filesSub = this.pieceJointeService.delete(pieceJointeId).subscribe(
          () => {
            this.toastr.info(
              this.translate.instant("Le fichier a été supprimé"),
            );
            // this.toastr.info('le fichier à été supprimé');
            this.dataSource.data[index].pending = false;
            this.dataSource.data.splice(index, 1);
            this.dataSource = new MatTableDataSource(this.dataSource.data);
          },
          () => {
            this.dataSource.data[index].pending = false;
            this.toastr.error(
              this.translate.instant("Le fichier n'a pas pu être supprimé"),
            );
            // this.toastr.error('le fichier n\'à pas pu etre supprimé');
          },
        );
      }

    });
  }

  @HostListener('window:resize', ['$event'])
  onResizeTable() {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth < 600) {
      this.displayedColumns = [
        'libelle',
        'options',
      ];
    } else if (this.innerWidth > 650 && this.innerWidth <= 700) {
      this.displayedColumns = [
        'libelle',
        'created_at',
        'options',
      ];
    } else if (this.innerWidth > 700 && this.innerWidth < 1200 ) {
      this.displayedColumns = [
        'libelle',
        'created_at',
        'type_piece_jointe',
        'options',
      ];
    } else if (this.innerWidth >= 1200 && this.innerWidth <= 1300){
      this.displayedColumns = [
        'libelle',
        'created_at',
        'options',
      ]
    } else if (this.innerWidth > 1300) {
      this.displayedColumns = [
        'libelle',
        'created_at',
        'type_piece_jointe',
        'options',
      ];
    }
  }
}
