import {Injectable} from "@angular/core";
import {FormControl, FormGroup} from "@angular/forms";

@Injectable()
export class FormfilterHelper {

  static hydrateForm(configForm: { input, show?, conf?: { value, disabled } }[], form: FormGroup) {
    configForm.forEach(function (value) {
      if (form.controls.hasOwnProperty(value.input) && (value.conf)) {
        form.controls[value.input] = new FormControl(value.conf.value);
      }
    })
  }

  static extractHiddenInput(displayInput) {
    let inputHidden = [];
    displayInput.forEach((inputConf) => {
      if (inputConf.show === false) {
        inputHidden[inputConf.input] = inputConf.input
        //inputHidden.push(inputConf.input);
      }
    });
    return inputHidden
  }
}
