import {Injectable} from '@angular/core';
import {Litige} from '../../models/api/litige.model';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {map} from 'rxjs/operators';
import {HttpUtil} from '../../utils/http.util';
import {Contestation} from '../../models/api/contestation.model';
import {Conversation} from '../../models/api/conversation.model';
import {PieceJointe} from '../../models/api/piece-jointe.model';
import {Article} from '../../models/api/article.model';
import {Historique} from '../../models/api/historique.model';
import {FilterMultiCritereModel} from "../../models/filters/filterMultiCritere.model";
import {ConversationData} from "../../models/api/conversation-data.model";
import {LitigeData} from "../../models/api/litige-data.model";
import {ContestationData} from "../../models/api/contestation-data.model";
import {PieceJointeGed} from "../../models/api/ged/piece-jointe-ged.model";

@Injectable({
  providedIn: 'root'
})
export class LitigeService {

  urlRessource = environment.url_api_espace_client + '/litiges';

  constructor(private  http: HttpClient) {}

  /**
   * @param filter
   */
  getLitiges(filter: any = null): Observable<Litige> {
    return this.http
      .get<Litige>(`${this.urlRessource}`, {params: HttpUtil.convertObjectToHttpParams(filter)})
      .pipe(map((litige) => {
        return new Litige().deserialize(litige);
      }));
  }

  /**
   * @param id
   */
  getLitige(id: number): Observable<LitigeData> {
    return this.http
      .get<LitigeData>(`${this.urlRessource}/${id}`)
      .pipe(map((litige) => {
        return new LitigeData().deserialize(litige);
      }));
  }

  /**
   * @param filter
   * @param litigeId
   */
  getContestations(filter: any = null, litigeId: number): Observable<Contestation> {
    return this.http
      .get<Contestation>(`${this.urlRessource}/${litigeId}/contestations`,{params: HttpUtil.convertObjectToHttpParams(filter)})
      .pipe(map((contestation) => {
        return new Contestation().deserialize(contestation);
      }));
  }

  /**
   * @param litigeId
   */
  getArticles(litigeId: number): Observable<Article[]> {
    return this.http
      .get<Article[]>(`${this.urlRessource}/${litigeId}/articles`)
      .pipe(map((articles) => articles.map((article) => {
        return new Article().deserialize(article);
      })));
  }

  /**
   * @param filter
   * @param litigeId
   */
  getConversations(filter: any = null, litigeId: number): Observable<Conversation> {
    return this.http
      .get<Conversation>(`${this.urlRessource}/${litigeId}/conversations`, {params: HttpUtil.convertObjectToHttpParams(filter)})
      .pipe(map((conversation) => {
        return new Conversation().deserialize(conversation);
      }));
  }

  /**
   * @param filter
   * @param litigeId
   */
  getHistoriques(filter: any = null, litigeId: number): Observable<Historique> {
    return this.http
      .get<Historique[]>(`${this.urlRessource}/${litigeId}/evenements`, {params: HttpUtil.convertObjectToHttpParams(filter)})
      .pipe(map((historique) => {
        return new Historique().deserialize(historique);
      }));
  }

  /**
   * @param id
   */
  getPieceJointes(id: number): Observable<PieceJointe> {
    return this.http
      .get<PieceJointe[]>(`${this.urlRessource}/${id}/piece-jointes`)
      .pipe(map((pieceJointe) => {
        return new PieceJointe().deserialize(pieceJointe);
      }));
  }

  postConversation(id: number, conv: ConversationData): Observable<ConversationData> {
    return this.http
      .post<ConversationData>(`${this.urlRessource}/${id}/nouvelle-conversation`, conv)
      .pipe(map((conversation) => {
        return new ConversationData().deserialize(conversation);
      }));
  }

  addContestation(litigeId: number, contestationData: ContestationData): Observable<ContestationData> {
    return this.http.post<ContestationData>(`${this.urlRessource}/${litigeId}/contestation`, contestationData)
      .pipe(map((contestation) => {
        return new ContestationData().deserialize(contestation);
      }));
  }

}
