import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FileUploader} from 'ng2-file-upload';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-file-uploader-grc',
  templateUrl: './file-uploader-grc.component.html',
  styleUrls: ['./file-uploader-grc.component.scss']
})

export class FileUploaderGrcComponent implements OnInit {

  @Input() defaultExtension: string[];
  @Input() uploader : FileUploader;
  @Input() modal: NgbModal;
  @Input() showReturnButton: boolean = true;
  @Input() showHeader: boolean = false;
  @Input() idGrc: string;
  @Input() numeroAvis: string;
  @Input() alert: string = null;

  @Output() uploaded = new EventEmitter<boolean>();
  @Output() canceled: EventEmitter<void> = new EventEmitter();

  public hasBaseDropZoneOver: boolean = false;
  public URL: string = null;
  public disabledButton: Boolean = false;
  public loading: boolean = false;
  public innerWidth = window.innerWidth;
  public fileName: string = "";

  constructor() {
  }

  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  onChange(event) {
    this.fileName = event.target.files[0].name;
  }

  ngOnInit() {

  }

}

