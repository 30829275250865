<div *ngIf="loading" style="height: 500px" class="row col-12">
  <span class="spinner"></span>
</div>
<div *ngIf="!loading">
  <div class="modal-header">
    <h4 class="modal-title  w-100 text-center">{{'Détails de la demande GRC' | translate }}</h4>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <div class="widget-chat widget-chat-rounded text-left">
          <div class="" ngx-auto-scroll style="min-height: 190px;">
            <div *ngIf="grcData.conversation_grc.length === 0"
                 class="text-center py-2">{{'Aucun message' | translate }}</div>
            <div *ngIf="grcData.conversation_grc.length > 0 " style="min-height: 190px;position: relative">
              <div *ngFor="let messageGrc of grcData.conversation_grc"
                   class="widget-chat-body"
                   data-scrollbar="true"
                   data-height="235px">
                <!-- Chatbox côté gauche si le user actuel correspond au client -->
                <div *ngIf="messageGrc.context_base === 'CLIENT' || messageGrc.context_base === 'GRCtoCLIENT'"
                     class="widget-chat-item with-media left">
                  <div class="widget-chat-info">
                    <div class="widget-chat-info-container" [ngClass]="{
                    'bg-color-client':messageGrc.context_base === 'CLIENT',
                    'bg-color-grc':messageGrc.context_base === 'GRCtoCLIENT'}">
                      <div class="float-right" *ngIf="messageGrc.context_base === 'GRCtoCLIENT'">
                        <img src="../../../../../assets/img/logo2.svg" alt="> Agediss" class="logo-img"
                             style="width: 70px">
                      </div>
                      <div
                        class="widget-chat-name" [ngClass]="{
                        'text-color':messageGrc.context_base === 'CLIENT',
                        'text-agediss':messageGrc.context_base === 'GRCtoCLIENT'}">
                        {{messageGrc.login | titlecase}}
                      </div>
                      <div class="widget-chat-title">{{messageGrc.libelle_event}}</div>

                      <div class="widget-chat-message">{{messageGrc.message}}</div>

                      <div class="dropdown py-2" style="width: 180px" *ngIf="messageGrc.doc != null" ngbDropdown>
                        <button class="btn btn-radius btn-agediss btn-block text-truncate shadow" ngbDropdownToggle>
                          {{'Liste des documents' | translate }}
                        </button>
                        <div class="dropdown-menu dropdown-menu-left ml-2 col-12" ngbDropdownMenu>
                          <div *ngFor="let doc of messageGrc.doc; let i = index">
                            <a class="dropdown-item font-weight-bold cursor-pointer"
                               (click)="openModalShowDocuments(modal_visualisation_file, this.doc)"><i
                              class="fa fa-file icon-agediss"></i> {{'Document n°' | translate }}{{i + 1}}</a>
                          </div>
                        </div>
                      </div>
                      <br>
                      <div class="widget-chat-time" [ngClass]="{
                       'text-white':messageGrc.context_base === 'CLIENT',
                       'text-grey':messageGrc.context_base === 'GRCtoCLIENT'}">
                        {{messageGrc.date_message}}
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Chatbox côté droit si le user du message correspond au Service GRC -->
                <div *ngIf="messageGrc.context_base === 'GRC_CLIENT' || messageGrc.context_base === 'CLIENTtoGRC'"
                     class="widget-chat-item with-media right">
                  <div class="widget-chat-info">
                    <div class="widget-chat-info-container"
                         [ngClass]="{
                     'bg-color-client':messageGrc.context_base === 'CLIENTtoGRC',
                     'bg-color-grc':messageGrc.context_base === 'GRC_CLIENT'}">
                      <div class="float-left" *ngIf="messageGrc.context_base === 'GRC_CLIENT'">
                        <img src="../../../../../assets/img/logo2.svg" alt="> Agediss" class="logo-img"
                             style="width: 70px">
                      </div>
                      <div
                        class="widget-chat-name" [ngClass]="{
                    'text-white':messageGrc.context_base === 'CLIENTtoGRC',
                    'text-agediss':messageGrc.context_base === 'GRC_CLIENT'}">
                        {{messageGrc.login | titlecase}}
                      </div>
                      <div class="widget-chat-title">{{messageGrc.libelle_event}}</div>

                      <div class="widget-chat-message">{{messageGrc.message}}</div>

                      <div class="dropdown py-2" style="width: 180px" *ngIf="messageGrc.doc != null" ngbDropdown>
                        <button class="btn btn-radius btn-agediss btn-block text-truncate shadow" ngbDropdownToggle>
                          {{'Liste des documents' | translate }}
                        </button>
                        <div class="dropdown-menu dropdown-menu-left ml-2 col-12" ngbDropdownMenu>
                          <div *ngFor="let doc of messageGrc.doc; let i = index">
                            <a class="dropdown-item font-weight-bold cursor-pointer"
                               (click)="openModalShowDocuments(modal_visualisation_file, this.doc)"><i
                              class="fa fa-file icon-agediss"></i> {{'Document n°' | translate }}{{i + 1}}</a>
                          </div>
                        </div>
                      </div>
                      <br>
                      <div class="widget-chat-time text-left" [ngClass]="{
                  'text-white':messageGrc.context_base === 'CLIENTtoGRC',
                  'text-grey':messageGrc.context_base === 'GRC_CLIENT'}">
                        {{messageGrc.date_message}}</div>
                    </div>
                  </div>
                  <!-- end widget-chat-item -->
                </div>
              </div>
              <div
                *ngIf="!loadingSubmitBtn && (grcData.sacodm == 'RPD' || grcData.sacodm == 'RPJ' || grcData.sacodm == 'RPL' || grcData.sacodm == 'RPM' || grcData.sacodm == 'RPO' || grcData.sacodm == 'RPP' || grcData.sacodm == 'RPV' || grcData.sacodm == 'RPX' ||grcData.sacodm == 'RPZ' || grcData.sacodm == 'RP8' || grcData.sacodm == 'DMZ' || grcData.sacodm == 'DMY' || grcData.sacodm == 'DMW')"
                class="widget-input widget-input-rounded">
                <div class="pt-3">
                  <h4 class="w-100">{{'Réponse' | translate }}</h4>
                </div>
                <form [formGroup]="reponseDemandeGrcForm">
                  <div class="row m-0 pt-2">
                    <div class="col-12 col-lg-4 px-0 px-lg-3">
                      <button class="btn btn-radius btn-agediss btn-block text-truncate shadow" (click)="toggle()">
                        {{'Ajouter une pièce jointe' | translate }}
                      </button>
                    </div>
                  </div>
                  <div class="row m-0 pt-2" *ngIf="showFileUploader">
                    <div class="col-12 px-3">
                      <app-file-uploader-grc [showHeader]="true" [uploader]="uploader"
                                             [idGrc]="grcData.numero_avis" [defaultExtension]="defaultExtension"></app-file-uploader-grc>                      <div class="dropdown-divider mt-4"></div>
                    </div>
                  </div>
                  <div class="row m-0 pt-2">
                    <div class="col-lg-12 col-md-12 p-0">
                      <label class="col-lg-12 col-md-12 col-sm-12">{{'Votre message' | translate }} :
                        <textarea rows="6" class="form-control form-control-filter mt-2" formControlName="message"
                                  [ngClass]="{'is-invalid': reponseDemandeGrcForm.get('message').invalid  && (reponseDemandeGrcForm.get('message').dirty || formSubmit)}">
                    </textarea>
                        <div class="text-center invalid-tooltip ml-3 mt-2"
                             *ngIf="reponseDemandeGrcForm.get('message').invalid  && (reponseDemandeGrcForm.get('message').dirty || formSubmit)">
                          {{'Veuillez saisir un message !' | translate}}
                        </div>
                      </label>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer p-5" *ngIf="!loadingSubmitBtn">
    <button
      *ngIf="(grcData.sacodm == 'RPD' || grcData.sacodm == 'RPJ' || grcData.sacodm == 'RPL' || grcData.sacodm == 'RPM' || grcData.sacodm == 'RPO' || grcData.sacodm == 'RPP' || grcData.sacodm == 'RPV' || grcData.sacodm == 'RPX' ||grcData.sacodm == 'RPZ' || grcData.sacodm == 'RP8' || grcData.sacodm == 'DMZ' || grcData.sacodm == 'DMY' || grcData.sacodm == 'DMW')"
      class="btn btn-agediss text-white" [ladda]="loadingSubmitBtn" data-style="zoom-in"
      (click)="submitForm()">{{ 'Envoyer' | translate}}
    </button>
    <button *ngIf="(this.grcData.code_statut == 'CLOS' || this.grcData.code_statut == 'TRAITER')" type="button"
            class="btn btn-green text-white" [ladda]="loadingArchiveBtn" data-style="zoom-in"
            (click)="archiveMessage()">
      {{ 'Archiver' | translate}}
    </button>
    <button type="button" class="btn bg-black-lighter text-white"
            (click)="cancel()">{{ 'Fermer' | translate}}</button>
  </div>

</div>


<ng-template #modal_visualisation_file let-modal>
  <app-file-preview-ged [imageGed]="pieceJointe.images" [documents]="true"
                        (canceled)="modal.close($event)"></app-file-preview-ged>
</ng-template>
