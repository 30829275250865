import {Injectable} from "@angular/core";
import {environment} from "../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {Pays} from "../../models/api/pays.model";

@Injectable({
  providedIn: 'root'
})
export class PaysService {

  urlRessource = environment.url_api_espace_client + '/pays';

  constructor(private  http: HttpClient) {}

  getPays(): Observable<Pays[]> {
    return this.http
      .get<Pays[]>(this.urlRessource)
      .pipe(map((pays) => pays.map((p) => {
        return new Pays().deserialize(p);
      })));
  }

}
