import {Deserializable} from '../interfaces/deserializable.interface';
import {LitigeData} from './litige-data.model';

export class FactureLitige implements Deserializable {

  public id?: number;
  public montant?: number;
  public litigeData?: LitigeData;

  deserialize(input: any): this {
    return Object.assign(this, input);
  }
}
