<div *ngIf="view.hasDroit('COMMANDE_MENU_TABLEAU-DE-BORD')">
  <div class="row m-0 padding">
    <h1 class="page-header text-center m-auto padding-h1">{{'Mon tableau de bord' | translate }}</h1>
  </div>

  <div class="row m-0 padding-table pb-0">
    <div
      class="offset-0 col-12 offset-lg-2 col-lg-10 offset-xl-6 col-xl-6 panel-width my-0 padding-filter-suivi-livraison alert-light fade show shadow"
      tourAnchor="panel.date.suivi.livraisons">
      <app-filter-tableau-de-bord (submitFilter)="onSubmit($event)"
                                  (onLoad)="onLoadFilter($event)"></app-filter-tableau-de-bord>
    </div>
  </div>

  <div class="ml-4" *ngIf="view.hasDroit('COMMANDE_MENU_ANOMALIES')">
    <a class="btn btn-anomalies" [routerLink]="['/','anomalies']" [ngClass]="{
              'btn-dark-grey' : !anomaliesCount,
              'btn-danger' : anomaliesCount !== null && anomaliesCount > 0,
              'glow' : anomaliesCount !== null && anomaliesCount > 0
           }">
      {{'Mes anomalies' | translate}} : {{anomaliesCount}}
      <i *ngIf="anomaliesCount === null" class='fas fa-spin fa-circle-notch'></i>
    </a>
  </div>

  <div class="padding" tourAnchor="stats.nps">
    <app-nps-stats [stats]="statsNps" [multi]="multiNps" [loadingCharts]="loadingChartsNps"
                   [loadingData]="loadingDataNps"></app-nps-stats>
  </div>

  <div class="padding" tourAnchor="stats.livraison">
    <app-livraisons-stats [stats]="statsLivraison" [multi]="multiLivraison" [loadingCharts]="loadingChartsLivraison"
                          [loadingData]="loadingDataLivraison"></app-livraisons-stats>
  </div>

  <div class="padding" tourAnchor="stats.grc" *ngIf="view.origine === 'tradiss'">
    <app-grc-stats [stats]="statsGrc" [multi]="multiGrc" [loadingCharts]="loadingChartsGrc"
                   [loadingData]="loadingDataGrcByStatut"></app-grc-stats>
  </div>

  <div class="padding" tourAnchor="stats.commande">
    <app-commande-pie-charts [single]="single" [showCharts]="showChartsLivraisonByStatut"
                             [loadingCharts]="loadingChartsLivraisonByStatut"></app-commande-pie-charts>
  </div>

  <div class="row m-0 padding-filter-suivi-livraison">
    <div class="col-12 m-0 alert alert-light fade show shadow">
      <app-filter-tableau-de-bord-suivi-de-livraison (submitFilter)="onSubmitSuiviLivraison($event)"
                                                     (onLoad)="onLoadFilterSuiviLivraison($event)"></app-filter-tableau-de-bord-suivi-de-livraison>
    </div>
  </div>

  <div class="row m-0 padding-table" tourAnchor="tableau.livraison-du-jour">
    <div class="col-12 p-0">
      <panel noButton="true" bodyClass="no-padding panel">
        <div class="row" header>
          <div class="col-12">
            <h5 class="p-t-5 text-agediss">
              {{'Livraisons du jour' | translate }}
            </h5>
          </div>
        </div>
        <div class="p-0">
          <app-table-suivi-de-livraison [data]="suiviLivraisonData" [showInfosBtn]="true" [pageSize]="5"
                                        [pageSizeOptions]="[5, 10, 20]"
                                        [loading]="loadingTableCommande"></app-table-suivi-de-livraison>
        </div>
      </panel>
    </div>
  </div>
</div>
