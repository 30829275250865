import {Deserializable} from '../interfaces/deserializable.interface';

export class TypeDemande implements Deserializable {

  public id?: string;
  public created_at?: Date;
  public updated_at?: Date;
  public code?: string;
  public libelle?: string;
  public contexte?: string;
  public SACODS?: string;
  public SACODM?: string;
  public CODMAJ?: string;

  deserialize(input: any): this {
    return Object.assign(this, input);
  }

}
