<table mat-table [dataSource]="dataSource" (matSortChange)="sortData($event)" multiTemplateDataRows matSort
       matSortActive="date_envoie" matSortDirection="desc"
       class="table mat-responsive table-hover table-striped-n3 table-bordered mat-elevation-z8 text-center">

  <ng-container matColumnDef="type">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Type' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="align-middle">
      {{ element?.type }}
    </td>
  </ng-container>
  <ng-container matColumnDef="destinataire">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Destinataire' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="align-middle">
      {{element?.destinataire  | uppercase }} </td>
  </ng-container>
  <ng-container matColumnDef="date_envoie">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Date' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="align-middle">
      {{element?.date_envoie}}</td>
  </ng-container>
  <ng-container matColumnDef="etat_envoie">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Etat' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="align-middle">
      {{element?.etat_envoie }} </td>
  </ng-container>
  <ng-container matColumnDef="message">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Message' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="with-img align-middle text-nowrap">
      <button *ngIf="showViewBtn"
              ngbTooltip="{{'Visualisation du message' | translate }}"
              placement="left"
              container="body"
              class="btn btn-rounded btn-sm btn-agediss mr-1"
              (click)="onSelectMessage(element)">
        <i class="fa fa-eye"></i>
      </button>
    </td>
  </ng-container>
  <ng-container matColumnDef="options">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element" class="with-img align-middle text-nowrap">
      <button *ngIf="showInfosBtn"
              ngbTooltip="{{'Informations complémentaires' | translate }}"
              placement="left"
              container="body"
              class="button btn btn-rounded-arrow btn-sm btn-agediss mr-1"
              (click)="expandedElement = expandedElement === element ? null : element">
        <i class="fas fa-angle-double-down"></i>
      </button>
    </td>
  </ng-container>

  <ng-container matColumnDef="noData">
    <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length" innerHTML="{{'Aucun message' | translate }}"></td>
  </ng-container>
  <ng-container matColumnDef="loading">
    <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length" innerHTML="{{'Chargement en cours' | translate }} <i class='fas fa-spin fa-circle-notch'></i>"></td>
  </ng-container>

  <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
  <ng-container matColumnDef="expandedDetail">
    <td class="table-td p-0" mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">

      <div class="table-detail"
           *ngIf="this.innerWidth < 680  || (this.innerWidth > 991 && this.innerWidth < 1050)"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description">
          <strong>{{'Date' | translate }} : </strong><span>{{element?.date_envoie}}</span>
        </div>
      </div>
      <div class="table-detail"
           *ngIf="this.innerWidth < 800  || (this.innerWidth > 991 && this.innerWidth < 1100)"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description">
          <strong>{{'Etat' | translate }} : </strong><span>{{element?.etat_envoie }}</span>
        </div>
      </div>
    </td>
  </ng-container>

  <tr mat-header-row style="height: 15px;" *matHeaderRowDef="displayedColumns;  sticky: true"></tr>
  <tr mat-row *matRowDef="let element; columns: displayedColumns;" [hidden]="loading"
      class="table-row overflow-scroll"
      [class.expanded-row]="expandedElement === element">
  </tr>
  <tr mat-footer-row *matFooterRowDef="['noData']" [hidden]="dataSource && dataSource.data.length > 0 || loading"></tr>
  <tr mat-footer-row *matFooterRowDef="['loading']" [hidden]="!loading"></tr>
  <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="table-detail-row"></tr>
</table>

<mat-paginator [length]="total" [pageIndex]="pageIndex" (page)="pageEvent = $event" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
               showFirstLastButtons></mat-paginator>
