import {Component, OnDestroy, OnInit} from '@angular/core';
import {FilterMultiCritereModel} from '../../shared/models/filters/filterMultiCritere.model';
import {Litige} from '../../shared/models/api/litige.model';
import {Subscription} from 'rxjs';
import {LitigeService} from '../../shared/services/api/litige.service';
import {TranslateService} from '@ngx-translate/core';
import {PageEvent} from "@angular/material/paginator";
import {PaginationService} from "../../shared/services/pagination.service";
import {LitigeData} from "../../shared/models/api/litige-data.model";
import {View} from "../../shared/models/view.model";
import {AuthenticationService} from "../../shared/services/authentication.service";
import {Router} from "@angular/router";


@Component({
  selector: 'app-litiges',
  templateUrl: './litiges.html',
  styleUrls: ['./litiges.scss']
})
export class LitigesPage implements OnInit, OnDestroy {

  public filter: FilterMultiCritereModel = new FilterMultiCritereModel();
  public view: View;

  //Tableau
  public pageSizeOption = [5, 10, 20];
  public litiges: Litige[];
  public litigesData: LitigeData[];
  public subscriptions: Subscription[] = [];

  // number
  public pageSize = 10;
  public nbLitige: number = 0;

  //loading
  public loadingTableLitige = false;
  public loadingPage = false;
  public loadingNbLitiges = false;

  constructor(private translate: TranslateService,
              private paginationSvc: PaginationService,
              private authSvc: AuthenticationService,
              private router: Router,
              private litigeSvc: LitigeService) {
    this.paginationSvc.defaultFilterPagination(this.filter, this.pageSize)
    this.authSvc.currentView.subscribe(x => this.view = x);
  }

  ngOnInit() {
    if (this.view.hasDroit('LITIGE_MENU_LITIGE')) {
      this.subscriptions.push(this.translate.onLangChange.subscribe(() => {
        this.loadDataLitiges()
      }))
    } else {
      this.router.navigate(['/', 'home']);
    }

  }

  onSubmit(filter = null) {
    //récupère les infos du filtre
    if (filter) {
      this.filter = filter;
    }
    this.paginationSvc.defaultFilterPagination(this.filter, this.pageSize)
    this.loadDataLitiges();
  }

  onLoadFilter(filter = null) {
    //récupère les infos du filtre
    if (filter) {
      this.filter = {...filter, ...this.filter};
    }
    this.loadDataLitiges();
  }

  /**
   * On récupère tous les litiges liès au compte client
   */
  loadDataLitiges() {
    this.loadingTableLitige = true;
    this.loadingNbLitiges = true;
    this.litigesData= []
    this.nbLitige = 0;
    this.subscriptions.push(this.litigeSvc.getLitiges(this.filter).subscribe((litige) => {
      this.litigesData= litige.data;
      this.nbLitige = litige.nb;
      this.loadingTableLitige = false;
      this.loadingNbLitiges = false;
      },
      (error) => {
        this.loadingTableLitige = false;
        this.loadingNbLitiges = false;
      }
    ));
  }

  onLoadDataByEvent(event: PageEvent) {
    this.filter.limit = event.pageSize
    this.filter.offset = event.pageIndex ? event.pageIndex * event.pageSize : 0
    this.loadDataLitiges();
  }

  ngOnDestroy(){
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
