import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, } from '@angular/router';

import {environment} from '../../../environments/environment';
import {AuthenticationService} from '../services/authentication.service';
import {urlWithProtocol} from '../utils/util';

@Injectable({providedIn: 'root'})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this.authenticationService.currentUserValue;
    const currentView = this.authenticationService.currentViewValue;
    if (currentUser && currentView) {
      // connecté retourne true
      return true;
    }

    // pas connecter redirige sur la page de login avec l'url de retour
    window.location.href = urlWithProtocol(environment.urlsso);
    return false;
  }
}
