import {Deserializable} from '../interfaces/deserializable.interface';
import {Application} from './application.model';

export class Droit implements Deserializable {

  public id: number;
  public code?: string;
  public libelle?: string;
  public global_context?: string;
  public context?: string;
  public description?: string | number;
  public application: Application;
  public created_at?: Date | string;
  public updated_at?: Date | string;
  public viewed?: Boolean;


  deserialize(input: any): this {
    Object.assign(this, input);
    this.application = (input.application) ? new Application().deserialize(input.application) : null;
    return this;
  }

}
