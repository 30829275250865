import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {Subscription} from 'rxjs';
import {Article} from '../../../../shared/models/api/article.model';
import {Prestation} from '../../../../shared/models/api/prestation.model';
import {Timeline} from '../../../../shared/models/api/timeline.model';
import {View} from '../../../../shared/models/view.model';
import {AuthenticationService} from '../../../../shared/services/authentication.service';
import {TranslateService} from "@ngx-translate/core";
import {CommandeService} from "../../../../shared/services/api/commande.service";
import {CommandeData} from "../../../../shared/models/api/commande-data.model";


@Component({
  selector: 'app-commande-content',
  styleUrls: ['./commande-content.scss'],
  templateUrl: './commande-content.html'
})

export class CommandeContent implements OnInit, OnDestroy {

  @Input() commande: CommandeData;

  public subscriptions: Subscription[] = [];
  public activModal: NgbModalRef;
  public selectArticle: number = null;
  public numeroAvis: number = null;
  public article: Article;

  // TABLEAUX
  public articles: Article[];
  public prestations: Prestation[];
  public timelines: Timeline[];
  public timeline: Timeline;

  // LOADER
  @Input() loadingPage = false;
  public loadingTimeline = false;
  public loadingTableArticle = false;
  public loadingTablePrestation = false;
  public noPrestation = false;
  public noTimeline = false;

  public innerWidth = window.innerWidth;
  public view: View;

  constructor(private translate: TranslateService,
              private commandeSvc: CommandeService,
              private authSvc: AuthenticationService,
              private modalSvc: NgbModal) {
  }

  ngOnInit() {
    if (!this.commande) {
      throw new Error('Le composent livraison-content attend un objet de type livraison.')
    }
    this.authSvc.currentView.subscribe(x => this.view = x);
    this.subscriptions.push(this.translate.onLangChange.subscribe(() => {
      this.loadDataTimeline();
      this.loadDataArticle();
      this.loadDataPrestation();
    }))
    this.loadDataTimeline();
    this.loadDataArticle();
    this.loadDataPrestation();
  }

  openModalShowArticle(modalName, article: Article) {
    this.article = article;
    this.activModal = this.modalSvc.open(modalName, {
      size: 'md', keyboard: true,
    });
  }

  /**
   * On affiche tous les documents en lien avec le litige
   * @param modalName
   * @param value
   */
  openModalSignatureLivraison(modalName, value: { id: string }) {
    this.activModal = this.modalSvc.open(modalName, {
      size: 'lg', keyboard: false
    });
  }

  /**
   * On affiche tous les documents en lien avec le litige
   * @param modalName
   * @param timeline
   */
  openModalGeolocalisation(modalName, timeline: Timeline) {
    this.timeline = timeline
    this.activModal = this.modalSvc.open(modalName, {
      size: 'lg', keyboard: false
    });
  }

  loadDataArticle() {
    this.loadingTableArticle = true;
    this.articles = [];
    this.subscriptions.push(this.commandeSvc.getArticles(this.commande.numero_avis).subscribe(articles => {
        this.articles = articles;
        this.loadingTableArticle = false;
      },
      (error) => {
        this.loadingTableArticle = false;
      }));
  }

  loadDataPrestation() {
    this.loadingTablePrestation = true;
    this.prestations = [];
    this.subscriptions.push(this.commandeSvc.getPrestations(this.commande.numero_avis).subscribe(prestations => {
        this.prestations = prestations;
        this.loadingTablePrestation = false;
      },
      (error) => {
        this.noPrestation = true;
        this.loadingTablePrestation = false;
      }));
  }

  loadDataTimeline() {
    this.loadingTimeline = true;
    this.timelines = [];
    this.subscriptions.push(this.commandeSvc.getTimeline(this.commande.numero_avis).subscribe(timelines => {
        this.timelines = timelines;
        this.loadingTimeline = false;
      },
      (error) => {
        this.noTimeline = true;
        this.loadingTimeline = false;
      }));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
