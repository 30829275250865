import {Injectable} from '@angular/core';
import {Client} from '../../../models/api/client.model';
import {Observable} from 'rxjs';
import {environment} from '../../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SousCompteSsoService {

  urlRessource = environment.url_api_espace_client + '/sso-sous-comptes';

  constructor(private http: HttpClient) {
  }

  getSousComptes(): Observable<Client[]> {
    return this.http
      .get<Client[]>(this.urlRessource)
      .pipe(map((clients) => clients.map((client) => {
        return new Client().deserialize(client);
      })));
  }

  getAllSousComptes(): Observable<Client[]> {
    return this.http
      .get<Client[]>(this.urlRessource + '/all')
      .pipe(map((clients) => clients.map((client) => {
        return new Client().deserialize(client);
      })));
  }
}
