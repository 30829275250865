<div *ngIf="view.hasDroit('LIBRAIRIE_MENU_KPI')">

  <div class="row m-0 padding">
    <h1 class="page-header m-auto text-center padding-h1">{{'Mes KPIs' | translate }}</h1>
  </div>

  <div class="row m-0 padding-table" *ngIf="view.hasDroit('LIBRAIRIE_KPI_CREATE')">
    <div class="col-12 p-0 col-lg-4 col-xl-2">
      <button class="form-control btn btn-green btn-block font-weight-bold shadow"
              (click)="openModalAddDocument(modal_add_document)">{{'Déposer un document' | translate }}
      </button>
    </div>
  </div>

  <div class="row m-0 padding-table">
    <div class="col-12 m-0 alert alert-light fade show shadow">
      <app-filter-rapports-kpi [view]="view" (submitFilter)="onSubmitFilter($event)" (loadFilter)="onLoadFilter($event)"></app-filter-rapports-kpi>
    </div>
  </div>

  <div class="row m-0 padding-table" tourAnchor="tableau.kpi">
    <div class="col-12 p-0">
      <panel noButton="true" bodyClass="no-padding panel">
        <div class="row" header><h5 class="p-t-5 text-agediss">{{'Mes KPIs' | translate }}</h5></div>
          <div>
          <app-table-ged [piecesJointes]="datas"
                         [showInfosBtn]="true"
                         [loading]="isLoadingTableKpi"
                         [isKpi]="true"
                         [showDownloadFileBtn]="view.hasDroit('LIBRAIRIE_KPI_DOWNLOAD')"
                         [showViewFileBtn]="view.hasDroit('LIBRAIRIE_KPI_SHOW')"
                         [showDeleteFileBtn]="view.hasDroit('LIBRAIRIE_KPI_DELETE')"
                         (onSelectFile)="openModalVisualisationFile(modal_visualisation_file,$event)"
                         [pageSize]="10" [pageSizeOptions]="[5, 10, 20]">
          </app-table-ged>
        </div>
      </panel>
    </div>
  </div>
</div>

<!--modal upload file-->
<ng-template #modal_add_document let-modal>
  <app-file-uploader [showHeader]="true" [isKpi]="true" (canceled)="modal.close()" (uploaded)="onUploaded()"></app-file-uploader>
</ng-template>
<!--fin de modal upload file-->

<!-- modal visualisation file -->
<ng-template #modal_visualisation_file let-modal>
  <app-file-preview-ged [imageGed]="data.images" [documents]="true"
                        (canceled)="modal.close($event)"></app-file-preview-ged>
</ng-template>
<!-- fin de modal visualisation file -->
