export function toInteger(value: any): number {
  return parseInt(`${value}`, 10);
}

export function toString(value: any): string {
  return (value !== undefined && value !== null) ? `${value}` : '';
}

export function getValueInRange(value: number, max: number, min = 0): number {
  return Math.max(Math.min(value, max), min);
}

export function isString(value: any): value is string {
  return typeof value === 'string';
}


export function isDate(value: any): value is Date {
  return toString.call(value) === '[object Date]';
}

export function isNumber(value: any): value is number {
  return !isNaN(toInteger(value));
}

export function isInteger(value: any): value is number {
  return typeof value === 'number' && isFinite(value) && Math.floor(value) === value;
}


export function isDefined(value: any): boolean {
  return value !== undefined && value !== null;
}

export function padNumber(value: number) {
  if (isNumber(value)) {
    return `0${value}`.slice(-2);
  } else {
    return '';
  }
}

export function regExpEscape(text) {
  return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

export function hasClassName(element: any, className: string): boolean {
  return element && element.className && element.className.split &&
    element.className.split(/\s+/).indexOf(className) >= 0;
}

if (typeof Element !== 'undefined' && !Element.prototype.closest) {
  // Polyfill for ie10+

  if (!Element.prototype.matches) {
    // IE uses the non-standard name: msMatchesSelector
    Element.prototype.matches = (Element.prototype as any).msMatchesSelector || Element.prototype.webkitMatchesSelector;
  }

  Element.prototype.closest = function (s: string) {
    let el = this;
    if (!document.documentElement.contains(el)) {
      return null;
    }
    do {
      if (el.matches(s)) {
        return el;
      }
      el = el.parentElement || el.parentNode;
    } while (el !== null && el.nodeType === 1);
    return null;
  };
}

export function closest(element: HTMLElement, selector): HTMLElement {
  if (!selector) {
    return null;
  }

  return element.closest(selector);
}


//Permetde a un component de capter les changements de son @input() si c'est une array qui est passé en paramètre
export function arrayPushAndUpdate(array, pushValue) {
  array = [...array, pushValue];
  return array;
}

export function arraySliceAndUpdate(array) {
  array = [...array];
  return array;
}


export function urlWithProtocol(url) {
  return document.location.protocol + '//' + url;
}

export function formatDateAgedissToSort(dateOne,dateTwo) {
  const dateOneFormat = dateOne.replace(/[#-]/g, '/');
  const dateTwoFormat2 = dateTwo.replace(/[#-]/g, '/');
  const nvValeur = dateOneFormat.split(' ');
  const nvValeur2 = dateTwoFormat2.split(' ');
  const nvValeur01 = nvValeur[0].split('/');
  const nvValeur02 = nvValeur2[0].split('/');
  const nvTab = nvValeur01[1] + '/' + nvValeur01[0] + '/' + nvValeur01[2] + ' ' + nvValeur[1];
  const nvTab2 = nvValeur02[1] + '/' + nvValeur02[0] + '/' + nvValeur02[2] + ' ' + nvValeur2[1];
  return [nvTab, nvTab2];

}

export function convertBytes(x){
  const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  let l = 0, n = parseInt(x, 10) || 0;

  while(n >= 1024 && ++l){
    n = n/1024;
  }

  return(n.toFixed(0) + ' ' + units[l]);
}
