import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {FieldParametersModel} from '../../../shared/models/filters/field-parameters.model';
import {FilterMultiCritereModel} from '../../../shared/models/filters/filterMultiCritere.model';
import {FormControl, FormGroup} from '@angular/forms';
import {Subscription} from 'rxjs';
import {FormfilterHelper} from '../../../shared/helpers/Formfilter.helper';
import {DatePickerMinDateComponent} from '../../shared/date-picker/date-picker-min-date/date-picker-min-date.component';
import {FilterService} from '../../../shared/services/filter.service';
import {PaysService} from "../../../shared/services/api/pays.service";
import {Pays} from "../../../shared/models/api/pays.model";
import {TranslateService} from "@ngx-translate/core";
import {ToastrService} from "ngx-toastr";
import {Client} from "../../../shared/models/api/client.model";
import {Statut} from "../../../shared/models/api/statut.model";
import {Activite} from "../../../shared/models/api/activite.model";
import {SousCompteSsoService} from "../../../shared/services/api/sso/sous-compte-sso.service";
import {StatutService} from "../../../shared/services/api/statut.service";
import {ActiviteService} from "../../../shared/services/api/activite.service";

@Component({
  selector: 'app-filter-tableau-de-bord-suivi-de-livraison',
  templateUrl: './filter-tableau-de-bord-suivi-de-livraison.component.html',
  styleUrls: ['./filter-tableau-de-bord-suivi-de-livraison.component.scss']
})
export class FilterTableauDeBordSuiviDeLivraisonComponent implements OnInit, OnDestroy {

  @Input() displayInput: FieldParametersModel[] = [];

  @Output() submitFilter = new EventEmitter<FilterMultiCritereModel>();
  @Output() onLoad = new EventEmitter<FilterMultiCritereModel>();

  public filterForm = new FormGroup({
    client: new FormControl(),
    statutsSuiviLivraison: new FormControl(),
    activites: new FormControl(),
    pays: new FormControl(),
    dateCommande: new FormControl(),
    createdAt: new FormControl(),
    search: new FormControl()
  });

  // Tableaux
  public hiddenInput = [];
  public clients: Client[];
  public statutsSuiviLivraison: Statut[];
  public activites: Activite[];
  public pays: Pays[];
  public subscriptions: Subscription[] = [];
  public date;
  public dataSource: any;

  // Loader
  public loadPays: boolean = false;
  public loadClient: boolean = false;
  public loadStatutsSuiviLivraison: boolean = false;
  public loadActivites: boolean = false;

  constructor(private translate: TranslateService,
              private clientSvc: SousCompteSsoService,
              private statutSvc: StatutService,
              private toastr: ToastrService,
              private activiteSvc: ActiviteService,
              private paysSvc: PaysService,
              private filterSvc: FilterService) {
  }

  ngOnInit() {
    this.subscriptions.push(this.translate.onLangChange.subscribe(() => {
      this.loadClient = true;
      this.loadStatutsSuiviLivraison = true;
      this.loadPays = true;
      this.loadActivites = true;
      this.onLoadSousComptes();
      this.onLoadStatuts();
      this.onLoadPays();
      this.onLoadActivites();
    }))
    this.onInitFilter();
  }

  onInitFilter() {
    this.loadClient = true;
    this.loadStatutsSuiviLivraison = true;
    this.loadPays = true;
    this.loadActivites = true;
    FormfilterHelper.hydrateForm(this.displayInput, this.filterForm);
    this.hiddenInput = FormfilterHelper.extractHiddenInput(this.displayInput);
    this.onLoadSousComptes();
    this.onLoadStatuts();
    this.onLoadPays();
    this.onLoadActivites();
    this.getValueLocalStorage();
    this.onLoad.emit(this.filterForm.getRawValue());
  }

  /**
   * Chargement des sous-comptes
   */
  onLoadSousComptes() {
    this.subscriptions.push(this.clientSvc.getSousComptes().subscribe(value => {
        this.clients = value;
        this.loadClient = false;
      },
      (error) => {
        this.toastr.error(
          this.translate.instant("La liste des sous-comptes n'est pas disponible", {application: this.translate.instant('Mes livraisons')}),
          this.translate.instant('Désolé'),
          {
            timeOut: 20000,
            progressBar: true
          }
        );
        this.loadClient = false;
      }
    ));
  }

  /**
   * Chargement des statuts
   */
  onLoadStatuts() {
    this.subscriptions.push(this.statutSvc.getStatutsAvis(StatutService.SUIVILIVRAISON).subscribe(value => {
        this.statutsSuiviLivraison = value;
        this.loadStatutsSuiviLivraison = false;
      },
      (error) => {
        this.toastr.error(
          this.translate.instant("La liste des statuts n'est pas disponible", { application: this.translate.instant('Mes livraisons')}),
          this.translate.instant('Désolé'),
          {
            timeOut: 20000,
            progressBar: true
          }
        );
        this.loadStatutsSuiviLivraison = false;
      }
    ));
  }

  /**
   * Chargement des pays
   */
  onLoadPays() {
    this.subscriptions.push(this.paysSvc.getPays().subscribe(
      value => {
        this.pays = value;
        this.loadPays = false;
      },
      (error) => {
        this.toastr.error(
          this.translate.instant("La liste des pays n'est pas disponible", {application: this.translate.instant('Mes livraisons')}),
          this.translate.instant('Désolé'),
          {
            timeOut: 20000,
            progressBar: true
          }
        );
        this.loadPays = false;
      }
    ));
  }

  /**
   * Chargement des activités
   */
  onLoadActivites() {
    this.subscriptions.push(this.activiteSvc.getActivites(ActiviteService.LIVRAISON).subscribe(value => {
        this.activites = value;
        this.loadActivites = false;
      },
      (error) => {
        this.toastr.error(
          this.translate.instant("La liste des activités n'est pas disponible", {application: this.translate.instant("Mes livraisons")}),
          this.translate.instant('Désolé'),
          {
            timeOut: 20000,
            progressBar: true
          }
        );
        this.loadActivites = false;
      }
    ));
  }

  /**
   * On récupère les valeurs du localstorage
   */
  getValueLocalStorage() {
      this.filterForm.get('search').setValue(localStorage.getItem('TableauDeBordSuiviLivraisonSearchFilter'));
      this.filterSvc.onCheckValueLocalStorage(this.filterForm, 'TableauDeBordSuiviLivraisonClientFilter', 'client');
      this.filterSvc.onCheckValueLocalStorage(this.filterForm, 'TableauDeBordSuiviLivraisonActiviteFilter', 'activites');
      this.filterSvc.onCheckValueLocalStorage(this.filterForm, 'TableauDeBordSuiviLivraisonPaysFilter', 'pays');
      this.filterSvc.onCheckValueLocalStorage(this.filterForm, 'TableauDeBordSuiviLivraisonStatutFilter', 'statutsSuiviLivraison');
  }

  onSubmit() {
    //récupère les infos du filtre
    this.filterSvc.onSaveInLocalstorage(this.filterForm, 'TableauDeBordSuiviLivraisonSearchFilter', 'search');
    this.filterSvc.onSaveInLocalstorage(this.filterForm, 'TableauDeBordSuiviLivraisonClientFilter', 'client');
    this.filterSvc.onSaveInLocalstorage(this.filterForm, 'TableauDeBordSuiviLivraisonActiviteFilter', 'activites');
    this.filterSvc.onSaveInLocalstorage(this.filterForm, 'TableauDeBordSuiviLivraisonPaysFilter', 'pays');
    this.filterSvc.onSaveInLocalstorage(this.filterForm, 'TableauDeBordSuiviLivraisonStatutFilter', 'statutsSuiviLivraison');
    this.submitFilter.emit(this.filterForm.getRawValue());
  }

  /**
   *  On efface tous les champs du filter
   */
  reset(): void {
    this.filterForm.reset();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
