<form [formGroup]="filterForm" (ngSubmit)="onSubmit()">
  <div class="row m-0 p-0">
    <div *ngIf="!hiddenInput['pays']" class="col-12 col-sm-8 px-0 pt-2">
      <div class="row">
        <div class="col-12">
          <label class="d-block d-sm-none">{{'Pays' | translate }}</label>
        </div>
      </div>
      <div class="col-12 p-0">
        <div class="input-group-prepend">
          <span class="input-group-text input-group-border-right d-none d-lg-flex align-items-center">
            {{'Pays' | translate }}
          </span>
          <ng-select class="ng-select filter"
                     [items]="pays"
                     [selectOnTab]="true"
                     [multiple]="true"
                     bindValue="code"
                     bindLabel="libelle"
                     [loading]="loadPays"
                     [clearable]="true"
                     labelForId="client"
                     placeholder="{{'Choisir' | translate }}"
                     formControlName="pays">
          </ng-select>
        </div>
      </div>
    </div>
    <div class="offset-3 col-6 offset-sm-0 col-sm-4 pt-2">
      <div class="input-group">
        <input class="form-control btn btn-radius btn-agediss font-weight-bold shadow" type="submit" value="{{'Filtrer' | translate}}">
        <div class="input-group-append pl-1">
          <button class="btn btn-radius btn-agediss btn-block shadow" type="button"
                  ngbTooltip="{{'Effacer les infos sélectionnées' | translate }}"
                  placement="top-right"
                  container="body"
                  (click)="reset()"><i class='fa fa-times'></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</form>
