<div *ngIf="loadingPage" style="height: 500px" class="row col-12">
  <span class="spinner"></span>
</div>
<div *ngIf="!loadingPage">
  <div *ngIf="pieceJointeData.path.startsWith('../var/')">
    <div id="objet" *ngIf="urlPreview && showImage">
      <!--Affichage des PDF-->
      <div class="embed-responsive  embed-responsive-1by1" *ngIf="currentType == 2">
        <object class="embed-responsive-item" [data]="urlPreview | safe">
        </object>
      </div>
      <!--Affichage des IMAGES-->
      <img class="img-fluid" *ngIf="currentType != 2" [src]="urlPreview | safe" style="cursor: pointer;"
           (click)="openLink(urlPreview)" alt="image">

    </div>
    <div *ngIf="!urlPreview || !showImage">
      <div class="preview width-full m-auto text-center rounded bg-grey-transparent-2">
        Aucune visualisation possible.<br>
        Veuillez télécharger le fichier<br>
        <button class="m-t-15 btn btn-rounded btn-sm btn-agediss" [ladda]="loadingDownloadBtn"
                (click)="download(pieceJointeData.id)">
          Télécharger
        </button>
      </div>
    </div>
  </div>
  <div *ngIf="!pieceJointeData.path.startsWith('../var/')">
    <div *ngFor="let doc of piecesJointesGed">
      <div *ngFor="let image of doc.images">
        <div class="embed-responsive embed-responsive-1by1" *ngIf="image.extension == 'pdf'">
          <object [data]="'data:application/pdf;base64,' +image.file_content | safe"></object>
        </div>
        <div class="picsum-img-wrapper">
          <img class="img-fluid"
               *ngIf="image.extension == 'jpg' || image.extension == 'tif' || image.extension == 'png' || image.extension == 'jpeg'"
               [src]="'data:image/png;base64,'+ image.file_content" style="cursor: pointer;" alt="">
          <img class="img-fluid" *ngIf="image.extension == 'gif'"
               [src]="'data:image/gif;base64,'+ image.file_content" style="cursor: pointer;" alt="">
        </div>
        <div *ngIf="image.extension == 'xlsx'">
          <div class="preview width-full m-auto text-center rounded bg-grey-transparent-2">
            <div>Aucune visualisation possible.</div>
            <div>Veuillez télécharger le fichier</div>
            <button class="m-t-15 btn btn-rounded btn-sm btn-agediss" [ladda]="loadingDownloadBtn"
                    (click)="downloadGed(doc.id, doc.type_image)">
              Télécharger
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
