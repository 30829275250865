import { Injectable } from '@angular/core';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {HttpUtil} from '../../utils/http.util';
import {map} from 'rxjs/operators';
import {Activite} from '../../models/api/activite.model';

@Injectable({
  providedIn: 'root'
})
export class ActiviteService {

  static CONTEXTE = ['LIVRAISON', 'RETOUR', 'EXTRACTION'];
  static LIVRAISON = 'LIVRAISON';
  static RETOUR = 'RETOUR';
  static EXTRACTION = 'EXTRACTION';


  urlRessource = environment.url_api_espace_client + '/activites';

  constructor(private http: HttpClient) {

  }

  /**
   * retourne les activités
   */
  getActivites(context: string): Observable<Activite[]> {
    if (ActiviteService.CONTEXTE.indexOf(context) === -1) {
      throw new Error("Le contexte " + context + " n'existe pas dans les activités ")
    }
    return this.http
      .get<Activite[]>(`${this.urlRessource}`, {params: HttpUtil.convertObjectToHttpParams({context: context})})
      .pipe(map((observe) => observe.map(function (observe) {
        return new Activite().deserialize(observe);
      })))
  }

}
