import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {WexlogGrcData} from "../../../../shared/models/api/wexlog/wexlog-grc-data.model";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {AuthenticationService} from "../../../../shared/services/authentication.service";
import {UserData} from "../../../../shared/models/user-data.model";
import {View} from "../../../../shared/models/view.model";
import {Subscription} from "rxjs";
import Swal from "sweetalert2";
import {TranslateService} from "@ngx-translate/core";
import {GrcWexlogService} from "../../../../shared/services/api/wexlog/grc-wexlog.service";
import {WexlogTypeMessageGrc} from "../../../../shared/models/api/wexlog/wexlog-type-message-grc.model";
import {Router} from "@angular/router";
import {environment} from "../../../../../environments/environment";
import {ToastrService} from "ngx-toastr";
import {WexlogTypeTextMessageGrc} from "../../../../shared/models/api/wexlog/wexlog-type-text-message-grc.model";
import {TypeTextMessageGrcWexlogService} from "../../../../shared/services/api/wexlog/type-text-message-grc-wexlog.service";
import {TypeMessageGrcWexlogService} from "../../../../shared/services/api/wexlog/type-message-grc-wexlog.service";
import {WexlogConversationGrc} from "../../../../shared/models/api/wexlog/wexlog-conversation-grc.model";
import {PieceJointeGed} from "../../../../shared/models/api/ged/piece-jointe-ged.model";
import {NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-visualisation-demande-grc-wexlog',
  templateUrl: './visualisation-demande-grc-wexlog.component.html',
  styleUrls: ['./visualisation-demande-grc-wexlog.component.scss']
})
export class VisualisationDemandeGrcWexlogComponent implements OnInit {

  @Input() loading: Boolean = false;
  @Input() wexlogGrcData: WexlogGrcData;

  @Output() canceled: EventEmitter<void> = new EventEmitter();
  @Output() submitted: EventEmitter<WexlogGrcData> = new EventEmitter<WexlogGrcData>();

  public currentUser: UserData;
  public currentView: View;
  public view: View;
  public typeTextMessage: WexlogTypeTextMessageGrc;
  public pieceJointe: PieceJointeGed = null;

  //string
  public commentaire: string = null;
  public placeholder: string = this.translate.instant("Sélectionnez d'abord un type de message");
  public statut: string;

  //number
  public maxNumberOfCharactersMessage = 500;
  public numberOfCharactersMessage = 500;

  //tableau
  public typeMessages: WexlogTypeMessageGrc[];
  public subscriptions: Subscription[] = [];
  public typeTextMessages: WexlogTypeTextMessageGrc[];

  //loading
  public formSubmit: Boolean = false;
  public formUpdate: Boolean = false;
  public loadingSubmitBtn: Boolean = false;
  public showDocuments: Boolean = false;
  public loadingTypeMessage: Boolean = false;
  public loadingTypeTextMessage: Boolean = false;
  public disabledButton: Boolean = false;
  public loadingUpdateResolvedStatutBtn: Boolean = false;
  public loadingUpdateAssignedStatutBtn: Boolean = false;
  public loadingUpdateClosedStatutBtn: Boolean = false;
  public isReadOnly: Boolean = true;

  //form
  public messageDemandeGrcForm: FormGroup;
  public updateStatutDemandeGrcForm: FormGroup;

  public typeMessage = 'ANSWER';

  constructor(private authSvc: AuthenticationService,
              private grcWexlogSvc: GrcWexlogService,
              private router: Router,
              private toastr: ToastrService,
              private modalSvc: NgbModal,
              private typeMessageGrcWexlogSvc: TypeMessageGrcWexlogService,
              private typeTextMessageGrcWexlogSvc: TypeTextMessageGrcWexlogService,
              private translate: TranslateService) {
  }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.loadDataDemandeGrcById(this.wexlogGrcData.id)
    this.authSvc.currentUser.subscribe(x => this.currentUser = x);
    this.authSvc.currentView.subscribe(x => this.view = x);
    if(this.wexlogGrcData.libelle_statut === 'ASSIGNED') {
      this.onloadTypeTextMessages(this.typeMessage)
    }
    this.messageDemandeGrcForm = new FormGroup({
      client: new FormControl(this.wexlogGrcData.numero_client),
      username: new FormControl(this.currentUser.getFullname(), Validators.required),
      typeMessage: new FormControl(this.typeMessage, Validators.required),
      typeTextMessage: new FormControl(null, Validators.required),
      message: new FormControl('', Validators.required),
    });
  }

  initFormStatut(statut) {
    this.updateStatutDemandeGrcForm = new FormGroup({
      client: new FormControl(this.wexlogGrcData.numero_client),
      username: new FormControl(this.currentUser.getFullname()),
      statut: new FormControl(statut, Validators.required),
    });
  }

  /**
   * On active ou désactive l'input sélectionné en paramètre
   * @param input
   * @param boolean
   */
  disableEnableInput(input: string, boolean: boolean) {
    let formControl = this.messageDemandeGrcForm.get(input);
    if (boolean) {
      formControl.reset({value: formControl.value, disabled: false});
    } else {
      formControl.reset({value: null, disabled: true});
    }
  }

  onChangeTextMessage(event: WexlogConversationGrc) {
    if (this.messageDemandeGrcForm.get('typeTextMessage').value) {
      this.isReadOnly = false
      this.messageDemandeGrcForm.get('message').setValue(event.commentaire);
      this.numberOfCharactersMessage = this.maxNumberOfCharactersMessage - event.commentaire.length
    } else {
      this.isReadOnly = true
      this.messageDemandeGrcForm.get('message').setValue(null);
      this.numberOfCharactersMessage = this.maxNumberOfCharactersMessage;
    }
  }

  onloadTypeTextMessages(typeMessageId, typeTextMessageId = null) {
    this.loadingTypeTextMessage = true;
    this.subscriptions.push(this.typeTextMessageGrcWexlogSvc.getTypeTextMessagesGrcClient(typeMessageId, typeTextMessageId).subscribe(value => {
      this.typeTextMessages = value;
      this.placeholder = this.translate.instant('Choisir');
      this.loadingTypeTextMessage = false;
      },
      (error) => {
        this.toastr.error(
          this.translate.instant("La liste des types de texte n'est pas disponible", { application: this.translate.instant('GRC')}),
          this.translate.instant('Désolé'),
          {
            timeOut: 20000,
            progressBar: true
          }
        );
        this.loadingTypeTextMessage = false;
      }
    ));
  }

  refreshConversation() {
    this.initForm();
    this.loadDataDemandeGrcById(this.wexlogGrcData.id);
    if(this.wexlogGrcData.libelle_statut === 'ASSIGNED') {
      this.onloadTypeTextMessages(this.typeMessage)
    }
    if(this.wexlogGrcData.libelle_statut === 'RESOLVED') {
      this.onloadTypeTextMessages(this.typeMessage)
    }
    this.numberOfCharactersMessage = this.maxNumberOfCharactersMessage;
  }

  /**
   * On affiche les informations de la commande liés aux demandes Grc
   * @param id
   */
  loadDataDemandeGrcById(id) {
    this.loading = true;
    this.subscriptions.push(this.grcWexlogSvc.getGrcById(id).subscribe(
      (grc) => {
        this.wexlogGrcData = grc;
        this.loading = false;
      },
      () => {
        this.swalAlert(this.translate.instant("Êtes-vous sûr de vouloir archiver la conversation ?"))
      }));
  }

  onKeyUpInfos(maxNumberOfCharacters, event: any): void {
    this.numberOfCharactersMessage = (maxNumberOfCharacters - event.target.value.length);
    if (this.numberOfCharactersMessage < 0) {
      event.target.value = event.target.value.slice(0, maxNumberOfCharacters);
      this.numberOfCharactersMessage = 0;
    }
  }

  onSubmit() {
    this.formSubmit = true;
    if (this.messageDemandeGrcForm.invalid) {
      return;
    }
    this.loadingSubmitBtn = true;
    const sub = this.grcWexlogSvc.messageDemandeGrc(this.wexlogGrcData.id, this.messageDemandeGrcForm.value).subscribe(
      grc => {
        this.submitted.emit(grc);
        this.formSubmit = false;
        this.loadingSubmitBtn = false;
        this.refreshConversation();
        sub.unsubscribe();
      },
      error => {
        this.loadingSubmitBtn = false;
        this.formSubmit = false;
        Swal.fire({
            title: this.translate.instant('Désolé'),
            text: this.translate.instant('Une erreur est survenue'),
            showCancelButton: false
          }
        ).then(() => {
          sub.unsubscribe();
          this.cancel();
        });
      }
    );
  }

  checkStatut(statut, boolean) {
    if (statut === 'RESOLVED') {
      this.loadingUpdateResolvedStatutBtn = boolean;
    } else if (statut === 'ASSIGNED') {
      this.loadingUpdateAssignedStatutBtn = boolean;
    } else {
      this.loadingUpdateClosedStatutBtn = boolean;
    }
  }

  onUpdateStatut(statut) {
    this.initFormStatut(statut)
    this.checkStatut(statut, true);
    const sub = this.grcWexlogSvc.updateStatutDemandeGrc(this.wexlogGrcData.id, this.updateStatutDemandeGrcForm.value).subscribe(
      grc => {
        this.submitted.emit(grc);
        this.refreshConversation()
        this.checkStatut(statut, false);
        sub.unsubscribe();
      },
      error => {
        this.checkStatut(statut, false);
        Swal.fire({
            title: this.translate.instant('Désolé'),
            text: this.translate.instant('Une erreur est survenue'),
            showCancelButton: false
          }
        ).then(() => {
          sub.unsubscribe();
          this.cancel();
        });
      }
    );
  }

  cancel() {
    this.canceled.emit();
  }

  swalAlert(titleText) {
    Swal
      .fire({titleText: this.translate.instant(titleText)})
      .then(
        () => {
          this.router.navigate(['/gestion-relation-client/wexlog' + this.wexlogGrcData.id]);
        }
      ).catch(() => {
      Swal.fire({
        title: this.translate.instant('Désolé'),
        text: this.translate.instant('Une erreur est survenue'),
        showCancelButton: false,
        type: 'error',
      });
    });
  }
}
