<div class="row m-0 pt-3">
  <div class="col-xl-4 col-lg-3 col-md-3 col-sm-3 col-6 p-0">
    <label class="col-lg-12 col-md-12 col-sm-12">{{"Type d'habitat" | translate }}
      <input type="text" [value]="commande?.type_habitat"
             class="input form-control form-control-filter text-center" readonly>
    </label>
  </div>
  <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-6 p-0">
    <label class="col-lg-12 col-md-12 col-sm-12">{{'Etage' | translate }}
      <input type="text" [value]="commande?.numero_etage"
             class="input form-control form-control-filter text-center" readonly>
    </label>
  </div>
  <div class="col-xl-3 col-lg-4 col-md-4 col-sm-4 col-8 p-0">
    <label class="col-lg-12 col-md-12 col-sm-12">{{'Interphone' | translate }}
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text text-white"
                [ngClass]="{'bg-dark': commande?.interphone == 'N' || commande?.interphone == null, 'bg-red-agediss': commande?.interphone == 'O'}">{{commande?.interphone | ouiNonTradiss}}
          </span>
        </div>
        <input type="text" [value]="commande?.code_interphone"
               class="input form-control form-control-filter text-center" readonly>
      </div>
    </label>
  </div>
  <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-4 p-0">
    <label class="col-lg-12 col-md-12 col-sm-12 text-truncate">{{'Ascenseur' | translate }}
      <span class="ascenseur form-control text-center text-white font-weight-bold"
            [ngClass]="{'bg-dark': commande?.ascenseur == 'N' || commande?.ascenseur == null, 'bg-red-agediss': commande?.ascenseur  == 'O'}">{{commande?.ascenseur | ouiNonTradiss}}
      </span>
    </label>
  </div>
  <div *ngIf="commande.cr_ttc !== null" class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-6 p-0">
    <label class="col-lg-12 col-md-12 col-sm-12">{{'CR TTC' | translate }}
      <div class="input-group">
        <input type="text" class="input form-control form-control-filter" [value]="commande?.cr_ttc" readonly>
        <span class="input-group-append">
              <span class="input-group-text input-group-border-left"> € </span>
            </span>
      </div>
    </label>
  </div>
  <div *ngIf="commande.port_du_ttc !== null" class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-6 p-0">
    <label class="col-lg-12 col-md-12 col-sm-12">{{'Port du TTC' | translate }}
      <div class="input-group">
        <input type="text" class="input form-control form-control-filter" [value]="commande?.port_du_ttc" readonly>
        <span class="input-group-append">
             <span class="input-group-text input-group-border-left"> € </span>
            </span>
      </div>
    </label>
  </div>
  <div *ngIf="commande.montant_percu !== null"
       class="offset-xl-0 col-xl-4 offset-lg-0 col-lg-4 offset-md-0 col-md-4 offset-sm-0 col-sm-4 col-6 p-0">
    <label class="col-lg-12 col-md-12 col-sm-12 col-12 text-truncate">{{'Montant reçu à la livraison' | translate }}
      <div class="input-group">
        <input type="text" class="input form-control form-control-filter" [value]="commande?.montant_percu" readonly>
        <span class="input-group-append">
              <span class="input-group-text input-group-border-left"> € </span>
            </span>
      </div>
    </label>
  </div>
</div>

<div class="row m-0 pt-2">
  <div class="col-lg-12 col-md-12 p-0">
    <label class="col-lg-12 col-md-12 col-sm-12">{{'Instruction de livraison' | translate }}
      <textarea readonly cols="30" rows="5"
                class="textarea form-control form-control-filter scrollbar scrollbar-black bordered-black square thin">
        {{commande?.instruction_complementaire}}
      </textarea>
    </label>
  </div>
</div>

