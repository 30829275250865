import {Component, Input, OnInit} from '@angular/core';
import {ChangeEvent} from '@ckeditor/ckeditor5-angular';
import * as Editor from '@ckeditor/ckeditor5-build-classic';
import {Contestation} from '../../../shared/models/api/contestation.model';
import Swal from 'sweetalert2';
import {ContestationService} from '../../../shared/services/api/contestation.service';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {ContestationData} from "../../../shared/models/api/contestation-data.model";

@Component({
  selector: 'app-reclamation-contestation',
  templateUrl: './reclamation-contestation.component.html',
  styleUrls: ['./reclamation-contestation.component.scss']
})
export class ReclamationContestationComponent implements OnInit {

  @Input() contestationData: ContestationData;
  public Editor = Editor;
  public EditorData = '';
  public loadingBtn = false;
  public loadingPage = false;
  public enableEditorSaveButton: Boolean = true;

  constructor(private translate: TranslateService,
              private contestationSvc: ContestationService,
              private toastr: ToastrService) {
  }

  ngOnInit() {
  }

  public onChange({editor}: ChangeEvent) {
    this.EditorData = editor.getData();
    this.enableEditorSaveButton = false;
  }

  saveObjetContestation() {
    this.loadingBtn = true;
    this.enableEditorSaveButton = true;
    this.contestationSvc.updateContestation(this.contestationData).subscribe(
      () => {
        this.loadingBtn = false;
        this.toastr.info(
          this.translate.instant("La contestation a été enregistrée"),
          this.translate.instant("Informations"),
          {progressBar: true}
        );
      }, (error) => {
        Swal.fire({
          title: this.translate.instant('Attention !'),
          text: this.translate.instant('Des modifications ont été effectuées - Êtes-vous sûr de vouloir perdre vos modifications ?'),
          type: 'warning',
          cancelButtonText: this.translate.instant('NON'),
          confirmButtonText: this.translate.instant('OUI'),
          confirmButtonColor: '#aa1c1c',
          allowOutsideClick: false,
          showCancelButton: true,
          allowEscapeKey: false,
        });
        this.loadingBtn = false;
      });
  }

}
