import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FileItem, FileUploader} from 'ng2-file-upload';
import {ToastrService} from 'ngx-toastr';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import {TranslateService} from '@ngx-translate/core';
import {TypePieceJointe} from "../../../../shared/models/api/type-piece-jointe.model";
import {AuthenticationService} from "../../../../shared/services/authentication.service";
import {PieceJointeService} from "../../../../shared/services/api/piece-jointe.service";
import {environment} from "../../../../../environments/environment";
import {convertBytes, urlWithProtocol} from "../../../../shared/utils/util";
import {CompteClientSsoService} from "../../../../shared/services/api/sso/compte-client-sso.service";
import {CompteClient} from "../../../../shared/models/api/compte-client.model";

@Component({
    selector: 'app-file-uploader',
    templateUrl: './file-uploader.component.html',
    styleUrls: ['./file-uploader.component.scss']
})

export class FileUploaderComponent implements OnInit {

    public defaultExtension: string[] = ['.pdf', '.jpg', '.gif'];
    public kpiExtension: string[] = ['.pdf', '.jpg', '.xlsx'];
    public videoExtension: string[] = ['.mp4', '.mpeg4', '.mov', '.avi'];

    public uploader: FileUploader;
    public hasBaseDropZoneOver: boolean = false;
    public URL: string = null;
    public disabledButton: Boolean = false;
    public loading: boolean = false;
    public typePiecesJointes: TypePieceJointe[] = [];
    public comptesClients: CompteClient[] = [];
    public laddaSentButton: Boolean = false;
    public innerWidth: number = window.innerWidth;
    public fileName: string = "";

    @Input() modal: NgbModal;
    @Input() showReturnButton: boolean = true;
    @Input() isKpi: boolean = false;
    @Input() isVideo: boolean = false;
    @Input() showHeader: boolean = false;
    @Input() idCommande: number;
    @Input() idContestation: number;
    @Input() type: string;
    @Input() idGrc: number;
    @Input() idCompteClient: number | string;
    @Input() idLitige: number;
    @Input() isNouvelleFacture: boolean = false;
    @Input() idAccount: number = null;

    @Output() uploaded: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() canceled: EventEmitter<void> = new EventEmitter();

    constructor(private translate: TranslateService,
                private authSvc: AuthenticationService,
                private toastr: ToastrService,
                private pieceJointeService: PieceJointeService,
                private compteClientService: CompteClientSsoService) {
    }

    public fileOverBase(e: any): void {
        this.hasBaseDropZoneOver = e;
    }

    ngOnInit() {
        if (this.isNouvelleFacture === true) {
            const sub = this.pieceJointeService.getTypePieceJointe(null, true).subscribe(
                typePiecesJointes => {
                    this.typePiecesJointes = typePiecesJointes;
                    sub.unsubscribe();
                },
                () => {
                    Swal.fire({
                        title: this.translate.instant('Désolé'),
                        text: this.translate.instant("La liste des types n'est pas disponible"),
                    }).then(() => {
                        this.canceled.emit();
                    });
                }
            );

        } else if (this.isKpi) {
            const sub = this.compteClientService.getAllCompteClient().subscribe(compteClients => {
                    this.comptesClients = compteClients;
                    sub.unsubscribe();
                }, () => {
                    Swal.fire({
                        title: this.translate.instant('Désolé'),
                        text: this.translate.instant("La liste des comptes-clients n'est pas disponible"),
                    }).then(() => {
                        this.canceled.emit();
                    });
                }
            );
        } else if (!this.isKpi && !this.isVideo) {
            // Les Kpi n'ont pas besoin de récupérer les types.
            const sub = this.pieceJointeService.getTypePieceJointe().subscribe(
                typePiecesJointes => {
                    this.typePiecesJointes = typePiecesJointes;
                    sub.unsubscribe();
                },
                () => {
                    Swal.fire({
                        title: this.translate.instant('Désolé'),
                        text: this.translate.instant("La liste des types n'est pas disponible"),
                    }).then(() => {
                        this.canceled.emit();
                    });
                }
            );
        }
        this.routeApiUploader();
        this.handleEventUplader();
    }

    uploadItem(item: FileItem) {
        if (this.kpiExtension.indexOf(item.formData.extention) < 0 && this.isKpi) {
            Swal.fire({
                title: this.translate.instant('Attention !'),
                text: this.translate.instant('L\'extension {{ extension }} n\'est pas acceptée', {extension: item.formData.extention}) + '. ' + this.translate.instant('Liste des extensions acceptées: {{ liste }}', {liste: this.kpiExtension}),
                type: 'warning'
            });
        } else if (this.videoExtension.indexOf(item.formData.extention) < 0 && this.isVideo) {
            Swal.fire({
                title: this.translate.instant('Attention !'),
                text: this.translate.instant('L\'extension {{ extension }} n\'est pas acceptée', {extension: item.formData.extention}) + '. ' + this.translate.instant('Liste des extensions acceptées: {{ liste }}', {liste: this.videoExtension}),
                type: 'warning'
            });
        } else if (this.defaultExtension.indexOf(item.formData.extention) < 0 && !this.isVideo && !this.isKpi) {
            Swal.fire({
                title: this.translate.instant('Attention !'),
                text: this.translate.instant('L\'extension {{ extension }} n\'est pas acceptée', {extension: item.formData.extention}) + '. ' + this.translate.instant('Liste des extensions acceptées: {{ liste }}', {liste: this.defaultExtension}),
                type: 'warning'
            });
        } else if (item.file.size > environment.size_max_file) {
            Swal.fire({
                title: this.translate.instant('Attention !'),
                text: this.translate.instant('Le fichier {{ name }} est trop volumineux', {name: item.formData.name}) + '. ' + this.translate.instant('Il ne doit pas être supérieur à {{ size }}', {size: convertBytes(environment.size_max_file)}),
                type: 'warning'
            });
        } else if (!item.formData.typePieceJointe && !this.isKpi && !this.isVideo) {
            Swal.fire({
                title: this.translate.instant('Attention !'),
                text: this.translate.instant('Veuillez sélectionner un type de pièce jointe'),
                type: 'warning'
            });
        } else if (!item.formData.compteClient && this.isKpi) {
            Swal.fire({
                title: this.translate.instant('Attention !'),
                text: this.translate.instant('Veuillez sélectionner un compte client !'),
                type: 'warning'
            });
        } else {
            item.upload();
        }
    }

    onChange(event) {
        this.fileName = event.target.files[0].name;
    }

    handleEventUplader() {
        // Apres avoir ajouté le fichier dans la queue de l'uploader
        this.uploader.onAfterAddingFile = (item: FileItem | any) => {
            const name = item.file.name;
            const split = name.split('.');
            item.formData.extention = '.' + split[split.length - 1];
            item.formData.name = name.replace(item.formData.extention, '');
            //set la valeur par default pour le ng-select s'il n'y a qu'un seul type de pièce jointe
            if (this.typePiecesJointes.length === 1) {
                item.formData.typePieceJointe = this.typePiecesJointes[0].id;
            }
        };

        // Avant l'uploade vers le serveur
        this.uploader.onBeforeUploadItem = (fileItem: FileItem | any) => {
            this.laddaSentButton = true;
            this.disabledButton = true;
            fileItem.isError = true;
            fileItem.file.name = fileItem.formData.name + fileItem.formData.extention;
            fileItem.withCredentials = false;
            return false;
        };

        this.uploader.onBuildItemForm = (fileItem: FileItem, form: any) => {
            if (fileItem.formData.typePieceJointe) {
                form.append('typePieceJointe', fileItem.formData.typePieceJointe ? fileItem.formData.typePieceJointe : null);
            }
            if (fileItem.formData.compteClient) {
                form.append('compteClient', fileItem.formData.compteClient ? fileItem.formData.compteClient : null);
            }
            if (fileItem.formData.name) {
                form.append('filename', fileItem.formData.name);
            }
        };

        this.uploader.onCompleteAll = () => {
            if (this.uploader.queue.length === 0) {
                this.uploaded.emit(true);
            }
            this.laddaSentButton = false;
        };

        // uploader reussi
        this.uploader.onSuccessItem = ((item: FileItem): any => {
            this.removeFileFromQueue(item.alias);
            this.toastr.success(
                this.translate.instant("Le fichier {{ value }} a été envoyé", {value: item.file.name}),
                this.translate.instant('Envoi terminé'),
                {progressBar: true}
            );
        });

        // erreur dans n'importe quels evenement ci-dessus.
        this.uploader.onErrorItem = ((item: FileItem, response: string): any => {
            item.progress = 0;
            const defaultMsg = this.translate.instant("Le fichier {{ value }} a rencontré un problème lors de l'envoi, merci de rééssayer ultérieurement", {value: item.file.name});
            try {
                const rep = JSON.parse(response);
                if (rep.code) {
                    switch (rep.code) {
                        case 2101 :
                            Swal.fire({
                                title: this.translate.instant('Attention !'),
                                text: this.translate.instant("Vous devez choisir un type pour le fichier {{ value }}", {value: item.formData.name}),
                                type: 'warning'
                            });
                            break;
                        case 2700 :
                            Swal.fire({
                                title: this.translate.instant('Attention !'),
                                text: this.translate.instant("Vous devez choisir un type pour le fichier {{ value }}", {value: item.formData.name}),
                                type: 'warning'
                            });
                            break;
                        default:
                            Swal.fire({
                                title: this.translate.instant('Désolé'),
                                text: defaultMsg,
                                type: 'error'
                            });
                    }
                } else {
                    Swal.fire({
                        title: this.translate.instant('Désolé'),
                        text: defaultMsg,
                        type: 'error'
                    });
                }
            } catch (e) {
                Swal.fire({
                    title: this.translate.instant('Désolé'),
                    text: defaultMsg,
                    type: 'error'
                });
            }
            this.disabledButton = false;
            this.laddaSentButton = false;

            item.isCancel = false;
            item.isReady = false;
            item.isUploaded = false;
        });
    }

    removeFileFromQueue(label: string) {
        for (let i = 0; i < this.uploader.queue.length; i++) {
            if (this.uploader.queue[i].alias === label) {
                this.uploader.queue[i].remove();
                return;
            }
        }
    }

    routeApiUploader() {

        if (this.idCompteClient != undefined || this.idCompteClient != null) {
            this.URL = urlWithProtocol(environment.url_api_espace_client) + '/comptes-clients/' + this.idCompteClient + '/upload';
        }
        if (this.idLitige != undefined || this.idLitige != null) {
            this.URL = urlWithProtocol(environment.url_api_espace_client) + '/litiges/' + this.idLitige + '/upload';
        }
        if (this.idContestation != undefined || this.idContestation != null) {
            this.URL = urlWithProtocol(environment.url_api_espace_client) + '/contestations/' + this.idContestation + '/upload';
        }
        if (this.isKpi) {
            this.URL = urlWithProtocol(environment.url_api_espace_client) + '/ged/add-kpi';
        }
        if (this.isVideo) {
            this.type = 'video';
            this.URL = urlWithProtocol(environment.url_api_scansrv) + '/ged/upload-' + this.type;
        }
        if (!this.URL) {
            throw new Error('Impossible de définir l\'url pour uploader les pièces jointes.');
        }
        this.uploader = new FileUploader({url: this.URL});
        this.authSvc.getToken().subscribe(token => {
            this.uploader.authToken = 'Bearer ' + token.token;
        });
    }

    sentAllFile() {
        let nope: boolean = false;
        this.uploader.queue.forEach((fileItem: FileItem) => {
            if (this.kpiExtension.indexOf(fileItem.formData.extention) < 0 && this.isKpi) {
                Swal.fire({
                    title: this.translate.instant('Désolé'),
                    text: this.translate.instant('L\'extension {{ extension }} n\'est pas acceptée', {extension: fileItem.formData.extention}) + '. ' + this.translate.instant('Liste des extensions acceptées: {{ liste }}', {liste: this.kpiExtension}),
                    type: 'warning'
                });
                nope = true;
            } else if (this.videoExtension.indexOf(fileItem.formData.extention) < 0 && this.isVideo) {
                Swal.fire({
                    title: this.translate.instant('Désolé'),
                    text: this.translate.instant('L\'extension {{ extension }} n\'est pas acceptée', {extension: fileItem.formData.extention}) + '. ' + this.translate.instant('Liste des extensions acceptées: {{ liste }}', {liste: this.videoExtension}),
                    type: 'warning'
                });
                nope = true;
            } else if (this.defaultExtension.indexOf(fileItem.formData.extention) < 0 && !this.isVideo && !this.isKpi) {
                Swal.fire({
                    title: this.translate.instant('Désolé'),
                    text: this.translate.instant('L\'extension {{ extension }} n\'est pas acceptée', {extension: fileItem.formData.extention}) + '. ' + this.translate.instant('Liste des extensions acceptées: {{ liste }}', {liste: this.defaultExtension}),
                    type: 'warning'
                });
                nope = true;
            } else if (fileItem.file.size > environment.size_max_file) {
                Swal.fire({
                    title: this.translate.instant('Désolé'),
                    text: this.translate.instant('Le fichier {{ name }} est trop volumineux', {name: fileItem.formData.name}) + '. ' + this.translate.instant('Il ne doit pas être supérieur à {{ size }}', {size: convertBytes(environment.size_max_file)}),
                    type: 'warning'
                });
                nope = true;
            } else if (!fileItem.formData.typePieceJointe && !this.isKpi && !this.isVideo) {
                Swal.fire({
                    title: this.translate.instant('Désolé'),
                    text: this.translate.instant('Veuillez sélectionner un type de pièce jointe'),
                    type: 'warning'
                });
                nope = true;
            } else if (!fileItem.formData.compteClient && this.isKpi) {
                Swal.fire({
                    title: this.translate.instant('Désolé'),
                    text: this.translate.instant('Veuillez sélectionner un compte client !'),
                    type: 'warning'
                });
                nope = true;
            }
        });
        if (!nope) {
            this.uploader.uploadAll();
        }
    }

    cancel() {
        this.canceled.emit();
    }

}

