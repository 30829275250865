<div *ngIf="view.hasDroit('LITIGE_MENU_LITIGE')">
  <div class="row m-0 padding">
    <h1 class="page-header m-auto text-center padding-h1">{{'Mes litiges' | translate }}</h1>
  </div>

  <div class="row m-0 padding-table">
    <div class="col-12 m-0 alert alert-light fade show shadow">
      <app-filter-litige (submitFilter)="onSubmit($event)" (onLoad)="onLoadFilter($event)"></app-filter-litige>
    </div>
  </div>

  <div class="row m-0 padding-table" tourAnchor="tableau.litiges">
    <div class="col-12 p-0">
      <panel noButton="true" bodyClass="no-padding panel">
        <div class="row" header>
          <div class="col-12"><h5 class="p-t-5 text-agediss">{{'Mes litiges' | translate }} : <span
            *ngIf="!loadingNbLitiges">{{nbLitige}}</span> <i *ngIf="loadingNbLitiges"
                                                             class='fas fa-spin fa-circle-notch'></i></h5></div>
        </div>
        <div>
          <app-table-litige [data]="litigesData"
                            [length]="nbLitige" [pageSize]="pageSize"
                            (paginatorEvent)="onLoadDataByEvent($event)"
                            [pageSizeOptions]="pageSizeOption"
                            [showViewBtn]="false" [showInfosBtn]="true"
                            [loading]="loadingTableLitige">
          </app-table-litige>
        </div>
      </panel>
    </div>
  </div>
</div>
