<form>
  <div class="form-group">
    <div class="input-group">
      <div class="input-group-prepend d-none d-sm-block" style="z-index: 5;">
        <span class="input-group-text input-group-border-right">{{ 'Semaine' | translate }}</span>
      </div>
      <input name="datepicker"
             #dateInput
             ngbDatepicker
             readonly
             class="input form-control form-control-filter text-left"
             #datePicker="ngbDatepicker"
             [formControl]="formControl"
             [autoClose]="true"
             (dateSelect)="onDateSelection($event)"
             [showWeekNumbers]="true"
             [dayTemplate]="t"
             autocomplete="off"
             outsideDays="visible"
             (click)="openDatePicker()">
      <ng-template #t let-date let-focused="focused">
        <span class="custom-day" [class.focused]="focused" [class.range]="isRange(date)">{{ date.day }}</span>
      </ng-template>
      <div class="input-group-append">
        <button class="btn btn-grey-agediss" (click)="openDatePicker()" type="button"><i class="fa fa-calendar"></i>
        </button>
      </div>
    </div>
  </div>
</form>
