import {Deserializable} from '../interfaces/deserializable.interface';
import {Application} from './application.model';
import {CompteClient} from './compte-client.model';
import {Droit} from './droit.model';
import {Client} from './client.model';

export class Role implements Deserializable {

  public id: number;
  public libelle?: string;
  public context?: string | number;
  public droits?: Droit[];
  public clients?: Client[];
  public application: Application;
  public compte_client: CompteClient;
  public created_at?: Date | string;
  public updated_at?: Date | string;


  deserialize(input: any): this {
    Object.assign(this, input);
    this.application = (input.application) ? new Application().deserialize(input.application) : null;
    this.compte_client = (input.compte_client) ? new CompteClient().deserialize(input.compte_client) : null;
    return this;
  }

}

