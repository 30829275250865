import {Deserializable} from '../interfaces/deserializable.interface';

export class FilterMultiCritereModel implements Deserializable {
  public plateforme?: number | string;
  public extraction?: number | string;
  public client?: number | string;
  public pole?: number | string;
  public origine?: number | string;
  public Motif?: number | string;
  public createdAt: string |  Date;
  public traitePar?: number | string;
  public statut?: number | string;
  public statutCode?: string
  public text?: number | string;
  public search?: number | string;
  public limit?: number;
  public flux?: string;
  public societe?: string;
  public offset?: number;
  public orderDirection?: string;
  public orderChamp?: string;
  public dateLitige?: string | Date;
  public minDate?: string | Date;
  public maxDate?: string | Date;
  public to_date?: string | Date;
  public date?: string | Date;
  public from_date?: string | Date;
  public minDateSaisie?: string | Date;
  public maxDateSaisie?: string | Date;
  public minDateRdv?: string | Date;
  public maxDateRdv?: string | Date;
  public minDateLivraison?: string | Date;
  public maxDateLivraison?: string | Date;
  public contestataire?: string ;
  public email?: string ;
  public username?: string ;
  public context?: string ;
  public numero_avis?: string ;
  public extractExcel?: boolean;
  public type?: string;
  public categorie?: string;
  public compteClient?: string;
  public clientEspaceClient?: boolean;
  public take_picture?: boolean;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }



}
