<div *ngIf="loading" style="height: 500px" class="row col-12">
  <span class="spinner"></span>
</div>
<div *ngIf="!loading">
  <div class="modal-header bg-modal">
    <h4 class="modal-title  w-100 text-center">{{'Changement de mot de passe' | translate}}
    </h4>
  </div>
  <div class="modal-body bg-modal">
    <form [formGroup]="passwordForm">
      <div class="row m-0 form-group">
        <div class="offset-0 col-12 offset-lg-2 col-lg-8">
          <label class="col-lg-12 col-md-12 col-sm-12">{{'Mot de passe actuel' | translate}}
            <div class="input-group">
              <input id="currentPassword" type="password" class="form-control form-control-filter" autocomplete="off"
                     formControlName="currentPassword"
                     [ngClass]="{ 'is-invalid': formSubmit && f.currentPassword.errors}">
              <div *ngIf="formSubmit && f.currentPassword.errors" class="invalid-feedback">
                <div *ngIf="f.currentPassword.errors.required">{{'Veuillez saisir votre mot de passe actuel !' | translate}}</div>
              </div>
            </div>
          </label>
        </div>
      </div>
      <div class="row m-0 form-group">
        <div class="offset-0 col-12 offset-lg-2 col-lg-8">
          <label class="col-lg-12 col-md-12 col-sm-12">{{'Nouveau mot de passe' | translate}}
            <div class="input-group">
              <input id="newPassword" type="password" class="form-control form-control-filter" autocomplete="off"
                     formControlName="newPassword"
                     [ngClass]="{ 'is-invalid': formSubmit && f.newPassword.errors}">
              <div *ngIf="formSubmit && f.newPassword.errors" class="invalid-feedback">
                <div *ngIf="f.newPassword.errors.required">{{'Veuillez saisir un nouveau mot de passe !' | translate}}</div>
              </div>
            </div>
          </label>
        </div>
      </div>
      <div class="row m-0 form-group">
        <div class="offset-0 col-12 offset-lg-2 col-lg-8">
          <label class="col-lg-12 col-md-12 col-sm-12">{{'Confirmation de votre nouveau mot de passe' | translate}}
            <div class="input-group">
              <input id="confirmNewPassword" type="password" class="form-control form-control-filter" autocomplete="off"
                     formControlName="confirmNewPassword"
                     [ngClass]="{ 'is-invalid': formSubmit && f.confirmNewPassword.errors}">
              <div *ngIf="formSubmit && f.confirmNewPassword.errors" class="invalid-feedback">
                <div *ngIf="f.confirmNewPassword.errors.required">{{'Veuillez confirmer votre nouveau mot de passe !' | translate}}</div>
                <div *ngIf="f.confirmPassword.errors.mustMatch">{{'Les mots de passe ne correspondent pas !' | translate}}</div>
              </div>
            </div>
          </label>
        </div>
      </div>
    </form>
  </div>
  <div class="row m-0 bg-modal">
    <div class="col-12 modal-footer text-center">
      <button [ladda]="loadingBtn" data-style="zoom-in" class="btn btn-agediss" (click)="onSubmitResetPassword()">
        {{'Enregistrer' | translate }}
      </button>
      <button class="btn bg-black-lighter text-white m-r-10" (click)="cancel()">{{'Fermer' | translate }}</button>
    </div>
  </div>
</div>
