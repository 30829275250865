import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";

@Pipe({name: 'contextConversation'})
export class ContextConversationPipe implements PipeTransform {

  public constructor(private translate: TranslateService) {
  }

  transform(value: string, exponent?: number): string {
    switch (value) {
      case 'DO|LI':
      case 'LI|DO' :
        return this.translate.instant("Donneur d'ordre <=> Service litige");
      case 'LI|PF':
      case 'PF|LI':
        return this.translate.instant('Plateforme <=> Service litige');
      default:
        return '';
    }

  }
}
