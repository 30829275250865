import {Component, HostListener, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {Router} from '@angular/router';
import pageSettings from './config/page-settings';
import {AuthenticationService} from './shared/services/authentication.service';
import {View} from './shared/models/view.model';
import {TranslateService} from '@ngx-translate/core';
import {NotificationService} from './shared/services/api/notification/notification.service';
import {Notif} from './shared/models/api/notif.model';
import {Subscription} from 'rxjs';
import {ToastrService} from 'ngx-toastr';
import {UserData} from './shared/models/user-data.model';
import {FilterMultiCritereModel} from './shared/models/filters/filterMultiCritere.model';
import {GrcService} from './shared/services/api/grc.service';
import {NotificationGrcData} from './shared/models/api/notification-grc-data.model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})

export class AppComponent implements OnInit {

  private filter: FilterMultiCritereModel = new FilterMultiCritereModel();

  public currentUser: UserData;
  public currentView: View;
  public pageSettings;
  public pageHasScroll;
  public loadingTableNotification = false;
  public subscriptions: Subscription[] = [];
  public notificationsGrcData: NotificationGrcData[];
  public nbData = 0;
  public lastNbNotif = 0;
  public notifInterval;
  public notifs: Notif[];
  public notifLength = 0;
  public loadingNbNotifs: boolean = false;

  constructor(
    private translate: TranslateService,
    private titleService: Title,
    private router: Router,
    private toastr: ToastrService,
    private authenticationService: AuthenticationService,
    private notificationService: NotificationService,
    private grcService: GrcService
  ) {}

  ngOnInit() {
    this.translate.addLangs(['fr', 'en', 'nl', 'es', 'de']);
    this.authenticationService.currentView.subscribe(x => {
      if (x !== null && x != undefined) {
        this.currentView = x;
      } else {
        this.authenticationService.logout();
      }
    });

    if (!this.currentView.lang || this.currentView.lang == '' || this.currentView.lang == null) {
      this.translate.setDefaultLang('fr');
    }else {
      this.translate.currentLang = this.currentView.lang
      localStorage.setItem('langueFilter', this.currentView.lang)
      if (!localStorage.getItem('langueFilter') || localStorage.getItem('langueFilter') == '') {
        this.translate.setDefaultLang(this.currentView.lang);
      }else {
        this.translate.setDefaultLang(localStorage.getItem('langueFilter'));
      }
    }

    // page settings
    this.pageSettings = pageSettings;
    this.authenticationService.currentUser.subscribe(x => {
      if (x !== null && x != undefined) {
        this.currentUser = x;
      } else {
        this.authenticationService.logout();
      }
    });

    if (this.currentView.hasDroit('GRC_PARAMETRE-NOTIFICATION-MAIL_SHOW') && this.currentUser.organisation.name === 'Client' && this.currentView.origine === 'tradiss') {
      this.loadDataNotification();
    }
  }

  loadDataNotification() {
    this.loadingTableNotification = true;
    this.filter.username = this.currentUser.username;
    this.filter.context = 'WITHSUBCONTEXTID';
    this.notificationsGrcData = [];
    this.nbData = 0;
    this.subscriptions.push(this.grcService.getNotificationGrc(this.filter).subscribe((notification) => {
      this.notificationsGrcData = notification.data;
      this.nbData = notification.nb;
      this.loadingTableNotification = false;
      },
      error => {
        this.loadingTableNotification = false;
      }));
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    const doc = document.documentElement;
    const top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    this.pageHasScroll = top > 0;
  }

  // set page minified
  onToggleSidebarMinified(open): void {
    this.pageSettings.pageSidebarMinified = open;
  }

  // set page right collapse
  onToggleSidebarRight(): void {
    this.pageSettings.pageSidebarRightCollapsed = !this.pageSettings.pageSidebarRightCollapsed;
  }

  // hide mobile sidebar
  onHideMobileSidebar(): void {
    if (this.pageSettings.pageMobileSidebarToggled) {
      if (this.pageSettings.pageMobileSidebarFirstClicked) {
        this.pageSettings.pageMobileSidebarFirstClicked = false;
      } else {
        this.pageSettings.pageMobileSidebarToggled = false;
      }
    }
  }

  // toggle mobile sidebar
  onToggleMobileSidebar(): void {
    if (this.pageSettings.pageMobileSidebarToggled) {
      this.pageSettings.pageMobileSidebarToggled = false;
    } else {
      this.pageSettings.pageMobileSidebarToggled = true;
      this.pageSettings.pageMobileSidebarFirstClicked = true;
    }
  }


  // hide right mobile sidebar
  onHideMobileRightSidebar(): void {
    if (this.pageSettings.pageMobileRightSidebarToggled) {
      if (this.pageSettings.pageMobileRightSidebarFirstClicked) {
        this.pageSettings.pageMobileRightSidebarFirstClicked = false;
      } else {
        this.pageSettings.pageMobileRightSidebarToggled = false;
      }
    }
  }

  // toggle right mobile sidebar
  onToggleMobileRightSidebar(): void {
    if (this.pageSettings.pageMobileRightSidebarToggled) {
      this.pageSettings.pageMobileRightSidebarToggled = false;
      this.notificationService.onLoadSubject.next(false);
    } else {
      this.pageSettings.pageMobileRightSidebarToggled = true;
      this.pageSettings.pageMobileRightSidebarFirstClicked = true;
      this.notificationService.onLoadSubject.next(true);
    }
  }

}
