import { Injectable } from '@angular/core';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Statut} from '../../models/api/statut.model';
import {StatutGrc} from '../../models/api/statut-grc.model';
import {HttpUtil} from '../../utils/http.util';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class StatutService {

  static CONTEXTE = ['LITIGE', 'CONTESTATION', 'FACTURATION', 'JUSTIFICATIF', 'FRAISTRANSPORT', 'LIVRAISON', 'SUIVILIVRAISON', 'RETOUR'];
  static LITIGE = 'LITIGE';
  static CONTESTATION = 'CONTESTATION';
  static FACTURATION = 'FACTURATION';
  static JUSTIFICATIF = 'JUSTIFICATIF';
  static LIVRAISON = 'LIVRAISON';
  static SUIVILIVRAISON = 'SUIVILIVRAISON';
  static RETOUR = 'RETOUR';

  urlRessource = environment.url_api_espace_client + '/statuts';

  constructor(private http: HttpClient) {

  }

  /**
   * retourne les statuts en fonction du context passé en parametre
   *
   * @param context
   */
  getStatuts(context: string): Observable<Statut[]> {
    if (StatutService.CONTEXTE.indexOf(context) === -1) {
      throw new Error("Le contexte " + context + " n'existe pas dans les statuts ")
    }
    return this.http
      .get<Statut[]>(`${this.urlRessource}`,{params: HttpUtil.convertObjectToHttpParams({context: context})})
      .pipe(map((observe) => observe.map(function (observe) {
        return new Statut().deserialize(observe);
      })))
  }

  /**
   * retourne les statuts de la grc
   */
  getStatutsGrc(): Observable<StatutGrc[]> {
    return this.http
      .get<StatutGrc[]>(`${this.urlRessource}/grc-statuts`)
      .pipe(map((observe) => observe.map(function (observe) {
        return new StatutGrc().deserialize(observe);
      })))
  }

  /**
   * retourne les statuts de la livraison
   */
  getStatutsAvis(context: string): Observable<Statut[]> {
    if (StatutService.CONTEXTE.indexOf(context) === -1) {
      throw new Error("Le contexte " + context + " n'existe pas dans les statuts ")
    }
    return this.http
      .get<Statut[]>(`${this.urlRessource}/statuts-avis`, {params: HttpUtil.convertObjectToHttpParams({context: context})})
      .pipe(map((observe) => observe.map(function (observe) {
        return new Statut().deserialize(observe);
      })))
  }
}
