import {Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild} from '@angular/core';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {DatatableHelper} from "../../../shared/helpers/datatable.helper";
import {tableAnimation} from "../../../animations";
import {Litige} from "../../../shared/models/api/litige.model";
import {CommandeData} from "../../../shared/models/api/commande-data.model";
import {MatPaginator, PageEvent} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";

@Component({
  selector: 'app-table-livraison',
  templateUrl: './table-livraison.component.html',
  styleUrls: ['./table-livraison.component.scss'],
  animations: [tableAnimation],
})
export class TableLivraisonComponent implements OnInit {

  expandedElement: CommandeData | null;

  @Input() data: CommandeData[];
  @Input() litige: Litige[];
  @Input() displayedColumns: string[] = [
    'numero_avis',
    'reference_commande',
    'numero_commande',
    'numero_commande_2',
    'plateforme_nom',
    'destinataire_nom',
    'destinataire_code_postal',
    'destinataire_localite',
    'date_commande',
    'activite',
    'statut',
    'options'
  ];
  @Input() loading: boolean = false;
  @Input() hiddenColumns: string[] = [];
  @Input() showInfosBtn: boolean = false;
  @Input() length: number;
  @Input() pageSize: number;
  @Input() pageIndex: number;
  @Input() pageEvent: PageEvent;
  @Input() pageSizeOptions: number[];

  @Output() onSorted: EventEmitter<Sort> = new EventEmitter();
  @Output() paginatorEvent : EventEmitter<PageEvent> = new EventEmitter<PageEvent>()

  public innerWidth: number;
  public dataSource: MatTableDataSource<CommandeData>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  ngOnInit() {
    this.onResizeTable();
    this.dataSource = new MatTableDataSource(this.data);
    if (this.hiddenColumns.length > 0) {
      this.hiddenColumns.forEach((value) => {
        let index = this.displayedColumns.indexOf(value);
        if (index) {
          this.displayedColumns.splice(index, 1)
        }
      })
    }
  }

  emit($event: PageEvent) {
    return this.paginatorEvent.emit($event);
  }

    /**
   * LifeHook : à chaque changement de value de data on rafraichi les données du tableau
   * @param changes
   */
  ngOnChanges(changes: any) {
    if (changes.data) {
      this.dataSource = new MatTableDataSource(changes.data.currentValue);
    }
    if (changes.loading) {
      if (changes.data) {
        this.dataSource = new MatTableDataSource(changes.data.currentValue);
      }
      if (changes.loading) {
        this.loading = changes.loading.currentValue;
      }
    }
  }

  /**
   * Permet d'ordonner les colones.
   */
  sortData(sort: Sort) {
    const data = this.data.slice();
    if (!sort.active || sort.direction === '') {
      this.dataSource = new MatTableDataSource(data);
      return;
    }
    this.dataSource = new MatTableDataSource(data.sort(
      (a, b) => {
        const isAsc = sort.direction === 'asc';
        if (sort.active in a) {
          return DatatableHelper.compare(a[sort.active], b[sort.active], isAsc);
        }
        return 0;
      }));
  }

  @HostListener('window:resize', ['$event'])
  onResizeTable() {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth < 400) {
      this.displayedColumns = [
        'numero_avis',
        'reference_commande',
        'options'
      ];
    } else if (this.innerWidth > 400 && this.innerWidth <= 500) {
      this.displayedColumns = [
        'numero_avis',
        'reference_commande',
        'destinataire_nom',
        'options'
      ];
    } else if (this.innerWidth > 500 && this.innerWidth <= 660) {
      this.displayedColumns = [
        'numero_avis',
        'reference_commande',
        'destinataire_nom',
        'destinataire_localite',
        'options'
      ];
    } else if (this.innerWidth > 660 && this.innerWidth <= 767) {
      this.displayedColumns = [
        'numero_avis',
        'reference_commande',
        'destinataire_nom',
        'destinataire_localite',
        'statut',
        'options'
      ];
    } else if (this.innerWidth > 767 && this.innerWidth <= 877) {
      this.displayedColumns = [
        'numero_avis',
        'reference_commande',
        'destinataire_nom',
        'destinataire_localite',
        'options'
      ];
    } else if (this.innerWidth > 877 && this.innerWidth <= 1051) {
      this.displayedColumns = [
        'numero_avis',
        'reference_commande',
        'destinataire_nom',
        'destinataire_localite',
        'statut',
        'options'
      ];
    } else if (this.innerWidth > 1051 && this.innerWidth <= 1211) {
      this.displayedColumns = [
        'numero_avis',
        'reference_commande',
        'numero_commande',
        'destinataire_nom',
        'destinataire_localite',
        'statut',
        'options'
      ];
    } else if (this.innerWidth > 1211 && this.innerWidth <= 1270) {
      this.displayedColumns = [
        'numero_avis',
        'reference_commande',
        'numero_commande',
        'numero_commande_2',
        'destinataire_nom',
        'destinataire_localite',
        'statut',
        'options',
      ];
    } else if (this.innerWidth > 1270 && this.innerWidth <= 1330) {
      this.displayedColumns = [
        'numero_avis',
        'reference_commande',
        'numero_commande',
        'numero_commande_2',
        'plateforme_nom',
        'destinataire_nom',
        'destinataire_localite',
        'statut',
        'options',
      ];
    } else if (this.innerWidth > 1330 && this.innerWidth <= 1400) {
      this.displayedColumns = [
        'numero_avis',
        'reference_commande',
        'numero_commande',
        'numero_commande_2',
        'plateforme_nom',
        'destinataire_nom',
        'destinataire_code_postal',
        'destinataire_localite',
        'statut',
        'options',
      ];
    }  else if (this.innerWidth > 1400 && this.innerWidth <= 1550) {
      this.displayedColumns = [
        'numero_avis',
        'reference_commande',
        'numero_commande',
        'numero_commande_2',
        'plateforme_nom',
        'destinataire_nom',
        'destinataire_code_postal',
        'destinataire_localite',
        'date_commande',
        'statut',
        'options',
      ];
    } else if (this.innerWidth > 1550) {
      this.displayedColumns = [
        'numero_avis',
        'reference_commande',
        'numero_commande',
        'numero_commande_2',
        'plateforme_nom',
        'destinataire_nom',
        'destinataire_code_postal',
        'destinataire_localite',
        'date_commande',
        'activite',
        'statut',
      ];
    }
  }
}
