<div *ngIf="view.hasDroit('GRC_MENU_GESTION-RELATION-CLIENTS')">
  <div class="row m-0 padding">
    <h1 class="page-header text-center m-auto padding-h1">{{'Gestion relation client' | translate }}</h1>
  </div>

  <div *ngIf="view.origine === 'tradiss'" class="row m-0 padding-table">
    <div class="col-12 m-0 alert alert-light fade show shadow">
      <app-filter-grc (submitFilter)="onSubmit($event)" (onLoad)="onLoad($event)"></app-filter-grc>
    </div>
  </div>

  <div *ngIf="view.origine === 'tradiss'" class="row m-0 padding-table" tourAnchor="tableau.grc">
    <div class="col-12 p-0">
      <panel noButton="true" bodyClass="no-padding panel">
        <div class="row" header>
          <div class="col-12"><h5 class="p-t-5 text-agediss">{{'Gestion relation client' | translate }} : <span
            *ngIf="!loadingNbGrc">{{nbData}}</span> <i *ngIf="loadingNbGrc" class='fas fa-spin fa-circle-notch'></i>
          </h5></div>
        </div>
        <div>
          <app-table-grc [data]="grcData" [length]="nbData" [pageSize]="pageSize"
                         (paginatorEvent)="onLoadDataGrcByEvent($event)" [pageSizeOptions]="pageSizeOption"
                         [loading]="loadingTableGrc" [showInfosBtn]="true"
                         [showDeleteDemandGrcBtn]="view.hasDroit('GRC_DELETE')"></app-table-grc>
        </div>
      </panel>
    </div>
  </div>

  <div *ngIf="view.origine === 'wexlog'" class="row m-0 padding-table">
    <div class="col-12 m-0 alert alert-light fade show shadow">
      <app-filter-grc-wexlog (submitFilter)="onSubmit($event)" (onLoad)="onLoad($event)"></app-filter-grc-wexlog>
    </div>
  </div>

  <div *ngIf="view.origine === 'wexlog'" class="row m-0 padding-table" tourAnchor="tableau.grc-wexlog">
    <div class="col-12 p-0">
      <panel noButton="true" bodyClass="no-padding panel">
        <div class="row" header>
          <div class="col-12"><h5 class="p-t-5 text-agediss">{{'Gestion relation client' | translate }}</h5></div>
        </div>
        <div>
          <app-table-grc-wexlog [data]="grcDataWexlog"
                                [loading]="loadingTableGrcWexlog"
                                [showInfosBtn]="true"
                                [showViewMessageBtn]="view.hasDroit('GRC_CONVERSATION_SHOW')"
                                (selectConversation)="openModalVisualisationMessages(modal_visualisation_messages_wexlog, $event)"
                                [showDeleteDemandGrcBtn]="view.hasDroit('GRC_DELETE')"
                                [pageSize]="10" [pageSizeOptions]="[5, 10, 20, 50]"></app-table-grc-wexlog>
        </div>
      </panel>
    </div>
  </div>
</div>


<!-- modal visualisation messages -->
<ng-template #modal_visualisation_messages_wexlog let-modal>
  <app-visualisation-demande-grc-wexlog [wexlogGrcData]="grcWexlog" [loading]="loading"
                                        (canceled)="modal.close($event)"></app-visualisation-demande-grc-wexlog>
</ng-template>
<!-- fin de modal visualisation messages -->
