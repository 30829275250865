<table mat-table [dataSource]="dataSource" (matSortChange)="sortData($event)" multiTemplateDataRows matSort
       class="table mat-responsive table-hover table-striped-n3 table-bordered mat-elevation-z8 text-center">

  <ng-container matColumnDef="id">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Id' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="align-middle">
      {{element?.id}}
    </td>
  </ng-container>
  <ng-container matColumnDef="contexte">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Contexte' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="align-middle">
      {{element?.contexte}}
    </td>
  </ng-container>
  <ng-container matColumnDef="code">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Code' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="align-middle">
      {{element?.code}}
    </td>
  </ng-container>
  <ng-container matColumnDef="libelle">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Libellé' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="align-middle">
      {{element?.libelle  | titlecase }}
    </td>
  </ng-container>
  <ng-container matColumnDef="date_creation">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Date de création' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="align-middle text-truncate">
      {{element?.date_creation}}
    </td>
  </ng-container>
  <ng-container matColumnDef="date_evenements">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{"Date d'évènement" | translate }}</th>
    <td mat-cell *matCellDef="let element" class="align-middle">
      {{element?.date_evenements}}
    </td>
  </ng-container>
  <ng-container matColumnDef="commentaire">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Commentaire' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="align-middle text-truncate" style="max-width: 200px">
      {{element?.commentaire | uppercase }}
    </td>
  </ng-container>
  <ng-container matColumnDef="creneaux_debut">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Début de créneau' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="align-middle">
      {{element?.creneaux_debut}}
    </td>
  </ng-container>
  <ng-container matColumnDef="creneaux_fin">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Fin de créneau' | translate }}u</th>
    <td mat-cell *matCellDef="let element" class="align-middle">
      {{element?.creneaux_fin }}
    </td>
  </ng-container>
  <ng-container matColumnDef="date_proposition_1">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Date de RDV proposée n°' | translate }} n° 1</th>
    <td mat-cell *matCellDef="let element" class="align-middle">
      {{element?.date_proposition_1 }}
    </td>
  </ng-container>
  <ng-container matColumnDef="date_proposition_2">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Date de RDV proposée n°' | translate }} n° 2</th>
    <td mat-cell *matCellDef="let element" class="align-middle">
      {{element?.date_proposition_2}}
    </td>
  </ng-container>
  <ng-container matColumnDef="options">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element; let i = dataIndex" class="with-img align-middle text-nowrap">
      <button *ngIf="showViewBtn && element.doc != null" class="button btn btn-rounded-arrow btn-sm btn-agediss mr-1"
              ngbTooltip="{{'Visualisation de l\'évènement' | translate }}"
              placement="left"
              container="body"
              (click)="selectFile(element)">
        <i class="fa fa-file"></i>
      </button>
      <button *ngIf="showInfosBtn && ((!!element?.creneau_debut || !!element?.creneau_fin || !!element?.date_proposition_1 || !!element?.date_proposition_2) || (!!element?.commentaire)) || innerWidth < 900"
              class="button btn btn-rounded-arrow btn-sm btn-agediss mr-1"
              ngbTooltip="{{'Informations complémentaires' | translate }}"
              placement="left"
              container="body"
              (click)="expandedElement = expandedElement === element ? null : element">
        <i class="fas fa-angle-double-down"></i>
      </button>
    </td>  </ng-container>

  <ng-container matColumnDef="noData">
    <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length" innerHTML="{{'Aucun évènement' | translate }}"></td>
  </ng-container>
  <ng-container matColumnDef="loading">
    <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length" innerHTML="{{'Chargement en cours' | translate }} <i class='fas fa-spin fa-circle-notch'></i>"></td>
  </ng-container>

  <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
  <ng-container matColumnDef="expandedDetail">
    <td class="table-td p-0" mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">

      <div class="table-detail"
           *ngIf="this.innerWidth < 400"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description">
          <strong>{{'Date de création' | translate }} : </strong><span>{{element?.date_creation}}</span>
        </div>
      </div>
      <div class="table-detail"
           *ngIf="this.innerWidth < 600"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description">
          <strong>{{'Date d\'évènement' | translate }} : </strong><span>{{element?.date_evenements}}</span>
        </div>
      </div>
      <div class="table-detail"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'" *ngIf="!!element?.creneau_debut">
        <div class="table-description">
          <strong>{{'Début de créneau' | translate }} : </strong><span>{{element?.creneau_debut }}</span>
        </div>
      </div>
      <div class="table-detail"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'" *ngIf="!!element?.creneau_fin">
        <div class="table-description">
          <strong>{{'Fin de créneau' | translate }} : </strong><span>{{element?.creneau_fin }}</span>
        </div>
      </div>
      <div class="table-detail"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'" *ngIf="!!element?.date_proposition_1">
        <div class="table-description">
          <strong>{{'Date de RDV proposée n°' | translate }} n°1 : </strong><span>{{ element?.date_proposition_1 }}</span>
        </div>
      </div>
      <div class="table-detail"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'" *ngIf="!!element?.date_proposition_2">
        <div class="table-description">
          <strong>{{'Date de RDV proposée n°' | translate }} n° 2 : </strong><span>{{element?.date_proposition_2}}</span>
      </div>
      </div>
      <div class="table-detail"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'" *ngIf="!!element?.commentaire">
        <div class="table-description">
          <strong>{{'Commentaire' | translate }} : </strong><span>{{element?.commentaire  | uppercase }}</span>
        </div>
      </div>
    </td>
  </ng-container>

  <tr mat-header-row style="height: 15px;" *matHeaderRowDef="displayedColumns;  sticky: true"></tr>
  <tr mat-row *matRowDef="let element; columns: displayedColumns;" [hidden]="loading"
      class="table-row overflow-scroll"
      [class.expanded-row]="expandedElement === element">
  </tr>
  <tr mat-footer-row *matFooterRowDef="['noData']" [hidden]="dataSource && dataSource.data.length > 0 || loading"></tr>
  <tr mat-footer-row *matFooterRowDef="['loading']" [hidden]="!loading"></tr>
  <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="table-detail-row"></tr>
</table>
