<!-- begin #sidebar-right -->
<div class="sidebar sidebar-right" id="sidebar-right">
  <!-- begin sidebar scrollbar -->
  <div class="height-full overflow-scroll">
    <!-- begin sidebar user -->
    <ul class="nav m-t-10">
      <li class="nav-widget">
        <p class="m-b-0 f-s-10 f-w-700">TOTAL VISITORS</p>
        <div>
          <ngx-trend
            [data]="[0,2,5,9,5,10,3,5,0,0,1,8,2,9,0]"
            [gradient]="[global.COLOR_BLUE]"
            autoDraw="true"
            autoDrawDuration="0"
            autoDrawEasing="ease-out"
            height="60"
            radius="0.1"
            smooth="true"
            strokeLinecap="butt"
            strokeWidth="5"
          >
          </ngx-trend>
        </div>
        <p class="m-b-0 f-s-10 f-w-700">TOTAL PAGE VIEWS</p>
        <div>
          <ngx-trend
            [data]="[0,10,3,5,0,0,1,8,2,2,5,9,5,9,0]"
            [gradient]="[global.COLOR_GREEN]"
            autoDraw="true"
            autoDrawDuration="0"
            autoDrawEasing="ease-out"
            height="60"
            radius="0.1"
            smooth="true"
            strokeLinecap="butt"
            strokeWidth="5"
          >
          </ngx-trend>
        </div>
        <p class="m-b-0 f-s-10 f-w-700">TOTAL UNIQUE VISITORS</p>
        <div>
          <ngx-trend
            [data]="[3,5,0,0,1,8,2,9,0,0,2,5,9,5,10]"
            [gradient]="[global.COLOR_ORANGE]"
            autoDraw="true"
            autoDrawDuration="0"
            autoDrawEasing="ease-out"
            height="60"
            radius="0.1"
            smooth="true"
            strokeLinecap="butt"
            strokeWidth="5"
          >
          </ngx-trend>
        </div>
        <p class="m-b-0 f-s-10 f-w-700">BOUNCE RATE</p>
        <div>
          <ngx-trend
            [data]="[0,2,3,5,0,0,1,8,2,9,0,5,9,5,10]"
            [gradient]="[global.COLOR_RED]"
            autoDraw="true"
            autoDrawDuration="0"
            autoDrawEasing="ease-out"
            height="60"
            radius="0.1"
            smooth="true"
            strokeLinecap="butt"
            strokeWidth="5"
          >
          </ngx-trend>
        </div>
      </li>
      <li class="nav-widget">
        <div class="card-accordion">
          <ngb-accordion #acc="ngbAccordion" activeIds="ngb-accordion-1">
            <ngb-panel>
              <ng-template ngbPanelTitle>
                <span class="text-inverse">
                  <i class="fa fa-plus-circle pull-right m-t-3"></i>
                  Accordion #1
                </span>
              </ng-template>
              <ng-template ngbPanelContent>
                Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid.
              </ng-template>
            </ngb-panel>
            <ngb-panel>
              <ng-template ngbPanelTitle>
                <span class="text-inverse">
                  <i class="fa fa-plus-circle pull-right m-t-3"></i>
                  Accordion #2
                </span>
              </ng-template>
              <ng-template ngbPanelContent>
                Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid.
              </ng-template>
            </ngb-panel>
            <ngb-panel>
              <ng-template ngbPanelTitle>
                <span class="text-inverse">
                  <i class="fa fa-plus-circle pull-right m-t-3"></i>
                  Accordion #3
                </span>
              </ng-template>
              <ng-template ngbPanelContent>
                Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid.
              </ng-template>
            </ngb-panel>
          </ngb-accordion>
        </div>
      </li>
    </ul>
    <!-- end sidebar user -->
  </div>
  <!-- end sidebar scrollbar -->
</div>
<div class="sidebar-bg sidebar-right"></div>
<!-- end #sidebar-right -->
