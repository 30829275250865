// Core Module
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {BrowserModule, Title} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';

import {APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import {CookieService} from 'ngx-cookie-service';
// Layout Components
import {AppComponent} from './app.component';
import {HeaderComponent} from './components/layout/header/header.component';
import {SidebarComponent} from './components/layout/sidebar/sidebar.component';
import {SidebarRightComponent} from './components/layout/sidebar-right/sidebar-right.component';
import {TopMenuComponent} from './components/layout/top-menu/top-menu.component';
import {FooterComponent} from './components/layout/footer/footer.component';
// Template Components
import {PanelComponent} from './components/template/panel/panel.component';
// Component Module
import {TrendModule} from 'ngx-trend';
import {HighlightJsModule} from 'ngx-highlight-js';
import {CKEditorModule} from '@ckeditor/ckeditor5-angular';
import {SweetAlert2Module} from '@sweetalert2/ngx-sweetalert2';

import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {LaddaModule} from 'angular2-ladda';
import {NgbDatepickerFrenchFormatter} from './shared/utils/ngDatepicker/ngbDatepickerFrenchFormatter';
import {registerLocaleData} from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import {JwtInterceptor} from './shared/interceptors/jwt.interceptor';
import {HttpErrorInterceptor} from './shared/interceptors/http-error.interceptor';
import {NgxAutoScrollModule} from 'ngx-auto-scroll';
import {checkApi} from './shared/interceptors/check-api.interceptor';
import {HttpDateInterceptor} from './shared/interceptors/http-date.interceptor';
import {NgbStringAdapter} from './shared/utils/ngDatepicker/NgbStringAdapter';
import {ToastrModule} from 'ngx-toastr';
import {FileUploadModule} from 'ng2-file-upload';
import {NgSelectModule} from '@ng-select/ng-select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule} from '@angular/material/tabs';
import { MatTreeModule } from '@angular/material/tree';
import {CdkTableModule} from '@angular/cdk/table';
import {TableauDeBord} from './pages/tableau-de-bord/tableau-de-bord';
import {LitigesPage} from './pages/litiges/litiges';
import {LitigePage} from './pages/litiges/show/litige';
import {TableLitigeComponent} from './components/litige/table/table-litige/table-litige.component';
import {TableContestationComponent} from './components/contestation/table/table-contestation/table-contestation.component';
import {TabLitigeComponent} from './components/litige/tab-litige/tab-litige.component';
import {LitigeComponent} from './components/litige/litige/litige.component';
import {InformationsComplementairesComponent} from './components/litige/informations-complementaires/informations-complementaires.component';
import {TableFileComponent} from './components/shared/table/table-file/table-file.component';
import {StatutTimelinePipe} from './shared/pipes/statut-timeline.pipe';
import {SafePipe} from './shared/pipes/safe.pipe';
import {OuiNonPipe, OuiNonTradissPipe} from './shared/pipes/oui-non.pipe';
import {FilterLitigeComponent} from './components/litige/filter-litige/filter-litige.component';
import {LitigeHistoriqueContent} from './components/litige/content/litige-historique-content/litige-historique-content';
import {LitigeConversationContent} from './components/litige/content/litige-conversation-content/litige-conversation-content';
import {TableArticleComponent} from './components/shared/article/table/table-article/table-article.component';
import {VisualisationArticleComponent} from './components/shared/article/modal/visualisation-article/visualisation-article.component';
import {TableColisComponent} from './components/shared/article/table/table-colis/table-colis.component';
import {FilePreviewComponent} from './components/shared/modal/file-preview/file-preview.component';
import {TableConversationComponent} from './components/shared/conversation/table/table-conversation/table-conversation.component';
import {SidebarLitigeComponent} from './components/litige/sidebar-litige/sidebar-litige.component';
import {ContestationsPage} from './pages/contestations/contestations';
import {FilterContestationComponent} from './components/contestation/filter-contestation/filter-contestation.component';
import {ContestationPage} from './pages/contestations/show/contestation';
import {TabContestationComponent} from './components/contestation/tab-contestation/tab-contestation.component';
import {CommandeContestationComponent} from './components/contestation/commande-contestation/commande-contestation.component';
import {StatutContestationComponent} from './components/contestation/statut-contestation/statut-contestation.component';
import {ReclamationContestationComponent} from './components/contestation/reclamation-contestation/reclamation-contestation.component';
import {ContestatairePipe} from './shared/pipes/contestataire.pipe';
import {ModalDocumentsComponent} from './components/shared/modal/modal-documents/modal-documents.component';
import {ChatConversationComponent} from './components/shared/conversation/chat/chat-conversation.component';
import {SidebarContestationComponent} from './components/contestation/sidebar-contestation/sidebar-contestation.component';
import {ContestationContent} from './components/contestation/content/contestation-content/contestation-content';
import {LitigeContestationContent} from './components/litige/content/litige-contestation-content/litige-contestation-content';
import {LitigeInfosContent} from './components/litige/content/litige-infos-content/litige-infos-content';
import {ContestationConversationContent} from './components/contestation/content/contestation-conversation-content/contestation-conversation-content';
import {TableHistoriqueComponent} from './components/litige/table/table-historique/table-historique.component';
import {ContextePipe} from './shared/pipes/contexte.pipe';
import {ContextConversationPipe} from './shared/pipes/context-conversation.pipe';
import {ShowConversationComponent} from './components/shared/conversation/modal/show-conversation/show-conversation.component';
import {TableLitigeFactureComponent} from './components/facture/table/table-litige-facture/table-litige-facture.component';
import {StatutGrcPipe} from './shared/pipes/statut.grc.pipe';
import {TableFactureAttenteComponent} from './components/facture/table/table-facture-attente/table-facture-attente.component';
import {VisualisationFactureComponent} from './components/facture/modal/visualisation-facture/visualisation-facture.component';
import {AddContestationComponent} from './components/contestation/modal/add-contestation/add-contestation.component';
import {TimelinePlateformeComponent} from './components/shared/timeline/timeline-plateforme/timeline-plateforme.component';
import {TimelineHubComponent} from './components/shared/timeline/timeline-hub/timeline-hub.component';
import {TableFactureATraiteesComponent} from './components/facture/table/table-facture-a-traitees/table-facture-a-traitees.component';
import {MesFacturesPage} from './pages/factures/mes-factures/mes-factures';
import {NouvelleFacturePage} from './pages/factures/nouvelle-facture/nouvelle-facture';
import { MatInputModule } from '@angular/material/input';
import {BarChartModule, LineChartModule, NgxChartsModule} from '@swimlane/ngx-charts';
import {SuiviDeLivraisonPage} from './pages/suivi-de-livraison/suivi-de-livraison.component';
import {AgmCoreModule, GoogleMapsAPIWrapper} from '@agm/core';
import {environment} from '../environments/environment.prod';
import {NpsStatsComponent} from './components/tableau-de-bord/nps-stats/nps-stats.component';
import {LivraisonsStatsComponent} from './components/tableau-de-bord/livraisons-stats/livraisons-stats.component';
import {GrcStatsComponent} from './components/tableau-de-bord/grc-stats/grc-stats.component';
import {TableContestationLitigeComponent} from './components/contestation/table/table-contestation-litige/table-contestation-litige.component';
import {TableGrcComponent} from './components/gestion-relation-clients/table/table-grc/table-grc.component';
import {TableGrcByCommandeComponent} from './components/gestion-relation-clients/table/table-grc-by-commande/table-grc-by-commande.component';
import {NouvelleDemandeComponent} from './components/gestion-relation-clients/modal/nouvelle-demande/nouvelle-demande.component';
import {VisualisationDemandeGrcComponent} from './components/gestion-relation-clients/modal/visualisation-demande-grc/visualisation-demande-grc.component';
import {FileUploaderGrcComponent} from './components/gestion-relation-clients/modal/file-uploader-grc/file-uploader-grc.component';
import {GestionRelationClientsPage} from './pages/gestion-relation-clients/gestion-relation-clients';
import {GestionRelationClientPage} from './pages/gestion-relation-clients/show/gestion-relation-client';
import {DestinataireGestionRelationClientsComponent} from './components/gestion-relation-clients/destinataire-gestion-relation-clients/destinataire-gestion-relation-clients.component';
import {InfosCommandeComponent} from './components/gestion-relation-clients/infos-commande/infos-commande.component';
import {LivraisonPage} from './pages/livraisons/show/livraison';
import {LivraisonsPage} from './pages/livraisons/livraisons';
import {VisualisationArticleLivraisonComponent} from './components/shared/article/modal/visualisation-article-livraison/visualisation-article-livraison.component';
import {LivraisonComponent} from './components/litige/livraison/livraison.component';
import {TableSuiviDeLivraisonComponent} from './components/suivi-de-livraison/table-suivi-de-livraison/table-suivi-de-livraison.component';
import {FilterSuiviLivraisonComponent} from './components/suivi-de-livraison/filter-suivi-livraison/filter-suivi-livraison.component';
import {RetoursPage} from './pages/retours/retours';
import {FilterRetourComponent} from './components/retour/filter-retour/filter-retour.component';
import {RetourPage} from './pages/retours/show/retour';
import {DatePickerMaxDateComponent} from './components/shared/date-picker/date-picker-max-date/date-picker-max-date.component';
import {DatePickerMinDateComponent} from './components/shared/date-picker/date-picker-min-date/date-picker-min-date.component';
import {TableRetourComponent} from './components/retour/table-retour/table-retour.component';
import {ParametresPage} from './pages/parametres/parametres';
import {ContactPage} from './pages/contact/contact';
import {AdministrationPage} from './pages/administration/administration';
import {TableUsersComponent} from './components/administration/gestion-utilisateur/table/table-users/table-users.component';
import {InformationsPersonnellesComponent} from './components/administration/parametres/informations-personnelles/informations-personnelles.component';
import {ContactPrivilegieComponent} from './components/administration/contact/contact-privilegie/contact-privilegie.component';
import {EditInformationsUserComponent} from './components/administration/parametres/modal/edit-informations-user/edit-informations-user.component';
import {EditPasswordUserComponent} from './components/administration/parametres/modal/edit-password-user/edit-password-user.component';
import {AddUserComponent} from './components/administration/gestion-utilisateur/modal/add-user/add-user.component';
import {EditUserComponent} from './components/administration/gestion-utilisateur/modal/edit-user/edit-user.component';
import {TimelineLivraisonComponent} from './components/shared/timeline/timeline-livraison/timeline-livraison.component';
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {StatutLitigePipe} from './shared/pipes/statut-litige.pipe';
import {HttpTranslateInterceptor} from './shared/interceptors/http-translate.interceptor';
import {PaginatorIntlService} from './shared/translation/PaginationTranslate';
import {FileUploaderComponent} from './components/shared/modal/file-uploader/file-uploader.component';
import {CommandeContent} from './components/commande/content/commande-content/commande-content';
import {EvenementContent} from './components/commande/content/evenement-content/evenement-content';
import {TableLivraisonComponent} from './components/livraison/table-livraison/table-livraison.component';
import {DestinataireComponent} from './components/commande/destinataire/destinataire.component';
import {PlateformeComponent} from './components/commande/plateforme/plateforme.component';
import {ListePrestationComponent} from './components/commande/prestation/liste-prestation/liste-prestation.component';
import {InformationComponent} from './components/commande/information/information.component';
import {SignatureLivraisonComponent} from './components/commande/modal/signature-livraison/signature-livraison.component';
import {VisualisationMessageComponent} from './components/commande/modal/visualisation-message/visualisation-message.component';
import {FilterLivraisonComponent} from './components/livraison/filter-livraison/filter-livraison.component';
import {TabLivraisonComponent} from './components/commande/tab-livraison/tab-livraison.component';
import {HeaderLivraisonComponent} from './components/commande/header-livraison/header-livraison.component';
import {TableMediaComponent} from './components/commande/table/table-medias/table-media.component';
import {MediaContent} from './components/commande/content/media-content/media-content';
import {LitigeContent} from './components/commande/content/litige-content/litige-content';
import {GrcContentComponent} from './components/commande/content/grc-content/grc-content.component';
import {InstructionComplementaireComponent} from './components/commande/instruction-complementaire/instruction-complementaire.component';
import {TableEvenementComponent} from './components/commande/table/table-evenement/table-evenement.component';
import {CommandePieChartsComponent} from './components/tableau-de-bord/commande-pie-charts/commande-pie-charts.component';
import {ExtractionsPage} from './pages/extractions/extractions.component';
import {MatTableExporterModule} from 'mat-table-exporter';
import {MatButtonModule} from '@angular/material/button';
import {TableExtractionsComponent} from './components/extraction/table-extractions/table-extractions.component';
import {FilterExtractionComponent} from './components/extraction/filter-extraction/filter-extraction.component';
import {DatePickerMinDateExtractionLivraisonComponent} from './components/shared/date-picker/extraction-livraison/date-picker-min-date-extraction-livraison/date-picker-min-date-extraction-livraison.component';
import {DatePickerMaxDateExtractionLivraisonComponent} from './components/shared/date-picker/extraction-livraison/date-picker-max-date-extraction-livraison/date-picker-max-date-extraction-livraison.component';
import {DatePickerMinDateExtractionRdvComponent} from './components/shared/date-picker/extraction-rdv/date-picker-min-date-extraction-rdv/date-picker-min-date-extraction-rdv.component';
import {DatePickerMinDateExtractionArrivageComponent} from './components/shared/date-picker/extraction-arrivage/date-picker-min-date-extraction-arrivage/date-picker-min-date-extraction-arrivage.component';
import {DatePickerMaxDateExtractionRdvComponent} from './components/shared/date-picker/extraction-rdv/date-picker-max-date-extraction-rdv/date-picker-max-date-extraction-rdv.component';
import {DatePickerMaxDateExtractionArrivageComponent} from './components/shared/date-picker/extraction-arrivage/date-picker-max-date-extraction-arrivage/date-picker-max-date-extraction-arrivage.component';
import {DatePickerMaxDateExtractionChargementComponent} from './components/shared/date-picker/extraction-chargement/date-picker-max-date-extraction-chargement/date-picker-max-date-extraction-chargement.component';
import {DatePickerMinDateExtractionChargementComponent} from './components/shared/date-picker/extraction-chargement/date-picker-min-date-extraction-chargement/date-picker-min-date-extraction-chargement.component';
import {Page401Page} from './pages/http/page401/page401';
import {Page404Page} from './pages/http/page404/page404';
import {Page503Page} from './pages/http/page503/page503';
import {Page500Page} from './pages/http/page500/page500';
import {DatePickerMaxDateExtractionSaisieComponent} from './components/shared/date-picker/extraction-saisie/date-picker-max-date-extraction-saisie/date-picker-max-date-extraction-saisie.component';
import {DatePickerMinDateExtractionSaisieComponent} from './components/shared/date-picker/extraction-saisie/date-picker-min-date-extraction-saisie/date-picker-min-date-extraction-saisie.component';
import {TableGedComponent} from './components/shared/table/table-ged/table-ged.component';
import { FilePreviewGedComponent } from './components/shared/modal/file-preview-ged/file-preview-ged.component';
import {TableEvenementColisComponent} from './components/commande/table/table-evenement-colis/table-evenement-colis.component';
import { EvenementColisComponent } from './components/commande/modal/evenement-colis/evenement-colis.component';
import {SchemeInterceptor} from './shared/interceptors/scheme.interceptor';
import {SchemePipe} from './shared/pipes/scheme.pipe';
import {FilePreviewModalComponent} from './components/shared/modal/file-preview-modal/file-preview-modal.component';
import {TourNgxBootstrapModule} from 'ngx-tour-ngx-bootstrap';
import {ActiviteLivraisonPipe, ActiviteReprisePipe, ActiviteRetourPipe} from './shared/pipes/activite.pipe';
import {HomePage} from './pages/home/home';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { TableParametresMailGrcComponent } from './components/gestion-relation-clients/table/table-parametres-mail-grc/table-parametres-mail-grc.component';
import {EditNotificationGrcComponent} from './components/gestion-relation-clients/modal/edit-notification-grc/edit-notification-grc.component';
import {EditAllNotificationGrcComponent} from './components/gestion-relation-clients/modal/edit-all-notification-grc/edit-all-notification-grc.component';
import { FilterTableauDeBordComponent } from './components/tableau-de-bord/filter-tableau-de-bord/filter-tableau-de-bord.component';
import { FilterGrcComponent } from './components/gestion-relation-clients/filter/filter-grc/filter-grc.component';
import {FilterGrcWexlogComponent} from './components/gestion-relation-clients-wexlog/filter/filter-grc-wexlog/filter-grc-wexlog.component';
import {TableGrcWexlogComponent} from './components/gestion-relation-clients-wexlog/table/table-grc-wexlog/table-grc-wexlog.component';
import {GestionRelationClientWexlogPage} from './pages/gestion-relation-clients/show-wexlog/gestion-relation-client-wexlog';
import {TableGrcByCommandeWexlogComponent} from './components/gestion-relation-clients-wexlog/table/table-grc-by-commande-wexlog/table-grc-by-commande-wexlog.component';
import {DestinataireGestionRelationClientsWexlogComponent} from './components/gestion-relation-clients-wexlog/destinataire-gestion-relation-clients-wexlog/destinataire-gestion-relation-clients-wexlog.component';
import { InfosCommandeWexlogComponent } from './components/gestion-relation-clients-wexlog/infos-commande-wexlog/infos-commande-wexlog.component';
import { VisualisationDemandeGrcWexlogComponent } from './components/gestion-relation-clients-wexlog/modal/visualisation-demande-grc-wexlog/visualisation-demande-grc-wexlog.component';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {NgxStarRatingModule} from 'ngx-star-rating';
import {ArraySortPipe} from './shared/pipes/array-sort.pipe';
import { NouvelleDemandeGrcWexlogComponent } from './components/gestion-relation-clients-wexlog/modal/nouvelle-demande-grc-wexlog/nouvelle-demande-grc-wexlog.component';
import {TimelineReverseHubComponent} from './components/shared/timeline/timeline-reverse-hub/timeline-reverse-hub.component';
import {TimelineReversePlateformeComponent} from './components/shared/timeline/timeline-reverse-plateforme/timeline-reverse-plateforme.component';
import {GeolocalisationComponent} from './components/commande/modal/geolocalisation/geolocalisation.component';
import {SentryErrorHandler} from './shared/interceptors/sentry-error-interceptor';
import {NgbDateAdapter, NgbDateParserFormatter, NgbDatepickerI18n, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {I18nDatepicker} from './shared/utils/ngDatepicker/i18nDatepicker';
import {WeekDatePickerComponent} from './components/shared/week-date-picker/week-date-picker';
import {CarouselModule} from 'ngx-bootstrap';
import {FirstLetterOfFirstnamePipe} from './shared/pipes/first-letter-of-firstname.pipe';
import {RegexPipe} from './shared/pipes/regex.pipe';
import {FilterTableauDeBordSuiviDeLivraisonComponent} from './components/tableau-de-bord/filter-tableau-de-bord-suivi-de-livraison/filter-tableau-de-bord-suivi-de-livraison.component';
import {AnomaliesPageComponent} from './pages/anomalies/anomalies';
import {TableAnomaliesComponent} from './components/anomalies/table-anomalies/table-anomalies.component';
import {FilterAnomaliesComponent} from './components/anomalies/filter-anomalies/filter-anomalies.component';
import {FilterFactureComponent} from './components/facture/filter-facture/filter-facture-component';
import { NotificationsComponent } from './components/notification/modal/notifications/notifications.component';
import {TableNotificationComponent} from "./components/notification/table-notification/table-notification.component";
import { RapportsKpi } from './pages/rapports-kpi/rapports-kpi';
import { FilterRapportsKpiComponent } from './components/rapports-kpi/filter-rapports-kpi/filter-rapports-kpi.component';
import { MatDialogModule } from '@angular/material/dialog';
import { VideosPage } from './pages/videos/videos';
import { FilterVideoComponent } from './components/video/filter-video/filter-video.component';

registerLocaleData(localeFr, 'fr-FR');

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SidebarComponent,
    SidebarRightComponent,
    TopMenuComponent,
    FooterComponent,
    PanelComponent,
    TableauDeBord,
    PanelComponent,
    LivraisonPage,
    CommandeContent,
    EvenementContent,
    TableEvenementComponent,
    TableArticleComponent,
    DestinataireComponent,
    PlateformeComponent,
    InstructionComplementaireComponent,
    HeaderComponent,
    HeaderLivraisonComponent,
    TabLivraisonComponent,
    TableMediaComponent,
    MediaContent,
    InformationComponent,
    VisualisationArticleComponent,
    TableColisComponent,
    TableLitigeComponent,
    LitigeContent,
    StatutTimelinePipe,
    TableFileComponent,
    FileUploaderComponent,
    FileUploaderGrcComponent,
    FilePreviewComponent,
    SafePipe,
    SchemePipe,
    OuiNonPipe,
    OuiNonTradissPipe,
    RegexPipe,
    StatutGrcPipe,
    StatutLitigePipe,
    FirstLetterOfFirstnamePipe,
    ListePrestationComponent,
    VisualisationMessageComponent,
    LitigesPage,
    FilterLitigeComponent,
    LitigePage,
    LivraisonComponent,
    LitigeComponent,
    TableContestationComponent,
    TableContestationLitigeComponent,
    TableConversationComponent,
    InformationsComplementairesComponent,
    TabLitigeComponent,
    LitigeInfosContent,
    LitigeHistoriqueContent,
    LitigeConversationContent,
    LitigeContestationContent,
    AddContestationComponent,
    SidebarLitigeComponent,
    ContestationsPage,
    FilterContestationComponent,
    LivraisonsPage,
    ContestationPage,
    FilterLivraisonComponent,
    TableSuiviDeLivraisonComponent,
    SidebarContestationComponent,
    TabContestationComponent,
    ContestationContent,
    ChatConversationComponent,
    ModalDocumentsComponent,
    ContestatairePipe,
    ReclamationContestationComponent,
    StatutContestationComponent,
    CommandeContestationComponent,
    ContestationConversationContent,
    TableFactureATraiteesComponent,
    FilterFactureComponent,
    GrcContentComponent,
    TableHistoriqueComponent,
    ContextePipe,
    ContextConversationPipe,
    ShowConversationComponent,
    TableLitigeFactureComponent,
    TableFactureAttenteComponent,
    MesFacturesPage,
    NouvelleFacturePage,
    VisualisationFactureComponent,
    VisualisationArticleLivraisonComponent,
    TimelinePlateformeComponent,
    TimelineHubComponent,
    FilterSuiviLivraisonComponent,
    SuiviDeLivraisonPage,
    SignatureLivraisonComponent,
    LivraisonsStatsComponent,
    NpsStatsComponent,
    GrcStatsComponent,
    TableGrcComponent,
    TableGrcByCommandeComponent,
    GestionRelationClientsPage,
    GestionRelationClientPage,
    FilterGrcComponent,
    NouvelleDemandeComponent,
    InfosCommandeComponent,
    VisualisationDemandeGrcComponent,
    DestinataireGestionRelationClientsComponent,
    TableLivraisonComponent,
    RetoursPage,
    FilterRetourComponent,
    RetourPage,
    DatePickerMinDateComponent,
    DatePickerMaxDateComponent,
    TableRetourComponent,
    ParametresPage,
    InformationsPersonnellesComponent,
    EditInformationsUserComponent,
    EditPasswordUserComponent,
    ContactPage,
    ContactPrivilegieComponent,
    TableNotificationComponent,
    AdministrationPage,
    TableUsersComponent,
    AddUserComponent,
    EditUserComponent,
    TimelineLivraisonComponent,
    CommandePieChartsComponent,
    ExtractionsPage,
    TableExtractionsComponent,
    FilterExtractionComponent,
    DatePickerMinDateExtractionLivraisonComponent,
    DatePickerMaxDateExtractionLivraisonComponent,
    DatePickerMinDateExtractionRdvComponent,
    DatePickerMaxDateExtractionRdvComponent,
    DatePickerMinDateExtractionArrivageComponent,
    DatePickerMaxDateExtractionArrivageComponent,
    DatePickerMinDateExtractionChargementComponent,
    DatePickerMaxDateExtractionChargementComponent,
    DatePickerMinDateExtractionSaisieComponent,
    DatePickerMaxDateExtractionSaisieComponent,
    WeekDatePickerComponent,
    Page401Page,
    Page404Page,
    Page500Page,
    Page503Page,
    TableGedComponent,
    FilePreviewGedComponent,
    TableEvenementColisComponent,
    EvenementColisComponent,
    FilePreviewModalComponent,
    ActiviteRetourPipe,
    ActiviteLivraisonPipe,
    HomePage,
    EditNotificationGrcComponent,
    EditAllNotificationGrcComponent,
    TableParametresMailGrcComponent,
    FilterTableauDeBordComponent,
    ActiviteReprisePipe,
    TimelineReverseHubComponent,
    TimelineReversePlateformeComponent,
    TableGrcWexlogComponent,
    FilterGrcWexlogComponent,
    GestionRelationClientWexlogPage,
    TableGrcByCommandeWexlogComponent,
    DestinataireGestionRelationClientsWexlogComponent,
    InfosCommandeWexlogComponent,
    NouvelleDemandeGrcWexlogComponent,
    VisualisationDemandeGrcWexlogComponent,
    ArraySortPipe,
    GeolocalisationComponent,
    FilterTableauDeBordSuiviDeLivraisonComponent,
    AnomaliesPageComponent,
    TableAnomaliesComponent,
    FilterAnomaliesComponent,
    NotificationsComponent,
    RapportsKpi,
    FilterRapportsKpiComponent,
    VideosPage,
    FilterVideoComponent
  ],
  imports: [
    AppRoutingModule,
    NgSelectModule,
    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    HighlightJsModule,
    NgbModule,
    ReactiveFormsModule,
    TrendModule,
    ToastrModule.forRoot(), // ToastrModule added
    HttpClientModule,
    MatSortModule,
    MatTableModule,
    LaddaModule,
    CKEditorModule,
    NgxAutoScrollModule,
    FileUploadModule,
    MatTabsModule,
    SweetAlert2Module.forRoot({
      buttonsStyling: false,
      customClass: {
        content: 'modal-content',
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn'
      },
    }),
    MatPaginatorModule,
    MatFormFieldModule,
    MatInputModule,
    LineChartModule,
    BarChartModule,
    MatSelectModule,
    CdkTableModule,
    AgmCoreModule.forRoot({
      apiKey: environment.api_key_google_map
    }),
    MatCheckboxModule,
    NgxChartsModule,
    MatTreeModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      }
    }),
    MatTableExporterModule,
    MatButtonModule,
    TourNgxBootstrapModule.forRoot(),
    MatSlideToggleModule,
    MatIconModule,
    MatTooltipModule,
    MatSnackBarModule,
    NgxStarRatingModule,
    CarouselModule,
    MatDialogModule
  ],

  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: checkApi,
      deps: [HttpClient, CookieService],

      multi: true
    },
    {provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true}, // intercepteur pour les codes erreur http 401
    {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: SchemeInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: HttpDateInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: HttpTranslateInterceptor, multi: true},
    {provide: NgbDateParserFormatter, useClass: NgbDatepickerFrenchFormatter}, // permet d'avoir le format de date en européen
    {provide: LOCALE_ID, useValue: 'fr-FR'}, // définit la locale
    {provide: NgbDatepickerI18n, useClass: I18nDatepicker}, // prise en charge de la locale pour les 'NgbDatepicker'
    {provide: NgbDateAdapter, useClass: NgbStringAdapter},
    {
      provide: MatPaginatorIntl,
      useFactory: (translate: TranslateService) => {
        const service = new PaginatorIntlService();
        service.injectTranslateService(translate);
        return service;
      },
      deps: [TranslateService]
    },
    { provide: ErrorHandler, useClass: SentryErrorHandler },
    CookieService,
    GoogleMapsAPIWrapper
  ],
  bootstrap: [AppComponent],
})

export class AppModule {
  constructor(public router: Router, private titleService: Title, private route: ActivatedRoute) {
    router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        const title = this.route.snapshot.firstChild.data.title;
        this.titleService.setTitle(title);
      }
    });
  }
}

// AOT compilation support
export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}


