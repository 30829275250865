import {Component, OnDestroy, OnInit} from '@angular/core';
import {AuthenticationService} from "../../shared/services/authentication.service";
import {Router} from "@angular/router";
import {View} from "../../shared/models/view.model";
import {FilterMultiCritereModel} from "../../shared/models/filters/filterMultiCritere.model";
import {Subject} from "rxjs";
import {PieceJointeGed} from "../../shared/models/api/ged/piece-jointe-ged.model";
import {TranslateService} from "@ngx-translate/core";
import {GedService} from "../../shared/services/api/ged/ged.service";
import {NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {ToastrService} from "ngx-toastr";
import {takeUntil} from "rxjs/operators";
import Swal from "sweetalert2";

@Component({
  selector: 'app-videos',
  templateUrl: './videos.html',
  styleUrls: ['./videos.scss']
})
export class VideosPage implements OnInit, OnDestroy {

  private _unsubscribeAll: Subject<any> = new Subject();
  private filter: FilterMultiCritereModel = new FilterMultiCritereModel();

  public view: View;
  public activModal: NgbModalRef;
  public data: PieceJointeGed;

  // string
  public type: string = 'VIDEO';

  // tableau
  public datas: PieceJointeGed[];
  public args: any[];
  public pageSizeOption: number[] = [5, 10, 20];

  // number
  public pageSize: number = 10;
  public nbData: number = 0;

  // boolean
  public isLoadingTableVideo: boolean = false;
  public isGedConnexion: boolean = false;

  constructor(private translate: TranslateService,
              private modalSvc: NgbModal,
              private authSvc: AuthenticationService,
              private router: Router,
              private gedService: GedService,
              private toastr: ToastrService) {
    this.authSvc.currentView.subscribe(x => this.view = x);
  }

  ngOnInit(): void {
    if (this.view.hasDroit('LIBRAIRIE_MENU_VIDEO')) {
      this.translate.onLangChange.pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
        if (this.isGedConnexion) {
          this.loadVideosFromGed();
        }
      });
    } else {
      this.router.navigate(['/', 'home']).then(x => x);
    }
  }

  onSubmitFilter(filter = null) {
    // récupère les infos du filtre
    if (filter) {
      this.filter = filter;
    }
    if (this.isGedConnexion) {
      this.loadVideosFromGed();
    }
  }

  onLoadFilter(filter = null) {
    // récupère les infos du filtre
    if (filter) {
      this.filter = {...filter, ...this.filter};
    }
    this.checkConnexionToGed();
  }

  loadVideosFromGed() {
    this.datas = [];
    this.isLoadingTableVideo = true;
    this.filter.clientEspaceClient = true;
    this.filter.type = this.type;
    this.filter.take_picture = false;
    this.gedService.getVideos(this.filter)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(video => {
        this.datas = video;
        this.isLoadingTableVideo = false;
      }, () => {
        this.isLoadingTableVideo = false;
      });
  }

  checkConnexionToGed() {
    this.isGedConnexion = false;
    this.gedService.checkConnexionToGed(this.type)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(connexion => {
        if (connexion['status'] === 'OK') {
          this.isGedConnexion = true;
          if (this.view.context === '1') {
            this.toastr.success(
              this.translate.instant("Connexion à l'api ged", {statut: connexion['status']}),
              this.translate.instant("Informations"),
              {progressBar: true}
            );
          }
          this.loadVideosFromGed();
        } else {
          this.isGedConnexion = false;
          Swal.fire({
            title: this.translate.instant('Désolé'),
            text: this.translate.instant("L'api ged est indisponible") + '. ' + this.translate.instant("Veuillez contacter le service informatique"),
          }).then(() => {
            this.router.navigate(['/', 'home']).then(x => x);
          });
        }
      }, () => {
        this.isGedConnexion = false;
        Swal.fire({
          title: this.translate.instant('Désolé'),
          text: this.translate.instant("L'api ged est indisponible") + ' ' + this.translate.instant("Veuillez contacter le service informatique"),
        }).then(() => {
          this.router.navigate(['/', 'home']).then(x => x);
        });
      });
  }

  openModalAddNewVideo(modalName: any) {
    this.activModal = this.modalSvc.open(modalName, {
      size: 'xl', keyboard: false, backdrop: 'static'
    });
    this.activModal.result.then(() => {
      this.loadVideosFromGed();
    });
  }

  openModalVisualisationVideo(modalName: any, pieceJointe: PieceJointeGed) {
    pieceJointe.SOCIETE = pieceJointe.societe;
    pieceJointe.codeImage = pieceJointe.code_image;
    this.data = undefined;
    this.gedService.getVideo(pieceJointe
    ).pipe(takeUntil(this._unsubscribeAll)).subscribe((singleImage: any) => {
      pieceJointe.images[0].file_content = singleImage[0].file_content;
      this.data = pieceJointe;

    });
    this.activModal = this.modalSvc.open(modalName, {
      size: 'lg',
    });
  }

  onUploaded() {
    this.loadVideosFromGed();
    this.cancel();
  }

  cancel() {
    this.activModal.close();
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(false);
    this._unsubscribeAll.complete();
  }

}
