import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {AgedissError} from '../models/agediss-error.model';
import {AuthenticationService} from './authentication.service';


@Injectable({
  providedIn: 'root'
})
export class LogService {

  constructor(
    private http: HttpClient, private authSvc: AuthenticationService
  ) {
  }

  logError(error: AgedissError) {
    // on ajout l'utilisateur courant et la date
    error.body.user = this.authSvc.currentUserValue;
    error.body.date = new Date();
    this.http.post(environment.url_api_espace_client + '/log/add-error-client', error).subscribe(
      (x) => {
        console.log('erreur envoyée au serveur de log');
      },
      (error) => alert('impossible de logger l\'erreur contacter le service informatique')
    );
  }
}
