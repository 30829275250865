import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import Swal from 'sweetalert2';
import {GrcService} from '../../../shared/services/api/grc.service';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {View} from '../../../shared/models/view.model';
import {AuthenticationService} from '../../../shared/services/authentication.service';
import {arrayPushAndUpdate} from '../../../shared/utils/util';
import {TranslateService} from '@ngx-translate/core';
import {Commande} from "../../../shared/models/api/commande.model";
import {GrcData} from "../../../shared/models/api/grc-data.model";
import {PageEvent} from "@angular/material/paginator";
import {FilterMultiCritereModel} from "../../../shared/models/filters/filterMultiCritere.model";
import {PaginationService} from "../../../shared/services/pagination.service";

@Component({
  selector: 'app-gestion-relation-client',
  templateUrl: './gestion-relation-client.html',
  styleUrls: ['./gestion-relation-client.scss']
})

export class GestionRelationClientPage implements OnInit, OnDestroy {

  private filter: FilterMultiCritereModel = new FilterMultiCritereModel();

  public grc: GrcData;
  public commande: Commande;
  public activModal: NgbModalRef;
  public view: View;

  //number
  public idDoc: number;
  public numeroAvis: string = null;
  public pageSize = 5;
  public nbData: number = 0;

  // tableaux
  public grcsData: GrcData[];
  public subscriptions: Subscription[] = [];
  public subscriptionsUnique: Subscription[] = [];
  public pageSizeOption = [5, 10, 20, 50];

  // loader
  public loadingPage = false;
  public loading: boolean = false;
  public loadingSubmitBtn: Boolean = false;
  public loadingTableGrc: boolean = false;
  public loadingNbGrc: boolean = false;
  public loadingInfosGrc: boolean = false;

  @ViewChild('modal_visualisation_messages') modal_visualisation_messages: ElementRef;

  constructor(private translate: TranslateService,
              private grcSvc: GrcService,
              private router: Router,
              private routeActive: ActivatedRoute,
              private authSvc: AuthenticationService,
              private paginationSvc: PaginationService,
              private modalSvc: NgbModal) {
    this.paginationSvc.defaultFilterPagination(this.filter, this.pageSize)
  }

  ngOnInit() {
    this.authSvc.currentView.subscribe(x => this.view = x);
    // récupération de l'id de la commande
    this.subscriptions.push(this.routeActive.params.subscribe((params) => {

      this.subscriptionsUnique.push(this.routeActive.queryParams.subscribe((queryParams) => {
        this.numeroAvis = queryParams.numeroAvis;
        this.subscriptions.push(this.translate.onLangChange.subscribe(() => {
          this.loadInfosGrc(params.id, this.numeroAvis);
        }));
        this.loadInfosGrc(params.id, this.numeroAvis);

      }));
      this.subscriptionsUnique.forEach(s => s.unsubscribe());

    }));
  }

  /**
   * On récupère les demandes Grc wexlog en fonction du numero Avis
   */
  loadDataGrcs(value) {
    this.loadingTableGrc = true;
    this.loadingNbGrc = true;
    this.grcsData = [];
    this.nbData = 0;
    this.subscriptions.push(this.grcSvc.getDemandesGrcByNumeroAvis(this.filter, value).subscribe(
      (grc) => {
        this.grcsData = grc.data;
        this.nbData = grc.nb;
        this.loadingTableGrc = false;
        this.loadingNbGrc = false;
      },
      () => {
        this.loadingTableGrc = false;
        this.loadingNbGrc = false;
      }));
  }


  /**
   * On affiche les informations de la commande liés aux demandes Grc
   * @param id
   * @param numeroAvis
   */
  loadInfosGrc(id: number, numeroAvis: string) {
    this.loadingPage = true;
    this.subscriptions.push(this.grcSvc.getGrcById(id, numeroAvis).subscribe(
      (grc) => {
        this.grc = grc;
        if(this.grc.numero_avis !== numeroAvis) {
          Swal
            .fire({titleText:  this.translate.instant("La demande GRC n'existe pas")})
            .then(
              () => {
                this.modalSvc.dismissAll();
                this.router.navigate(['/gestion-relation-clients']);
              }
            ).catch(() => {
            Swal.fire({
              title: this.translate.instant('Désolé'),
              text: this.translate.instant('Une erreur est survenue'),
              showCancelButton: false,
              type: 'error',
            });
          });
        }
        this.openModalvisualisationMessages(this.modal_visualisation_messages, this.grc);
        this.loadDataGrcs(this.grc.numero_avis);
        this.loadingPage = false;
      },
      () => {
        Swal
          .fire({text: this.translate.instant("La demande GRC n'existe pas")})
          .then(
            () => {
              this.router.navigate(['/gestion-relation-clients']);
            }
          ).catch(() => {
          Swal.fire({
            title: this.translate.instant('Désolé'),
            text: this.translate.instant('Une erreur est survenue'),
            showCancelButton: false,
            type: 'error',
          });
        });
      }));
  }

  /**
   * On crée une nouvlle demande grc
   * @param modalName
   */
  openModalNouvelleDemande(modalName) {
    this.activModal = this.modalSvc.open(modalName, {
      size: 'lg', keyboard: false
    });
    this.activModal.result.then(() => {
      this.loadDataGrcs(this.numeroAvis);
    });
  }

  /**
   * On affiche la conversion Grc sélectionnée
   * @param modalName
   * @param grc
   */
  openModalvisualisationMessages(modalName, grc: GrcData) {
    this.grc = grc;
    this.activModal = this.modalSvc.open(modalName, {
      size: 'xl', keyboard: false
    });
  }

  /**
   * On affiche la conversion Grc sélectionnée
   * @param modalName
   * @param value
   */
  openModalConversationDemandeGrc(modalName, value: { id: number }) {
    this.activModal = this.modalSvc.open(modalName, {
      size: 'xl', keyboard: false
    });
  }

  openModalVisualisationFile(modalName, grc: GrcData) {
    this.grc = grc
    this.activModal = this.modalSvc.open(modalName, {
      size: 'lg', keyboard: false
    });
  }

  submit(grc) {
    this.grcsData = arrayPushAndUpdate(this.grcsData, grc);
    this.activModal.close();
  }

  ngOnDestroy(){
    this.activModal.dismiss();
    this.subscriptions.forEach(s => s.unsubscribe());
    this.subscriptionsUnique.forEach(s => s.unsubscribe());

  }

  onLoadDataByEvent(event: PageEvent) {
    this.paginationSvc.onPaginationEvent(this.filter, event)
    this.loadDataGrcs(this.numeroAvis);
  }



}
