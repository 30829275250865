import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {HttpUtil} from '../../utils/http.util';

@Injectable({
  providedIn: 'root'
})
export class AdminService {


  urlRessourceAdmin = environment.url_api_espace_client + '/admin';

  constructor(
    private http: HttpClient) {
  }

  downloadFichierIlesEtAltitudes(): Observable<{ filename: string, data: any }> {
    return this.http
      .get(this.urlRessourceAdmin + '/download-iles-et-altitudes',
        {
          responseType: 'blob',
          observe: 'response'
        }
      ).pipe(map(res => {
        return {
          filename: HttpUtil.extractFilenameInContentDisposition(res.headers.get('content-disposition')),
          data: res.body,
        };
      }));
  }

}
