import {Deserializable} from '../interfaces/deserializable.interface';

export class Application implements Deserializable {

  public id: number;
  public libelle?: string;
  public url?: string;
  public logo?: string;
  public description?: string;
  public code?: string;
  public visible?: boolean;
  public created_at?: Date | string;
  public updated_at?: Date | string;
  public deleted_at?: Date | string;

  deserialize(input: any): this {
    return Object.assign(this, input);
  }

}

