<div class="row m-0 pt-3">
  <div class="col-lg-3 col-md-3 p-0">
    <label class="col-lg-12 col-md-12 col-sm-8">{{'Contestataire' | translate }}
      <input type="text"
             [disabled]="true" value="{{contestationData.contestataire | contestatairePipe}}"
             class="input form-control form-control-filter text-truncate" readonly>
    </label>
  </div>
  <div class="col-lg-4 col-md-4 p-0">
    <label class="col-lg-12 col-md-12 col-sm-8 text-truncate">{{'Date de contestation' | translate }}
      <input type="text"
             placement="top"
             container="body"
             ngbTooltip="{{contestationData.updated_at | date:'dd-MM-yyyy'}}"
             value="{{contestationData.updated_at | date:'dd-MM-yyyy'}}"
             class="input form-control form-control-filter text-truncate" readonly>
    </label>
  </div>
  <div class="col-lg-5 col-md-5 p-0">
    <label class="col-lg-12 col-md-12 col-sm-8">{{'Date de maj' | translate }}
      <div class="input-group">
        <input type="text"
               value="{{contestationData.updated_at | date:'dd-MM-yyyy'}}"
               class="input form-control form-control-filter text-truncate" readonly>
        <button class="btn btn-agediss m-l-5" data-style="zoom-out" [ladda]="loadingBtn"
                placement="top"
                container="body"
                ngbTooltip="{{'Sauvegarder' | translate }}"
                [disabled]="enableEditorSaveButton"
                (click)="saveObjetContestation()" style="position: sticky">
          <i class="fa fa-save"></i>
        </button>
      </div>
    </label>
  </div>
</div>
<div class="row m-0">
  <div class="col-md-12 p-0">
    <ckeditor [editor]="Editor"
              [config]="{language:'fr'}"
              (change)="onChange($event)"
              [(ngModel)]="contestationData.objet"></ckeditor>
  </div>
</div>

