<div *ngIf="loading" class="spinner"></div>
<div *ngIf="!loading">
  <div *ngFor="let contact of contacts">
    <div class="row m-0 pt-4">
      <div class="col-12 px-2">
        <p class="text-truncate">
          <strong placement="right"
                  container="body"
                  ngbTooltip="{{contact?.nom}} {{contact?.prenom}}">{{'Suivi par' | translate }} :</strong>
          <span> {{contact?.nom}} {{contact?.prenom}}</span>
        </p>
      </div>
    </div>
    <div class="row m-0">
      <div class="col-12 px-2">
        <p class="text-truncate">
          <strong placement="right"
                  container="body"
                  ngbTooltip="{{contact?.email}}">{{'Email' | translate }} :</strong>
          <span> {{contact?.email}}</span>
        </p>
      </div>
    </div>
    <div class="row m-0">
      <div class="col-md-6 col-12 px-2">
        <p class="text-truncate">
          <strong placement="right"
                  container="body"
                  ngbTooltip="{{contact?.pole_tel_fixe}}">{{'Fixe' | translate }} :</strong>
          <span> {{contact?.pole_tel_fixe}}</span>
        </p>
      </div>
      <div class="col-md-6 col-12 px-2">
        <p class="text-truncate">
          <strong placement="right"
                  container="body"
                  ngbTooltip="{{contact?.pole_fax}}">{{'Fax' | translate }} :</strong>
          <span> {{contact?.pole_fax}}</span>
        </p>
      </div>
    </div>
    <div class="row m-0">
      <div class="col-md-6 offset-0 col-12 px-2">
        <p class="text-truncate">
          <strong placement="right"
                  container="body"
                  ngbTooltip="{{contact?.pole_libelle}}">{{'Pole' | translate }} :</strong>
          <span> {{contact?.pole_libelle}}</span>
        </p>
      </div>
    </div>
  </div>
</div>
