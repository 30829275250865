import {Deserializable} from '../interfaces/deserializable.interface';

export class StatutGrc implements Deserializable{

  public id:number;
  public vu_par: string;
  public code: string;
  public ordre: string;
  public context_base: string;
  public code_maj: string;
  public libelle: string;
  public couleur: string;
  public defaut: string;
  public commentaire: string;
  public actif: string;

  deserialize(input: any): this {
    return Object.assign(this, input);
  }

}
