import {Deserializable} from '../interfaces/deserializable.interface';
import {MessageConversation} from './message-conversation.model';
import {LitigeData} from "./litige-data.model";

export class ConversationData implements Deserializable {
  public id: number;
  public litige_data: LitigeData;
  public message: MessageConversation;
  public context: string;
  public contexte: string;
  public created_at: Date;
  public updated_at: Date;
  public pending: boolean;


  deserialize(input: any): this {
    Object.assign(this, input);
    this.litige_data = (input.litige_data) ? new LitigeData().deserialize(input.litige_data) : null;
    this.message = (input.message) ? new MessageConversation().deserialize(input.message) : null;
    return this;
  }
}
