import {Component, ElementRef, Input, OnInit, Renderer2, ViewChild} from '@angular/core';
import {ContestationData} from "../../../shared/models/api/contestation-data.model";
import {AuthenticationService} from "../../../shared/services/authentication.service";
import {TranslateService} from "@ngx-translate/core";
import {View} from "../../../shared/models/view.model";

@Component({
  selector: 'app-tab-contestation',
  templateUrl: './tab-contestation.component.html',
  styleUrls: ['./tab-contestation.component.scss']
})
export class TabContestationComponent implements OnInit {

  @Input() contestationData: ContestationData;

  public view: View;

  //number
  public widthTab: number;

  //string
  public minWidth: string;

  //array
  public tabs = [];
  public tabsContestation = [];

  @ViewChild('parent') someInput: ElementRef;

  constructor(private authSvc: AuthenticationService,
              public translate: TranslateService,
              private renderer: Renderer2) {
    this.authSvc.currentView.subscribe(x => this.view = x);
  }

  ngOnInit() {
    if(!this.contestationData) {
      throw new Error('Le composent tab-contestation attend un objet de type contestation.')
    }

    this.tabs = [
      {
        code: 'CONTESTATION',
        libelle: this.translate.instant('Contestation'),
        content: this.translate.instant('Informations générales liées à la contestation'),
        droit: 'LITIGE_MENU_CONTESTATION',
      },
      {
        code: 'CONVERSATION',
        libelle: this.translate.instant('Conversation'),
        content: this.translate.instant('Historique des conversations avec votre conseiller Agediss'),
        droit: 'LITIGE_MENU_CONTESTATION',
      },
    ];
    this.tabs.forEach((val) => {
      if(this.view.hasDroit(val.droit)) {
        this.tabsContestation.push(val)
      }
    })
    this.widthTab = 100 / this.tabsContestation.length;
    this.minWidth = this.widthTab + '%';
  }

  ngAfterViewInit() {
    let parent = this.someInput['_elementRef'].nativeElement
    for(let i = 1; i <= this.tabsContestation.length; i++ ) {
      let children = parent['children'][0].querySelector(".mat-tab-label:nth-child(0n+" + i + ")");
      this.renderer.setStyle(children, 'min-width', this.minWidth);
    }
  }

}
