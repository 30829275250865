<div *ngIf="loading" style="height: 500px" class="row col-12">
  <span class="spinner"></span>
</div>
<div *ngIf="!loading">
  <div class="row m-b-30 m-t-2">
    <div class="col-12 px-3">
      <div class="row m-b-30 m-t-2">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 mt-2 p-0">
          <div ng2FileDrop
               [ngClass]="{'nv-file-over': hasBaseDropZoneOver}"
               (fileOver)="fileOverBase($event)"
               [uploader]="uploader"
               class="well my-drop-zone text-center">
            <div class="pt-5">
              {{'Glissez un fichier ici' | translate }}
              <div class="mt-2 text-center">
                <div class="offset-sm-4 col-sm-4 offset-3 col-6 px-0" style="display:flex; flex-direction: row;">
                  <button class="btn btn-radius btn-agediss btn-block text-truncate shadow" (click)="fileInput.click()" >{{ 'Ajouter un ou plusieurs fichiers' | translate}} ...</button>
                  <input #fileInput type="file" (change)="onChange($event)" ng2FileSelect [uploader]="uploader" multiple  style="display:none"/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>{{'* les formats acceptés:' | translate }} {{ defaultExtension }}</div>
      </div>
      <div class="row">
        <div class="col-12 d-none d-lg-block">
          <table class="table">
            <thead>
            <tr>
              <th width="30%">{{'Nom' | translate }}</th>
              <th>{{'Extension' | translate }}</th>
              <th>{{'Taille' | translate }}</th>
              <th>{{'Actions' | translate }}</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let item of uploader.queue;" class="align-middle">
              <td class="with-input-group"><input class="form-control" type="text"
                                                  [(ngModel)]="item.formData.name"/>
              </td>
              <td class="align-middle">{{item.formData.extention}}</td>
              <td class="align-middle">{{ item?.file?.size / 1024 / 1024 | number:'.2' }} MB</td>
              <td nowrap>
                <button type="button" class="btn btn-danger"
                        (click)="item.remove()">
                  <i class="fa fa-trash" ngbTooltip="{{'Retirer' | translate }}"
                     container="body"></i>
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="col-12 d-block d-lg-none">
          <table id="responsive-table" class="table ">
            <tbody *ngFor="let item of uploader.queue;" class="align-middle">
            <tr>
              <td>{{'Nom' | translate }}</td>
              <td class="with-input-group select-name">
                <input class="form-control" type="text" [(ngModel)]="item.formData.name"/>
              </td>
            </tr>
            <tr>
              <td>{{'Extension' | translate }}</td>
              <td class="align-middle">{{item.formData.extention}}</td>
            </tr>
            <tr>
              <td>{{'Taille' | translate }}</td>
              <td class="align-middle">{{ item?.file?.size / 1024 / 1024 | number:'.2' }} MB</td>
            </tr>
            <tr>
              <td>{{'Actions' | translate }}</td>
              <td>
                <button type="button" class="btn btn-danger"
                        (click)="item.remove()">
                  <i class="fa fa-trash" ngbTooltip="{{'Retirer' | translate }}"
                     container="body"></i>
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 m-t-15">
          <div>
            Progression:
            <div class="progress" style="">
              <div class="progress-bar" role="progressbar"
                   [ngStyle]="{ 'width': uploader.progress + '%' }"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
