import {Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {Conversation} from "../../../../shared/models/api/conversation.model";
import {PieceJointeService} from "../../../../shared/services/api/piece-jointe.service";
import {FileService} from "../../../../shared/services/file.service";
import {MatTableDataSource} from "@angular/material/table";
import {MatSort, Sort} from "@angular/material/sort";
import {PieceJointeGed} from "../../../../shared/models/api/ged/piece-jointe-ged.model";
import {tableAnimation} from "../../../../animations";
import {MatPaginator, PageEvent} from "@angular/material/paginator";
import Swal from "sweetalert2";
import {TranslateService} from "@ngx-translate/core";
import {AuthenticationService} from "../../../../shared/services/authentication.service";
import {View} from "../../../../shared/models/view.model";
import {UserData} from "../../../../shared/models/user-data.model";
import {FilterMultiCritereModel} from "../../../../shared/models/filters/filterMultiCritere.model";
import {ToastrService} from "ngx-toastr";
import {Subject, Subscription} from "rxjs";
import {GedService} from "../../../../shared/services/api/ged/ged.service";
import {formatDateAgedissToSort} from "../../../../shared/utils/util";
import {takeUntil} from "rxjs/operators";

@Component({
  selector: 'app-table-ged',
  templateUrl: './table-ged.component.html',
  styleUrls: ['./table-ged.component.scss'],
  animations: [tableAnimation],
})
export class TableGedComponent implements OnInit, OnDestroy {

  private _unsubscribeAll: Subject<any> = new Subject();
  private filter: FilterMultiCritereModel = new FilterMultiCritereModel();

  expandedElement: PieceJointeGed | null;

  @Output() onAdd: EventEmitter<Conversation> = new EventEmitter();
  @Output() onSelectFile: EventEmitter<PieceJointeGed> = new EventEmitter<PieceJointeGed>();
  @Output() paginatorEvent: EventEmitter<PageEvent> = new EventEmitter<PageEvent>()

  @Input() displayedColumns: string[] = [
    'nom',
    'extension',
    'categorie',
    'type_document',
    'created_at',
    'type_image',
    'options',
  ];

  @Input() piecesJointes: PieceJointeGed[];
  @Input() pieceJointe: PieceJointeGed;
  @Input() loading: boolean = false;
  @Input() hiddenColumns: string[] = [];
  @Input() showViewFileBtn: boolean = false;
  @Input() showDownloadFileBtn: boolean = false;
  @Input() showDeleteFileBtn: boolean = false;
  @Input() showEditFileBtn: boolean = false;
  @Input() showInfosBtn: boolean = false;
  @Input() showTitle: boolean = false;
  @Input() isKpi: boolean = false;
  @Input() isVideo: boolean = false;
  @Input() pageSize: number = 10;
  @Input() pageSizeOptions: number[] = [5, 10, 20];

  public innerWidth: number;
  public dataSource: MatTableDataSource<PieceJointeGed>;
  public subscriptions: Subscription[];
  public view: View;
  public currentUser: UserData;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private pieceJointeService: PieceJointeService,
              private translate: TranslateService,
              private authSvc: AuthenticationService,
              private toastr: ToastrService,
              private gedService: GedService) {
    this.authSvc.currentUser.pipe(takeUntil(this._unsubscribeAll)).subscribe(x => this.currentUser = x);
    this.authSvc.currentView.pipe(takeUntil(this._unsubscribeAll)).subscribe(x => this.view = x);
  }

  ngOnInit() {
    this.onResizeTable()
    this.dataSource = new MatTableDataSource<PieceJointeGed>(this.piecesJointes);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    if (this.hiddenColumns.length > 0) {
      this.hiddenColumns.forEach((value) => {
        let index = this.displayedColumns.indexOf(value);
        if (index) {
          this.displayedColumns.splice(index, 1)
        }
      })
    }

  }

  /**
   * LifeHook : à chaque changement de value de data on rafraichit les données du tableau
   * @param changes
   */
  ngOnChanges(changes: any) {
    if (changes.piecesJointes) {
      this.dataSource = new MatTableDataSource(changes.piecesJointes.currentValue);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
    if (changes.loading) {
      if (changes.piecesJointes) {
        this.dataSource = new MatTableDataSource(changes.piecesJointes.currentValue);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
      if (changes.loading) {
        this.loading = changes.loading.currentValue;
      }
    }
  }

  /**
   * Permet d'ordonner les colones.
   */
  sortData(sort: Sort) {
    const data = this.piecesJointes.slice();
    if (!sort.active || sort.direction === '') {
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.sort = this.sort;

      return;
    }
    if (sort.active === 'created_at') {
      const datasourceSort = this.dataSource.data.sort((a, b) => {
        const dateOne = a.date.toString();
        const dateTwo = b.date.toString();

        const formatDate = formatDateAgedissToSort(dateOne, dateTwo);

        if (sort.direction === 'desc') {
          if (new Date(formatDate[0]) < new Date(formatDate[1])) {
            return 1;
          } else {
            return -1;
          }
        } else if (sort.direction === 'asc') {
          if (new Date(formatDate[0]) > new Date(formatDate[1])) {
            return 1;
          } else {
            return -1;
          }
        }

      });
      this.dataSource = new MatTableDataSource(datasourceSort);
    }
  }

  download(element: { id: string; type_image: string; nom: string; }) {
    this.gedService.downloadFileGed(element.id, element.type_image).pipe(takeUntil(this._unsubscribeAll)).subscribe(res => {
        res.filename = element.nom;
        FileService.downloadDataToFile(res);
      },
      FileService.handleDownloadError
    );
  }

  selectFile(file: PieceJointeGed) {
    this.onSelectFile.emit(file);
  }

  delete(id: any) {
    let file = this.piecesJointes.find(x => {
      return x.id == id;
    });
    let index = this.piecesJointes.indexOf(file);
    Swal.fire({
      title: this.translate.instant('Attention !'),
      text: this.translate.instant("Êtes-vous sûr de vouloir supprimer le message {{ value }} ?", {value: this.dataSource.data[index].id}),
      type: 'warning',
      showCancelButton: true,
      cancelButtonText: this.translate.instant('NON'),
      confirmButtonText: this.translate.instant('OUI'),

    }).then((result) => {
      if (result.value) {
        //affiche le chargement
        this.dataSource.data[index].pending = true;
        //service pour supprimer le fichier
        this.gedService.deleteFileGed(id, this.filter).pipe(takeUntil(this._unsubscribeAll)).subscribe(
          (pieceJointe) => {
            this.pieceJointe = pieceJointe;
            this.toastr.info(
              this.translate.instant("La pièce-jointe a été supprimée"),
            );
            this.dataSource.data[index].pending = false;
            this.dataSource.data.splice(index, 1);
            this.dataSource = new MatTableDataSource(this.dataSource.data);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort
          },
          () => {
            this.dataSource.data[index].pending = false;
            this.toastr.error(
              this.translate.instant("La pièce-jointe n'a pas pu être supprimée"),
            );
          },
        );
      }

    });
  }

  @HostListener('window:resize', ['$event'])
  onResizeTable() {
    this.innerWidth = window.innerWidth;
    this.displayedColumns = [];
    if (this.isKpi || this.isVideo) {
      if (this.innerWidth < 600) {
        this.displayedColumns = [
          'nom',
          'extension',
          'options',
        ];
      } else if (this.innerWidth > 600 && this.innerWidth <= 700) {
        this.displayedColumns = [
          'nom',
          'extension',
          'created_at',
          'options',
        ];
      } else if (this.innerWidth > 700 && this.innerWidth <= 900) {
        this.displayedColumns = [
          'nom',
          'extension',
          'created_at',
          'options',
        ];
      } else if (this.innerWidth > 900) {
        this.displayedColumns = [
          'nom',
          'extension',
          'categorie',
          'created_at',
          'options',
        ];
      }
    } else {
      if (this.innerWidth < 600) {
        this.displayedColumns = [
          'nom',
          'extension',
          'options',
        ];
      } else if (this.innerWidth > 600 && this.innerWidth <= 700) {
        this.displayedColumns = [
          'nom',
          'extension',
          'type_document',
          'options',
        ];
      } else if (this.innerWidth > 700 && this.innerWidth < 1200) {
        this.displayedColumns = [
          'nom',
          'extension',
          'type_document',
          'created_at',
          'options',
        ];
      } else if (this.innerWidth > 1200) {
        this.displayedColumns = [
          'nom',
          'extension',
          'type_document',
          'created_at',
          'type_image',
          'options',
        ];
      }
    }
  }

  ngOnDestroy() {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(false);
    this._unsubscribeAll.complete();
  }
}
