<table mat-table [dataSource]="dataSource" (matSortChange)="sortData($event)" multiTemplateDataRows matSort
       matSortActive="created_at" matSortDirection="desc"
       class="table mat-responsive table-hover table-striped-n3 table-bordered mat-elevation-z8 text-center">

  <ng-container matColumnDef="id">
    <th mat-header-cell *matHeaderCellDef mat-sort-header class="px-10">{{'Pièce-jointe' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="px-10 align-middle">
      {{element?.id}}
    </td>
  </ng-container>
  <ng-container matColumnDef="bl">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="px-10">{{'DO' | translate }}</th>
      <td mat-cell *matCellDef="let element" class="px-10 align-middle">
          {{element?.compte_client.libelle}}
      </td>
  </ng-container>
  <ng-container matColumnDef="filename">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Documents' | translate }}</th>
      <td mat-cell *matCellDef="let element" class="px-10 align-middle">
          {{element?.filename}}
      </td>
  </ng-container>
  <ng-container matColumnDef="created_at">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Date' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="align-middle">
      {{element?.created_at | date :'dd-MM-yyyy'}}
    </td>
  </ng-container>
  <ng-container matColumnDef="type_piece_jointe">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Type' | translate }}</th>
      <td mat-cell *matCellDef="let element" class="px-10 align-middle">
          {{element?.type_piece_jointe.libelle}}
      </td>
  </ng-container>
  <ng-container matColumnDef="options">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element; let i = index" class="with-img align-middle text-nowrap">
      <button *ngIf="showViewBtn"
              ngbTooltip="{{'Visualisation des documents' | translate }}"
              placement="top"
              container="body"
              class="btn btn-sm btn-agediss"
              (click)="selectFile(element,i)"><i class="fa fa-eye"></i>
      </button>
      <button *ngIf="showDeleteBtn && element.deletable" [ladda]="element.pending" data-style="zoom-in"
              ngbTooltip="{{'Supprimer le document' | translate }}"
              placement="left"
              container="body"
              class="btn btn-rounded btn-sm btn-agediss ml-1" (click)="delete(element.id)"><i
        class="fa fa-trash"></i></button>
      <button *ngIf="showInfosBtn && this.innerWidth < 900"
              ngbTooltip="{{'Informations complémentaires' | translate }}"
              placement="left"
              container="body"
              class="btn btn-sm btn-agediss ml-1"
              (click)="expandedElement = expandedElement === element ? null : element">
        <i class="fas fa-angle-double-down"></i>
      </button>
    </td>
  </ng-container>
  <ng-container matColumnDef="noData">
    <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length" innerHTML="{{'Aucun document' | translate }}"></td>
  </ng-container>
  <ng-container matColumnDef="loading">
    <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length" innerHTML="{{'Chargement en cours' | translate }} <i class='fas fa-spin fa-circle-notch'></i>"></td>
  </ng-container>

  <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
  <ng-container matColumnDef="expandedDetail">
    <td class="table-td p-0" mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">

      <div class="table-detail"
           *ngIf="this.innerWidth < 550"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description">
          <strong>{{'Pièce-jointe' | translate }} : </strong><span>{{element?.id}}</span>
        </div>
      </div>
      <div class="table-detail"
           *ngIf="this.innerWidth < 460"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description">
          <strong>{{'Date' | translate }} : </strong><span>{{element?.created_at | date :'dd-MM-yyyy'}}</span>
        </div>
      </div>
      <div class="table-detail"
           *ngIf="this.innerWidth < 900"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description">
          <strong>{{'Type' | translate }} : </strong><span>{{element?.type_piece_jointe.libelle}}</span>
        </div>
      </div>
    </td>
  </ng-container>

  <tr mat-header-row style="height: 15px;" *matHeaderRowDef="displayedColumns;  sticky: true"></tr>
  <tr mat-row *matRowDef="let element; columns: displayedColumns;" [hidden]="loading"
      class="table-row overflow-scroll"
      [class.expanded-row]="expandedElement === element">
  </tr>
  <tr mat-footer-row *matFooterRowDef="['noData']" [hidden]="dataSource && dataSource.data.length > 0 || loading"></tr>
  <tr mat-footer-row *matFooterRowDef="['loading']" [hidden]="!loading"></tr>
  <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="table-detail-row"></tr>
</table>

<mat-paginator [length]="length"  [pageIndex]="pageIndex" (page)="emit($event)" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
               showFirstLastButtons></mat-paginator>
