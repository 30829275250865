<form>
  <div class="form-group ">
    <div class="input-group">
      <div class="input-group-prepend"><span class="input-group-text">{{'au' | translate }}</span></div>
      <input name="datepicker"
             ngbDatepicker
             readonly
             class="input form-control form-control-filter text-left"
             placeholder="{{'jj-mm-aaaa' | translate }}"
             #datepicker="ngbDatepicker"
             [autoClose]="true"
             (dateSelect)="onDateSelection($event)"
             [displayMonths]="1"
             autocomplete="off"
             outsideDays="hidden"
             [startDate]="date"
             (click)="openDatePicker()"
             [value]="_parserFormatter.format(date)"
             (input)="date">
      <div class="input-group-append">
        <button class="btn btn-grey-agediss" (click)="openDatePicker()" type="button"><i class="fa fa-calendar"></i>
        </button>
      </div>
    </div>
  </div>
</form>
