import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'firstLetterOfFirstnamePipe'})
export class FirstLetterOfFirstnamePipe implements PipeTransform {
  public transform(value:string): string{
    if (!value) {
      return '';
    } else {
      let firstname = value.substr(0,value.indexOf(' '));
      let lastname = value.substr(value.indexOf(' ')+1);
      return firstname.charAt(0).toUpperCase() + firstname.slice(1) + ' ' + lastname.charAt(0).toUpperCase() + '.'
    }
  }
}


