<table mat-table [dataSource]="dataSorted" (matSortChange)="sortData($event)" multiTemplateDataRows matSort
       class="table mat-responsive table-hover table-striped-n3 table-bordered mat-elevation-z8 text-center">

  <ng-container matColumnDef="reference">
    <th mat-header-cell *matHeaderCellDef mat-sort-header class="px-10">{{'Référence' | translate }}</th>
    <td class="align-middle"
        mat-cell *matCellDef="let element">
      {{element?.reference}}
    </td>
  </ng-container>
  <ng-container matColumnDef="libelle">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Libellé' | translate }}</th>
    <td class="align-middle text-truncate" mat-cell *matCellDef="let element"
        placement="top"
        container="body"
        ngbTooltip="{{element?.libelle}}"
        style="max-width: 100px;">
      {{element?.libelle}}
    </td>
  </ng-container>
  <ng-container matColumnDef="nbColis">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Colis' | translate }}</th>
    <td class="align-middle" mat-cell *matCellDef="let element">
      {{element?.nb_colis }}
    </td>
  </ng-container>
  <ng-container matColumnDef="volume">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Volume' | translate }}</th>
    <td class="align-middle" mat-cell *matCellDef="let element">
      {{element?.volume}} m³
    </td>
  </ng-container>
  <ng-container matColumnDef="poids">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Poids' | translate }}</th>
    <td class="align-middle" mat-cell *matCellDef="let element">
      {{element?.poids}} kg
    </td>
  </ng-container>
  <ng-container matColumnDef="options">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element" class="with-img align-middle text-nowrap">
      <button *ngIf="showViewBtn"
              ngbTooltip="{{'Visualisation de l\'article' | translate }}"
              placement="top"
              container="body"
              class="btn btn-agediss btn-sm btn-rounded ml-1"
              (click)="selectShowArticleId(element.id)">
        <i class="fa fa-eye"></i>
      </button>
      <button *ngIf="showViewBtnArticle"
              ngbTooltip="{{'Visualisation de l\'article' | translate }}"
              placement="top"
              container="body"
              class="btn btn-agediss btn-sm btn-rounded ml-1"
              (click)="onSelectArticle(element)">
        <i class="fa fa-eye"></i>
      </button>
      <button *ngIf="showInfosBtn && ((this.innerWidth > 700 && this.innerWidth <= 767) || (this.innerWidth > 850 && this.innerWidth <= 991) || this.innerWidth <= 1450)"
              ngbTooltip="{{'Informations complémentaires' | translate }}"
              placement="left"
              container="body"
              class="btn btn-agediss btn-sm btn-rounded ml-1 mr-1"
              (click)="expandedElement = expandedElement === element ? null : element">
        <i class="fas fa-angle-double-down"></i>
      </button>
    </td>
  </ng-container>

  <ng-container matColumnDef="noData">
    <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length" innerHTML="{{'Aucun article' | translate }}"></td>
  </ng-container>
  <ng-container matColumnDef="loading">
    <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length" innerHTML="{{'Chargement en cours' | translate }} <i class='fas fa-spin fa-circle-notch'></i>"></td>
  </ng-container>

  <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
  <ng-container matColumnDef="expandedDetail">
    <td class="table-td p-0" mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">

      <div class="table-detail"
           *ngIf="this.innerWidth < 365"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description">
          <strong>{{'Libellé' | translate }} : </strong><span>{{element?.libelle}}</span>
        </div>
      </div>
      <div class="table-detail"
           *ngIf="this.innerWidth < 600 || (this.innerWidth > 991 && this.innerWidth < 1100)"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description">
          <strong>{{'Colis' | translate }} : </strong><span>{{element?.nb_colis}}</span>
        </div>
      </div>
      <div class="table-detail"
           *ngIf="this.innerWidth <= 650 || (this.innerWidth > 991 && this.innerWidth < 1300)"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description">
          <strong>{{'Volume' | translate }} : </strong><span>{{element?.volume}} m³</span>
        </div>
      </div>
      <div class="table-detail"
           *ngIf="this.innerWidth <= 700 || (this.innerWidth > 767 && this.innerWidth < 840) || (this.innerWidth > 991 && this.innerWidth < 1450) "
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description">
          <strong>{{'Poids' | translate }} : </strong><span>{{element?.poids}} kg</span>
        </div>
      </div>
    </td>
  </ng-container>

  <tr mat-header-row style="height: 15px;" *matHeaderRowDef="displayedColumns;  sticky: true"></tr>
  <tr mat-row *matRowDef="let element; columns: displayedColumns;" [hidden]="loading"
      class="table-row overflow-scroll"
      [class.expanded-row]="expandedElement === element">
  </tr>
  <tr mat-footer-row *matFooterRowDef="['noData']" [hidden]="dataSorted && dataSorted.data.length > 0 || loading"></tr>
  <tr mat-footer-row *matFooterRowDef="['loading']" [hidden]="!loading"></tr>
  <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="table-detail-row"></tr>
</table>
