import {Deserializable} from '../interfaces/deserializable.interface';
import {PieceJointeData} from "./piece-jointe-data.model";

export class PieceJointe implements Deserializable {

  public nb?: number;
  public data: PieceJointeData[];

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }

}
