import {Deserializable} from '../interfaces/deserializable.interface';
import {CommandeData} from "./commande-data.model";

export class Commande implements Deserializable {

  public nb?: number;
  public data?: CommandeData[];

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }

}
