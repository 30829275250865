import {Component, ElementRef, EventEmitter, OnInit, Output} from '@angular/core';
import {Subscription} from "rxjs";
import {TranslateService} from "@ngx-translate/core";
import {Router} from "@angular/router";
import {NotificationService} from "../../../../shared/services/api/notification/notification.service";
import {View} from "../../../../shared/models/view.model";
import {FilterMultiCritereModel} from "../../../../shared/models/filters/filterMultiCritere.model";
import {UserData} from "../../../../shared/models/user-data.model";
import {NotificationGrcData} from "../../../../shared/models/api/notification-grc-data.model";
import {PaginationService} from "../../../../shared/services/pagination.service";
import {NotifData} from "../../../../shared/models/api/notif-data.model";

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {

  private filter: FilterMultiCritereModel = new FilterMultiCritereModel();

  @Output() canceled: EventEmitter<void> = new EventEmitter();

  public notifsData: NotifData[];
  public nbData: number = 0;
  public lastNbNotif: number = 0;
  public selectNotif: number = null;
  public selectNotifUrl: string = null;
  public notifsSub: Subscription;
  public showBtn;
  public innerWidth;
  public currentView: View;
  public currentUser: UserData;
  public subscriptions: Subscription[] = [];
  public notificationsGrcData: NotificationGrcData[];

  // number
  public pageSize = 10;

  // loading
  public loadingTableNotification: boolean = false;
  public loading: boolean = false;
  public loadingBtn: boolean = false;

  constructor(private eRef: ElementRef,
              public translate: TranslateService,
              private router: Router,
              private paginationSvc: PaginationService,
              private notificationSvc: NotificationService) {
  }

  ngOnInit() {
    this.getNotif();
  }

  getNotif() {
    this.loadingTableNotification = true;
    const sub = this.notificationSvc.getNotifications(this.filter).subscribe(notifs => {
      this.notifsData = notifs.data;
      this.nbData = notifs.nb
      this.loadingTableNotification = false;
      sub.unsubscribe();
    }, err => {
      this.loadingTableNotification = false;
      sub.unsubscribe();
    });
  }


  /**
   * LifeHook : à chaque changement de value de data on rafraichi les données du tableau
   * @param changes
   */
  ngOnChanges(changes: any) {
    if (changes.notifs) {
      this.notifsData = changes.notifs.currentValue;
    }
  }


  checkNotif(value: { id: number, index: number }) {
    this.selectNotif = value.id;
    this.notifsData[value.index].viewed = !this.notifsData[value.index].viewed;
    this.notifsData = this.notifsData.slice();
    this.notificationSvc.checkNotif(this.selectNotif).subscribe(() => {
    });
  }

  goTo(value: { url: string, id: number, index: number }) {
    this.selectNotifUrl = value.url;
    this.router.navigateByUrl(this.selectNotifUrl);
    this.selectNotif = value.id;
    this.notifsData[value.index].viewed = !this.notifsData[value.index].viewed;
    this.notifsData = this.notifsData.slice();
    this.notificationSvc.checkNotif(this.selectNotif).subscribe(() => {
    });
    this.cancel();
  }

  checkAllNotif() {
    let notificationsId = [];
    this.showBtn = !this.showBtn;
    this.notifsData.forEach(val => {
      if (val.viewed !== true) {
        val.viewed = true;
        notificationsId.push(val.id);
        this.notifsData = this.notifsData.slice();
      } else {
        val.viewed = false;
        notificationsId.push(val.id);
        this.notifsData = this.notifsData.slice();
      }
    });
    this.notificationSvc.checkAllNotif(notificationsId).subscribe(() => {
      this.notifsData = this.notifsData.slice();
    });
  }

  removeAllNotif() {
    let notificationsId = [];
    this.loadingBtn = true;
    this.notifsData.forEach(val => {
      val.viewed = true;
      notificationsId.push(val.id);
    });
    this.notifsSub = this.notificationSvc.removeAllNotif(notificationsId).subscribe(() => {
        this.notifsData = [];
        this.loadingBtn = false;
      }
    );
  }

  cancel() {
    this.canceled.emit();
  }

}
