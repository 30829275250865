import {Component, Input, OnInit} from '@angular/core';
import {LitigeService} from '../../../../shared/services/api/litige.service';
import {Article} from '../../../../shared/models/api/article.model';
import {Subscription} from 'rxjs';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {View} from '../../../../shared/models/view.model';
import {AuthenticationService} from '../../../../shared/services/authentication.service';
import {TranslateService} from "@ngx-translate/core";
import {Commande} from "../../../../shared/models/api/commande.model";
import {LitigeData} from "../../../../shared/models/api/litige-data.model";

@Component({
  selector: 'app-litige-infos-content',
  templateUrl: './litige-infos-content.html',
  styleUrls: ['./litige-infos-content.scss']
})
export class LitigeInfosContent implements OnInit {

  @Input() litigeData: LitigeData;
  @Input() commande: Commande;

  public selectArticle: number = null;
  public activModal: NgbModalRef;
  public article: Article;

  // LOADER
  public loadingPage = false;
  public loadingTableArticle = false;

  // TABLEAUX
  public articles: Article[];
  public subscriptions: Subscription[] = [];
  public view: View;

  constructor(private translate: TranslateService,
              private litigeSvc: LitigeService,
              private authSvc: AuthenticationService,
              private modalSvc: NgbModal) { }

  ngOnInit() {
    if(!this.litigeData) {
      throw new Error('Le composent litige-infos-content attend un objet de type litige.')
    }
    this.authSvc.currentView.subscribe(x => this.view = x);
    this.subscriptions.push(this.translate.onLangChange.subscribe(() => {
      this.loadDataArticle();

    }))
    this.loadDataArticle();
  }

  /**
   * On recupère tous les articles
   */
  loadDataArticle() {
    this.loadingTableArticle = true;
    this.subscriptions.push(this.litigeSvc.getArticles(this.litigeData.id).subscribe(articles => {
      this.articles = articles;
      this.loadingTableArticle = false;
      },
      (error) => {
        this.loadingTableArticle = false;
      }
    ));
  }


  // MODALS

  /**
   * On affiche tous les documents en lien avec le litige
   * @param modalName
   * @param value
   */
  openModalShowDocuments(modalName, value: { id: number }) {
    this.activModal = this.modalSvc.open(modalName, {
      size: 'lg', keyboard: false
    });
  }

  /**
   * On affiche les informations d'un article dans la modal
   */
  openModalShowArticle(modalName, value: { id: number }) {
    this.selectArticle = value.id;
    this.activModal = this.modalSvc.open(modalName, {
      size: 'md', keyboard: false
    });
    this.activModal.result.then(
      (result) => {
        this.loadDataArticle();
      }, reason => {
      });
  }
}
