import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'contextePipe'})
export class ContextePipe implements PipeTransform {
  transform(value: string, exponent?: number): string {
    switch(value) {
      case 'DO|LI':
        return 'LIT <=> DO';
      case 'LI|DO':
        return 'LIT <=> DO';
      case 'PF|LI':
        return 'LIT <=> PF';
      case 'LI|PF':
        return 'LIT <=> PF';
    }
  }
}
