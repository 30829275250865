import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {Litige} from '../../../../shared/models/api/litige.model';
import {View} from '../../../../shared/models/view.model';
import {AuthenticationService} from '../../../../shared/services/authentication.service';
import {TranslateService} from "@ngx-translate/core";
import {Commande} from "../../../../shared/models/api/commande.model";
import {CommandeService} from "../../../../shared/services/api/commande.service";
import {FilterMultiCritereModel} from "../../../../shared/models/filters/filterMultiCritere.model";
import {LitigeData} from "../../../../shared/models/api/litige-data.model";
import {PageEvent} from "@angular/material/paginator";
import {CommandeData} from "../../../../shared/models/api/commande-data.model";
import {PaginationService} from "../../../../shared/services/pagination.service";


@Component({
  selector: 'app-litige-content',
  styleUrls: ['./litige-content.scss'],
  templateUrl: './litige-content.html'
})

export class LitigeContent implements OnInit, OnDestroy {

  @Input() commande: CommandeData;
  @Input() litige: LitigeData;
  @Input() commandeId: number;
  @Input() litigeNumeroAvis: string;

  public filter: FilterMultiCritereModel = new FilterMultiCritereModel();

  //Tableau
  public pageSizeOption = [5, 10, 20];
  public litiges: Litige[];
  public litigesData: LitigeData[];
  public subscriptions: Subscription[] = [];

  // number
  public pageSize = 10;
  public nbData: number = 0;

  //loading
  public loadingTableLitige = false;
  public loadingPage = false;
  public loadingNbLitiges = false;

  public view: View;

  constructor(private translate: TranslateService,
              private commandeSvc: CommandeService,
              private paginationSvc: PaginationService,
              private authSvc: AuthenticationService) {
    this.paginationSvc.defaultFilterPagination(this.filter, this.pageSize)
  }

  ngOnInit() {
    this.authSvc.currentView.subscribe(x => this.view = x);
    this.subscriptions.push(this.translate.onLangChange.subscribe(() => {
      this.loadDataLitiges();
    }))
    this.loadDataLitiges();
  }

  loadDataLitiges() {
    this.loadingTableLitige = true;
    this.loadingNbLitiges = true;
    this.litigesData= [];
    this.nbData = 0;
    this.subscriptions.push(this.commandeSvc.getLitigesByNumeroAvis(this.filter, this.litigeNumeroAvis).subscribe((litige) => {
      this.litigesData= litige.data;
      this.nbData = litige.nb;
      this.loadingTableLitige = false;
      this.loadingNbLitiges = false;
      },
      (error) => {
        this.loadingTableLitige = false;
        this.loadingNbLitiges = false;
      }
    ));
  }

  onLoadDataGrcByEvent(event: PageEvent) {
    this.filter.limit = event.pageSize
    this.filter.offset = event.pageIndex ? event.pageIndex * event.pageSize : 0
    this.loadDataLitiges();
  }

  ngOnDestroy(){
    this.subscriptions.forEach(s => s.unsubscribe());
  }


}
