import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";

@Pipe({name: 'regex'})
export class RegexPipe implements PipeTransform {
  transform(input: string, exponent?: number): string {
    // const re = /\n$/;
    const re = /^(\n)+|(\n)+$/g;
    return input.replace(re, '' )
  }
}
