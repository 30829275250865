import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {NgbModal, NgbModalConfig, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {PieceJointeService} from '../../../../shared/services/api/piece-jointe.service';
import {Subscription} from 'rxjs';
import {LitigeService} from '../../../../shared/services/api/litige.service';
import {ContestationService} from '../../../../shared/services/api/contestation.service';
import Swal from 'sweetalert2';
import {FileService} from '../../../../shared/services/file.service';
import {View} from '../../../../shared/models/view.model';
import {AuthenticationService} from '../../../../shared/services/authentication.service';
import {GrcService} from '../../../../shared/services/api/grc.service';
import {TranslateService} from '@ngx-translate/core';
import {PieceJointeGed} from "../../../../shared/models/api/ged/piece-jointe-ged.model";
import {Evenement} from "../../../../shared/models/api/evenement.model";
import {PieceJointeData} from "../../../../shared/models/api/piece-jointe-data.model";

@Component({
  selector: 'app-modal-documents',
  templateUrl: './modal-documents.component.html',
})
export class ModalDocumentsComponent implements OnInit, OnChanges, OnDestroy {


  @Input() litigeId: number;
  @Input() contestationId: number;
  @Input() grcId: number;
  @Input() commandeId: number;
  @Input() configModal: { backdrop: string, keyboard: string };
  @Input() public evenement: Evenement;

  @Output() canceled: EventEmitter<void> = new EventEmitter();

  public loading: boolean = false;
  public loadingTable = false;
  public activModal: NgbModalRef;
  public piecesJointes: PieceJointeData[];
  public pieceJointe: PieceJointeData;
  public piecesJointesGed: PieceJointeGed[];
  public pieceJointeGed: PieceJointeGed
  public pieceJointeSub: Subscription;
  public loadPreview: boolean = false;
  public loadingFile: boolean = false;
  public view: View;
  public showImage: boolean;
  public urlPreview: string;
  public currentType : number =  null;
  public availableFormat = [
    'image/png',
    'image/jpeg',
    'application/pdf'
  ];

  constructor(private translate: TranslateService,
              private modalSvc: NgbModal,
              private config: NgbModalConfig,
              private authSvc: AuthenticationService,
              private fileSvc: PieceJointeService,
              private litigeSvc: LitigeService,
              private contestationSvc: ContestationService,
              private grcSvc: GrcService) {
    if (this.configModal) {
      Object.assign(this.config, this.configModal);
    }
  }

  ngOnInit() {
    if (!this.litigeId && !this.contestationId) {
      throw new Error('Vous devez passer un ID de LITIGE ou de CONTESTATION  ou de DEMANDES GRC en paramètre du composant \'app-modal-document\'.');
    }

    if (this.litigeId && this.contestationId) {
      throw new Error('Vous devez passer seulement UN  "id" de litige OU de contestation ou de demandes Grc en paramètre du composant \'app-modal-document\',');
    }
    this.authSvc.currentView.subscribe(x => this.view = x);
    this.getData();
  }

  openModalUpload(modal) {
    this.activModal = this.modalSvc.open(modal, {
      size: 'xl', keyboard: false
    });
  }

  openModalVisualisation(modal, selectFile: { id: number, filename: string, path: string }) {
    this.pieceJointe = new PieceJointeData().deserialize({id: selectFile.id, libelle: selectFile.filename, path: selectFile.path});
    this.activModal = this.modalSvc.open(modal, {
      size: 'xl',
    });
    this.showPieceJointe(selectFile.id)
  }

  showPieceJointe(pieceJointeId) {
    this.loadingFile = true;
    if(this.pieceJointe.path.startsWith('../var/')) {
      const sub1 = this.fileSvc.getFilePreview(pieceJointeId).subscribe(
        (urlPreview) => {
          this.currentType =  this.availableFormat.indexOf(urlPreview.type);
          if (this.availableFormat.indexOf(urlPreview.type) >= 0) {
            this.showImage = true;
          }
          this.urlPreview = window.URL.createObjectURL(urlPreview);
          this.loadingFile = false;
          sub1.unsubscribe()
        },
        () => {
          Swal.fire({
            title: this.translate.instant('Désolé'),
            text: this.translate.instant('Une erreur est survenue'),
            showCancelButton: false}
          ).then(() => {
            this.activModal.close();
            this.loadingFile = false;
            sub1.unsubscribe();
          });
        }
      );
    } else {
      const sub = this.fileSvc.getFilePreviewGed(pieceJointeId).subscribe(
        (piecesJointesGed) => {
          this.piecesJointesGed = piecesJointesGed
          this.loadingFile = false;
          sub.unsubscribe()
        },
        () => {
          Swal.fire({
            title: this.translate.instant('Désolé'),
            text: this.translate.instant('Une erreur est survenue'), showCancelButton: false}).then(() => {
            this.activModal.close();
            this.loadingFile = false;
            sub.unsubscribe();
          });

        }
      );
    }

  }

  openModalVisualisationFileGed(modalName, pieceJointe: PieceJointeGed) {
    this.pieceJointeGed = pieceJointe
    this.activModal = this.modalSvc.open(modalName, {
      size: 'lg',
    });
  }

  onUploaded(bool) {
    if (bool) {
      this.refreshData();
    }
    this.activModal.close();
  }

  refreshData() {
    this.getData();
  }

  getData() {
    this.loadingTable = true;
    if (this.litigeId) {
      this.pieceJointeSub = this.litigeSvc.getPieceJointes(this.litigeId).subscribe(
        (files) => {
          this.loadingTable = false;
          this.piecesJointes = files.data;
        }, () => {
          this.loadingTable = false;
        }
      );
    } else if (this.contestationId) {
      this.pieceJointeSub = this.contestationSvc.getPieceJointes(this.contestationId).subscribe(
        (files) => {
          this.loadingTable = false;
          this.piecesJointes = files.data;
        }, () => {
          this.loadingTable = false;
        }
      );
    } else {
      this.pieceJointeSub = this.grcSvc.getPieceJointes(this.grcId).subscribe(
        (files) => {
          this.loadingTable = false;
          this.piecesJointes = files.data;
        }, () => {
          this.loadingTable = false;
        }
      );
    }
  }

  ngOnDestroy(): void {
    this.pieceJointeSub.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.litigeId) {
      this.litigeId = changes.litigeId.currentValue;
    }
    if (changes.commandeId) {
      this.commandeId = changes.commandeId.currentValue;
    }
  }

  download(fileId) {
    this.fileSvc.downloadFile(fileId).subscribe(res => {
        FileService.downloadDataToFile(res);
      },
      FileService.handleDownloadError
    );
  }

  cancel() {
    this.canceled.emit();
  }

}
