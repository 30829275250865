import { Injectable } from '@angular/core';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Extraction} from "../../models/api/extraction.model";
import {HttpUtil} from "../../utils/http.util";

@Injectable({
  providedIn: 'root'
})
export class ExtractionService {

  urlRessource = environment.url_api_espace_client + '/extractions';

  constructor(private http: HttpClient) {

  }

  /**
   * retourne toutes les extractions
   */
  getExtractions(): Observable<Extraction[]> {

    return this.http
      .get<Extraction[]>(this.urlRessource)
      .pipe(map((extractions) => extractions.map((extraction) => {
        return new Extraction().deserialize(extraction);
      })));
  }

  /**
   * retourne les tableaux des extractions
   */
  getDataExtractions(filter: any = null): Observable<Extraction> {
    return this.http
      .get<Extraction>(`${this.urlRessource}/data-extraction`, {params: HttpUtil.convertObjectToHttpParams(filter)})
      .pipe(map(function (extraction) {
        return new Extraction().deserialize(extraction);
      }));
  }

}
