import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Conversation} from '../../../../../shared/models/api/conversation.model';
import {ConversationService} from '../../../../../shared/services/api/conversation.service';
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-show-conversation',
  templateUrl: './show-conversation.component.html',
  styleUrls: ['./show-conversation.component.scss']
})
export class ShowConversationComponent implements OnInit {

  @Output() canceled: EventEmitter<void> = new EventEmitter();

  public conversation: Conversation;
  public activModal: NgbModalRef;
  @Input() conversationId: number;
  @Input() modalTitleClass: string = "text-center";
  @Input() modalHeaderClass: string;

  constructor(private conversationSvc: ConversationService) { }

  ngOnInit() {
    if (!this.conversationId) {
      throw new Error('Vous devez passer un id de conversation en parametre du composant \'app-modal-conversation\'.');
    }
    const sub = this.conversationSvc.getConversation(this.conversationId).subscribe(conversation=>{
      this.conversation = conversation;
    },()=>{
      this.activModal.close();
    })
  }

  cancel() {
    this.canceled.emit()
  }

}
