import {Component, OnDestroy} from '@angular/core';
import pageSettings from '../../../config/page-settings';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-page503',
  templateUrl: './page503.html',
  styleUrls: ['./page503.scss']
})
export class Page503Page implements OnDestroy {

  pageSettings = pageSettings;

  dateFin: string = 'indéterminée';


  constructor() {
    this.pageSettings.pageEmpty = true;
  }

  ngOnDestroy() {
    this.pageSettings.pageEmpty = false;
  }

}
