<div *ngIf="loadingPage" class="spinner"></div>
<div *ngIf="!loadingPage">
  <div class="row m-0">
    <div class="col-md-12 p-10">
      <p class="text-truncate">
        <strong placement="right"
                container="body"
                ngbTooltip="{{contestationData.litige?.destinataire_nom}}">{{'Destinataire' | translate }} :</strong>
        <span> {{contestationData.litige?.destinataire_nom}}</span>
      </p>
      <p class="text-truncate">
        <strong placement="right"
                container="body"
                ngbTooltip="{{contestationData.litige?.destinataire_adresse  | uppercase}}
              {{contestationData.litige?.destinataire_code_postal}}
              {{contestationData.litige?.destinataire_ville | uppercase}}">{{'Adresse' | translate }} :</strong>
        <span> {{contestationData.litige?.destinataire_adresse  | uppercase}}
           {{contestationData.litige?.destinataire_code_postal}}
           {{contestationData.litige?.destinataire_ville | uppercase}}
        </span>
      </p>
    </div>
  </div>
</div>
