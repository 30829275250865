<div *ngIf="loadingPage" class="spinner"></div>
<div *ngIf="!loadingPage">
  <div class="row m-0 py-2 px-2">
    <h1
      class="page-header text-center m-auto padding-h1">{{'Historique des demandes GRC' | translate }}</h1>
  </div>
  <div class="row m-0 padding-tab">
    <div class="col-md-12 col-lg-6 pt-4 m-0">
      <panel variants="danger" title="{{'Destinataire' | translate }}" noButton="true"
             bodyClass="no-padding panel"
             class="text-center bg-primary shadow">
        <div class="scrollbar scrollbar-black bordered-black square thin overflow-scroll text-left"
             style="min-height: 140px">
          <app-destinataire-gestion-relation-clients [grc]="grc"
                                                     [loading]="loadingInfosGrc"></app-destinataire-gestion-relation-clients>
        </div>
      </panel>
    </div>
    <div class="col-md-12 col-lg-6 pt-4 m-0">
      <panel variants="danger" title="{{'Informations de la commande' | translate }}"
             noButton="true" bodyClass="no-padding panel"
             class="text-center">
        <div class="scrollbar scrollbar-black bordered-black square thin overflow-scroll text-left py-3"
             style="min-height: 140px">
          <app-infos-commande [grc]="grc" [loading]="loadingInfosGrc"
                              [showViewCommandeBtn]="view.hasDroit('COMMANDE_SHOW')"></app-infos-commande>
        </div>
      </panel>
    </div>
  </div>
  <div class="row m-0 padding-tab">
    <div class="col-sm-12 py-4 m-0">
      <panel noButton="true" bodyClass="no-padding panel">
        <div class="row" header>
          <div class="col-xl-10 col-sm-8 col-12 title-table"><h5
            class="p-t-5 text-agediss">{{'Gestion relation client' | translate }} : <span
            *ngIf="!loadingNbGrc">{{nbData}}</span> <i *ngIf="loadingNbGrc" class='fas fa-spin fa-circle-notch'></i>
          </h5></div>
          <div *ngIf="view.hasDroit('GRC_CREATE')"
               class="offset-xl-0 col-xl-2 offset-sm-0 col-sm-4 offset-2 col-8">
            <button class="btn btn-radius btn-agediss btn-block text-truncate shadow"
                    (click)="openModalNouvelleDemande(modal_nouvelle_demande)"
            >{{'Nouvelle demande GRC' | translate }}
            </button>
          </div>
        </div>
        <div>
          <app-table-grc-by-commande [data]="grcsData"
                                     [length]="nbData" [pageSize]="pageSize"
                                     (paginatorEvent)="onLoadDataByEvent($event)" [pageSizeOptions]="pageSizeOption"
                                     [loading]="loadingTableGrc" [showInfosBtn]="true"
                                     (selectConversation)="openModalvisualisationMessages(modal_visualisation_messages, $event)"
                                     (onSelectFile)="openModalVisualisationFile(modal_visualisation_file, $event)"
                                     [showDeleteDemandGrcBtn]="view.hasDroit('GRC_DELETE')"
                                     [showViewMessageBtn]="view.hasDroit('GRC_CONVERSATION_SHOW')"
                                     [showFileBtn]="view.hasDroit('GRC_PIECE-JOINTE_SHOW')">
          </app-table-grc-by-commande>
        </div>
      </panel>
    </div>
  </div>
</div>

<!-- modal Nouvelle demande -->
<ng-template #modal_nouvelle_demande let-modal>
  <app-nouvelle-demande [grcNumeroAvis]="grc.numero_avis" (submited)="submit($event)"
                        (canceled)="modal.close($event)"></app-nouvelle-demande>
</ng-template>
<!-- fin de modal Nouvelle demande -->

<!-- modal visualisation messages -->
<ng-template #modal_visualisation_messages let-modal>
  <app-visualisation-demande-grc [grcData]="grc" (submited)="modal.close($event)" [loading]="loading"
                                 (canceled)="modal.close($event)"></app-visualisation-demande-grc>
</ng-template>
<!-- fin de modal visualisation messages -->

<ng-template #modal_visualisation_file let-modal>
  <app-file-preview-ged [piecesJointesGed]="grc.doc" [doc]="true" (canceled)="modal.close($event)"></app-file-preview-ged>
</ng-template>
