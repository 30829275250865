<div>
  <div class="modal-header">
    <h4 class="modal-title  w-100 text-center">{{'Notifications' | translate }}
    </h4>
  </div>
  <div class="modal-body">
    <app-table-notification
      [data]="notifsData"
      [loading]="loadingTableNotification"
      [showBtn]="showBtn"
      [loadingBtn]="loadingBtn"
      (selectCheckNotif)="checkNotif($event)"
      (selectCheckAllNotif)="checkAllNotif()"
      (onGoToUrl)="goTo($event)"
      (selectDeleteAllNotif)="removeAllNotif()"
      [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 20, 50, 100]">
    </app-table-notification>
  </div>
  <div class="modal-footer p-5">
    <button class="btn bg-black-lighter text-white m-r-10" (click)="cancel()">{{'Fermer' | translate }}</button>
  </div>
</div>
