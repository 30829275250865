import {Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, ViewChild} from '@angular/core';
import {MatSort, Sort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {DatatableHelper} from '../../../../shared/helpers/datatable.helper';
import {tableAnimation} from '../../../../animations';
import {MatPaginator, PageEvent} from "@angular/material/paginator";
import {ContestationData} from "../../../../shared/models/api/contestation-data.model";

@Component({
  selector: ' app-table-contestation-litige',
  templateUrl: './table-contestation-litige.component.html',
  styleUrls: ['./table-contestation-litige.component.scss'],
  animations: [tableAnimation],
})
export class TableContestationLitigeComponent implements OnInit, OnChanges {

  expandedElement: ContestationData | null;

  @Output() onSelectConverstation: EventEmitter<number> = new EventEmitter<number>();
  @Output() onAddContestation: EventEmitter<void> = new EventEmitter<void>();
  @Output() onSorted: EventEmitter<Sort> = new EventEmitter();

  @Input() data: ContestationData[];
  @Input() loading = false;
  @Input() showAddButton: boolean = false;
  @Input() showTitle: boolean = false;
  @Input() showInfosBtn: boolean = false;
  @Input() showViewBtn: boolean = false;
  @Input() length: number;
  @Input() pageSize: number = 10;
  @Input() pageIndex: number;
  @Input() pageEvent: PageEvent;
  @Input() pageSizeOptions: number[] = [5, 10, 20];
  @Input() displayedColumns: string[] = [
    'id',
    'numero_avis',
    'numero_commande',
    'created_at',
    'destinataire_nom',
    'client',
    'statut',
    'traite_par',
    'options',
  ];

  @Output() paginatorEvent : EventEmitter<PageEvent> = new EventEmitter<PageEvent>()

  public innerWidth: number;
  public dataSource: MatTableDataSource<ContestationData>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor() {}

  ngOnInit() {
    this.dataSource = new MatTableDataSource(this.data);
    this.onResizeTable();
  }

  emit($event: PageEvent) {
    return this.paginatorEvent.emit($event);
  }

  /**
   * LifeHook : à chaque changement de value de data on rafraichi les données du tableau
   * @param changes
   */
  ngOnChanges(changes: any) {
    if (changes.data) {
      this.dataSource = new MatTableDataSource(changes.data.currentValue);
    }
    if (changes.loading) {
      if (changes.data) {
        this.dataSource = new MatTableDataSource(changes.data.currentValue);
      }
      if (changes.loading) {
        this.loading = changes.loading.currentValue;
      }
    }
  }

  selectAddContestation() {
    this.onAddContestation.emit()
  }

  /**
   * Permet d'ordonner les colones.
   */
  sortData(sort: Sort) {
    const data = this.data.slice();
    if (!sort.active || sort.direction === '') {
      this.dataSource = new MatTableDataSource(data);
      return;
    }
    this.dataSource = new MatTableDataSource(data.sort(
      (a, b) => {
        const isAsc = sort.direction === 'asc';
        if (sort.active in a) {
          return DatatableHelper.compare(a[sort.active], b[sort.active], isAsc);
        }
        return 0;
      }));
  }

  @HostListener('window:resize', ['$event'])
  onResizeTable() {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth < 400) {
      this.displayedColumns = [
        'numero_avis',
        'options'
      ];
    } if (this.innerWidth > 400 && this.innerWidth <= 511) {
      this.displayedColumns = [
        'numero_avis',
        'statut',
        'options'
      ];
    }else if (this.innerWidth > 511 && this.innerWidth <= 600) {
      this.displayedColumns = [
        'numero_avis',
        'numero_commande',
        'statut',
        'options'
      ];
    } else if (this.innerWidth > 600 && this.innerWidth <= 767) {
      this.displayedColumns = [
        'numero_avis',
        'numero_commande',
        'destinataire_nom',
        'statut',
        'options'
      ];
    } else if (this.innerWidth > 767 && this.innerWidth <= 790) {
      this.displayedColumns = [
        'numero_avis',
        'numero_commande',
        'statut',
        'options'
      ];
    } else if (this.innerWidth > 790 && this.innerWidth <= 1040) {
      this.displayedColumns = [
        'numero_avis',
        'numero_commande',
        'destinataire_nom',
        'statut',
        'options'
      ];
    } else if (this.innerWidth > 1040 && this.innerWidth <= 1050) {
      this.displayedColumns = [
        'numero_avis',
        'numero_commande',
        'destinataire_nom',
        'created_at',
        'statut',
        'options'
      ];
    } else if (this.innerWidth > 1050 && this.innerWidth <= 1200) {
      this.displayedColumns = [
        'numero_avis',
        'numero_commande',
        'destinataire_nom',
        'created_at',
        'client',
        'statut',
        'options'
      ];
    } else if (this.innerWidth > 1200) {
      this.displayedColumns = [
        'numero_avis',
        'numero_commande',
        'destinataire_nom',
        'created_at',
        'client',
        'statut',
        'traite_par',
        'options'
      ];
    }
  }

}
