<table mat-table [dataSource]="dataSource" (matSortChange)="sortData($event)" multiTemplateDataRows matSort
       matSortActive="id" matSortDirection="desc"
       class="table mat-responsive table-hover table-striped-n3 table-bordered mat-elevation-z8 text-center">

  <ng-container matColumnDef="id">
    <th mat-header-cell *matHeaderCellDef mat-sort-header class="px-10">{{'Litige' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="align-middle">
      <a [routerLink]="['/','litiges',element.id]" class="btn btn-icon btn-info">
        {{element?.id}}
      </a>
    </td>
  </ng-container>
  <ng-container matColumnDef="numero_avis">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Bon de livraison' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="align-middle">
      {{element?.numero_avis}}
    </td>
  </ng-container>
  <ng-container matColumnDef="date_litige">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Date' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="align-middle">
      {{element?.date_litige  | date:'dd-MM-yyyy'}}
    </td>
  </ng-container>
  <ng-container matColumnDef="client">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Client' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="align-middle">
      {{element?.client?.libelle}}
    </td>
  </ng-container>
  <ng-container matColumnDef="numero_commande">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Commande' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="align-middle">
      {{element?.numero_commande}}
    </td>
  </ng-container>
  <ng-container matColumnDef="traite_par">
    <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center">
      <span><i class="fa fa-user"></i></span>
    </th>
    <td mat-cell *matCellDef="let element" class="align-middle">
      {{element?.traite_par?.getFullname()}}
    </td>
  </ng-container>
  <ng-container matColumnDef="destinataire_nom">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Destinataire' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="align-middle">
      {{element?.destinataire_nom | titlecase}}
    </td>
  </ng-container>
  <ng-container matColumnDef="plateforme">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Plateforme' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="align-middle">
      {{element?.plateforme?.libelle | titlecase}}
    </td>
  </ng-container>
  <ng-container matColumnDef="type_approche">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Approche' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="align-middle">
      {{element?.type_approche?.libelle | titlecase}}
    </td>
  </ng-container>
  <ng-container matColumnDef="statut">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Statut' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="align-middle text-white">
      <ng-template #statutLitige>
        <div class="p-0">
          <span *ngIf="element?.statut?.code === 'LIT_1'">{{'Litige refusé par Agediss' | translate }}</span>
          <span *ngIf="element?.statut?.code === 'LIT_3'">{{'Le litige sera pris en charge par Agediss' | translate }}</span>
          <span *ngIf="element?.statut?.code === 'LIT_5'">{{'Litige pris en charge par Agediss' | translate }}</span>
          <span *ngIf="element?.statut?.code === 'LIT_0'">{{'Litige à traiter par Agediss' | translate }}</span>
          <span *ngIf="element?.statut?.code === 'LIT_6'">{{'Litige en attente d\'aprobation par Agediss' | translate }}</span>
          <span *ngIf="element?.statut?.code === 'LIT_2'">{{'Litige en cours d\'analyse par Agediss' | translate }}</span>
        </div>
        <div class="p-0 mt-2">
          <span *ngIf="element?.statut?.code === 'LIT_1'">{{ element?.commentaire_statut | titlecase }}</span>
        </div>
      </ng-template>
      <div placement="left"
           container="body"
           [ngbPopover]="statutLitige" triggers="mouseenter:mouseleave">
           <span *ngIf="element?.date_prescrit" class="label label-dark mr-2">
             {{'Prescrit' | translate}}
           </span>
           <span class="label {{ element?.statut.libelle | statutLitigePipe: element?.statut.code}}">
             <span *ngIf="element?.statut?.code === 'LIT_0'">{{'A traiter' | translate }}</span>
             <span *ngIf="element?.statut?.code === 'LIT_1'">{{'Refusé' | translate }}</span>
             <span *ngIf="element?.statut?.code === 'LIT_2'">{{"En cours d'analyse" | translate }}</span>
             <span *ngIf="element?.statut?.code === 'LIT_3'">{{'Eligible' | translate }}</span>
             <span *ngIf="element?.statut?.code === 'LIT_5'">{{'Validé' | translate }}</span>
             <span *ngIf="element?.statut?.code === 'LIT_6'">{{"En attente d'approbation" | translate }}</span>
           </span>
      </div>
    </td>
  </ng-container>
  <ng-container matColumnDef="flux">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Flux' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="align-middle">
      {{element?.flux?.libelle | titlecase}}
    </td>
  </ng-container>
  <ng-container matColumnDef="entite">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Entité' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="align-middle">
      {{element?.entite | titlecase}}
    </td>
  </ng-container>
  <ng-container matColumnDef="evenement">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Evènement' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="text-truncate align-middle"
        style="max-width: 140px;">
      {{element?.dernier_evenement?.libelle | titlecase}}
    </td>
  </ng-container>
  <ng-container matColumnDef="instruction">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Instruction' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="align-middle">
      {{element?.instruction | titlecase}}
    </td>
  </ng-container>
  <ng-container matColumnDef="options">
    <th mat-header-cell *matHeaderCellDef></th>
    <td class="text-center align-middle" mat-cell *matCellDef="let element">
      <button *ngIf="showViewBtn"
              ngbTooltip="{{'Visualisation du litige' | translate }}"
              placement="left"
              container="body"
              class="button btn btn-rounded-arrow btn-sm btn-agediss"
              [routerLink]="['/','litiges',element.id]">
        <i class="fa fa-eye"></i>
      </button>
      <button *ngIf="showInfosBtn"
              ngbTooltip="{{'Informations complémentaires' | translate }}"
              placement="left"
              container="body"
              class="button btn  btn-sm btn-agediss ml-1"
              (click)="expandedElement = expandedElement === element ? null : element">
        <i class="fas fa-angle-double-down"></i>
      </button>
    </td>
  </ng-container>


  <ng-container matColumnDef="noData">
    <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length"
        innerHTML="{{'Aucun litige' | translate }}"></td>
  </ng-container>
  <ng-container matColumnDef="loading">
    <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length"
        innerHTML="{{'Chargement en cours' | translate }} <i class='fas fa-spin fa-circle-notch'></i>"></td>
  </ng-container>

  <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
  <ng-container matColumnDef="expandedDetail">
    <td class="table-td p-0" mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">

      <div class="table-detail"
           *ngIf="this.innerWidth < 400"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description">
          <strong>{{'Commande' | translate }} : </strong><span>{{element?.numero_commande}}</span>
        </div>
      </div>
      <div class="table-detail"
           *ngIf="(this.innerWidth < 820 && this.innerWidth > 767) || this.innerWidth < 600"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description">
          <strong>{{'Destinataire' | translate }}
            : </strong><span>{{element?.destinataire_nom | titlecase}}</span>
        </div>
      </div>
      <div class="table-detail"
           *ngIf="this.innerWidth < 1230"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description">
          <strong>{{'Plateforme' | translate }}
            : </strong><span>{{element?.plateforme?.libelle | titlecase}}</span>
        </div>
      </div>
      <div class="table-detail"
           *ngIf="this.innerWidth < 1051"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description">
          <strong>{{'Date' | translate }} : </strong><span>{{element?.date_litige  | date:'dd-MM-yyyy'}}</span>
        </div>
      </div>
      <div class="table-detail"
           *ngIf="this.innerWidth < 965"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description">
          <strong>{{'Statut' | translate }} : </strong>
          <span>
            <ng-template #statutLitige>
              <div class="p-0">
                <span *ngIf="element?.statut?.code === 'LIT_1'">{{'Litige à traiter par Agediss' | translate }}</span>
                <span *ngIf="element?.statut?.code === 'LIT_3'">{{'Le litige sera pris en charge par Agediss' | translate }}</span>
                <span *ngIf="element?.statut?.code === 'LIT_5'">{{'Litige pris en charge par Agediss' | translate }}</span>
                <span *ngIf="element?.statut?.code === 'LIT_0'">{{'Litige à traiter par Agediss' | translate }}</span>
                <span *ngIf="element?.statut?.code === 'LIT_6'">{{'Litige en attente d\'aprobation par Agediss' | translate }}</span>
                <span *ngIf="element?.statut?.code === 'LIT_2'">{{'Litige en cours d\'analyse par Agediss' | translate }}</span>
              </div>
              <div class="p-0 mt-2">
                <span *ngIf="element?.statut?.code === 'LIT_1'">{{ element.commentaire_statut }}</span>
              </div>
            </ng-template>
            <div placement="top-left"
                 container="body"
                 [ngbPopover]="statutLitige" triggers="mouseenter:mouseleave"class="label {{ element?.statut.libelle | statutLitigePipe: element?.statut.code }}">
              {{element?.statut?.libelle }}
            </div>
          </span>
        </div>
      </div>
    </td>
  </ng-container>

  <tr mat-header-row style="height: 15px;" *matHeaderRowDef="displayedColumns;  sticky: true"></tr>
  <tr mat-row *matRowDef="let element; columns: displayedColumns;" [hidden]="loading"
      class="table-row overflow-scroll"
      [class.expanded-row]="expandedElement === element">
  </tr>
  <tr mat-footer-row *matFooterRowDef="['noData']" [hidden]="dataSource && dataSource.data.length > 0 || loading"></tr>
  <tr mat-footer-row *matFooterRowDef="['loading']" [hidden]="!loading"></tr>
  <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="table-detail-row"></tr>
</table>

<mat-paginator [length]="length"  [pageIndex]="pageIndex" (page)="emit($event)" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
               showFirstLastButtons></mat-paginator>
