<div *ngIf="loadingPage" class="spinner"></div>
<div *ngIf="!loadingPage">
  <div class="row m-0 padding-tab">
    <div class="col-sm-12 py-4 m-0">
      <panel variants="danger" noButton="true" bodyClass="no-padding panel">
        <div class="row" header>
          <div class="col-12"><h5 class="p-t-5 text-agediss">{{'Contestation' | translate}} : <span *ngIf="!loadingNbContestations" >{{nbData}}</span> <i *ngIf="loadingNbContestations" class='fas fa-spin fa-circle-notch'></i></h5></div>
        </div>
        <div>
          <app-table-contestation-litige [data]="contestationData"
                                         [showInfosBtn]="true"
                                         [length]="nbData" [pageSize]="pageSize"
                                         (paginatorEvent)="onLoadDataByEvent($event)" [pageSizeOptions]="pageSizeOption"
                                         [showViewBtn]="view.hasDroit('LITIGE_CONTESTATION_SHOW')"
                                         [showAddButton]="(['LIT_1', 'LIT_0', 'LIT_5'].indexOf(this.litigeStatusCode) >= 0) && view.hasDroit('LITIGE_CONTESTATION_CREATE')"
                                         [loading]="loadingTableContestations"
                                         (onAddContestation)="openModalAddContestation(modal_add_contestation)">
          </app-table-contestation-litige>
        </div>
      </panel>
    </div>
  </div>
</div>


<!--modal add contestation-->
<ng-template #modal_add_contestation let-modal>
  <app-add-contestation [litigeId]="litigeId" (submited)="modal.close($event)"
                        (canceled)="modal.close($event)"></app-add-contestation>
</ng-template>
<!-- fin de modal add contestation-->
