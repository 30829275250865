<div *ngIf="loadingPage" class="spinner"></div>
<div *ngIf="!loadingPage">
  <div class="row m-0 padding-tab">
    <div class="col-sm-12 py-4 m-0">
      <panel variants="danger" noButton="true" bodyClass="no-padding panel">
        <div class="row" header>
          <div class="col-12"><h5 class="p-t-5 text-agediss">{{'Conversation' | translate}} : <span *ngIf="!loadingNbConversation" >{{nbData}}</span> <i *ngIf="loadingNbConversation" class='fas fa-spin fa-circle-notch'></i></h5></div>
        </div>
        <div>
          <app-table-conversation [data]="conversationsData"
                                  [length]="nbData" [pageSize]="pageSize"
                                  (paginatorEvent)="onLoadDataByEvent($event)" [pageSizeOptions]="pageSizeOption"
                                  [loading]="loadingTableConversation"
                                  [showAddConversationBtn]="view.hasDroit('LITIGE_CONVERSATION_CREATE')"
                                  [showCommentsBtn]="view.hasDroit('LITIGE_CONVERSATION_SHOW')"
                                  [showInfosBtn]="true"
                                  [loadingAddConversationBtn]="loadingAddConversation"
                                  (onAdd)="openModalAddConversation()"
                                  (selectConversation)="openModalConversation(modal_conversation, $event)">
          </app-table-conversation>
        </div>
      </panel>
    </div>
  </div>
</div>



<!--modal conversation-->
<ng-template #modal_conversation let-modal>
    <app-show-conversation [conversationId]="selectConversation" (canceled)="modal.close($event)"></app-show-conversation>
</ng-template>
<!-- fin de modal conversation-->
