import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Contact} from '../../models/api/contact.model';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  urlRessource = environment.url_api_espace_client + '/contacts';

  constructor(private http: HttpClient) {
  }

  getContactPrivilegie(): Observable<Contact[]> {
    return this.http
      .get<Contact[]>(`${this.urlRessource}`)
      .pipe(map((contacts) =>contacts.map((contact) => {
        return new Contact().deserialize(contact);
      })));
  }

}
