<div *ngIf="loadingPage || !imageGed" style="height: 500px" class="modal-body">
    <div class="spinner"></div>
    <span class="loading-message">{{"Chargement en cours cela peut prendre jusqu\'a une minute en fonction de votre connexion internet"| translate}}</span>
</div>
<div *ngIf="!loadingPage && imageGed">
    <div class="modal-header">
        <h4 class="modal-title w-100 text-center">{{"Documents & photos" | translate }}</h4>
    </div>
    <div class="modal-body">
        <div *ngIf="loading" style="height: 500px" class="row col-12">
            <span class="spinner"></span>
        </div>

        <!-- Image -->

        <div *ngIf="!loading && documents">
            <div *ngIf="imageGed.length > 1">
                <carousel
                        [pauseOnFocus]="true"
                        [noWrap]="false"
                        interval="12000">
                    <div *ngFor="let image of imageGed; let index=index">
                        <div class="picsum-img-wrapper">
                            <slide>
                                <div class="col-12 embed-responsive embed-responsive-1by1"
                                     *ngIf="image.extension == 'pdf'">
                                    <object [data]="'data:application/pdf;base64,' +image.file_content | safe"></object>
                                </div>
                                <div class="row" *ngIf="image.extension != 'pdf'">
                                    <div class="offset-1 col-10 offset-lg-4 col-lg-4 mb-2">
                                        <a class="btn btn-radius btn-agediss btn-block text-truncate shadow"
                                           [href]="'data:image/png;base64,'+ image.file_content"
                                           [download]="'file.' + image.extension">{{'Téléchargement' | translate }}</a>
                                    </div>
                                    <div class="col-12">
                                        <img class="img-fluid"
                                             *ngIf="image.extension == 'jpg' || image.extension == 'tif' || image.extension == 'png'"
                                             [src]="'data:image/png;base64,'+ image.file_content"
                                             style="cursor: pointer;" alt="">
                                        <img class="img-fluid"
                                             *ngIf="image.extension == 'gif'"
                                             [src]="'data:image/gif;base64,'+ image.file_content"
                                             style="cursor: pointer;" alt="">
                                    </div>
                                </div>
                            </slide>
                        </div>
                    </div>
                </carousel>
            </div>
            <div *ngIf="imageGed.length == 1">
                <div *ngFor="let image of imageGed">
                    <div class="picsum-img-wrapper">
                        <div class="col-12 embed-responsive embed-responsive-1by1" *ngIf="image.extension == 'pdf'">
                            <object [data]="'data:application/pdf;base64,' +image.file_content | safe"></object>
                        </div>
                        <div class="row" *ngIf="image.extension != 'pdf'">
                            <div class="offset-1 col-10 offset-lg-4 col-lg-4 mb-2">
                                <a class="btn btn-radius btn-agediss btn-block text-truncate shadow"
                                   [href]="'data:image/png;base64,'+ image.file_content"
                                   [download]="'file.' + image.extension">{{'Téléchargement' | translate }}</a>
                            </div>
                            <div class="col-12">
                                <img class="img-fluid"
                                     *ngIf="image.extension == 'jpg' || image.extension == 'tif' || image.extension == 'png'"
                                     [src]="'data:image/png;base64,'+ image.file_content" style="cursor: pointer;"
                                     alt="">
                                <img class="img-fluid"
                                     *ngIf="image.extension == 'gif'"
                                     [src]="'data:image/gif;base64,'+ image.file_content" style="cursor: pointer;"
                                     alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Doc -->

        <div *ngIf="!loading && doc">
            <div *ngIf="piecesJointesGed.length > 1">
                <carousel
                        [pauseOnFocus]="true"
                        interval="12000">
                    <div *ngFor="let doc of piecesJointesGed">
                        <div *ngFor="let image of doc.images; let index=index">
                            <div class="picsum-img-wrapper">
                                <slide>
                                    <div class="col-12 embed-responsive embed-responsive-1by1"
                                         *ngIf="image.extension == 'pdf'">
                                        <object [data]="'data:application/pdf;base64,' +image.file_content | safe"></object>
                                    </div>
                                    <div class="row" *ngIf="image.extension != 'pdf'">
                                        <div class="offset-1 col-10 offset-lg-4 col-lg-4 mb-2">
                                            <a class="btn btn-radius btn-agediss btn-block text-truncate shadow"
                                               [href]="'data:image/png;base64,'+ image.file_content"
                                               [download]="'file.' + image.extension">{{'Téléchargement' | translate }}</a>
                                        </div>
                                        <div class="col-12">
                                            <img class="img-fluid"
                                                 *ngIf="image.extension == 'jpg' || image.extension == 'tif' || image.extension == 'png'"
                                                 [src]="'data:image/png;base64,'+ image.file_content"
                                                 style="cursor: pointer;" alt="">
                                            <img class="img-fluid"
                                                 *ngIf="image.extension == 'gif'"
                                                 [src]="'data:image/gif;base64,'+ image.file_content"
                                                 style="cursor: pointer;" alt="">
                                        </div>
                                    </div>
                                </slide>
                            </div>
                        </div>
                    </div>
                </carousel>
            </div>
            <div *ngIf="piecesJointesGed.length == 1">
                <div *ngFor="let doc of piecesJointesGed">
                    <div *ngFor="let image of doc.images">
                        <div class="picsum-img-wrapper">
                            <div class="col-12 embed-responsive embed-responsive-1by1" *ngIf="image.extension == 'pdf'">
                                <object [data]="'data:application/pdf;base64,' +image.file_content | safe"></object>
                            </div>
                            <div class="row" *ngIf="image.extension != 'pdf'">
                                <div class="offset-1 col-10 offset-lg-4 col-lg-4 mb-2">
                                    <a class="btn btn-radius btn-agediss btn-block text-truncate shadow"
                                       [href]="'data:image/png;base64,'+ image.file_content"
                                       [download]="'file.' + image.extension">{{'Téléchargement' | translate }}</a>
                                </div>
                                <div class="col-12" *ngIf="image.extension != 'pdf'">
                                    <img class="img-fluid"
                                         *ngIf="image.extension == 'jpg' || image.extension == 'tif' || image.extension == 'png'"
                                         [src]="'data:image/png;base64,'+ image.file_content" style="cursor: pointer;"
                                         alt="">
                                    <img class="img-fluid"
                                         *ngIf="image.extension == 'gif'"
                                         [src]="'data:image/gif;base64,'+ image.file_content" style="cursor: pointer;"
                                         alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="imageGed.length == 1 && isVideo">

                <div *ngFor="let image of imageGed">
                    <video class="w-100" controls [src]="'data:video/mp4;base64,' + image.file_content"></video>
                </div>

        </div>
    </div>
    <div class="modal-footer p-5">
        <button type="button" class="btn  bg-black-lighter text-white"
                (click)="cancel()">{{'Fermer' |translate}}</button>
    </div>
</div>
