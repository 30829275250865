import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import 'rxjs-compat/add/operator/do';

@Injectable()
export class HttpDateInterceptor implements HttpInterceptor {

  constructor() {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const reqCloned = HttpDateInterceptor.handleBodyIn(request);
    return next.handle(reqCloned);
  }

  static handleBodyIn(req: HttpRequest<any>) {
    if (req.method.toLowerCase() === 'post' || req.method.toLowerCase() === 'put') {
      for (let val in req.body) {
        if (typeof req.body[val] !== 'object' || req.body[val] === null) {
          continue;
        }
        if(req.body[val]['year'] && req.body[val]['month'] && req.body[val]['day']){
          req.body[val] = new Date(Date.UTC(req.body[val].year, req.body[val].month -1 , req.body[val].day , 0, 0, 0));
        }
        req = req.clone({
          body: {...req.body}
        })
      }
    }

    if (req.method.toLowerCase() === 'get') {
      for (let val in req.params) {
        if (typeof req.params[val] !== 'object' || req.params[val] === null) {
          continue;
        }
        if(req.params[val]['year'] && req.params[val]['month'] && req.params[val]['day']){
          req.params[val] = new Date(Date.UTC(req.params[val].year, req.params[val].month -1 , req.params[val].day , 0, 0, 0));
        }
        req = req.clone({
          body: {...req.params}
        })
      }
    }
    return req;
  }
}
