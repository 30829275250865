<form [formGroup]="filterForm" (ngSubmit)="onSubmit()">
  <div class="row">
    <div *ngIf="!hiddenInput['sous-compte']" class="col-md-12 col-lg-12 col-xl-6 m-b-15">
      <div class="col-12 p-0">
        <label class="d-block d-lg-none">{{'Sous-compte' | translate }}</label>
      </div>
      <div class="input-group-prepend">
        <span class="input-group-text input-group-border-right d-none d-lg-flex align-items-center">
          {{'Sous-compte' | translate }}
        </span>
        <ng-select class="ng-select filter"
                   [items]="clients"
                   [selectOnTab]="true"
                   [multiple]="true"
                   bindValue="code"
                   bindLabel="libelle"
                   [loading]="loadClient"
                   [clearable]="true"
                   labelForId="client"
                   placeholder="{{'Choisir' | translate }}"
                   formControlName="client">
        </ng-select>
      </div>
    </div>
    <div *ngIf="!hiddenInput['extraction'] " class="col-md-12 col-lg-12 col-xl-6 m-b-15">
      <div class="col-12 p-0">
        <label class="d-block d-lg-none">{{'Type d\'extraction' | translate }}</label>
      </div>
      <div class="input-group-prepend">
        <span class="input-group-text input-group-border-right d-none d-lg-flex align-items-center">
          {{'Type d\'extraction' | translate }}
        </span>
        <ng-select class="ng-select filter"
                   [items]="extractions"
                   [selectOnTab]="true"
                   [multiple]="false"
                   bindValue="code"
                   bindLabel="libelle"
                   [loading]="loadExtraction"
                   [clearable]="true"
                   labelForId="extraction"
                   placeholder="{{'Choisir' | translate }}"
                   formControlName="extraction">
        </ng-select>
      </div>
    </div>
  </div>
  <div class="row">
    <div *ngIf="!hiddenInput['Pays']" class="col-md-6 col-lg-6 col-xl-6 m-b-15">
      <div class="col-12 p-0">
        <label class="d-block d-lg-none">{{'Pays' | translate }}</label>
      </div>
      <div class="input-group-prepend">
        <span class="input-group-text input-group-border-right d-none d-lg-flex align-items-center">
          {{'Pays' | translate }}
        </span>
        <ng-select class="ng-select filter"
                   [items]="pays"
                   [selectOnTab]="true"
                   [multiple]="true"
                   bindValue="code"
                   bindLabel="libelle"
                   [loading]="loadPays"
                   [clearable]="true"
                   labelForId="client"
                   placeholder="{{'Choisir' | translate }}"
                   formControlName="pays">
        </ng-select>
      </div>
    </div>
    <div *ngIf="!hiddenInput['Activite']" class="col-md-6 col-lg-6 m-b-15">
      <div class="col-12 p-0">
        <label class="d-block d-lg-none">{{'Activité' | translate }}</label>
      </div>
      <div class="input-group-prepend">
        <span class="input-group-text input-group-border-right d-none d-lg-flex align-items-center">
          {{'Activité' | translate }}
        </span>
        <ng-select class="ng-select filter"
                   [items]="activites"
                   [selectOnTab]="true"
                   [groupBy]="type"
                   [multiple]="true"
                   bindValue="code_activite"
                   bindLabel="destination"
                   [clearable]="true"
                   [loading]="loadActivites"
                   labelForId="activites"
                   i18n-placeholder
                   placeholder="{{'Choisir' | translate }}"
                   formControlName="activites">
          <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
            <div *ngIf="item.destination !== null "><span>{{item.destination}}</span></div>
            <div *ngIf="item.destination == null"><span>{{item.nom_activite}}</span></div>
          </ng-template>
          <ng-template ng-label-tmp let-item="item" let-clear="clear">
            <div *ngIf="item.destination !== null "><span class="ng-value-icon right" (click)="clear(item)">×</span><span class="mx-1">{{item.destination}}</span></div>
            <div *ngIf="item.destination == null"><span class="ng-value-icon right" (click)="clear(item)">×</span><span class="mx-1">{{item.nom_activite}}</span></div>
          </ng-template>
        </ng-select>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-md-12 col-lg-12 col-xl-6">
      <div class="row">
        <div class="col-12">
          <label class="d-block d-lg-none">{{'Saisie' | translate }}</label>
        </div>
        <div class="col-12 col-sm-6 col-lg-7">
          <app-date-picker-min-date-extraction-saisie [filterForm]="filterForm"
                                                      [filterName]="'extractionMinDateSaisieFilter'"
                                                      [date]="minDateSaisie"
                                                      #inputMinDateSaisie></app-date-picker-min-date-extraction-saisie>
        </div>
        <div class="col-12 col-sm-6 col-lg-5">
          <app-date-picker-max-date-extraction-saisie [filterForm]="filterForm"
                                                      [filterName]="'extractionMaxDateSaisieFilter'"
                                                      [date]="maxDateSaisie"
                                                      #inputMaxDateSaisie></app-date-picker-max-date-extraction-saisie>

        </div>
      </div>
    </div>
    <div *ngIf="!hiddenInput['option'] " class="col-md-12 col-lg-12 col-xl-6 m-b-15">
      <div class="col-12 p-0">
        <label class="d-block d-lg-none"
               placement="top"
               container="body"
               [ngbPopover]="legendPopover"
               triggers="mouseenter:mouseleave">
          {{'Option de date' | translate }}
          <i class="fas fa-question-circle cursor-pointer text-agediss ml-1"></i>
        </label>
      </div>
      <div class="input-group-prepend">
        <span placement="top"
              container="body"
              [ngbPopover]="legendPopover"
              triggers="mouseenter:mouseleave"
              class="input-group-text input-group-border-right d-none d-lg-flex align-items-center">
          {{'Option de date' | translate }}
          <i class="fas fa-question-circle cursor-pointer text-agediss ml-1"></i>
        </span>
        <ng-select class="ng-select filter"
                   [items]="optionDates"
                   (change)="showDateSaisie()"
                   bindLabel="name"
                   bindValue="code"
                   [clearable]="true"
                   [(ngModel)]="selectedOptionCode"
                   placeholder="{{'Choisir' | translate }}"
                   formControlName="optionDates">
          <ng-template ng-label-tmp let-item="item">
            {{item.name}}
          </ng-template>
        </ng-select>
      </div>
    </div>
  </div>
  <div class="row" style="border-top: 2px solid #e40134" *ngIf="showDateRdv">
    <div class="col-md-12 col-lg-12 col-xl-6 pt-sm-0 pt-xl-3">
      <div class="row">
        <div class="col-12">
          <label class="d-block d-lg-none">{{'Date de rdv' | translate }}</label>
        </div>
        <div class="col-12 col-sm-6 col-lg-7">
          <app-date-picker-min-date-extraction-rdv [filterForm]="filterForm"
                                                   [filterName]="'extractionMinDateRdvFilter'" [date]="minDateRdv"
                                                   #inputMinDateRdv></app-date-picker-min-date-extraction-rdv>
        </div>
        <div class="col-12 col-sm-6 col-lg-5">
          <app-date-picker-max-date-extraction-rdv [filterForm]="filterForm"
                                                   [filterName]="'extractionMaxDateRdvFilter'" [date]="maxDateRdv"
                                                   #inputMaxDateRdv></app-date-picker-max-date-extraction-rdv>
        </div>
      </div>
    </div>
  </div>
  <div class="row" style="border-top: 2px solid #e40134" *ngIf="showDateLivraison">
    <div class="col-md-12 col-lg-12 col-xl-6 pt-sm-0 pt-xl-3">
      <div class="row">
        <div class="col-12">
          <label class="d-block d-lg-none">{{'Date de livraison' | translate }}</label>
        </div>
        <div class="col-12 col-sm-6 col-lg-7">
          <app-date-picker-min-date-extraction-livraison [filterForm]="filterForm"
                                                         [filterName]="'extractionMinDateLivraisonFilter'"
                                                         [date]="minDateLivraison"
                                                         #inputMinDateLivraison></app-date-picker-min-date-extraction-livraison>
        </div>
        <div class="col-12 col-sm-6 col-lg-5">
          <app-date-picker-max-date-extraction-livraison [filterForm]="filterForm"
                                                         [filterName]="'extractionMaxDateLivraisonFilterExtraction'"
                                                         [date]="maxDateLivraison"
                                                         #inputMaxDateLivraison></app-date-picker-max-date-extraction-livraison>
        </div>
      </div>
    </div>
  </div>
  <div class="form-group m-b-0 row m-t-10">
    <div class="offset-sm-5 col-sm-2 offset-4 col-4 px-0">
      <div class="input-group ">
        <input class="form-control btn btn-radius btn-agediss font-weight-bold shadow" type="submit"
               value="{{'Filtrer' | translate }}">
        <div class="input-group-append pl-1">
          <button class="btn btn-radius btn-agediss btn-block shadow" type="button"
                  ngbTooltip="{{'Effacer les infos sélectionnées' | translate }}"
                  placement="right"
                  container="body"
                  (click)="reset()"><i class='fa fa-times'></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</form>

<ng-template #legendPopover>
  <div class="p-0">
    <p>{{'Vous pouvez saisir une période date de rdv ou date de livraison en fonction de la période date de saisie sélectionnée !' | translate }}</p>
  </div>
</ng-template>
